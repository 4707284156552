import SendDataHandlerInterface
  from '@/pages/ScoringFormPage/lib/Contract/Handler/SendDataHandlerInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import { StatusCodeEnum } from '@/shared/Validator';
import IState from '@/pages/Service/PriceList/PriceListFormPage/Model/IState';
import IHandlerService from '@/pages/Service/PriceList/PriceListFormPage/Contract/IHandlerService';
import IPriceListRequestModel from '@/shared/Api/Model/Service/PriceListApi/IPriceListRequestModel';
import EPriceListVariant from '@/pages/Service/PriceList/PriceListFormPage/Enum/EPriceListVariant';

class SendDataHandler implements SendDataHandlerInterface {
  private state: IState;

  private readonly serviceContainer: IServiceContainer;

  private handlerService: IHandlerService;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
  }

  handle = async (): Promise<void> => {
    this.handlerService.validationHandler.validateFrom(this.state.formData);

    const isFormValid = Object
      .values(this.state.validationStatusRegistry)
      .filter((status) => status.code === StatusCodeEnum.ERROR).length === 0;

    if (!isFormValid) {
      return Promise.reject();
    }

    this.state.isLoading = true;

    const data = this.getData();

    try {
      await this.serviceContainer.apiService.priceListApi.create(data);
    } catch (e) {
      this.state.isLoading = false;

      return Promise.reject(e);
    }

    this.state.isLoading = false;

    return Promise.resolve();
  };

  private getData = (): IPriceListRequestModel => {
    const formData = this.serviceContainer.utilsService.data.cloneImmutable(this.state.formData);
    const data: IPriceListRequestModel = {
      comment: formData.comment,
    };

    const linkVariant = formData.variant.find((item) => item.id === EPriceListVariant.LINK);

    if (linkVariant && linkVariant.checked) {
      data.url = formData.link;
    } else {
      data.documents = this.state.documentFiles ?? [];
    }

    return data;
  };
}

export default SendDataHandler;
