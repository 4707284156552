enum ChipsEnum {
  BORDER_WIDTH = '--chips-border-width',
  L_BORDER_RADIUS_TOP_RIGHT = '--chips-l-border-radius-top-right',
  L_BORDER_RADIUS_TOP_LEFT = '--chips-l-border-radius-top-left',
  L_BORDER_RADIUS_BOTTOM_LEFT = '--chips-l-border-radius-bottom-left',
  L_BORDER_RADIUS_BOTTOM_RIGHT = '--chips-l-border-radius-bottom-right',
  M_BORDER_RADIUS_TOP_RIGHT = '--chips-m-border-radius-top-right',
  M_BORDER_RADIUS_TOP_LEFT = '--chips-m-border-radius-top-left',
  M_BORDER_RADIUS_BOTTOM_LEFT = '--chips-m-border-radius-bottom-left',
  M_BORDER_RADIUS_BOTTOM_RIGHT = '--chips-m-border-radius-bottom-right',
  S_BORDER_RADIUS_TOP_RIGHT = '--chips-s-border-radius-top-right',
  S_BORDER_RADIUS_TOP_LEFT = '--chips-s-border-radius-top-left',
  S_BORDER_RADIUS_BOTTOM_LEFT = '--chips-s-border-radius-bottom-left',
  S_BORDER_RADIUS_BOTTOM_RIGHT = '--chips-s-border-radius-bottom-right',
  XS_BORDER_RADIUS_TOP_RIGHT = '--chips-xs-border-radius-top-right',
  XS_BORDER_RADIUS_TOP_LEFT = '--chips-xs-border-radius-top-left',
  XS_BORDER_RADIUS_BOTTOM_LEFT = '--chips-xs-border-radius-bottom-left',
  XS_BORDER_RADIUS_BOTTOM_RIGHT = '--chips-xs-border-radius-bottom-right',
  L_GAP = '--chips-l-gap',
  M_GAP = '--chips-m-gap',
  S_GAP = '--chips-s-gap',
  XS_GAP = '--chips-xs-gap',
  L_PADDING_TOP = '--chips-l-padding-top',
  L_PADDING_RIGHT = '--chips-l-padding-right',
  L_PADDING_BOTTOM = '--chips-l-padding-bottom',
  L_PADDING_LEFT = '--chips-l-padding-left',
  M_PADDING_TOP = '--chips-m-padding-top',
  M_PADDING_RIGHT = '--chips-m-padding-right',
  M_PADDING_BOTTOM = '--chips-m-padding-bottom',
  M_PADDING_LEFT = '--chips-m-padding-left',
  S_PADDING_TOP = '--chips-s-padding-top',
  S_PADDING_RIGHT = '--chips-s-padding-right',
  S_PADDING_BOTTOM = '--chips-s-padding-bottom',
  S_PADDING_LEFT = '--chips-s-padding-left',
  XS_PADDING_TOP = '--chips-xs-padding-top',
  XS_PADDING_RIGHT = '--chips-xs-padding-right',
  XS_PADDING_BOTTOM = '--chips-xs-padding-bottom',
  XS_PADDING_LEFT = '--chips-xs-padding-left',
}

export default ChipsEnum;
