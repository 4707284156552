import IAboutApi from '@/shared/Api/Contract/Api/IAboutApi';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import IAboutRequestModel from '@/shared/Api/Model/Service/AboutApi/IAboutRequestModel';

class AboutApi implements IAboutApi {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  create(data: IAboutRequestModel): Promise<void> {
    return this.clientService.instance.post('/service/company', data);
  }
}

export default AboutApi;
