import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dbe052b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  fill: "none",
  height: "100%",
  viewBox: "0 0 24 24",
  width: "100%",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M15.3437 12.0003L8.22167 4.87826C8.01533 4.67192 7.89941 4.39206 7.89941 4.10026C7.89941 3.95577 7.92787 3.8127 7.98317 3.67921C8.03846 3.54572 8.1195 3.42443 8.22167 3.32226C8.32384 3.22009 8.44513 3.13905 8.57862 3.08375C8.71211 3.02846 8.85518 3 8.99967 3C9.29148 3 9.57133 3.11592 9.77767 3.32226L17.6777 11.2223C17.7799 11.3244 17.8609 11.4457 17.9163 11.5792C17.9716 11.7127 18 11.8558 18 12.0003C18 12.1448 17.9716 12.2878 17.9163 12.4213C17.8609 12.5548 17.7799 12.6761 17.6777 12.7783L9.77767 20.6783C9.57133 20.8846 9.29148 21.0005 8.99967 21.0005C8.70787 21.0005 8.42801 20.8846 8.22167 20.6783C8.01533 20.4719 7.89941 20.1921 7.89941 19.9003C7.89941 19.6084 8.01533 19.3286 8.22167 19.1223L15.3437 12.0003Z"
    }, null, 2)
  ]))
}