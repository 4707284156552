import IObjectTool from '@/app/Service/UtilsService/Contract/IObjectTool';
import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';

class ObjectTool implements IObjectTool {
  private readonly utilsService: IUtilsService;

  constructor(utils: IUtilsService) {
    this.utilsService = utils;
  }

  isEmpty = (object: object): boolean => Object.keys(object).length === 0;

  mapObjectSnakeKeysToCamelCaseRecursive = <T, R>(object: T | object): R | object => {
    if (!object) {
      return object as unknown as R | object;
    }

    if (typeof object === 'object') {
      if (Array.isArray(object)) {
        return object.map((arrayElement) => (typeof arrayElement === 'object'
          ? this.mapObjectSnakeKeysToCamelCaseRecursive(arrayElement)
          : arrayElement));
      }

      if ((object as object).constructor.name !== 'Object') {
        throw new Error('Instances of classes can`t be mapped.');
      }
    }

    return Object.entries(object).reduce((changed, pair) => {
      const [key, value] = pair;
      const changedKey = this.utilsService.string.snakeToCamelCase(key);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      changed[changedKey] = typeof value === 'object' ? this.mapObjectSnakeKeysToCamelCaseRecursive(value) : value;

      return changed;
    }, {} as R | object);
  };

  // TODO: стоит вынести в отдельную утилиту чтения строк-шаблонов
  readProp = <Value, Data extends object>(object: Data, pathStr: string): Value => {
    let result = object;

    if (pathStr.length > 0) {
      const parts = pathStr.split('.');

      parts.forEach((part) => {
        if (this.isFind(part) && this.utilsService.typeCheck.isArray(result)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          result = this.findInArray(result, part);
        } else if (this.utilsService.typeCheck.isObject(result) || this.utilsService.typeCheck.isArray(result)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          result = result[part];
        }
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return result;
  };

  // TODO: стоит вынести в отдельную утилиту чтения строк-шаблонов
  private isFind(part: string): boolean {
    return /\(find:(.*)\)/.test(part);
  }

  // TODO: стоит вынести в отдельную утилиту чтения строк-шаблонов
  private findInArray(data: Array<unknown>, part: string): unknown {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const paramsString = /\(find:(.*)\)/.exec(part)[1] ?? '';
    const [key, value] = paramsString.split('=');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data.find((item) => item[key] === value);
  }
}

export default ObjectTool;
