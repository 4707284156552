import moment from 'moment/moment';

import {
  SChipsItemInterface,
} from '@/shared/DesignSystem';

import {
  StatusCodeEnum,
} from '@/shared/Validator';

import SendDataHandlerInterface from '@/pages/ScoringFormPage/lib/Contract/Handler/SendDataHandlerInterface';
import StateModelInterface from '@/pages/ScoringFormPage/lib/Model/StateModelInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import ScoringRequestModelInterface from '@/shared/Api/Model/Scoring/ScoringRequestModelInterface';
import ScoringFormPageServiceInterface from '@/pages/ScoringFormPage/lib/Contract/Service/ScoringFormPageServiceInterface';
import ScoringSupplyContractRequestModelInterface from '@/shared/Api/Model/Scoring/ScoringSupplyContractRequestModelInterface';
import FormDataInterface from '@/pages/ScoringFormPage/lib/Model/FormDataInterface';

class SendDataHandler implements SendDataHandlerInterface {
  private state: StateModelInterface;

  private readonly serviceContainer: IServiceContainer;

  private scoringFormPageService: ScoringFormPageServiceInterface;

  constructor(
    state: StateModelInterface,
    serviceContainer: IServiceContainer,
    scoringFormPageService: ScoringFormPageServiceInterface,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.scoringFormPageService = scoringFormPageService;
  }

  handle = async (): Promise<void> => {
    this.scoringFormPageService.handlerService.validationHandler.validateFrom(this.state.formData);

    const isFormValid = Object
      .values(this.state.validationStatusRegistry)
      .filter((status) => status.code === StatusCodeEnum.ERROR).length === 0;

    if (!isFormValid || !this.state.isPrivacyPolicyChecked) {
      return Promise.reject();
    }

    this.state.isLoading = true;

    const data = this.getData();

    try {
      await this.serviceContainer.apiService.scoringApi.create(data);
    } catch (e) {
      this.state.isLoading = false;

      return Promise.reject(e);
    }

    this.state.isLoading = false;

    return Promise.resolve();
  };

  private getData = (): ScoringRequestModelInterface | ScoringSupplyContractRequestModelInterface => {
    const formData = this.serviceContainer.utilsService.data.cloneImmutable(this.state.formData);
    const hasContractWithSoftline = this.getSingleValue(formData.hasContractWithSoftline);

    if (hasContractWithSoftline === 'supply_contract') {
      return this.getDataSupplyContract(formData);
    }

    const data: ScoringRequestModelInterface = {
      hasContractWithSoftline: this.getSingleValue(formData.hasContractWithSoftline),
      acceptPrivacyPolicy: true,
      annualTurnover: this.getSingleValue(formData.annualTurnover),
      companyName: formData.companyName,
      customerInteraction: this.getMultipleValues(formData.customerInteraction),
      exclusiveContractWithCompany: this.getBooleanValue(formData.exclusiveContractWithCompany),
      expectedAnnualTurnover: this.getSingleValue(formData.expectedAnnualTurnover),
      hasCopyrightDocuments: this.getBooleanValue(formData.hasCopyrightDocuments),
      hasPartnerProgram: this.getBooleanValue(formData.hasPartnerProgram),
      inn: formData.inn,
      interestedServices: this.getMultipleValues(formData.interestedServices),
      marketingBudget: this.getSingleValue(formData.marketingBudget),
      numberOfEmployees: this.getSingleValue(formData.numberOfEmployees),
      productType: this.getMultipleValues(formData.productType),
      targetAudience: this.getMultipleValues(formData.targetAudience),
      website: formData.website,
      workDirection: this.getMultipleValues(formData.workDirection),
    };

    if (data.hasContractWithSoftline === 'no_contract') {
      data.collaborationPreference = this.getBooleanValue(formData.collaborationPreference);

      if (data.collaborationPreference) {
        data.productsOffer = this.getMultipleValues(formData.productsOffer);
      } else {
        data.acceptsContractChanges = this.getBooleanValue(formData.acceptsContractChanges);
        data.documents = this.state.formData.s3Files.documents ?? [];
      }
    }

    return data;
  };

  private getDataSupplyContract = (formData: FormDataInterface): ScoringSupplyContractRequestModelInterface => {
    const data: ScoringSupplyContractRequestModelInterface = {
      acceptPrivacyPolicy: true,
      hasContractWithSoftline: this.getSingleValue(formData.hasContractWithSoftline),
      variantFindCompany: this.getSingleValue(formData.variantFindCompany),
    };

    if (data.variantFindCompany === 'company_details') {
      data.companyName = formData.companyName;
      data.inn = formData.inn;
      data.numberContract = formData.numberContract;
      data.dateContract = moment(formData.dateContract).format('YYYY-MM-DD');
      data.legalEntity = formData.legalEntity;
    } else {
      data.copiesContract = this.state.formData.s3Files.contractCopy ?? [];
    }

    return data;
  };

  private getMultipleValues = (values: Array<SChipsItemInterface>): Array<string> => values
    .filter((value) => value.checked)
    .map((value) => value.id as string);

  private getSingleValue = (values: Array<SChipsItemInterface>): string => {
    const selectedValue = values.find((value) => value.checked);
    if (!selectedValue) {
      return '';
    }

    return selectedValue.id as string;
  };

  private getBooleanValue = (values: Array<SChipsItemInterface>): boolean => {
    const selectedValue = values.find((value) => value.checked);
    if (!selectedValue) {
      return false;
    }

    const positiveAnswers = ['yes', 'softline'];

    return positiveAnswers.includes(selectedValue.id as string);
  };
}

export default SendDataHandler;
