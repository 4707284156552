import {
  computed,
  reactive,
} from 'vue';
import { VueI18nTranslation } from 'vue-i18n';

import { StatusCodeEnum } from '@/shared/Validator';

import HandlerService from '@/pages/Service/Portfolio/PortfolioFormPage/Service/HandlerService';

import IAboutFormPage from '@/pages/Service/Portfolio/PortfolioFormPage/Contract/IAboutFormPage';
import IHandlerService from '@/pages/Service/Portfolio/PortfolioFormPage/Contract/IHandlerService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import IState from '@/pages/Service/Portfolio/PortfolioFormPage/Model/IState';
import IVendorModel from '@/entities/Model/IVendorModel';

class PortfolioFormPage implements IAboutFormPage {
  private readonly serviceContainer: IServiceContainer;

  private _handlerService?: IHandlerService;

  private state?: IState;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get handlerService(): IHandlerService {
    if (!this._handlerService) {
      this._handlerService = new HandlerService(
        this.getState(),
        this.serviceContainer,
      );
    }

    return this._handlerService;
  }

  initState(t: VueI18nTranslation): IState {
    if (!this.state) {
      const {
        store,
      } = this.serviceContainer;

      const vendor = computed<IVendorModel>(() => store.getters.getVendor);

      this.state = reactive({
        localisation: {
          title: t('pages.portfolio.form.title'),
        },
        t,
        formConfig: [],
        formData: {
          publicNameCompany: '',
          keywords: '',
          website: vendor.value.website,
          whoCanBeReplaced: '',
          productDescriptionsFunctions: '',
          listOfRegistries: '',
          listOfCertificationSystems: '',
          directContacts: '',
          implementationCases: [{
            id: 0,
            value: '',
          }],
          comment: '',
          documents: [],
        },
        isLoading: false,
        isSendButtonPressed: false,
        isFormSent: false,
        validationStatusRegistry: {
          publicNameCompany: {
            code: StatusCodeEnum.DEFAULT,
          },
          keywords: {
            code: StatusCodeEnum.DEFAULT,
          },
          website: {
            code: StatusCodeEnum.DEFAULT,
          },
          whoCanBeReplaced: {
            code: StatusCodeEnum.DEFAULT,
          },
          productDescriptionsFunctions: {
            code: StatusCodeEnum.DEFAULT,
          },
          listOfRegistries: {
            code: StatusCodeEnum.DEFAULT,
          },
          listOfCertificationSystems: {
            code: StatusCodeEnum.DEFAULT,
          },
          directContacts: {
            code: StatusCodeEnum.DEFAULT,
          },
          implementationCases: {
            code: StatusCodeEnum.DEFAULT,
          },
          comment: {
            code: StatusCodeEnum.DEFAULT,
          },
          documents: {
            code: StatusCodeEnum.DEFAULT,
          },
        },
        implementationValidationStatusRegistry: [],
        documentFiles: [],
      });
    }

    return this.state;
  }

  getState(): IState {
    if (!this.state) {
      throw new Error('State not initialized. Call initState method first.');
    }

    return this.state;
  }
}

export default PortfolioFormPage;
