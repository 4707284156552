enum FieldTypeEnum {
  CHIPS_GROUP = 'chipsGroup',
  CUSTOM_INFO_BLOCK = 'customInfoBlock',
  DATE_FIELD = 'dateField',
  FILE_UPLOAD = 'fileUpload',
  MASKED_INPUT = 'maskedInput',
  MULTIPLE_SELECT = 'multipleSelect',
  TEXT_AREA = 'textArea',
  TEXT_INPUT = 'textInput',
  TEXT_INPUT_LIST = 'textInputList',
}

export default FieldTypeEnum;
