import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "s-label__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "s-label__label"
    }, [
      (_ctx.isLabel)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "label")
          ]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    _renderSlot(_ctx.$slots, "control", { id: _ctx.id })
  ], 2))
}