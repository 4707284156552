enum CheckboxEnum {
  BORDER_WIDTH = '--checkbox-border-width',
  BORDER_RADIUS_TOP_RIGHT = '--checkbox-border-radius-top-right',
  BORDER_RADIUS_TOP_LEFT = '--checkbox-border-radius-top-left',
  BORDER_RADIUS_BOTTOM_LEFT = '--checkbox-border-radius-bottom-left',
  BORDER_RADIUS_BOTTOM_RIGHT = '--checkbox-border-radius-bottom-right',
  S_WIDTH = '--checkbox-s-width',
  S_HEIGHT = '--checkbox-s-height',
  M_WIDTH = '--checkbox-m-width',
  M_HEIGHT = '--checkbox-m-height',
  L_WIDTH = '--checkbox-l-width',
  L_HEIGHT = '--checkbox-l-height',
}

export default CheckboxEnum;
