import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCLick && _ctx.handleCLick(...args)))
  }, [
    (_ctx.icon && _ctx.iconFirst)
      ? (_openBlock(), _createBlock(_component_SIcon, {
          key: 0,
          color: _ctx.icon.color,
          size: _ctx.icon.size,
          name: _ctx.icon.name,
          class: "s-chips__icon",
          onClick: _withModifiers(_ctx.handleIconCLick, ["stop"])
        }, null, 8, ["color", "size", "name", "onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_STextElement, {
      color: _ctx.textColor,
      variant: _ctx.textVariant
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["color", "variant"]),
    (_ctx.icon && !_ctx.iconFirst)
      ? (_openBlock(), _createBlock(_component_SIcon, {
          key: 1,
          color: _ctx.icon.color,
          size: _ctx.icon.size,
          name: _ctx.icon.name,
          class: "s-chips__icon",
          onClick: _withModifiers(_ctx.handleIconCLick, ["stop"])
        }, null, 8, ["color", "size", "name", "onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}