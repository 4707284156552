import moment from 'moment/moment';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

import {
  StatusCodeEnum,
} from '@/shared/Validator';

import {
  SChipsItemInterface,
} from '@/shared/DesignSystem';

import EApplication from '@/pages/Service/Promo/PromoFormPage/Enum/EApplication';
import ECategoriesParticipants from '@/pages/Service/Promo/PromoFormPage/Enum/ECategoriesParticipants';
import EFormType from '@/pages/Service/Promo/PromoFormPage/Enum/EFormType';

import TPromotionRequestModel from '@/shared/Api/Model/Service/PromotionApi/TPromotionRequestModel';

import IHandlerService from '@/pages/Service/Promo/PromoFormPage/Contract/IHandlerService';
import IProduct from '@/pages/Service/Promo/PromoFormPage/Model/IProduct';
import IPromotionRequestDiscountModel from '@/shared/Api/Model/Service/PromotionApi/IPromotionRequestDiscountModel';
import IPromotionRequestPromoCodesModel from '@/shared/Api/Model/Service/PromotionApi/IPromotionRequestPromoCodesModel';
import IPromotionRequestPromotionModel from '@/shared/Api/Model/Service/PromotionApi/IPromotionRequestPromotionModel';
import IPromotionRequestKitModel from '@/shared/Api/Model/Service/PromotionApi/IPromotionRequestKitModel';
import IPromotionRequestStickerModel from '@/shared/Api/Model/Service/PromotionApi/IPromotionRequestStickerModel';
import IPromotionRequestTextPromotionModel from '@/shared/Api/Model/Service/PromotionApi/IPromotionRequestTextPromotionModel';
import ISendDataHandler from '@/pages/Service/Promo/PromoFormPage/Contract/Handler/ISendDataHandler';
import IState from '@/pages/Service/Promo/PromoFormPage/Model/IState';

class SendDataHandler implements ISendDataHandler {
  private state: IState;

  private readonly serviceContainer: IServiceContainer;

  private handlerService: IHandlerService;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
  }

  handle = async (): Promise<void> => {
    this.handlerService.validationHandler.validateFrom(this.state.formData);

    const isFormValid = Object
      .values(this.state.validationStatusRegistry)
      .filter((status) => status.code === StatusCodeEnum.ERROR).length === 0;

    if (!isFormValid) {
      return Promise.reject();
    }

    this.state.isLoading = true;

    const data = this.getData();

    try {
      await this.serviceContainer.apiService.promotionApi.create(data);
    } catch (e) {
      this.state.isLoading = false;

      return Promise.reject(e);
    }

    this.state.isLoading = false;

    return Promise.resolve();
  };

  private getData = (): TPromotionRequestModel => {
    const formData = this.serviceContainer.utilsService.data.cloneImmutable(this.state.formData);
    const formType: EFormType = formData.formType ?? EFormType.STICKER;

    const activeType = this.getSingleValue(formData.activeType);
    const {
      descriptionForCustomers,
    } = formData;
    const activationDate = moment(formData.activationDate).format('DD-MM-YYYY');
    const activationTime = moment(formData.activationDate).format('HH:mm');
    const expirationDate = moment(formData.expirationDate).format('DD-MM-YYYY');
    const expirationTime = moment(formData.expirationDate).format('HH:mm');
    const products: Array<IProduct> = formData.products.map((product) => {
      const data: IProduct = {
        productName: product.value,
      };

      if (
        product.additionalData?.discount
        && ![EFormType.STICKER, EFormType.TEXT_PROMOTION].includes(formType)
        && this.getSingleValue(formData.application) === EApplication.EVERY_PRODUCTS
      ) {
        data.discount = product.additionalData.discount as string;
      }

      return data;
    });

    let data: TPromotionRequestModel = {
      activeType,
      descriptionForCustomers,
      activationDate,
      activationTime,
      expirationDate,
      expirationTime,
      products,
    };

    if (formType === EFormType.STICKER) {
      const {
        textSticker,
      } = formData;

      data = {
        ...data,
        textSticker,
      } as IPromotionRequestStickerModel;
    }

    if (formType !== EFormType.STICKER) {
      const promotionType = this.getSingleValue(formData.promotionType);
      const {
        promotionName,
        whichCategory,
        textSticker,
        descriptionBuyers,
      } = formData;
      const participantsPromotion = this.getSingleValue(formData.participantsPromotion);
      const buyersParticipantsPromotion = this.getSingleValue(formData.buyersParticipantsPromotion);
      const categoriesParticipants = this.getMultipleValues(formData.categoriesParticipants);
      const banner = this.state.documentFiles ?? [];
      const useSticker = this.getBooleanValue(formData.useSticker);

      data = {
        ...data,
        promotionType,
        promotionName,
        participantsPromotion,
        buyersParticipantsPromotion,
        categoriesParticipants,
        banner,
        useSticker,
      } as IPromotionRequestPromotionModel;

      if (useSticker) {
        data = {
          ...data,
          textSticker,
          descriptionBuyers,
        } as IPromotionRequestPromotionModel;
      }

      if (categoriesParticipants.includes(ECategoriesParticipants.OTHER)) {
        data = {
          ...data,
          whichCategory,
        } as IPromotionRequestPromotionModel;
      }

      if (formType === EFormType.DISCOUNT) {
        const {
          amountDiscount,
        } = formData;
        const variantAmountDiscount = this.getSingleValue(formData.variantAmountDiscount);
        const application = this.getSingleValue(formData.application);
        const discountReflected = this.getBooleanValue(formData.discountReflected);

        data = {
          ...data,
          variantAmountDiscount,
          application,
          discountReflected,
        } as IPromotionRequestDiscountModel;

        if (application === EApplication.ALL_PRODUCTS) {
          data = {
            ...data,
            amountDiscount,
          } as IPromotionRequestDiscountModel;
        }
      }

      if (formType === EFormType.PROMO_CODES) {
        const {
          amountDiscount,
        } = formData;
        const variantAmountDiscount = this.getSingleValue(formData.variantAmountDiscount);
        const application = this.getSingleValue(formData.application);
        const discountReflected = this.getBooleanValue(formData.discountReflected);
        const couponValidity = this.getSingleValue(formData.couponValidity);

        data = {
          ...data,
          variantAmountDiscount,
          application,
          discountReflected,
          couponValidity,
        } as IPromotionRequestPromoCodesModel;

        if (application === EApplication.ALL_PRODUCTS) {
          data = {
            ...data,
            amountDiscount,
          } as IPromotionRequestPromoCodesModel;
        }
      }

      if (formType === EFormType.TEXT_PROMOTION) {
        const {
          mechanicsPromotion,
        } = formData;

        data = {
          ...data,
          mechanicsPromotion,
        } as IPromotionRequestTextPromotionModel;
      }

      if (formType === EFormType.KIT) {
        const {
          amountDiscount,
          articleKit,
        } = formData;
        const variantAmountDiscount = this.getSingleValue(formData.variantAmountDiscount);
        const application = this.getSingleValue(formData.application);
        const discountReflected = this.getBooleanValue(formData.discountReflected);
        const variantArticleKit = this.getSingleValue(formData.variantArticleKit);

        data = {
          ...data,
          variantAmountDiscount,
          application,
          discountReflected,
          variantArticleKit,
          articleKit,
        } as IPromotionRequestKitModel;

        if (application === EApplication.ALL_PRODUCTS) {
          data = {
            ...data,
            amountDiscount,
          } as IPromotionRequestKitModel;
        }
      }
    }

    return data;
  };

  private getMultipleValues = (values: Array<SChipsItemInterface>): Array<string> => values
    .filter((value) => value.checked)
    .map((value) => value.id as string);

  private getSingleValue = (values: Array<SChipsItemInterface>): string => {
    const selectedValue = values.find((value) => value.checked);
    if (!selectedValue) {
      return '';
    }

    return selectedValue.id as string;
  };

  private getBooleanValue = (values: Array<SChipsItemInterface>): boolean => {
    const selectedValue = values.find((value) => value.checked);
    if (!selectedValue) {
      return false;
    }

    const positiveAnswers = ['use', 'include'];

    return positiveAnswers.includes(selectedValue.id as string);
  };
}

export default SendDataHandler;
