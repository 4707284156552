<template>
  <div
    :class="classes"
    @click.stop="handleClick"
  >
    <input
      ref="element"
      :id="id"
      :checked="state.checked"
      class="s-checkbox__input"
      type="checkbox"
    >
    <SIcon
      class="s-checkbox__icon"
      name="checkboxOn"
      :color="color"
      size="s"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';

import ColorEnum from '../../../lib/Enum/ColorEnum';
import SizeEnum from '../../../lib/Enum/SizeEnum';

import SIcon from '../Icon/SIcon.vue';

export default defineComponent({
  name: 'SCheckbox',
  components: {
    SIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<ColorEnum>,
      default: ColorEnum.PRIMARY,
    },
    size: {
      type: String as PropType<SizeEnum>,
      default: SizeEnum.M,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      checked: false,
    });

    const element = ref();

    const classes = computed(() => [
      's-checkbox',
      `s-checkbox_size-${props.size}`,
    ]);

    function handleClick() {
      state.checked = !state.checked;

      emit('update:modelValue', state.checked);
    }

    watch(() => props.modelValue, (newValue) => {
      state.checked = newValue;
    });

    onMounted(() => {
      state.checked = props.modelValue;
    });

    return {
      state,
      element,
      classes,
      handleClick,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-checkbox {
  margin: 0;
  border: var(--checkbox-border-width) solid var(--color-disabled);
  border-radius: var(--checkbox-border-radius-top-right)
    var(--checkbox-border-radius-top-left)
    var(--checkbox-border-radius-bottom-left)
    var(--checkbox-border-radius-bottom-right);
  transition: all linear .2s;
  appearance: none;

  &_size-s {
    width: var(--checkbox-s-width);
    height: var(--checkbox-s-height);
  }

  &_size-m {
    width: var(--checkbox-m-width);
    height: var(--checkbox-m-height);
  }

  &_size-l {
    width: var(--checkbox-l-width);
    height: var(--checkbox-l-height);
  }

  &:hover {
    border: var(--checkbox-border-width) solid var(--color-primary);
  }

  &__input {
    position: absolute;
    z-index: -10;
    overflow: hidden;
    width: 0;
    height: 0;
    appearance: none;

    &:checked + .s-checkbox__icon {
      display: block;
    }
  }

  &__icon {
    display: none;
    margin: -1px;
  }
}
</style>
