import { reactive } from 'vue';
import { VueI18nTranslation } from 'vue-i18n';
import ScoringFormPageServiceInterface
  from '@/pages/ScoringFormPage/lib/Contract/Service/ScoringFormPageServiceInterface';
import HandlerServiceInterface
  from '@/pages/ScoringFormPage/lib/Contract/Service/HandlerServiceInterface';
import HandlerService from '@/pages/ScoringFormPage/lib/Service/HandlerService';
import StateModelInterface from '@/pages/ScoringFormPage/lib/Model/StateModelInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import { StatusCodeEnum } from '@/shared/Validator';

class ScoringFormPageService implements ScoringFormPageServiceInterface {
  private state?: StateModelInterface;

  private readonly serviceContainer: IServiceContainer;

  private _handlerService?: HandlerServiceInterface;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get handlerService(): HandlerServiceInterface {
    if (!this._handlerService) {
      this._handlerService = new HandlerService(
        this.getState(),
        this.serviceContainer,
        this,
      );
    }

    return this._handlerService;
  }

  initState = (t: VueI18nTranslation): StateModelInterface => {
    if (!this.state) {
      this.state = reactive({
        formConfig: [],
        formData: {
          hasContractWithSoftline: [],
          collaborationPreference: [],
          productsOffer: [],
          acceptsContractChanges: [],
          exclusiveContractWithCompany: [],
          documents: [],
          inn: '',
          companyName: '',
          variantFindCompany: [],
          numberContract: '',
          dateContract: new Date(),
          legalEntity: '',
          contractCopy: [],
          website: '',
          annualTurnover: [],
          expectedAnnualTurnover: [],
          numberOfEmployees: [],
          workDirection: [],
          hasCopyrightDocuments: [],
          productType: [],
          customerInteraction: [],
          targetAudience: [],
          interestedServices: [],
          hasPartnerProgram: [],
          marketingBudget: [],
          s3Files: {
            documents: [],
            contractCopy: [],
          },
        },
        t,
        isLoading: false,
        isPrivacyPolicyChecked: false,
        isSendButtonPressed: false,
        validationStatusRegistry: {
          hasContractWithSoftline: {
            code: StatusCodeEnum.DEFAULT,
          },
          collaborationPreference: {
            code: StatusCodeEnum.DEFAULT,
          },
          productsOffer: {
            code: StatusCodeEnum.DEFAULT,
          },
          acceptsContractChanges: {
            code: StatusCodeEnum.DEFAULT,
          },
          exclusiveContractWithCompany: {
            code: StatusCodeEnum.DEFAULT,
          },
          documents: {
            code: StatusCodeEnum.DEFAULT,
          },
          inn: {
            code: StatusCodeEnum.DEFAULT,
          },
          companyName: {
            code: StatusCodeEnum.DEFAULT,
          },
          website: {
            code: StatusCodeEnum.DEFAULT,
          },
          annualTurnover: {
            code: StatusCodeEnum.DEFAULT,
          },
          expectedAnnualTurnover: {
            code: StatusCodeEnum.DEFAULT,
          },
          numberOfEmployees: {
            code: StatusCodeEnum.DEFAULT,
          },
          workDirection: {
            code: StatusCodeEnum.DEFAULT,
          },
          hasCopyrightDocuments: {
            code: StatusCodeEnum.DEFAULT,
          },
          productType: {
            code: StatusCodeEnum.DEFAULT,
          },
          customerInteraction: {
            code: StatusCodeEnum.DEFAULT,
          },
          targetAudience: {
            code: StatusCodeEnum.DEFAULT,
          },
          interestedServices: {
            code: StatusCodeEnum.DEFAULT,
          },
          hasPartnerProgram: {
            code: StatusCodeEnum.DEFAULT,
          },
          marketingBudget: {
            code: StatusCodeEnum.DEFAULT,
          },
          variantFindCompany: {
            code: StatusCodeEnum.DEFAULT,
          },
          numberContract: {
            code: StatusCodeEnum.DEFAULT,
          },
          dateContract: {
            code: StatusCodeEnum.DEFAULT,
          },
          legalEntity: {
            code: StatusCodeEnum.DEFAULT,
          },
          contractCopy: {
            code: StatusCodeEnum.DEFAULT,
          },
        },
      });
    }

    return this.state;
  };

  getState(): StateModelInterface {
    if (!this.state) {
      throw new Error('State not initialized. Call initState method.');
    }

    return this.state;
  }
}

export default ScoringFormPageService;
