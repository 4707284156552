import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4275a5f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-with-sidebar-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutWithToolbarContentHeader = _resolveComponent("LayoutWithToolbarContentHeader")!
  const _component_LayoutWithToolbarContent = _resolveComponent("LayoutWithToolbarContent")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_LayoutWithToolbarContentHeader, {
          key: 0,
          class: "layout-with-sidebar-container__header",
          title: _ctx.title
        }, _createSlots({ _: 2 }, [
          (_ctx.isHeaderLeftSlot)
            ? {
                name: "header-left",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header-left", {}, undefined, true)
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.isHeaderRightSlot)
            ? {
                name: "header-right",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header-right", {}, undefined, true)
                ]),
                key: "1"
              }
            : undefined
        ]), 1032, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_LayoutWithToolbarContent, { class: "layout-with-sidebar-container__content" }, _createSlots({ _: 2 }, [
      (_ctx.isContentSlot)
        ? {
            name: "content",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ]),
            key: "0"
          }
        : undefined
    ]), 1024)
  ]))
}