<template>
  <button
    :class="classes"
    :disabled="disabled"
  >
    <slot
      name="icon-before"
    />
    <STextElement
      v-if="text"
      variant="medium"
      :color="textColor"
    >
      {{ text }}
    </STextElement>
    <slot
      name="icon-after"
    />
  </button>
</template>

<script
  lang="ts"
>

import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import SButtonVariantEnum from '../../../lib/Enum/Components/Simple/UI/SButtonVariantEnum';
import ColorEnum from '../../../lib/Enum/ColorEnum';
import SizeEnum from '../../../lib/Enum/SizeEnum';

import STextElement from '../Text/STextElement.vue';

export default defineComponent({
  name: 'SButton',
  components: {
    STextElement,
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    variant: {
      type: String as PropType<SButtonVariantEnum>,
      default: SButtonVariantEnum.FILLED,
    },
    color: {
      type: String as PropType<Exclude<ColorEnum, ColorEnum.TOOLBAR | ColorEnum.DISABLED>>,
      default: ColorEnum.PRIMARY,
    },
    size: {
      type: String as PropType<SizeEnum>,
      default: SizeEnum.M,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classes = computed(() => {
      let color: string = props.color as string;
      if (props.variant === SButtonVariantEnum.TRANSPARENT) {
        color = 'transparent';
      } else if (props.variant === SButtonVariantEnum.TERTIARY) {
        color = ColorEnum.SECONDARY;
      } else if (props.disabled) {
        color = ColorEnum.DISABLED;
      }

      return [
        's-button',
        `s-button_size-${props.size}`,
        `s-button_color-${color}`,
        `s-button_variant-${props.variant}`,
        props.disabled ? 's-button_disabled' : null,
        props.stretch ? 's-button_stretch' : null,
      ];
    });

    const textColor = computed(
      () => {
        const isFilled = props.variant === SButtonVariantEnum.FILLED;

        if (props.disabled) {
          return isFilled ? ColorEnum.WHITE : ColorEnum.DISABLED;
        }

        return isFilled ? ColorEnum.WHITE : props.color;
      },
    );

    return {
      classes,
      textColor,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  outline: none;
  transition: color linear .2s, background linear .2s, border linear .2s;
  cursor: pointer;
  border-radius:
    var(--button-border-radius-top-left)
    var(--button-border-radius-top-right)
    var(--button-border-radius-bottom-right)
    var(--button-border-radius-bottom-left);
  -webkit-border-radius:
    var(--button-border-radius-top-left)
    var(--button-border-radius-top-right)
    var(--button-border-radius-bottom-right)
    var(--button-border-radius-bottom-left);
  -moz-border-radius:
    var(--button-border-radius-top-left)
    var(--button-border-radius-top-right)
    var(--button-border-radius-bottom-right)
    var(--button-border-radius-bottom-left);
  user-select: none;

  &_stretch {
    width: 100%;
  }

  &_size-l {
    gap: var(--button-l-gap);
    padding:
      var(--button-l-padding-top)
      var(--button-l-padding-right)
      var(--button-l-padding-bottom)
      var(--button-l-padding-left);
  }

  &_size-m {
    gap: var(--button-m-gap);
    padding:
      var(--button-m-padding-top)
      var(--button-m-padding-right)
      var(--button-m-padding-bottom)
      var(--button-m-padding-left);
  }

  &_size-s {
    gap: var(--button-s-gap);
    padding:
      var(--button-s-padding-top)
      var(--button-s-padding-right)
      var(--button-s-padding-bottom)
      var(--button-s-padding-left);
  }

  &_disabled {
    pointer-events: none;
  }

  &_variant-transparent {
    &:hover {
      background-color: var(--color-secondary);
    }
  }

  &_color-disabled {
    background-color: var(--color-disabled);
  }

  &_color-danger {
    background-color: var(--color-danger);

    &.s-button_variant-filled:hover {
      background-color: var(--color-danger-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-gray {
    background-color: var(--color-gray);

    &.s-button_variant-filled:hover {
      background-color: var(--color-gray-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-linked {
    background-color: var(--color-linked);

    &.s-button_variant-filled:hover {
      background-color: var(--color-linked-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-navigation {
    background-color: var(--color-navigation);

    &.s-button_variant-filled:hover {
      background-color: var(--color-navigation-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-primary {
    background-color: var(--color-primary);

    &.s-button_variant-filled:hover {
      background-color: var(--color-primary-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-secondary {
    background-color: var(--color-secondary);

    &.s-button_variant-filled:hover {
      background-color: var(--color-secondary-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-success {
    background-color: var(--color-success);

    &.s-button_variant-filled:hover {
      background-color: var(--color-success-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-warn {
    background-color: var(--color-warn);

    &.s-button_variant-filled:hover {
      background-color: var(--color-warn-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-white {
    background-color: var(--color-white);

    &.s-button_variant-filled:hover {
      background-color: var(--color-white-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-black {
    background-color: var(--color-black);

    &.s-button_variant-filled:hover {
      background-color: var(--color-black-hover);
    }

    &.s-button_variant-tertiary.s-button_disabled:hover {
      background-color: var(--color-secondary);
    }

    &.s-button_variant-filled.s-button_disabled:hover {
      background-color: var(--color-disabled);
    }

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }

  &_color-transparent {
    background-color: transparent;

    &.s-button_variant-transparent.s-button_disabled:hover {
      background-color: transparent;
    }
  }
}
</style>
