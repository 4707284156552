<template>
  <div
    class="help-widget"
  >
    <CDropdownMenu
      :menu-item-list-full="menuItemListFull"
      :menu-item-list-hover="menuItemListHover"
      position="top-left"
    >
      <button
        type="button"
        class="help-widget__button"
      >
        <SIcon
          name="question"
          color="toolbar"
          size="xl"
        />
      </button>
    </CDropdownMenu>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';

import {
  CDropdownMenuItemTypeEnum,
  CDropdownMenuItemType,
} from '@/shared/DesignSystem';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

export default defineComponent({
  name: 'HelpWidget',
  setup() {
    const { t } = useI18n();

    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;

    const { utilsService } = serviceContainer;

    const menuItemListHover = computed<Array<Array<CDropdownMenuItemType>>>(() => [
      [
        {
          type: CDropdownMenuItemTypeEnum.TEXT,
          title: t('common.help_widget.help_and_resources.title'),
        },
      ],
    ]);

    const menuItemListFull = computed<Array<Array<CDropdownMenuItemType>>>(() => [
      [
        {
          type: CDropdownMenuItemTypeEnum.LINK,
          title: t('common.help_widget.getting_started.title'),
          url: t('common.help_widget.getting_started.url'),
          target: '_blank',
        },
        {
          type: CDropdownMenuItemTypeEnum.LINK,
          title: t('common.help_widget.knowledge_base.title'),
          url: t('common.help_widget.knowledge_base.url'),
          target: '_blank',
        },
      ],
      [
        {
          type: CDropdownMenuItemTypeEnum.LINK,
          title: t('common.help_widget.privacy_policy.title'),
          url: t('common.help_widget.privacy_policy.url'),
          target: '_blank',
        },
        {
          type: CDropdownMenuItemTypeEnum.LINK,
          title: t('common.help_widget.terms_of_service.title'),
          url: t('common.help_widget.terms_of_service.url'),
          target: '_blank',
        },
      ],
      [
        {
          type: CDropdownMenuItemTypeEnum.LINK,
          title: t('common.help_widget.market_place_allsoft.title'),
          url: t('common.help_widget.market_place_allsoft.url'),
          target: '_blank',
        },
        {
          type: CDropdownMenuItemTypeEnum.LINK,
          title: t('common.help_widget.personal_cart.title'),
          url: t('common.help_widget.personal_cart.url'),
          target: '_blank',
        },
        {
          type: CDropdownMenuItemTypeEnum.LINK,
          title: t('common.help_widget.payments.title'),
          url: t('common.help_widget.payments.url'),
          target: '_blank',
        },
      ],
      [
        {
          type: CDropdownMenuItemTypeEnum.EMAIL,
          title: t('common.help_widget.vendor_support.title'),
          text: t('common.help_widget.vendor_support.email'),
          url: `mailto:${t('common.help_widget.vendor_support.email')}`,
        },
        {
          type: CDropdownMenuItemTypeEnum.EMAIL,
          title: t('common.help_widget.merchant_support.title'),
          text: t('common.help_widget.merchant_support.email'),
          url: `mailto:${t('common.help_widget.merchant_support.email')}`,
        },
      ],
    ]);

    return {
      utilsService,
      menuItemListHover,
      menuItemListFull,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.help-widget {
  position: relative;

  &__button {
    display: block;
    width: 48px;
    height: 48px;
    padding: 0;
    border: none;
    border-radius: 50%;
    outline: none;
    background: transparent;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    appearance: none;
  }
}
</style>
