import { Store } from 'vuex';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import IApiService from '@/shared/Api/Contract/IApiService';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import AxiosClientService from '@/app/Service/AxiosClientService/AxiosClientService';
import EnvironmentService from '@/app/Service/EnvironmentService/EnvironmentService';
import IEnvironmentService from '@/app/Service/EnvironmentService/Contract/IEnvironmentService';
import ApiService from '@/shared/Api/ApiService';
import IAuthorizationService from '@/app/Service/Authorization/Contract/IAuthorizationService';
import AuthorizationService from '@/app/Service/Authorization/AuthorizationService';
import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';
import UtilsService from '@/app/Service/UtilsService/UtilsService';
import ILocalStorageService from '@/app/Service/LocalStorage/Contract/ILocalStorageService';
import LocalStorageService from '@/app/Service/LocalStorage/LocalStorageService';
import { store } from '@/app/providers/store';
import ISidebarService from '@/app/Service/SidebarService/model/ISidebarService';
import SidebarService from '@/app/Service/SidebarService/SidebarService';
import ICookieService from '@/app/Service/CookieService/Contract/ICookieService';
import CookieService from '@/app/Service/CookieService/CookieService';

class ServiceContainer implements IServiceContainer {
  private axiosClient?: IAxiosClientService;

  private _apiService?: IApiService;

  private _cookieService?: ICookieService;

  private _environmentService?: IEnvironmentService;

  private _authorizationService?: IAuthorizationService;

  private _utilsService?: IUtilsService;

  private _localStorageService?: ILocalStorageService;

  private _sidebarService?: ISidebarService;

  private _store?: Store<unknown>;

  get apiService(): IApiService {
    if (!this._apiService) {
      this._apiService = new ApiService(
        this.getAxiosClientService(),
        this,
      );
    }

    return this._apiService;
  }

  get cookieService(): ICookieService {
    if (!this._cookieService) {
      this._cookieService = new CookieService(
        this,
      );
    }

    return this._cookieService;
  }

  get environmentService(): IEnvironmentService {
    if (!this._environmentService) {
      this._environmentService = new EnvironmentService();
    }

    return this._environmentService;
  }

  get authorizationService(): IAuthorizationService {
    if (!this._authorizationService) {
      this._authorizationService = new AuthorizationService(
        this.apiService,
        this.localStorageService,
        this.store,
        this.utilsService,
      );
    }

    return this._authorizationService;
  }

  private getAxiosClientService(): IAxiosClientService {
    if (!this.axiosClient) {
      this.axiosClient = new AxiosClientService(this);

      this.axiosClient.addRequestInterceptors();
      this.axiosClient.addResponseInterceptors();
    }

    return this.axiosClient;
  }

  get utilsService(): IUtilsService {
    if (!this._utilsService) {
      this._utilsService = new UtilsService();
    }

    return this._utilsService;
  }

  get localStorageService(): ILocalStorageService {
    if (!this._localStorageService) {
      this._localStorageService = new LocalStorageService(this);
    }

    return this._localStorageService;
  }

  get sidebarService(): ISidebarService {
    if (!this._sidebarService) {
      this._sidebarService = new SidebarService();
    }

    return this._sidebarService;
  }

  get store(): Store<unknown> {
    if (!this._store) {
      this._store = store(this);
    }
    return this._store;
  }
}

export default ServiceContainer;
