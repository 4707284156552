import IPriceListApi from '@/shared/Api/Contract/Api/IPriceListApi';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import IPriceListRequestModel from '@/shared/Api/Model/Service/PriceListApi/IPriceListRequestModel';

class PriceListApi implements IPriceListApi {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  create(data: IPriceListRequestModel): Promise<void> {
    return this.clientService.instance.post('/service/pricing', data);
  }
}

export default PriceListApi;
