import { App } from '@vue/runtime-core';
import IUtilsService from './Contract/IUtilsService';
import UtilsService from './UtilsService';

export default {
  install(app: App) {
    app.provide('utilsService', new UtilsService());
  },
};

export {
  IUtilsService,
};
