import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5df06d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  fill: "none",
  height: "24",
  viewBox: "0 0 24 24",
  width: "24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      "clip-rule": "evenodd",
      d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.8586 8.71154C10.9189 8.86211 11.0096 8.99861 11.125 9.1125C11.35 9.35 11.65 9.4875 12 9.4875C12.3297 9.4828 12.6442 9.34802 12.875 9.1125C13.1125 8.8875 13.225 8.5875 13.225 8.2375C13.225 7.8875 13.1 7.6 12.875 7.3625C12.65 7.125 12.35 7.0125 12 7.0125C11.65 7.0125 11.3625 7.1375 11.125 7.3625C10.8875 7.5875 10.775 7.8875 10.775 8.2375C10.7699 8.39958 10.7984 8.56097 10.8586 8.71154ZM12.8625 11.125C13.1125 11.3875 13.225 11.675 13.25 11.9875V15.7375H13.5288C14.0593 15.7375 14.4971 16.2346 14.1125 16.6C13.85 16.85 13.5875 16.9875 13.25 16.9875H12C11.6625 16.9875 11.3875 16.85 11.1375 16.6C10.8875 16.3625 10.775 16.075 10.75 15.7375V11.9875H10.4685C9.93998 11.9875 9.5138 11.4987 9.8875 11.125C10.15 10.9 10.4125 10.7625 10.75 10.7375H12C12.3375 10.75 12.6125 10.8875 12.8625 11.125Z",
      fill: "#ABACAC",
      "fill-rule": "evenodd"
    }, null, 2)
  ]))
}