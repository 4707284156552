import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-778f423d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "s-file-upload-area__before"
}
const _hoisted_2 = {
  key: 1,
  class: "s-file-upload-area__content"
}
const _hoisted_3 = {
  key: 2,
  class: "s-file-upload-area__after"
}
const _hoisted_4 = ["id", "multiple"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "s-file-upload-area",
      onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
      onDragleave: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"]))
    }, [
      (_ctx.hasBeforeSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "before", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasDefaultSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasAfterSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "after", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ], 32),
    _createElementVNode("input", {
      ref: "element",
      type: "file",
      id: _ctx.id,
      multiple: _ctx.multiple,
      hidden: true,
      onChange: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleInputChange && _ctx.handleInputChange(...args)), ["prevent"]))
    }, null, 40, _hoisted_4)
  ], 64))
}