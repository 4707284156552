import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-148d9493"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-info-block__icon"
}
const _hoisted_2 = { class: "c-info-block__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SIcon, {
            name: _ctx.icon.name,
            color: _ctx.icon.color,
            size: _ctx.icon.size
          }, null, 8, ["name", "color", "size"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_STextElement, {
            key: 0,
            class: "c-info-block__title",
            variant: "medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.text)
        ? (_openBlock(), _createBlock(_component_STextElement, {
            key: 1,
            class: "c-info-block__text"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}