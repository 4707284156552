<template>
  <div
    class="progress-bar"
  >
    <div
      class="progress-bar__top"
    >
      <div
        class="progress-bar__text"
      >
        <SHeader
          :size="2"
        >
          2/3
        </SHeader>
        <STextElement
          variant="medium"
        >
          {{ $t('pages.home.setup.progressbar.text') }}
        </STextElement>
      </div>
      <SButton
        v-if="currentStep === 4 && !isMobile"
        :size="buttonConfig.size"
        :text="buttonConfig.text"
        :variant="buttonConfig.variant"
        @click="handleClick"
      />
    </div>

    <div
      class="progress-bar__bottom"
    >
      <STextElement
        :color="stepText.color"
        :variant="stepText.variant"
      >
        {{ stepText.text }}
      </STextElement>
      <progress
        :max="stepCount"
        :value="currentStep"
        class="progress-bar__progress-bar"
      />
    </div>

    <SButton
      v-if="currentStep === 4 && isMobile"
      :size="buttonConfig.size"
      :stretch="true"
      :text="buttonConfig.text"
      :variant="buttonConfig.variant"
      @click="handleClick"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';

import {
  ColorEnum,
  SButtonPropsInterface,
} from '@/shared/DesignSystem';
import SButtonVariantEnum
  from '@/shared/DesignSystem/lib/Enum/Components/Simple/UI/SButtonVariantEnum';
import SizeEnum from '@/shared/DesignSystem/lib/Enum/SizeEnum';
import FontVariantEnum from '@/shared/DesignSystem/lib/Enum/FontVariantEnum';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

export default defineComponent({
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    stepCount: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const { store } = serviceContainer;

    const isMobile = computed(() => store.getters.getIsMobile);

    const buttonConfig = computed<SButtonPropsInterface>(() => {
      const nextStepName = t('pages.home.setup.step_list.step2.title');

      return {
        text: t('pages.home.setup.progressbar.button', { nextStep: nextStepName }),
        variant: SButtonVariantEnum.FILLED,
        size: SizeEnum.M,
      };
    });

    const stepText = computed(() => {
      const stepName = t('pages.home.setup.step_list.step2.title');
      const text = t(
        'pages.home.setup.progressbar.step',
        {
          stepNumber: '2',
          stepName,
        },
      );

      return {
        variant: FontVariantEnum.MEDIUM,
        color: ColorEnum.LINKED,
        text,
      };
    });

    function handleClick() {
      emit('click-button');
    }

    return {
      buttonConfig,
      stepText,
      isMobile,
      handleClick,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.progress-bar {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-background-primary);
  gap: 32px;

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__progress-bar {
    width: 100%;
    height: 12px;
    border-radius: 6px;
    -webkit-appearance: none;

    &::-webkit-progress-bar {
      border-radius: 6px;
      background-color: #d5e5f1;
    }

    &::-webkit-progress-value {
      border-radius: 6px;
      background-color: var(--color-primary);
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 16px;
  }
}
</style>
