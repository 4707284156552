enum ColorEnum {
  COLOR_DISABLED = '--color-disabled',
  COLOR_DISABLED_HOVER = '--color-disabled-hover',
  COLOR_DISABLED_ACTIVE = '--color-disabled-active',
  COLOR_DISABLED_FOCUS = '--color-disabled-focus',
  COLOR_DANGER = '--color-danger',
  COLOR_DANGER_HOVER = '--color-danger-hover',
  COLOR_DANGER_ACTIVE = '--color-danger-active',
  COLOR_DANGER_FOCUS = '--color-danger-focus',
  COLOR_GRAY = '--color-gray',
  COLOR_GRAY_HOVER = '--color-gray-hover',
  COLOR_GRAY_ACTIVE = '--color-gray-active',
  COLOR_GRAY_FOCUS = '--color-gray-focus',
  COLOR_LINKED = '--color-linked',
  COLOR_LINKED_HOVER = '--color-linked-hover',
  COLOR_LINKED_ACTIVE = '--color-linked-active',
  COLOR_LINKED_FOCUS = '--color-linked-focus',
  COLOR_NAVIGATION = '--color-navigation',
  COLOR_NAVIGATION_HOVER = '--color-navigation-hover',
  COLOR_NAVIGATION_ACTIVE = '--color-navigation-active',
  COLOR_NAVIGATION_FOCUS = '--color-navigation-focus',
  COLOR_PRIMARY = '--color-primary',
  COLOR_PRIMARY_HOVER = '--color-primary-hover',
  COLOR_PRIMARY_ACTIVE = '--color-primary-active',
  COLOR_PRIMARY_FOCUS = '--color-primary-focus',
  COLOR_SECONDARY = '--color-secondary',
  COLOR_SECONDARY_HOVER = '--color-secondary-hover',
  COLOR_SECONDARY_ACTIVE = '--color-secondary-active',
  COLOR_SECONDARY_FOCUS = '--color-secondary-focus',
  COLOR_SUCCESS = '--color-success',
  COLOR_SUCCESS_HOVER = '--color-success-hover',
  COLOR_SUCCESS_ACTIVE = '--color-success-active',
  COLOR_SUCCESS_FOCUS = '--color-success-focus',
  COLOR_TOOLBAR = '--color-toolbar',
  COLOR_TOOLBAR_HOVER = '--color-toolbar-hover',
  COLOR_TOOLBAR_ACTIVE = '--color-toolbar-active',
  COLOR_TOOLBAR_FOCUS = '--color-toolbar-focus',
  COLOR_WARN = '--color-warn',
  COLOR_WARN_HOVER = '--color-warn-hover',
  COLOR_WARN_ACTIVE = '--color-warn-active',
  COLOR_WARN_FOCUS = '--color-warn-focus',
  COLOR_WHITE = '--color-white',
  COLOR_WHITE_HOVER = '--color-white-hover',
  COLOR_WHITE_ACTIVE = '--color-white-active',
  COLOR_WHITE_FOCUS = '--color-white-focus',
  COLOR_BLACK = '--color-black',
  COLOR_BLACK_HOVER = '--color-black-hover',
  COLOR_BLACK_ACTIVE = '--color-black-active',
  COLOR_BLACK_FOCUS = '--color-black-focus',
  COLOR_STROKE = '--color-stroke',
  COLOR_SURFACE = '--color-surface',
  COLOR_BACKGROUND_PRIMARY = '--color-background-primary',
  COLOR_BACKGROUND_DANGER = '--color-background-danger',
  COLOR_BACKGROUND_WARN = '--color-background-warn',
  COLOR_BACKGROUND_SUCCESS = '--color-background-success',
  COLOR_BACKGROUND_GRAY = '--color-background-gray',
}

export default ColorEnum;
