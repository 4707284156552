enum ButtonEnum {
  BORDER_WIDTH = '--button-border-width',
  BORDER_RADIUS_TOP_RIGHT = '--button-border-radius-top-right',
  BORDER_RADIUS_TOP_LEFT = '--button-border-radius-top-left',
  BORDER_RADIUS_BOTTOM_LEFT = '--button-border-radius-bottom-left',
  BORDER_RADIUS_BOTTOM_RIGHT = '--button-border-radius-bottom-right',
  L_GAP = '--button-l-gap',
  M_GAP = '--button-m-gap',
  S_GAP = '--button-s-gap',
  L_PADDING_TOP = '--button-l-padding-top',
  L_PADDING_RIGHT = '--button-l-padding-right',
  L_PADDING_BOTTOM = '--button-l-padding-bottom',
  L_PADDING_LEFT = '--button-l-padding-left',
  M_PADDING_TOP = '--button-m-padding-top',
  M_PADDING_RIGHT = '--button-m-padding-right',
  M_PADDING_BOTTOM = '--button-m-padding-bottom',
  M_PADDING_LEFT = '--button-m-padding-left',
  S_PADDING_TOP = '--button-s-padding-top',
  S_PADDING_RIGHT = '--button-s-padding-right',
  S_PADDING_BOTTOM = '--button-s-padding-bottom',
  S_PADDING_LEFT = '--button-s-padding-left',
}

export default ButtonEnum;
