<template>
  <div
    class="layout-with-sidebar-content"
  >
    <slot
      name="content"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';

export default defineComponent({});
</script>

<style
  scoped
  lang="scss"
>

</style>
