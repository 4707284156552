import { VueI18nTranslation } from 'vue-i18n';
import { reactive } from 'vue';
import IPriceListFormPage
  from '@/pages/Service/PriceList/PriceListFormPage/Contract/IPriceListFormPage';
import IState from '@/pages/Service/PriceList/PriceListFormPage/Model/IState';
import IHandlerService from '@/pages/Service/PriceList/PriceListFormPage/Contract/IHandlerService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import HandlerService from '@/pages/Service/PriceList/PriceListFormPage/Service/HandlerService';
import { StatusCodeEnum } from '@/shared/Validator';

class PriceListFormPage implements IPriceListFormPage {
  private readonly serviceContainer: IServiceContainer;

  private _handlerService?: IHandlerService;

  private state?: IState;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get handlerService(): IHandlerService {
    if (!this._handlerService) {
      this._handlerService = new HandlerService(
        this.getState(),
        this.serviceContainer,
      );
    }

    return this._handlerService;
  }

  initState(t: VueI18nTranslation): IState {
    if (!this.state) {
      this.state = reactive({
        localisation: {
          title: t('pages.pricelist.form.title'),
        },
        t,
        formConfig: [],
        formData: {
          variant: [],
          link: '',
          documents: [],
          comment: '',
        },
        isLoading: false,
        isSendButtonPressed: false,
        isFormSent: false,
        validationStatusRegistry: {
          variant: {
            code: StatusCodeEnum.DEFAULT,
          },
          documents: {
            code: StatusCodeEnum.DEFAULT,
          },
          link: {
            code: StatusCodeEnum.DEFAULT,
          },
          comment: {
            code: StatusCodeEnum.DEFAULT,
          },
        },
        documentFiles: [],
      });
    }

    return this.state;
  }

  getState(): IState {
    if (!this.state) {
      throw new Error('State not initialized. Call initState method first.');
    }

    return this.state;
  }
}

export default PriceListFormPage;
