<template>
  <div
    class="c-multiple-select"
  >
    <STextInput
      :id="id"
      v-model="state.filter"
      :color="color"
      :placeholder="placeholder"
      @focus="handleInputFocus"
    >
      <template
        #before
      >
        <SIcon
          color="disabled"
          name="search"
        />
      </template>
    </STextInput>
    <div
      v-if="state.checkedList.length > 0"
      class="c-multiple-select__chips"
    >
      <SChips
        v-for="chips in state.checkedList"
        :key="chips.id"
        :icon="state.chipsIcon"
        :modelValue="true"
        color="primary"
        stateType="outer"
        variant="filled"
        @icon-click="handleChipsClick(chips)"
      >
        {{ chips.title }}
      </SChips>
    </div>
    <div
      v-if="state.active"
      v-click-outside="handleClickOutside"
      class="c-multiple-select__dropdown"
    >
      <PerfectScrollbar
        :settings="state.scrollBarSettings"
        class="c-multiple-select__dropdown-scroll-area"
      >
        <div
          v-for="item in filteredList"
          :key="item.id"
          class="c-multiple-select__dropdown-item"
          @click="handleItemCLick(item)"
        >
          <SLabel
            class="c-multiple-select__dropdown-label"
            side="right"
            @click.stop
          >
            <template
              #label
            >
              {{ item.title }}
            </template>
            <template
              #control="{ id }"
            >
              <SCheckbox
                :id="id"
                :modelValue="item.checked"
                class="c-multiple-select__dropdown-checkbox"
                @update:modelValue="handleItemCLick(item)"
                @input.stop
              />
            </template>
          </SLabel>
        </div>
      </PerfectScrollbar>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
} from 'vue';

import SIconPropsInterface from '../../lib/Model/Components/Simple/Icon/SIconPropsInterface';
import IconNameEnum from '../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import ColorEnum from '../../lib/Enum/ColorEnum';
import SizeEnum from '../../lib/Enum/SizeEnum';
import SChipsItemInterface from '../../lib/Model/Components/Complex/SChipsItemInterface';

import SCheckbox from '../Simple/UI/SCheckbox.vue';
import SChips from '../Simple/UI/SChips.vue';
import SIcon from '../Simple/Icon/SIcon.vue';
import SLabel from '../Simple/Text/SLabel.vue';
import STextInput from '../Simple/UI/STextInput.vue';

export default defineComponent({
  name: 'CMultipleSelect',
  components: {
    SCheckbox,
    SChips,
    SIcon,
    SLabel,
    STextInput,
  },
  props: {
    list: {
      type: Array as PropType<Array<SChipsItemInterface>>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    color: {
      type: String as PropType<ColorEnum>,
      default: ColorEnum.PRIMARY,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const state = reactive<{
      active: boolean,
      list: Array<SChipsItemInterface>,
      checkedList: Array<SChipsItemInterface>,
      scrollBarSettings: {
        suppressScrollY: boolean,
        suppressScrollX: boolean,
        wheelPropagation: boolean,
      },
      chipsIcon: SIconPropsInterface,
      filter: string,
    }>({
      active: false,
      list: [],
      checkedList: [],
      scrollBarSettings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
      },
      chipsIcon: {
        name: IconNameEnum.CROSS,
        color: ColorEnum.PRIMARY,
        size: SizeEnum.M,
      },
      filter: '',
    });

    const filteredList = computed(
      () => state.list.filter((item) => {
        const lowerCaseTitle = item.title.toLowerCase();
        const lowerCaseFilter = state.filter.toLowerCase();

        return lowerCaseTitle.includes(lowerCaseFilter);
      }),
    );

    function cloneImmutable(items: Array<SChipsItemInterface>): Array<SChipsItemInterface> {
      return items.map((item) => ({ ...item }));
    }

    function updateItemStatus(item: SChipsItemInterface) {
      item.checked = !item.checked;

      if (item.checked) {
        state.checkedList.push(item);
      } else {
        state.checkedList = state.checkedList.filter((stateItem) => stateItem.id !== item.id);
      }
    }

    function handleItemCLick(item: SChipsItemInterface) {
      updateItemStatus(item);
    }

    function handleChipsClick(item: SChipsItemInterface) {
      updateItemStatus(item);

      emit('change-item', state.list);
    }

    function handleClickOutside() {
      state.active = false;

      emit('change-item', state.list);
    }

    function handleInputFocus() {
      state.active = true;
    }

    onMounted(() => {
      state.list = cloneImmutable(props.list);

      state.checkedList = state.list.filter((item) => item.checked);
    });

    return {
      state,
      handleItemCLick,
      handleChipsClick,
      filteredList,
      handleClickOutside,
      handleInputFocus,
    };
  },
});
</script>

<style
  lang="scss"
>
.c-multiple-select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  &__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    margin-top: 12px;
    padding: 12px 8px;
    border: 1px solid var(--color-surface);
    border-radius: 8px;
    background-color: var(--color-white);

    &-scroll-area {
      max-height: 240px;
    }

    &-item {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding: 12px 8px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: var(--color-secondary-hover);
      }
    }

    &-checkbox {
      flex: 0 0 auto;
    }
  }
}
</style>
