import { VueI18nTranslation } from 'vue-i18n';
import { reactive } from 'vue';
import IAboutFormPage
  from '@/pages/Service/About/AboutFormPage/Contract/IAboutFormPage';
import IState from '@/pages/Service/About/AboutFormPage/Model/IState';
import IHandlerService from '@/pages/Service/About/AboutFormPage/Contract/IHandlerService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import HandlerService from '@/pages/Service/About/AboutFormPage/Service/HandlerService';
import { StatusCodeEnum } from '@/shared/Validator';

class AboutFormPage implements IAboutFormPage {
  private readonly serviceContainer: IServiceContainer;

  private _handlerService?: IHandlerService;

  private state?: IState;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get handlerService(): IHandlerService {
    if (!this._handlerService) {
      this._handlerService = new HandlerService(
        this.getState(),
        this.serviceContainer,
      );
    }

    return this._handlerService;
  }

  initState(t: VueI18nTranslation): IState {
    if (!this.state) {
      this.state = reactive({
        localisation: {
          title: t('pages.about.form.title'),
        },
        t,
        formConfig: [],
        formData: {
          description: '',
          image: [],
        },
        isLoading: false,
        isSendButtonPressed: false,
        isFormSent: false,
        validationStatusRegistry: {
          description: {
            code: StatusCodeEnum.DEFAULT,
          },
          image: {
            code: StatusCodeEnum.DEFAULT,
          },
        },
        documentFiles: [],
      });
    }

    return this.state;
  }

  getState(): IState {
    if (!this.state) {
      throw new Error('State not initialized. Call initState method first.');
    }

    return this.state;
  }
}

export default AboutFormPage;
