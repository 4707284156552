<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM8.70711 16.7071L12 13.4142L15.2929 16.7071L16.7071 15.2929L13.4142 12L16.7071 8.70711L15.2929 7.29289L12 10.5858L8.70711 7.29289L7.29289 8.70711L10.5858 12L7.29289 15.2929L8.70711 16.7071Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SCrossCircleIcon',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-cross-circle_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-cross-circle {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

  &_color-stroke {
    fill: var(--color-stroke);
  }
}
</style>
