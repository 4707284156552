<template>
  <LayoutWithoutSidebar
    v-if="vendor.levelPartnerProgram === null"
    width="lg"
  >
    <template
      #content
    >
      <div
        class="error-page"
      >
        <CStatusWindow
          :subtitle="statusWindowComponent.subtitle"
          :title="statusWindowComponent.title"
        >
          <template
            #buttons
          >
            <SButton
              :text="buttonHome.text"
              :variant="buttonHome.variant"
              @click="buttonHome.handler"
            >
              <template
                #icon-before
              >
                <SIcon
                  :color="buttonHome.icon.color"
                  :name="buttonHome.icon.name"
                />
              </template>
            </SButton>
          </template>
        </CStatusWindow>
      </div>
    </template>
  </LayoutWithoutSidebar>
  <LayoutWithSidebar
    width="lg"
  >
    <template
      #content
    >
      <div
        class="error-page"
      >
        <CStatusWindow
          :subtitle="statusWindowComponent.subtitle"
          :title="statusWindowComponent.title"
        >
          <template
            #buttons
          >
            <SButton
              :text="buttonHome.text"
              :variant="buttonHome.variant"
              @click="buttonHome.handler"
            >
              <template
                #icon-before
              >
                <SIcon
                  :color="buttonHome.icon.color"
                  :name="buttonHome.icon.name"
                />
              </template>
            </SButton>
          </template>
        </CStatusWindow>
      </div>
    </template>
  </LayoutWithSidebar>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';
import { useRouter } from 'vue-router';

import {
  ColorEnum,
  IconNameEnum,
  SButtonVariantEnum,
} from '@/shared/DesignSystem';

import EDefaultRouteName from '@/app/Enum/router/EDefaultRouteName';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import IVendorModel from '@/entities/Model/IVendorModel';

import LayoutWithSidebar from '@/app/Component/Layout/LayoutWithToolbar/LayoutWithSidebar.vue';
import LayoutWithoutSidebar from '@/app/Component/Layout/LayoutWithToolbar/LayoutWithoutSidebar.vue';

export default defineComponent({
  components: {
    LayoutWithSidebar,
    LayoutWithoutSidebar,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const {
      store,
    } = serviceContainer;

    const vendor = computed<IVendorModel>(() => store.getters.getVendor);

    const statusWindowComponent = computed(() => ({
      title: t('pages.not_found.title'),
      subtitle: t('pages.not_found.subtitle'),
    }));

    const buttonHome = computed(() => ({
      text: t('common.to_home'),
      variant: SButtonVariantEnum.FILLED,
      icon: {
        name: IconNameEnum.SERVICES,
        color: ColorEnum.WHITE,
      },
      handler: () => router.push({ name: EDefaultRouteName.HOME }),
    }));

    return {
      vendor,
      statusWindowComponent,
      buttonHome,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
