import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import { StatusCodeEnum } from '@/shared/Validator';
import IState from '@/pages/Service/About/AboutFormPage/Model/IState';
import IHandlerService from '@/pages/Service/About/AboutFormPage/Contract/IHandlerService';
import ISendDataHandler
  from '@/pages/Service/About/AboutFormPage/Contract/Handler/ISendDataHandler';
import IAboutRequestModel from '@/shared/Api/Model/Service/AboutApi/IAboutRequestModel';

class SendDataHandler implements ISendDataHandler {
  private state: IState;

  private readonly serviceContainer: IServiceContainer;

  private handlerService: IHandlerService;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
  }

  handle = async (): Promise<void> => {
    this.handlerService.validationHandler.validateFrom(this.state.formData);

    const isFormValid = Object
      .values(this.state.validationStatusRegistry)
      .filter((status) => status.code === StatusCodeEnum.ERROR).length === 0;

    if (!isFormValid) {
      return Promise.reject();
    }

    this.state.isLoading = true;

    const data = this.getData();

    try {
      await this.serviceContainer.apiService.aboutApi.create(data);
    } catch (e) {
      this.state.isLoading = false;

      return Promise.reject(e);
    }

    this.state.isLoading = false;

    return Promise.resolve();
  };

  private getData = (): IAboutRequestModel => {
    const formData = this.serviceContainer.utilsService.data.cloneImmutable(this.state.formData);

    return {
      description: formData.description,
      image: this.state.documentFiles[0],
    };
  };
}

export default SendDataHandler;
