import IPromotionApi from '@/shared/Api/Contract/Api/IPromotionApi';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import TPromotionRequestModel from '@/shared/Api/Model/Service/PromotionApi/TPromotionRequestModel';

class PromotionApi implements IPromotionApi {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  create(data: TPromotionRequestModel): Promise<void> {
    return this.clientService.instance.post('/service/promotion', data);
  }

  get(id: number): Promise<void> {
    return this.clientService.instance.get(`/service/promotion/${id}`);
  }

  list(): Promise<void> {
    return this.clientService.instance.get('/service/promotion');
  }
}

export default PromotionApi;
