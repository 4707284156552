enum FileErrorTypeEnum {
  MAX_SIZE = 'invalidFileSize',
  MAX_TOTAL_SIZE = 'invalidTotalFileSize',
  NO_ZERO_SIZE = 'noZeroFileSize',
  NAME = 'invalidFileName',
  MIME_TYPE = 'invalidFileMimeType',
  LOAD = 'loadError',
}

export default FileErrorTypeEnum;
