<template>
  <div
    class="form-component"
    ref="formComponentRef"
  >
    <template
      v-for="(block, index) in state.formConfig"
      :key="index"
    >
      <hr
        v-if="blockTypeCheckHandler.isDelimiter(block) && checkCondition(block.conditionList)"
        class="form-component__delimiter"
      >
      <div
        v-else-if="blockTypeCheckHandler.isBlock(block) && checkCondition(block.conditionList)"
        class="form-component__block"
      >
        <div
          v-if="blockTypeCheckHandler.isBlock(block) && (block.title || block.subTitle)"
          class="block__header"
        >
          <SHeader
            v-if="blockTypeCheckHandler.isBlock(block) && block.title"
            :size="3"
          >
            {{ block.title }}
          </SHeader>
          <STextElement
            v-if="blockTypeCheckHandler.isBlock(block) && block.subTitle"
          >
            {{ block.subTitle }}
          </STextElement>
        </div>
        <template
          v-if="blockTypeCheckHandler.isBlock(block)"
        >
          <template
            v-for="field in block.fields"
            :key="`field-${field.fieldName}`"
          >
            <div
              v-if="fieldTypeCheckHandler.isCustomInfoBlock(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <slot
                name="custom-info-block"
                :field="field"
              />
            </div>
            <div
              v-if="fieldTypeCheckHandler.isTextInput(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default="{ color, id }"
                >
                  <STextInput
                    :placeholder="field.config.placeholder"
                    :color="color"
                    :id="id"
                    :model-value="fieldValueHandler.getStringValue(field, state.formData)"
                    @blur="(event, value) => handleStringBlur(event, value, field)"
                  />
                </template>
              </FormField>
            </div>
            <div
              v-if="fieldTypeCheckHandler.isMaskedInput(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default="{ color, id }"
                >
                  <SMaskedInput
                    :placeholder="field.config.placeholder"
                    :color="color"
                    :id="id"
                    :mask-options="field.config.mask"
                    :model-value="fieldValueHandler.getStringValue(field, state.formData)"
                    @blur="(event, value) => handleStringBlur(event, value, field)"
                  />
                </template>
              </FormField>
            </div>
            <div
              v-if="fieldTypeCheckHandler.isDateField(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default="{ color, id }"
                >
                  <SDatePicker
                    :placeholder="field.config.placeholder"
                    :base-time-zone="field.config.baseTimeZone"
                    :color="color"
                    :buttons="field.config.buttons"
                    :min-date="field.config.minDate"
                    :max-date="field.config.maxDate"
                    :id="id"
                    :mode="field.config.mode"
                    :model-value="fieldValueHandler.getStringValue(field, state.formData)"
                    :dropdown-position="state.dropdownPosition"
                    @date-input="(value) => handleDateInput(value, field)"
                    @dropdown-shown="handleShowDropdown"
                  />
                </template>
              </FormField>
            </div>
            <div
              v-if="fieldTypeCheckHandler.isTextInputList(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default
                >
                  <CTextInputList
                    :placeholder="field.config.placeholder"
                    :min-items="field.config.minItems"
                    :max-items="field.config.maxItems"
                    :add-button-text="field.config.addButtonText"
                    :statuses="field.config.statuses"
                    :input-delay="field.config.inputDelay"
                    :model-value="fieldValueHandler.getTextInputListValue(field, state.formData)"
                    @text-input-list-input="(value) => handleTextInputListInput(value, field)"
                    @text-input-list-add="(value) => handleTextInputListAddField(value, field)"
                    @text-input-list-delete="(value) => handleTextInputListDeleteField(value, field)"
                  >
                    <template
                      #before-item="slotProps"
                    >
                      <slot
                        name="input-list-item-before-item"
                        :index="slotProps.index"
                        :item="slotProps.item"
                        :status="slotProps.status"
                      />
                    </template>
                    <template
                      #before-input="slotProps"
                    >
                      <slot
                        name="input-list-item-before-input"
                        :index="slotProps.index"
                        :item="slotProps.item"
                        :status="slotProps.status"
                      />
                    </template>
                    <template
                      #after-input="slotProps"
                    >
                      <slot
                        name="input-list-item-after-input"
                        :index="slotProps.index"
                        :item="slotProps.item"
                        :status="slotProps.status"
                      />
                    </template>
                    <template
                      #after-item="slotProps"
                    >
                      <slot
                        name="input-list-item-after-item"
                        :index="slotProps.index"
                        :item="slotProps.item"
                        :status="slotProps.status"
                      />
                    </template>
                  </CTextInputList>
                </template>
              </FormField>
            </div>
            <div
              v-if="fieldTypeCheckHandler.isTextArea(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default="{ color, id }"
                >
                  <STextArea
                    :placeholder="field.config.placeholder"
                    :min-rows-count="field.config.minRowsCount"
                    :max-rows-count="field.config.maxRowsCount"
                    :max-length="field.config.maxLength"
                    :color="color"
                    :id="id"
                    :model-value="fieldValueHandler.getStringValue(field, state.formData)"
                    @blur="(event, value) => handleStringBlur(event, value, field)"
                  />
                </template>
              </FormField>
            </div>
            <div
              v-else-if="fieldTypeCheckHandler.isMultipleSelect(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default="{ color, id }"
                >
                  <CMultipleSelect
                    :placeholder="field.config.placeholder"
                    :color="color"
                    :list="field.config.list"
                    :id="id"
                    @change-item="(value) => handleMultipleSelectInput(value, field)"
                  />
                </template>
              </FormField>
            </div>
            <div
              v-else-if="fieldTypeCheckHandler.isChipsGroup(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default="{ color }"
                >
                  <CChipsGroup
                    :color="color"
                    :list="field.config.list"
                    :mode="field.config.mode"
                    @change-selection="(value) => handleChipsGroupChange(value, field)"
                  />
                </template>
              </FormField>
            </div>
            <div
              v-else-if="fieldTypeCheckHandler.isFileUpload(field) && checkCondition(field.conditionList)"
              class="block__field"
            >
              <FormField
                :label="field.config.label"
                :description="field.config.description"
                :status="field.status"
              >
                <template
                  #default="{ color, id }"
                >
                  <CFileUploadArea
                    :options="field.config.options"
                    :files="fieldValueHandler.getFileListValue(field, state.formData)"
                    :color="color"
                    :id="id"
                    @input-file="(value) => handleInputFile(value, field)"
                    @delete-file="(value) => handleDeleteFile(value, field)"
                    @download-file="(value) => handleDownloadFile(value, field)"
                  />
                </template>
              </FormField>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  inject, nextTick,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';

import {
  IUtilsService,
} from '@/app/Service/UtilsService';
import {
  CTextInputListItemInterface,
  DropdownPositionEnum,
  FileInterface,
} from '@/shared/DesignSystem';

import ConditionModelType from '../lib/Model/Condition/ConditionModelType';
import FormComponentServiceInterface from '../lib/Contract/FormComponentInterface';
import MultipleSelectFieldConfigInterface from '../lib/Model/Fields/MultipleSelectFieldConfigInterface';

import FieldConfigType from '../lib/Model/Fields/FieldConfigType';
import FormBlockType from '../lib/Model/FormBlockType';

import FormField from './FormField.vue';

export default defineComponent({
  name: 'FormComponent',
  components: {
    FormField,
  },
  props: {
    formConfig: {
      type: Array as PropType<Array<FormBlockType>>,
      default: () => [],
    },
    formData: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({} as Record<string, unknown>),
    },
  },
  setup(props, { emit }) {
    const formComponentService = inject<FormComponentServiceInterface>(
      'formComponentService',
    ) as FormComponentServiceInterface;
    const utilsService = inject<IUtilsService>('utilsService');

    const state = reactive<{
      formConfig: FormBlockType[];
      formData: Record<string, unknown>;
      formHeight: number;
      dropdownPosition: DropdownPositionEnum;
    }>({
      formConfig: [] as FormBlockType[],
      formData: {} as Record<string, unknown>,
      formHeight: 0,
      dropdownPosition: DropdownPositionEnum.BOTTOM,
    });

    const formComponentRef = ref();

    function handleStringBlur(event: FocusEvent, value: string, field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'string-field-blur',
        event,
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleDateInput(value: Date, field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'date-input',
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleShowDropdown() {
      state.dropdownPosition = DropdownPositionEnum.BOTTOM;

      nextTick(() => {
        state.dropdownPosition = formComponentRef.value.scrollHeight - state.formHeight > 5
          ? DropdownPositionEnum.TOP
          : DropdownPositionEnum.BOTTOM;
      });
    }

    function handleTextInputListInput(value: CTextInputListItemInterface[], field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'text-input-list-input',
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleTextInputListAddField(value: CTextInputListItemInterface[], field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'text-input-list-add',
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleTextInputListDeleteField(value: CTextInputListItemInterface[], field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'text-input-list-delete',
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleMultipleSelectInput(
      value: Array<MultipleSelectFieldConfigInterface>,
      field: FieldConfigType,
    ) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'multiple-select-input',
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleChipsGroupChange(
      value: Array<MultipleSelectFieldConfigInterface>,
      field: FieldConfigType,
    ) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'chips-group-change',
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleInputFile(value: Array<FileInterface>, field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = formComponentService.fieldValueHandler.updateFormData(
        utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData),
        field,
        value,
      );

      emit(
        'file-upload-input',
        {
          formData,
          value,
          field,
        },
      );
    }

    function handleDeleteFile(file: FileInterface, field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData);

      emit('delete-file', { field, formData, value: [file] });
    }

    function handleDownloadFile(event: unknown, field: FieldConfigType) {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      const formData = utilsService.data.cloneImmutable<Record<string, unknown>>(state.formData);

      emit('download-file', { field, formData });
    }

    watch(() => props.formConfig, (newValue) => {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      state.formConfig = utilsService.data.cloneImmutable(newValue);

      nextTick(() => {
        state.formHeight = formComponentRef.value.scrollHeight;
      });
    });

    watch(() => props.formData, (newValue) => {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      state.formData = utilsService.data.cloneImmutable(newValue);
    });

    onMounted(() => {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      state.formData = utilsService.data.cloneImmutable(props.formData);
      state.formConfig = utilsService.data.cloneImmutable(props.formConfig);
      state.formHeight = formComponentRef.value.scrollHeight;

      nextTick(() => {
        state.formHeight = formComponentRef.value.scrollHeight;
      });
    });

    const {
      fieldTypeCheckHandler,
      blockTypeCheckHandler,
      fieldValueHandler,
      checkConditionTool,
    } = formComponentService;

    function checkCondition(conditionList?: Array<ConditionModelType>): boolean {
      if (!conditionList || conditionList.length === 0) {
        return true;
      }

      return checkConditionTool.resolveAndCondition(conditionList, state.formData);
    }

    return {
      state,
      formComponentRef,
      fieldTypeCheckHandler,
      blockTypeCheckHandler,
      fieldValueHandler,
      handleStringBlur,
      handleDateInput,
      handleShowDropdown,
      handleTextInputListAddField,
      handleTextInputListDeleteField,
      handleTextInputListInput,
      handleMultipleSelectInput,
      handleChipsGroupChange,
      handleInputFile,
      handleDeleteFile,
      handleDownloadFile,
      checkCondition,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.form-component {
  max-width: 560px;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 540px;
    margin-bottom: 44px;
  }

  &__delimiter {
    width: 100%;
    margin: 0 0 44px;
    border: none;
    border-top: 1px solid #e5e5e5;
  }
}
.block {
  &__fields {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }

  &__subtitle {
    display: block;
    margin-top: 4px;
  }
}
</style>
