<template>
  <main
    :class="classes"
  >
    <LayoutWithoutToolbarContentHeader
      v-if="title"
      :title="title"
      class="layout-without-toolbar-container__header"
    />

    <LayoutWithoutToolbarContent>
      <template
        #content
      >
        <slot
          name="content"
        />
      </template>
    </LayoutWithoutToolbarContent>
  </main>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import ELayoutWidth from '@/app/Component/Layout/LayoutWithoutToolbar/Enum/ELayoutWidth';
import LayoutWithoutToolbarContent
  from '@/app/Component/Layout/LayoutWithoutToolbar/Module/LayoutWithoutToolbarContent.vue';
import LayoutWithoutToolbarContentHeader
  from '@/app/Component/Layout/LayoutWithoutToolbar/Module/LayoutWithoutToolbarContentHeader.vue';

export default defineComponent({
  components: {
    LayoutWithoutToolbarContentHeader,
    LayoutWithoutToolbarContent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String as PropType<ELayoutWidth>,
      default: ELayoutWidth.SM,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'layout-without-toolbar-container',
      `layout-without-toolbar-container_width_${props.width}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.layout-without-toolbar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: var(--layout-without-toolbar-container-xxl-padding-top)
    var(--layout-without-toolbar-container-xxl-padding-right)
    var(--layout-without-toolbar-container-xxl-padding-bottom)
    var(--layout-without-toolbar-container-xxl-padding-left);
  background-color: var(--color-white);
  transition: width ease-in 0.2s;
  gap: 22px;

  &_width {
    &_sm {
      max-width: calc(
        var(--layout-without-toolbar-container-max-width-s)
        + var(--layout-without-toolbar-container-xxl-padding-right)
        + var(--layout-without-toolbar-container-xxl-padding-left)
      );
    }

    &_md {
      max-width: calc(
        var(--layout-without-toolbar-container-max-width-m)
        + var(--layout-without-toolbar-container-xxl-padding-right)
        + var(--layout-without-toolbar-container-xxl-padding-left)
      );
    }

    &_lg {
      max-width: calc(
        var(--layout-without-toolbar-container-max-width-l)
        + var(--layout-without-toolbar-container-xxl-padding-right)
        + var(--layout-without-toolbar-container-xxl-padding-left)
      );
    }
  }

  @media screen and (max-width: $breakpoint-xl) {
    padding: var(--layout-without-toolbar-container-xl-padding-top)
      var(--layout-without-toolbar-container-xl-padding-right)
      var(--layout-without-toolbar-container-xl-padding-bottom)
      var(--layout-without-toolbar-container-xl-padding-left);

    &_width {
      &_sm {
        max-width: calc(
          var(--layout-without-toolbar-container-max-width-s)
          + var(--layout-without-toolbar-container-xl-padding-right)
          + var(--layout-without-toolbar-container-xl-padding-left)
        );
      }

      &_md {
        max-width: calc(
          var(--layout-without-toolbar-container-max-width-m)
          + var(--layout-without-toolbar-container-xl-padding-right)
          + var(--layout-without-toolbar-container-xl-padding-left)
        );
      }

      &_lg {
        max-width: calc(
          var(--layout-without-toolbar-container-max-width-l)
          + var(--layout-without-toolbar-container-xl-padding-right)
          + var(--layout-without-toolbar-container-xl-padding-left)
        );
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: var(--layout-without-toolbar-container-sm-padding-top)
      var(--layout-without-toolbar-container-sm-padding-right)
      var(--layout-without-toolbar-container-sm-padding-bottom)
      var(--layout-without-toolbar-container-sm-padding-left);

    &_width {
      &_sm {
        max-width: calc(
          var(--layout-without-toolbar-container-max-width-s)
          + var(--layout-without-toolbar-container-sm-padding-right)
          + var(--layout-without-toolbar-container-sm-padding-left)
        );
      }

      &_md {
        max-width: calc(
          var(--layout-without-toolbar-container-max-width-m)
          + var(--layout-without-toolbar-container-sm-padding-right)
          + var(--layout-without-toolbar-container-sm-padding-left)
        );
      }

      &_lg {
        max-width: calc(
          var(--layout-without-toolbar-container-max-width-l)
          + var(--layout-without-toolbar-container-sm-padding-right)
          + var(--layout-without-toolbar-container-sm-padding-left)
        );
      }
    }
  }
}
</style>
