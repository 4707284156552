import IFormDraftRequest from '@/shared/Api/Model/Common/IFormDraftRequest';
import IFormDraftResponse from '@/shared/Api/Model/Common/IFormDraftResponse';
import EFormName from '@/app/Enum/EFormName';
import IFormDraftApi from '@/shared/Api/Contract/Api/IFormDraftApi';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';

class FormDraftApi implements IFormDraftApi {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  create = async <FormData extends object>(
    requestParams: IFormDraftRequest<FormData>,
  ): Promise<IFormDraftResponse<FormData>> => this.clientService.instance
    .post<IFormDraftRequest<FormData>>('/common/form-data/draft', requestParams)
    .then((response) => response.data);

  get = <FormData extends object>(
    formName: EFormName,
  ): Promise<IFormDraftResponse<FormData>> => this.clientService.instance
    .get<IFormDraftRequest<FormData>>(`/common/form-data/draft/${formName}`)
    .then((response) => response.data);
}

export default FormDraftApi;
