import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b5c8d9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  fill: "none",
  viewBox: "0 0 20 20",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M7.08341 17.5H12.9167C13.4693 17.5 13.9992 17.2805 14.3899 16.8898C14.7806 16.4991 15.0001 15.9692 15.0001 15.4167V5.83333H5.00008V15.4167C5.00008 15.9692 5.21957 16.4991 5.61028 16.8898C6.00098 17.2805 6.53088 17.5 7.08341 17.5ZM7.30675 3.33333C7.45302 3.07997 7.66341 2.86958 7.91677 2.7233C8.17013 2.57702 8.45753 2.50001 8.75008 2.5H11.2501C11.5426 2.50001 11.83 2.57702 12.0834 2.7233C12.3367 2.86958 12.5471 3.07997 12.6934 3.33333H15.0001C15.2211 3.33333 15.4331 3.42113 15.5893 3.57741C15.7456 3.73369 15.8334 3.94565 15.8334 4.16667V4.79167C15.8334 4.84692 15.8115 4.89991 15.7724 4.93898C15.7333 4.97805 15.6803 5 15.6251 5H4.37508C4.31983 5 4.26684 4.97805 4.22777 4.93898C4.1887 4.89991 4.16675 4.84692 4.16675 4.79167V4.16667C4.16675 3.94565 4.25455 3.73369 4.41083 3.57741C4.56711 3.42113 4.77907 3.33333 5.00008 3.33333H7.30675Z"
    }, null, 2)
  ]))
}