import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cdb9aa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolio-form-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SButton = _resolveComponent("SButton")!
  const _component_FormComponent = _resolveComponent("FormComponent")!
  const _component_CStatusWindow = _resolveComponent("CStatusWindow")!
  const _component_LayoutWithoutToolbar = _resolveComponent("LayoutWithoutToolbar")!

  return (_openBlock(), _createBlock(_component_LayoutWithoutToolbar, {
    width: "md",
    title: _ctx.state.localisation.title
  }, {
    "control-left": _withCtx(() => [
      (!_ctx.state.isFormSent)
        ? (_openBlock(), _createBlock(_component_SButton, {
            key: 0,
            text: _ctx.buttonCancel.text,
            variant: _ctx.buttonCancel.variant,
            onClick: _ctx.buttonCancel.handler
          }, {
            "icon-before": _withCtx(() => [
              _createVNode(_component_SIcon, {
                color: _ctx.buttonCancel.icon.color,
                name: _ctx.buttonCancel.icon.name
              }, null, 8, ["color", "name"])
            ]),
            _: 1
          }, 8, ["text", "variant", "onClick"]))
        : (_openBlock(), _createBlock(_component_SButton, {
            key: 1,
            text: _ctx.buttonBack.text,
            variant: _ctx.buttonBack.variant,
            onClick: _ctx.buttonBack.handler
          }, {
            "icon-before": _withCtx(() => [
              _createVNode(_component_SIcon, {
                color: _ctx.buttonBack.icon.color,
                name: _ctx.buttonBack.icon.name
              }, null, 8, ["color", "name"])
            ]),
            _: 1
          }, 8, ["text", "variant", "onClick"]))
    ]),
    "control-right": _withCtx(() => [
      (!_ctx.state.isFormSent)
        ? (_openBlock(), _createBlock(_component_SButton, {
            key: 0,
            size: _ctx.buttonSave.size,
            text: _ctx.buttonSave.text,
            variant: _ctx.buttonSave.variant,
            onClick: _ctx.buttonSave.handler
          }, null, 8, ["size", "text", "variant", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.state.isFormSent)
          ? (_openBlock(), _createBlock(_component_FormComponent, {
              key: 0,
              "form-config": _ctx.state.formConfig,
              "form-data": _ctx.state.formData,
              onStringFieldBlur: _ctx.formFieldHandler.handleStringBlur,
              onTextInputListAdd: _ctx.formFieldHandler.handleTextInputListAddField,
              onTextInputListDelete: _ctx.formFieldHandler.handleTextInputListDeleteField,
              onTextInputListInput: _ctx.formFieldHandler.handleTextInputListInput,
              onFileUploadInput: _ctx.formFieldHandler.handleFileUploadInput,
              onDeleteFile: _ctx.formFieldHandler.handleDeleteFile,
              onDownloadFile: _ctx.formFieldHandler.handleDownloadFile
            }, null, 8, ["form-config", "form-data", "onStringFieldBlur", "onTextInputListAdd", "onTextInputListDelete", "onTextInputListInput", "onFileUploadInput", "onDeleteFile", "onDownloadFile"]))
          : (_openBlock(), _createBlock(_component_CStatusWindow, {
              key: 1,
              icon: _ctx.statusWindow.icon,
              title: _ctx.statusWindow.title,
              subtitle: _ctx.statusWindow.subtitle
            }, null, 8, ["icon", "title", "subtitle"]))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}