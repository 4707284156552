import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_CMenuSubItem = _resolveComponent("CMenuSubItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.mainClasses)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.itemClasses),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _createVNode(_component_SIcon, {
        name: _ctx.iconConfig.name,
        color: _ctx.iconConfig.color,
        class: "c-menu-item__icon"
      }, null, 8, ["name", "color"]),
      _createVNode(_component_STextElement, {
        color: _ctx.textElementConfig.color,
        variant: _ctx.textElementConfig.variant,
        class: "c-menu-item__text"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.title), 1)
        ]),
        _: 1
      }, 8, ["color", "variant"]),
      (_ctx.item.subList)
        ? (_openBlock(), _createBlock(_component_SIcon, {
            key: 0,
            class: _normalizeClass(_ctx.arrowClasses),
            name: _ctx.iconConfig.name,
            color: _ctx.iconConfig.color
          }, null, 8, ["class", "name", "color"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.item.subList)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.subListClasses),
          ref: "sublistRef"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.subList, (subItem) => {
            return (_openBlock(), _createBlock(_component_CMenuSubItem, {
              key: subItem.title,
              item: subItem
            }, null, 8, ["item"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}