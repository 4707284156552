<template>
  <div
    class="layout-without-toolbar-content"
  >
    <slot
      name="content"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';

export default defineComponent({});
</script>

<style
  lang="scss"
  scoped
>
.layout-without-toolbar-content {

}
</style>
