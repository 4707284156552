import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd50b016"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["stop"]))
  }, [
    _createElementVNode("input", {
      ref: "element",
      id: _ctx.id,
      checked: _ctx.state.checked,
      class: "s-checkbox__input",
      type: "checkbox"
    }, null, 8, _hoisted_1),
    _createVNode(_component_SIcon, {
      class: "s-checkbox__icon",
      name: "checkboxOn",
      color: _ctx.color,
      size: "s"
    }, null, 8, ["color"])
  ], 2))
}