<template>
  <div
    :class="classes"
  >
    <label
      :for="id"
      class="s-label__label"
    >
      <span
        v-if="isLabel"
        class="s-label__text"
      >
        <slot
          name="label"
        />
      </span>
    </label>
    <slot
      name="control"
      :id="id"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import SizeEnum from '../../../lib/Enum/SizeEnum';
import LabelSideEnum from '../../../lib/Enum/Components/Simple/UI/LabelSideEnum';

export default defineComponent({
  name: 'SLabel',
  props: {
    size: {
      type: String as PropType<SizeEnum>,
      default: SizeEnum.M,
    },
    side: {
      type: String as PropType<LabelSideEnum>,
      default: LabelSideEnum.TOP,
    },
  },
  setup(props, { slots }) {
    const isLabel = computed(() => !!slots.label);

    function generateUID() {
      return Date.now().toString(36) + Math.floor(10 ** 12 + Math.random() * 9 * 10 ** 12).toString(36);
    }

    const id = generateUID();

    const classes = computed(() => [
      's-label',
      `s-label_size-${props.size}`,
      `s-label_side-${props.side}`,
    ]);

    return {
      isLabel,
      classes,
      id,
    };
  },
});
</script>

<style
  lang="scss"
>
.s-label {
  display: flex;

  &_size-l {
    gap: var(--label-l-gap);
  }

  &_size-m {
    gap: var(--label-m-gap);
  }

  &_size-s {
    gap: var(--label-s-gap);
  }

  &_side-left {
    flex-direction: row;
    align-items: center;
  }

  &_side-top {
    flex-direction: column;
  }

  &_side-right {
    flex-direction: row-reverse;
    align-items: center;
  }

  &__label {
    cursor: pointer;
  }

  &__text {
    font-family: var(--label-font-family);
    font-size: var(--label-desktop-size);
    line-height: var(--label-desktop-line-height);
    font-weight: var(--label-desktop-weight);
    color: var(--color-stroke);
  }
}
</style>
