import IFieldStatusHandler from './Contract/IFieldStatusHandler';
import IValidator from './Contract/IValidator';
import IFieldStatus from './Model/FieldStatusInterface';
import StatusCodeEnum from './Enum/StatusCodeEnum';

class SimpleFieldStatusHandler implements IFieldStatusHandler {
  getStatus = (
    validator: IValidator,
  ): IFieldStatus => {
    const status = {
      code: validator.hasErrors() ? StatusCodeEnum.ERROR : StatusCodeEnum.DEFAULT,
    } as IFieldStatus;

    if (validator.hasErrors()) {
      // eslint-disable-next-line prefer-destructuring
      status.message = validator.getMessages()[0];
    }

    return status;
  };
}

export default SimpleFieldStatusHandler;
