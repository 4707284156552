enum ItalicTextEnum {
  SIZE_DESKTOP = '--text-variant-desktop-italic-size',
  LINE_HEIGHT_DESKTOP = '--text-variant-desktop-italic-line-height',
  WEIGHT_DESKTOP = '--text-variant-desktop-italic-weight',
  SIZE_MOBILE = '--text-variant-mobile-italic-size',
  LINE_HEIGHT_MOBILE = '--text-variant-mobile-italic-line-height',
  WEIGHT_MOBILE = '--text-variant-mobile-italic-weight',
}

export default ItalicTextEnum;
