<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path :class="classes"
      clip-rule="evenodd"
      d="M19.3636 18.2726L19.363 19.363L20.4545 19.3635V21.5453H3V19.3635L4.09 19.363L4.09091 18.2726H19.3636ZM18.2738 9.5508V17.1872H16.092V9.5508H18.2738ZM12.8193 9.54535V17.1817H10.6375V9.54535H12.8193ZM7.36473 9.54098V17.1773H5.18291V9.54098H7.36473ZM11.7275 3L20.4548 6.27273V8.45455H3.00022V6.27273L11.7275 3Z"
      fill-rule="evenodd"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>

</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconBank',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-bank_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.s-icon-bank {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

}
</style>
