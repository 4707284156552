<template>
  <div
    class="layout-without-sidebar"
  >
    <CTopBar>
      <template
        #left-side
      >
        <SoftlineLogoLink/>
        <router-link
          class="layout-without-sidebar__top-bar-title"
          to="/"
        >
          <SHeader
            :size="3"
            color="white"
          >
            {{ state.localisation.topBarTitle }}
          </SHeader>
          <SIcon
            :name="dotIcon.name"
          />
        </router-link>
      </template>
      <template
        #right-side
      >
        <CUserMenu
          :logout="logoutUserMenuItem"
          :user="userUserMenuItem"
        >
          <div
            v-if="state.partnerLevel"
            :class="state.partnerLevel.classes"
          >
            <STextElement
              color="secondary"
              variant="x-small-medium"
            >
              {{ state.partnerLevel?.title }}
            </STextElement>
          </div>
        </CUserMenu>
      </template>
    </CTopBar>
    <div
      class="layout-without-sidebar__main-section"
    >
      <div
        class="layout-without-sidebar__container"
      >
        <LayoutWithSidebarContainer
          :title="title"
        >
          <template
            v-if="isHeaderLeftSlot"
            #header-left
          >
            <slot
              name="header-left"
            />
          </template>
          <template
            v-if="isHeaderRightSlot"
            #header-right
          >
            <slot
              name="header-right"
            />
          </template>
          <template
            v-if="isContentSlot"
            #content
          >
            <slot
              name="content"
            />
          </template>
        </LayoutWithSidebarContainer>
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  reactive,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';

import {
  IconNameEnum,
  SIconPropsInterface,
  UserMenuItemInterface,
} from '@/shared/DesignSystem';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import IState from '@/app/Component/Layout/LayoutWithToolbar/Model/IState';

import SoftlineLogoLink from '@/shared/UI/SoftlineLogoLink.vue';
import LayoutWithSidebarContainer
  from '@/app/Component/Layout/LayoutWithToolbar/Module/LayoutWithToolbarContainer.vue';

export default defineComponent({
  components: {
    LayoutWithSidebarContainer,
    SoftlineLogoLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const { t } = useI18n();

    const isHeaderLeftSlot = computed(() => !!slots['header-left']);
    const isHeaderRightSlot = computed(() => !!slots['header-right']);
    const isContentSlot = computed(() => !!slots.content);

    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const {
      authorizationService,
      store,
    } = serviceContainer;

    const state = reactive<IState>({
      vendor: store.getters.getVendor,
      localisation: {
        topBarTitle: t('layout.toolbar.topbar.title'),
      },
      partnerLevel: null,
    });

    const userUserMenuItem: UserMenuItemInterface = {
      title: t('layout.toolbar.topbar.menu.profile'),
      text: state.vendor.email,
    };

    const logoutUserMenuItem: UserMenuItemInterface = {
      title: t('layout.toolbar.topbar.menu.logout'),
      icon: IconNameEnum.ARROW_RETURN,
      handler: async () => {
        await authorizationService.logout();
      },
    };

    if (state.vendor.levelPartnerProgram) {
      state.partnerLevel = {
        title: t(
          `layout.toolbar.topbar.menu.partner_level.${state.vendor.levelPartnerProgram}`,
        ),
        classes: [
          'layout-without-sidebar__partner-level',
          `layout-without-sidebar__partner-level_${state.vendor.levelPartnerProgram}`,
        ],
      };
    }

    const dotIcon: SIconPropsInterface = {
      name: IconNameEnum.DOTS,
    };

    return {
      state,
      dotIcon,
      userUserMenuItem,
      logoutUserMenuItem,
      isHeaderLeftSlot,
      isHeaderRightSlot,
      isContentSlot,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.layout-without-sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  &__top-bar {
    &-title {
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 8px;
    }
  }

  &__partner-level {
    padding: 6px 8px;
    border-radius: 12px;

    &_authorized {
      background-color: rgba(181, 149, 122, 0.20);
    }

    &_focused {
      background-color: rgba(255, 0, 4, 0.30);
    }

    &_key {
      background-color: rgba(181, 149, 122, 0.20);
    }

    &_strategic {
      background-color: rgba(181, 149, 122, 0.20);
    }
  }

  &__main-section {
    position: relative;
    display: flex;
    overflow: auto;
    flex: 1 1 auto;
    background-color: var(--color-white);
  }

  &__container {
    overflow: auto;
    flex: 1 1 auto;
    height: 100%;
    background-color: var(--color-white);
    transition: width ease-in 0.2s;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid #ffffff;
      border-radius: 5px;
      background: #aaaaaa;

      &:hover {
        background: #999999;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__top-bar {
      &-title {
        display: none;
      }
    }
  }
}
</style>
