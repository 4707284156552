<template>
  <main
    class="layout-with-sidebar-container"
  >
    <LayoutWithToolbarContentHeader
      v-if="title"
      class="layout-with-sidebar-container__header"
      :title="title"
    >
      <template
        v-if="isHeaderLeftSlot"
        #header-left
      >
        <slot
          name="header-left"
        />
      </template>
      <template
        v-if="isHeaderRightSlot"
        #header-right
      >
        <slot
          name="header-right"
        />
      </template>
    </LayoutWithToolbarContentHeader>

    <LayoutWithToolbarContent
      class="layout-with-sidebar-container__content"
    >
      <template
        v-if="isContentSlot"
        #content
      >
        <slot
          name="content"
        />
      </template>
    </LayoutWithToolbarContent>
  </main>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
} from 'vue';
import LayoutWithToolbarContentHeader
  from '@/app/Component/Layout/LayoutWithToolbar/Module/LayoutWithToolbarContentHeader.vue';
import LayoutWithToolbarContent
  from '@/app/Component/Layout/LayoutWithToolbar/Module/LayoutWithToolbarContent.vue';

export default defineComponent({
  components: {
    LayoutWithToolbarContent,
    LayoutWithToolbarContentHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const isHeaderLeftSlot = computed(() => !!slots['header-left']);
    const isHeaderRightSlot = computed(() => !!slots['header-right']);
    const isContentSlot = computed(() => !!slots.content);

    return {
      isHeaderLeftSlot,
      isHeaderRightSlot,
      isContentSlot,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
@import "@/app/style/breakpoints";

.layout-with-sidebar-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  margin: 0 auto;
  max-width: calc(
    var(--layout-with-toolbar-container-max-width)
    + var(--layout-with-toolbar-container-xxl-padding-right)
    + var(--layout-with-toolbar-container-xxl-padding-left)
  );

  padding:
    var(--layout-with-toolbar-container-xxl-padding-top)
    var(--layout-with-toolbar-container-xxl-padding-right)
    var(--layout-with-toolbar-container-xxl-padding-bottom)
    var(--layout-with-toolbar-container-xxl-padding-left);

  @media screen and (max-width: $breakpoint-xxl) {
    max-width: calc(
      var(--layout-with-toolbar-container-max-width)
      + var(--layout-with-toolbar-container-xl-padding-right)
      + var(--layout-with-toolbar-container-xl-padding-left)
    );
    padding:
      var(--layout-with-toolbar-container-xl-padding-top)
      var(--layout-with-toolbar-container-xl-padding-right)
      var(--layout-with-toolbar-container-xl-padding-bottom)
      var(--layout-with-toolbar-container-xl-padding-left);
  }

  @media screen and (max-width: $breakpoint-md) {
    max-width: calc(
      var(--layout-with-toolbar-container-max-width)
      + var(--layout-with-toolbar-container-sm-padding-right)
      + var(--layout-with-toolbar-container-sm-padding-left)
    );
    padding:
      var(--layout-with-toolbar-container-sm-padding-top)
      var(--layout-with-toolbar-container-sm-padding-right)
      var(--layout-with-toolbar-container-sm-padding-bottom)
      var(--layout-with-toolbar-container-sm-padding-left);
  }
}
</style>
