<template>
  <LayoutWithSidebar
    :title="state.localisation.title"
  >
    <template
      #content
    >
      <ServiceInfo
        :button="button"
        :content="state.localisation.content"
      />
    </template>
  </LayoutWithSidebar>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  reactive,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import {
  useI18n,
} from 'vue-i18n';

import ECoMarketingRoutePath from '@/app/Enum/router/Service/ECoMarketingRoutePath';
import IButtonConfig from '@/app/Model/UI/IButtonConfig';
import {
  SButtonVariantEnum,
  SizeEnum,
} from '@/shared/DesignSystem';

import LayoutWithSidebar from '@/app/Component/Layout/LayoutWithToolbar/LayoutWithSidebar.vue';
import ServiceInfo from '@/shared/Component/ServiceInfo/ServiceInfo.vue';

export default defineComponent({
  components: {
    ServiceInfo,
    LayoutWithSidebar,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    const state = reactive({
      localisation: {
        title: t('pages.co_marketing.overview.title'),
        content: t('pages.co_marketing.overview.content'),
      },
    });

    const button: IButtonConfig = {
      props: {
        variant: SButtonVariantEnum.FILLED,
        text: t('pages.co_marketing.overview.button'),
        size: SizeEnum.L,
      },
      handler: () => router.push(ECoMarketingRoutePath.FORM),
    };

    return {
      state,
      button,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>

</style>
