enum TextAreaEnum {
  BORDER_WIDTH = '--textarea-border-width',
  BORDER_RADIUS_TOP_RIGHT = '--textarea-border-radius-top-right',
  BORDER_RADIUS_TOP_LEFT = '--textarea-border-radius-top-left',
  BORDER_RADIUS_BOTTOM_LEFT = '--textarea-border-radius-bottom-left',
  BORDER_RADIUS_BOTTOM_RIGHT = '--textarea-border-radius-bottom-right',
  PADDING_TOP = '--textarea-s-padding-top',
  PADDING_RIGHT = '--textarea-s-padding-right',
  PADDING_BOTTOM = '--textarea-s-padding-bottom',
  PADDING_LEFT = '--textarea-s-padding-left',
}

export default TextAreaEnum;
