import TextAreaEnum from '@/shared/DesignSystem/lib/Enum/CSSVariables/Form/TextAreaEnum';
import StatusWindowEnum from '@/shared/DesignSystem/lib/Enum/CSSVariables/StatusWindowEnum';
import ChipsEnum from './Enum/CSSVariables/Form/ChipsEnum';
import FontFamilyEnum from './Enum/CSSVariables/Font/FontFamilyEnum';
import HeaderTextEnum from './Enum/CSSVariables/Font/HeaderTextEnum';
import ItalicTextEnum from './Enum/CSSVariables/Font/ItalicTextEnum';
import MediumTextEnum from './Enum/CSSVariables/Font/MediumTextEnum';
import RegularTextEnum from './Enum/CSSVariables/Font/RegularTextEnum';
import SmallTextEnum from './Enum/CSSVariables/Font/SmallTextEnum';
import SmallLongLineTextEnum from './Enum/CSSVariables/Font/SmallLongLineTextEnum';
import SmallMediumTextEnum from './Enum/CSSVariables/Font/SmallMediumTextEnum';
import SubheaderTextEnum from './Enum/CSSVariables/Font/SubheaderTextEnum';
import XSmallMediumTextEnum from './Enum/CSSVariables/Font/XSmallMediumTextEnum';
import ConfigInterface from './Model/ConfigInterface';
import DesignSystemInterface from './Contract/DesignSystemInterface';
import ColorSchemeType from './Model/ColorSchemeType';
import ColorEnum from './Enum/CSSVariables/ColorEnum';
import ButtonEnum from './Enum/CSSVariables/Form/ButtonEnum';
import CheckboxEnum from './Enum/CSSVariables/Form/CheckboxEnum';
import TextInputEnum from './Enum/CSSVariables/Form/TextInputEnum';
import SelectEnum from './Enum/CSSVariables/Form/SelectEnum';
import IconEnum from './Enum/CSSVariables/IconEnum';
import LabelEnum from './Enum/CSSVariables/Form/LabelEnum';

class DesignSystem implements DesignSystemInterface {
  private config: ConfigInterface;

  private schemas: ColorSchemeType;

  constructor(
    defaultConfig: ConfigInterface,
    config?: Partial<ConfigInterface>,
    schemas?: ColorSchemeType,
  ) {
    this.config = this.mergeConfig(defaultConfig, config);
    this.schemas = schemas ?? { default: this.config.color };
  }

  initCSSVariables = (): void => {
    const root = document.documentElement;

    this.setTextCSSVariables(root);
    this.setHeaderCSSVariables(root);
    this.setColorCSSVariables(root);
    this.setFormElementsCSSVariables(root);
    this.setIconsCSSVariables(root);
    this.setStatusWindowCSSVariables(root);
  };

  private setTextCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(FontFamilyEnum.TEXT_FONT_FAMILY, this.config.text.fontFamily);

    root.style.setProperty(
      ItalicTextEnum.SIZE_DESKTOP,
      `${this.config.text.italic.desktop.size}px`,
    );
    root.style.setProperty(
      ItalicTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.italic.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      ItalicTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.italic.desktop.weight}`,
    );
    root.style.setProperty(
      ItalicTextEnum.SIZE_MOBILE,
      `${this.config.text.italic.mobile.size}px`,
    );
    root.style.setProperty(
      ItalicTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.italic.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      ItalicTextEnum.WEIGHT_MOBILE,
      `${this.config.text.italic.mobile.weight}`,
    );

    root.style.setProperty(
      MediumTextEnum.SIZE_DESKTOP,
      `${this.config.text.medium.desktop.size}px`,
    );
    root.style.setProperty(
      MediumTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.medium.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      MediumTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.medium.desktop.weight}`,
    );
    root.style.setProperty(
      MediumTextEnum.SIZE_MOBILE,
      `${this.config.text.medium.mobile.size}px`,
    );
    root.style.setProperty(
      MediumTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.medium.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      MediumTextEnum.WEIGHT_MOBILE,
      `${this.config.text.medium.mobile.weight}`,
    );

    root.style.setProperty(
      RegularTextEnum.SIZE_DESKTOP,
      `${this.config.text.regular.desktop.size}px`,
    );
    root.style.setProperty(
      RegularTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.regular.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      RegularTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.regular.desktop.weight}`,
    );
    root.style.setProperty(
      RegularTextEnum.SIZE_MOBILE,
      `${this.config.text.regular.mobile.size}px`,
    );
    root.style.setProperty(
      RegularTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.regular.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      RegularTextEnum.WEIGHT_MOBILE,
      `${this.config.text.regular.mobile.weight}`,
    );

    root.style.setProperty(
      SmallTextEnum.SIZE_DESKTOP,
      `${this.config.text.small.desktop.size}px`,
    );
    root.style.setProperty(
      SmallTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.small.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      SmallTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.small.desktop.weight}`,
    );
    root.style.setProperty(
      SmallTextEnum.SIZE_MOBILE,
      `${this.config.text.small.mobile.size}px`,
    );
    root.style.setProperty(
      SmallTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.small.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      SmallTextEnum.WEIGHT_MOBILE,
      `${this.config.text.small.mobile.weight}`,
    );

    root.style.setProperty(
      SmallLongLineTextEnum.SIZE_DESKTOP,
      `${this.config.text.smallLongLine.desktop.size}px`,
    );
    root.style.setProperty(
      SmallLongLineTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.smallLongLine.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      SmallLongLineTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.smallLongLine.desktop.weight}`,
    );
    root.style.setProperty(
      SmallLongLineTextEnum.SIZE_MOBILE,
      `${this.config.text.smallLongLine.mobile.size}px`,
    );
    root.style.setProperty(
      SmallLongLineTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.smallLongLine.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      SmallLongLineTextEnum.WEIGHT_MOBILE,
      `${this.config.text.smallLongLine.mobile.weight}`,
    );

    root.style.setProperty(
      SmallMediumTextEnum.SIZE_DESKTOP,
      `${this.config.text.smallMedium.desktop.size}px`,
    );
    root.style.setProperty(
      SmallMediumTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.smallMedium.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      SmallMediumTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.smallMedium.desktop.weight}`,
    );
    root.style.setProperty(
      SmallMediumTextEnum.SIZE_MOBILE,
      `${this.config.text.smallMedium.mobile.size}px`,
    );
    root.style.setProperty(
      SmallMediumTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.smallMedium.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      SmallMediumTextEnum.WEIGHT_MOBILE,
      `${this.config.text.smallMedium.mobile.weight}`,
    );

    root.style.setProperty(
      SubheaderTextEnum.SIZE_DESKTOP,
      `${this.config.text.subheader.desktop.size}px`,
    );
    root.style.setProperty(
      SubheaderTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.subheader.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      SubheaderTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.subheader.desktop.weight}`,
    );
    root.style.setProperty(
      SubheaderTextEnum.SIZE_MOBILE,
      `${this.config.text.subheader.mobile.size}px`,
    );
    root.style.setProperty(
      SubheaderTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.subheader.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      SubheaderTextEnum.WEIGHT_MOBILE,
      `${this.config.text.subheader.mobile.weight}`,
    );

    root.style.setProperty(
      XSmallMediumTextEnum.SIZE_DESKTOP,
      `${this.config.text.xSmallMedium.desktop.size}px`,
    );
    root.style.setProperty(
      XSmallMediumTextEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.text.xSmallMedium.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      XSmallMediumTextEnum.WEIGHT_DESKTOP,
      `${this.config.text.xSmallMedium.desktop.weight}`,
    );
    root.style.setProperty(
      XSmallMediumTextEnum.SIZE_MOBILE,
      `${this.config.text.xSmallMedium.mobile.size}px`,
    );
    root.style.setProperty(
      XSmallMediumTextEnum.LINE_HEIGHT_MOBILE,
      `${this.config.text.xSmallMedium.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      XSmallMediumTextEnum.WEIGHT_MOBILE,
      `${this.config.text.xSmallMedium.mobile.weight}`,
    );
  };

  private setHeaderCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(FontFamilyEnum.HEADER_FONT_FAMILY, this.config.header.fontFamily);

    root.style.setProperty(
      HeaderTextEnum.H1_SIZE_DESKTOP,
      `${this.config.header.h1.desktop.size}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H1_LINE_HEIGHT_DESKTOP,
      `${this.config.header.h1.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H1_WEIGHT_DESKTOP,
      `${this.config.header.h1.desktop.weight}`,
    );
    root.style.setProperty(
      HeaderTextEnum.H1_SIZE_MOBILE,
      `${this.config.header.h1.mobile.size}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H1_LINE_HEIGHT_MOBILE,
      `${this.config.header.h1.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H1_WEIGHT_MOBILE,
      `${this.config.header.h1.mobile.weight}`,
    );

    root.style.setProperty(
      HeaderTextEnum.H2_SIZE_DESKTOP,
      `${this.config.header.h2.desktop.size}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H2_LINE_HEIGHT_DESKTOP,
      `${this.config.header.h2.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H2_WEIGHT_DESKTOP,
      `${this.config.header.h2.desktop.weight}`,
    );
    root.style.setProperty(
      HeaderTextEnum.H2_SIZE_MOBILE,
      `${this.config.header.h2.mobile.size}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H2_LINE_HEIGHT_MOBILE,
      `${this.config.header.h2.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H2_WEIGHT_MOBILE,
      `${this.config.header.h2.mobile.weight}`,
    );

    root.style.setProperty(
      HeaderTextEnum.H3_SIZE_DESKTOP,
      `${this.config.header.h3.desktop.size}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H3_LINE_HEIGHT_DESKTOP,
      `${this.config.header.h3.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H3_WEIGHT_DESKTOP,
      `${this.config.header.h3.desktop.weight}`,
    );
    root.style.setProperty(
      HeaderTextEnum.H3_SIZE_MOBILE,
      `${this.config.header.h3.mobile.size}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H3_LINE_HEIGHT_MOBILE,
      `${this.config.header.h3.mobile.lineHeight}px`,
    );
    root.style.setProperty(
      HeaderTextEnum.H3_WEIGHT_MOBILE,
      `${this.config.header.h3.mobile.weight}`,
    );
  };

  private setColorCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(ColorEnum.COLOR_DISABLED, this.config.color.disabled);
    root.style.setProperty(ColorEnum.COLOR_DISABLED_HOVER, this.config.color.disabledHover);
    root.style.setProperty(ColorEnum.COLOR_DISABLED_ACTIVE, this.config.color.disabledActive);
    root.style.setProperty(ColorEnum.COLOR_DISABLED_FOCUS, this.config.color.disabledFocus);
    root.style.setProperty(ColorEnum.COLOR_DANGER, this.config.color.danger);
    root.style.setProperty(ColorEnum.COLOR_DANGER_HOVER, this.config.color.dangerHover);
    root.style.setProperty(ColorEnum.COLOR_DANGER_ACTIVE, this.config.color.dangerActive);
    root.style.setProperty(ColorEnum.COLOR_DANGER_FOCUS, this.config.color.dangerFocus);
    root.style.setProperty(ColorEnum.COLOR_GRAY, this.config.color.gray);
    root.style.setProperty(ColorEnum.COLOR_GRAY_HOVER, this.config.color.grayHover);
    root.style.setProperty(ColorEnum.COLOR_GRAY_ACTIVE, this.config.color.grayActive);
    root.style.setProperty(ColorEnum.COLOR_GRAY_FOCUS, this.config.color.grayFocus);
    root.style.setProperty(ColorEnum.COLOR_LINKED, this.config.color.linked);
    root.style.setProperty(ColorEnum.COLOR_LINKED_HOVER, this.config.color.linkedHover);
    root.style.setProperty(ColorEnum.COLOR_LINKED_ACTIVE, this.config.color.linkedActive);
    root.style.setProperty(ColorEnum.COLOR_LINKED_FOCUS, this.config.color.linkedFocus);
    root.style.setProperty(ColorEnum.COLOR_NAVIGATION, this.config.color.navigation);
    root.style.setProperty(ColorEnum.COLOR_NAVIGATION_HOVER, this.config.color.navigationHover);
    root.style.setProperty(ColorEnum.COLOR_NAVIGATION_ACTIVE, this.config.color.navigationActive);
    root.style.setProperty(ColorEnum.COLOR_NAVIGATION_FOCUS, this.config.color.navigationFocus);
    root.style.setProperty(ColorEnum.COLOR_PRIMARY, this.config.color.primary);
    root.style.setProperty(ColorEnum.COLOR_PRIMARY_HOVER, this.config.color.primaryHover);
    root.style.setProperty(ColorEnum.COLOR_PRIMARY_ACTIVE, this.config.color.primaryActive);
    root.style.setProperty(ColorEnum.COLOR_PRIMARY_FOCUS, this.config.color.primaryFocus);
    root.style.setProperty(ColorEnum.COLOR_SECONDARY, this.config.color.secondary);
    root.style.setProperty(ColorEnum.COLOR_SECONDARY_HOVER, this.config.color.secondaryHover);
    root.style.setProperty(ColorEnum.COLOR_SECONDARY_ACTIVE, this.config.color.secondaryActive);
    root.style.setProperty(ColorEnum.COLOR_SECONDARY_FOCUS, this.config.color.secondaryFocus);
    root.style.setProperty(ColorEnum.COLOR_SUCCESS, this.config.color.success);
    root.style.setProperty(ColorEnum.COLOR_SUCCESS_HOVER, this.config.color.successHover);
    root.style.setProperty(ColorEnum.COLOR_SUCCESS_ACTIVE, this.config.color.successActive);
    root.style.setProperty(ColorEnum.COLOR_SUCCESS_FOCUS, this.config.color.successFocus);
    root.style.setProperty(ColorEnum.COLOR_TOOLBAR, this.config.color.toolbar);
    root.style.setProperty(ColorEnum.COLOR_TOOLBAR_HOVER, this.config.color.toolbarHover);
    root.style.setProperty(ColorEnum.COLOR_TOOLBAR_ACTIVE, this.config.color.toolbarActive);
    root.style.setProperty(ColorEnum.COLOR_TOOLBAR_FOCUS, this.config.color.toolbarFocus);
    root.style.setProperty(ColorEnum.COLOR_WARN, this.config.color.warn);
    root.style.setProperty(ColorEnum.COLOR_WARN_HOVER, this.config.color.warnHover);
    root.style.setProperty(ColorEnum.COLOR_WARN_ACTIVE, this.config.color.warnActive);
    root.style.setProperty(ColorEnum.COLOR_WARN_FOCUS, this.config.color.warnFocus);
    root.style.setProperty(ColorEnum.COLOR_WHITE, this.config.color.white);
    root.style.setProperty(ColorEnum.COLOR_WHITE_HOVER, this.config.color.whiteHover);
    root.style.setProperty(ColorEnum.COLOR_WHITE_ACTIVE, this.config.color.whiteActive);
    root.style.setProperty(ColorEnum.COLOR_WHITE_FOCUS, this.config.color.whiteFocus);
    root.style.setProperty(ColorEnum.COLOR_BLACK, this.config.color.black);
    root.style.setProperty(ColorEnum.COLOR_BLACK_HOVER, this.config.color.blackHover);
    root.style.setProperty(ColorEnum.COLOR_BLACK_ACTIVE, this.config.color.blackActive);
    root.style.setProperty(ColorEnum.COLOR_BLACK_FOCUS, this.config.color.blackFocus);
    root.style.setProperty(ColorEnum.COLOR_STROKE, this.config.color.stroke);
    root.style.setProperty(ColorEnum.COLOR_SURFACE, this.config.color.surface);
    root.style.setProperty(ColorEnum.COLOR_BACKGROUND_PRIMARY, this.config.color.backgroundPrimary);
    root.style.setProperty(ColorEnum.COLOR_BACKGROUND_DANGER, this.config.color.backgroundDanger);
    root.style.setProperty(ColorEnum.COLOR_BACKGROUND_WARN, this.config.color.backgroundWarn);
    root.style.setProperty(ColorEnum.COLOR_BACKGROUND_SUCCESS, this.config.color.backgroundSuccess);
    root.style.setProperty(ColorEnum.COLOR_BACKGROUND_GRAY, this.config.color.backgroundGray);
  }

  private setFormElementsCSSVariables = (root: HTMLElement) => {
    this.setButtonCSSVariables(root);
    this.setChipsCSSVariables(root);
    this.setTextInputCSSVariables(root);
    this.setTextAreaCSSVariables(root);
    this.setCheckboxCSSVariables(root);
    this.setSelectCSSVariables(root);
    this.setLabelCSSVariables(root);
  }

  private setButtonCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      ButtonEnum.BORDER_WIDTH,
      `${this.config.form.button.borderWidth}px`,
    );
    root.style.setProperty(
      ButtonEnum.BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.button.borderRadius.topRight}px`,
    );
    root.style.setProperty(
      ButtonEnum.BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.button.borderRadius.topLeft}px`,
    );
    root.style.setProperty(
      ButtonEnum.BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.button.borderRadius.bottomLeft}px`,
    );
    root.style.setProperty(
      ButtonEnum.BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.button.borderRadius.bottomRight}px`,
    );
    root.style.setProperty(
      ButtonEnum.L_PADDING_TOP,
      `${this.config.form.button.padding.l.top}px`,
    );
    root.style.setProperty(
      ButtonEnum.L_PADDING_RIGHT,
      `${this.config.form.button.padding.l.right}px`,
    );
    root.style.setProperty(
      ButtonEnum.L_PADDING_BOTTOM,
      `${this.config.form.button.padding.l.bottom}px`,
    );
    root.style.setProperty(
      ButtonEnum.L_PADDING_LEFT,
      `${this.config.form.button.padding.l.left}px`,
    );
    root.style.setProperty(
      ButtonEnum.M_PADDING_TOP,
      `${this.config.form.button.padding.m.top}px`,
    );
    root.style.setProperty(
      ButtonEnum.M_PADDING_RIGHT,
      `${this.config.form.button.padding.m.right}px`,
    );
    root.style.setProperty(
      ButtonEnum.M_PADDING_BOTTOM,
      `${this.config.form.button.padding.m.bottom}px`,
    );
    root.style.setProperty(
      ButtonEnum.M_PADDING_LEFT,
      `${this.config.form.button.padding.m.left}px`,
    );
    root.style.setProperty(
      ButtonEnum.S_PADDING_TOP,
      `${this.config.form.button.padding.s.top}px`,
    );
    root.style.setProperty(
      ButtonEnum.S_PADDING_RIGHT,
      `${this.config.form.button.padding.s.right}px`,
    );
    root.style.setProperty(
      ButtonEnum.S_PADDING_BOTTOM,
      `${this.config.form.button.padding.s.bottom}px`,
    );
    root.style.setProperty(
      ButtonEnum.S_PADDING_LEFT,
      `${this.config.form.button.padding.s.left}px`,
    );
    root.style.setProperty(
      ButtonEnum.L_GAP,
      `${this.config.form.button.gap.l}px`,
    );
    root.style.setProperty(
      ButtonEnum.M_GAP,
      `${this.config.form.button.gap.m}px`,
    );
    root.style.setProperty(
      ButtonEnum.S_GAP,
      `${this.config.form.button.gap.s}px`,
    );
  };

  private setChipsCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      ChipsEnum.BORDER_WIDTH,
      `${this.config.form.chips.borderWidth}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.chips.borderRadius.l.topRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.chips.borderRadius.l.topLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.chips.borderRadius.l.bottomLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.chips.borderRadius.l.bottomRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.chips.borderRadius.m.topRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.chips.borderRadius.m.topLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.chips.borderRadius.m.bottomLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.chips.borderRadius.m.bottomRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.chips.borderRadius.s.topRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.chips.borderRadius.s.topLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.chips.borderRadius.s.bottomLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.chips.borderRadius.s.bottomRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.chips.borderRadius.xs.topRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.chips.borderRadius.xs.topLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.chips.borderRadius.xs.bottomLeft}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.chips.borderRadius.xs.bottomRight}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_PADDING_TOP,
      `${this.config.form.chips.padding.l.top}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_PADDING_RIGHT,
      `${this.config.form.chips.padding.l.right}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_PADDING_BOTTOM,
      `${this.config.form.chips.padding.l.bottom}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_PADDING_LEFT,
      `${this.config.form.chips.padding.l.left}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_PADDING_TOP,
      `${this.config.form.chips.padding.m.top}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_PADDING_RIGHT,
      `${this.config.form.chips.padding.m.right}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_PADDING_BOTTOM,
      `${this.config.form.chips.padding.m.bottom}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_PADDING_LEFT,
      `${this.config.form.chips.padding.m.left}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_PADDING_TOP,
      `${this.config.form.chips.padding.s.top}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_PADDING_RIGHT,
      `${this.config.form.chips.padding.s.right}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_PADDING_BOTTOM,
      `${this.config.form.chips.padding.s.bottom}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_PADDING_LEFT,
      `${this.config.form.chips.padding.s.left}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_PADDING_TOP,
      `${this.config.form.chips.padding.xs.top}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_PADDING_RIGHT,
      `${this.config.form.chips.padding.xs.right}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_PADDING_BOTTOM,
      `${this.config.form.chips.padding.xs.bottom}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_PADDING_LEFT,
      `${this.config.form.chips.padding.xs.left}px`,
    );
    root.style.setProperty(
      ChipsEnum.L_GAP,
      `${this.config.form.chips.gap.l}px`,
    );
    root.style.setProperty(
      ChipsEnum.M_GAP,
      `${this.config.form.chips.gap.m}px`,
    );
    root.style.setProperty(
      ChipsEnum.S_GAP,
      `${this.config.form.chips.gap.s}px`,
    );
    root.style.setProperty(
      ChipsEnum.XS_GAP,
      `${this.config.form.chips.gap.xs}px`,
    );
  };

  private setTextInputCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      TextInputEnum.BORDER_WIDTH,
      `${this.config.form.textInput.borderWidth}px`,
    );
    root.style.setProperty(
      TextInputEnum.BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.textInput.borderRadius.topRight}px`,
    );
    root.style.setProperty(
      TextInputEnum.BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.textInput.borderRadius.topLeft}px`,
    );
    root.style.setProperty(
      TextInputEnum.BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.textInput.borderRadius.bottomLeft}px`,
    );
    root.style.setProperty(
      TextInputEnum.BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.textInput.borderRadius.bottomRight}px`,
    );
    root.style.setProperty(
      TextInputEnum.L_PADDING_TOP,
      `${this.config.form.textInput.padding.l.top}px`,
    );
    root.style.setProperty(
      TextInputEnum.L_PADDING_RIGHT,
      `${this.config.form.textInput.padding.l.right}px`,
    );
    root.style.setProperty(
      TextInputEnum.L_PADDING_BOTTOM,
      `${this.config.form.textInput.padding.l.bottom}px`,
    );
    root.style.setProperty(
      TextInputEnum.L_PADDING_LEFT,
      `${this.config.form.textInput.padding.l.left}px`,
    );
    root.style.setProperty(
      TextInputEnum.M_PADDING_TOP,
      `${this.config.form.textInput.padding.m.top}px`,
    );
    root.style.setProperty(
      TextInputEnum.M_PADDING_RIGHT,
      `${this.config.form.textInput.padding.m.right}px`,
    );
    root.style.setProperty(
      TextInputEnum.M_PADDING_BOTTOM,
      `${this.config.form.textInput.padding.m.bottom}px`,
    );
    root.style.setProperty(
      TextInputEnum.M_PADDING_LEFT,
      `${this.config.form.textInput.padding.m.left}px`,
    );
    root.style.setProperty(
      TextInputEnum.S_PADDING_TOP,
      `${this.config.form.textInput.padding.s.top}px`,
    );
    root.style.setProperty(
      TextInputEnum.S_PADDING_RIGHT,
      `${this.config.form.textInput.padding.s.right}px`,
    );
    root.style.setProperty(
      TextInputEnum.S_PADDING_BOTTOM,
      `${this.config.form.textInput.padding.s.bottom}px`,
    );
    root.style.setProperty(
      TextInputEnum.S_PADDING_LEFT,
      `${this.config.form.textInput.padding.s.left}px`,
    );
    root.style.setProperty(
      TextInputEnum.L_GAP,
      `${this.config.form.textInput.gap.l}px`,
    );
    root.style.setProperty(
      TextInputEnum.M_GAP,
      `${this.config.form.textInput.gap.m}px`,
    );
    root.style.setProperty(
      TextInputEnum.S_GAP,
      `${this.config.form.textInput.gap.s}px`,
    );
    root.style.setProperty(
      TextInputEnum.L_HEIGHT,
      `${this.config.form.textInput.height.l}px`,
    );
    root.style.setProperty(
      TextInputEnum.M_HEIGHT,
      `${this.config.form.textInput.height.m}px`,
    );
    root.style.setProperty(
      TextInputEnum.S_HEIGHT,
      `${this.config.form.textInput.height.s}px`,
    );
  };

  private setTextAreaCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      TextAreaEnum.BORDER_WIDTH,
      `${this.config.form.textArea.borderWidth}px`,
    );
    root.style.setProperty(
      TextAreaEnum.BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.textArea.borderRadius.topRight}px`,
    );
    root.style.setProperty(
      TextAreaEnum.BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.textArea.borderRadius.topLeft}px`,
    );
    root.style.setProperty(
      TextAreaEnum.BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.textArea.borderRadius.bottomLeft}px`,
    );
    root.style.setProperty(
      TextAreaEnum.BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.textArea.borderRadius.bottomRight}px`,
    );
    root.style.setProperty(
      TextAreaEnum.PADDING_TOP,
      `${this.config.form.textArea.padding.top}px`,
    );
    root.style.setProperty(
      TextAreaEnum.PADDING_RIGHT,
      `${this.config.form.textArea.padding.right}px`,
    );
    root.style.setProperty(
      TextAreaEnum.PADDING_BOTTOM,
      `${this.config.form.textArea.padding.bottom}px`,
    );
    root.style.setProperty(
      TextAreaEnum.PADDING_LEFT,
      `${this.config.form.textArea.padding.left}px`,
    );
  };

  private setCheckboxCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      CheckboxEnum.BORDER_WIDTH,
      `${this.config.form.checkbox.borderWidth}px`,
    );
    root.style.setProperty(
      CheckboxEnum.BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.checkbox.borderRadius.topRight}px`,
    );
    root.style.setProperty(
      CheckboxEnum.BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.checkbox.borderRadius.topLeft}px`,
    );
    root.style.setProperty(
      CheckboxEnum.BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.checkbox.borderRadius.bottomLeft}px`,
    );
    root.style.setProperty(
      CheckboxEnum.BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.checkbox.borderRadius.bottomRight}px`,
    );
    root.style.setProperty(
      CheckboxEnum.S_WIDTH,
      `${this.config.form.checkbox.size.s.width}px`,
    );
    root.style.setProperty(
      CheckboxEnum.S_HEIGHT,
      `${this.config.form.checkbox.size.s.height}px`,
    );
    root.style.setProperty(
      CheckboxEnum.M_WIDTH,
      `${this.config.form.checkbox.size.m.width}px`,
    );
    root.style.setProperty(
      CheckboxEnum.M_HEIGHT,
      `${this.config.form.checkbox.size.m.height}px`,
    );
    root.style.setProperty(
      CheckboxEnum.L_WIDTH,
      `${this.config.form.checkbox.size.l.width}px`,
    );
    root.style.setProperty(
      CheckboxEnum.L_HEIGHT,
      `${this.config.form.checkbox.size.l.height}px`,
    );
  };

  private setSelectCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      SelectEnum.BORDER_WIDTH,
      `${this.config.form.select.borderWidth}px`,
    );
    root.style.setProperty(
      SelectEnum.BORDER_RADIUS_TOP_RIGHT,
      `${this.config.form.select.borderRadius.topRight}px`,
    );
    root.style.setProperty(
      SelectEnum.BORDER_RADIUS_TOP_LEFT,
      `${this.config.form.select.borderRadius.topLeft}px`,
    );
    root.style.setProperty(
      SelectEnum.BORDER_RADIUS_BOTTOM_LEFT,
      `${this.config.form.select.borderRadius.bottomLeft}px`,
    );
    root.style.setProperty(
      SelectEnum.BORDER_RADIUS_BOTTOM_RIGHT,
      `${this.config.form.select.borderRadius.bottomRight}px`,
    );
  };

  private setLabelCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      FontFamilyEnum.LABEL,
      this.config.form.label.fontFamily,
    );

    root.style.setProperty(
      LabelEnum.L_GAP,
      `${this.config.form.label.gap.l}px`,
    );
    root.style.setProperty(
      LabelEnum.M_GAP,
      `${this.config.form.label.gap.m}px`,
    );
    root.style.setProperty(
      LabelEnum.S_GAP,
      `${this.config.form.label.gap.s}px`,
    );

    root.style.setProperty(
      LabelEnum.SIZE_DESKTOP,
      `${this.config.form.label.desktop.size}px`,
    );
    root.style.setProperty(
      LabelEnum.LINE_HEIGHT_DESKTOP,
      `${this.config.form.label.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      LabelEnum.WEIGHT_DESKTOP,
      `${this.config.form.label.desktop.weight}`,
    );
    root.style.setProperty(
      LabelEnum.SIZE_MOBILE,
      `${this.config.form.label.desktop.size}px`,
    );
    root.style.setProperty(
      LabelEnum.LINE_HEIGHT_MOBILE,
      `${this.config.form.label.desktop.lineHeight}px`,
    );
    root.style.setProperty(
      LabelEnum.WEIGHT_MOBILE,
      `${this.config.form.label.desktop.weight}`,
    );
  };

  private setIconsCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      IconEnum.S_WIDTH,
      `${this.config.icon.s.width}px`,
    );
    root.style.setProperty(
      IconEnum.S_HEIGHT,
      `${this.config.icon.s.height}px`,
    );
    root.style.setProperty(
      IconEnum.M_WIDTH,
      `${this.config.icon.m.width}px`,
    );
    root.style.setProperty(
      IconEnum.M_HEIGHT,
      `${this.config.icon.m.height}px`,
    );
    root.style.setProperty(
      IconEnum.L_WIDTH,
      `${this.config.icon.l.width}px`,
    );
    root.style.setProperty(
      IconEnum.L_HEIGHT,
      `${this.config.icon.l.height}px`,
    );
    root.style.setProperty(
      IconEnum.XL_WIDTH,
      `${this.config.icon.xl.width}px`,
    );
    root.style.setProperty(
      IconEnum.XL_HEIGHT,
      `${this.config.icon.xl.height}px`,
    );
    root.style.setProperty(
      IconEnum.XXL_WIDTH,
      `${this.config.icon.xxl.width}px`,
    );
    root.style.setProperty(
      IconEnum.XXL_HEIGHT,
      `${this.config.icon.xxl.height}px`,
    );
  };

  private setStatusWindowCSSVariables = (root: HTMLElement) => {
    root.style.setProperty(
      StatusWindowEnum.BORDER_RADIUS,
      `${this.config.statusWindow.borderRadius}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_L_TOP,
      `${this.config.statusWindow.padding.l.top}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_L_RIGHT,
      `${this.config.statusWindow.padding.l.right}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_L_BOTTOM,
      `${this.config.statusWindow.padding.l.bottom}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_L_LEFT,
      `${this.config.statusWindow.padding.l.left}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_M_TOP,
      `${this.config.statusWindow.padding.m.top}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_M_RIGHT,
      `${this.config.statusWindow.padding.m.right}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_M_BOTTOM,
      `${this.config.statusWindow.padding.m.bottom}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.PADDING_M_LEFT,
      `${this.config.statusWindow.padding.m.left}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_L_TOP,
      `${this.config.statusWindow.xs.padding.l.top}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_L_RIGHT,
      `${this.config.statusWindow.xs.padding.l.right}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_L_BOTTOM,
      `${this.config.statusWindow.xs.padding.l.bottom}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_L_LEFT,
      `${this.config.statusWindow.xs.padding.l.left}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_M_TOP,
      `${this.config.statusWindow.xs.padding.m.top}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_M_RIGHT,
      `${this.config.statusWindow.xs.padding.m.right}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_M_BOTTOM,
      `${this.config.statusWindow.xs.padding.m.bottom}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.XS_PADDING_M_LEFT,
      `${this.config.statusWindow.xs.padding.m.left}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.MARGIN_AFTER_ICON,
      `${this.config.statusWindow.margin.afterIcon}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.MARGIN_AFTER_TITLE,
      `${this.config.statusWindow.margin.afterTitle}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.MARGIN_AFTER_TEXT,
      `${this.config.statusWindow.margin.afterText}px`,
    );
    root.style.setProperty(
      StatusWindowEnum.BUTTON_GAP,
      `${this.config.statusWindow.buttonGap}px`,
    );
  };

  private mergeConfig = (
    defaultConfig: ConfigInterface,
    config?: Partial<ConfigInterface>,
  ): ConfigInterface => {
    if (!config) {
      return defaultConfig;
    }

    defaultConfig.color = {
      ...defaultConfig.color,
      ...(config.color ?? {}),
    };

    defaultConfig.header.fontFamily = config.header?.fontFamily ?? defaultConfig.header.fontFamily;

    defaultConfig.header.h1.desktop = {
      ...defaultConfig.header.h1.desktop,
      ...(config.header?.h1?.desktop ?? {}),
    };
    defaultConfig.header.h1.mobile = {
      ...defaultConfig.header.h1.mobile,
      ...(config.header?.h1?.mobile ?? {}),
    };

    defaultConfig.header.h2.desktop = {
      ...defaultConfig.header.h2.desktop,
      ...(config.header?.h2?.desktop ?? {}),
    };
    defaultConfig.header.h2.mobile = {
      ...defaultConfig.header.h2.mobile,
      ...(config.header?.h2?.mobile ?? {}),
    };

    defaultConfig.header.h3.desktop = {
      ...defaultConfig.header.h3.desktop,
      ...(config.header?.h3?.desktop ?? {}),
    };
    defaultConfig.header.h3.mobile = {
      ...defaultConfig.header.h3.mobile,
      ...(config.header?.h3?.mobile ?? {}),
    };

    defaultConfig.text.fontFamily = config.text?.fontFamily ?? defaultConfig.header.fontFamily;

    defaultConfig.text.italic.desktop = {
      ...defaultConfig.text.italic.desktop,
      ...(config.text?.italic?.desktop ?? {}),
    };
    defaultConfig.text.italic.mobile = {
      ...defaultConfig.text.italic.mobile,
      ...(config.text?.italic?.mobile ?? {}),
    };

    defaultConfig.text.medium.desktop = {
      ...defaultConfig.text.medium.desktop,
      ...(config.text?.medium?.desktop ?? {}),
    };
    defaultConfig.text.medium.mobile = {
      ...defaultConfig.text.medium.mobile,
      ...(config.text?.medium?.mobile ?? {}),
    };

    defaultConfig.text.regular.desktop = {
      ...defaultConfig.text.regular.desktop,
      ...(config.text?.regular?.desktop ?? {}),
    };
    defaultConfig.text.regular.mobile = {
      ...defaultConfig.text.regular.mobile,
      ...(config.text?.regular?.mobile ?? {}),
    };

    defaultConfig.text.small.desktop = {
      ...defaultConfig.text.small.desktop,
      ...(config.text?.small?.desktop ?? {}),
    };
    defaultConfig.text.small.mobile = {
      ...defaultConfig.text.small.mobile,
      ...(config.text?.small?.mobile ?? {}),
    };

    defaultConfig.text.smallLongLine.desktop = {
      ...defaultConfig.text.smallLongLine.desktop,
      ...(config.text?.smallLongLine?.desktop ?? {}),
    };
    defaultConfig.text.smallLongLine.mobile = {
      ...defaultConfig.text.smallLongLine.mobile,
      ...(config.text?.smallLongLine?.mobile ?? {}),
    };

    defaultConfig.text.smallMedium.desktop = {
      ...defaultConfig.text.smallMedium.desktop,
      ...(config.text?.smallMedium?.desktop ?? {}),
    };
    defaultConfig.text.smallMedium.mobile = {
      ...defaultConfig.text.smallMedium.mobile,
      ...(config.text?.smallMedium?.mobile ?? {}),
    };

    defaultConfig.text.subheader.desktop = {
      ...defaultConfig.text.subheader.desktop,
      ...(config.text?.subheader?.desktop ?? {}),
    };
    defaultConfig.text.subheader.mobile = {
      ...defaultConfig.text.subheader.mobile,
      ...(config.text?.subheader?.mobile ?? {}),
    };

    defaultConfig.text.xSmallMedium.desktop = {
      ...defaultConfig.text.xSmallMedium.desktop,
      ...(config.text?.xSmallMedium?.desktop ?? {}),
    };
    defaultConfig.text.xSmallMedium.mobile = {
      ...defaultConfig.text.xSmallMedium.mobile,
      ...(config.text?.xSmallMedium?.mobile ?? {}),
    };

    defaultConfig.form.button.borderWidth = config.form?.button?.borderWidth
      ?? defaultConfig.form.button.borderWidth;
    defaultConfig.form.button.borderRadius = {
      ...defaultConfig.form.button.borderRadius,
      ...(config.form?.button?.borderRadius ?? {}),
    };
    defaultConfig.form.button.padding.l = {
      ...defaultConfig.form.button.padding.l,
      ...(config.form?.button?.padding.l ?? {}),
    };
    defaultConfig.form.button.padding.m = {
      ...defaultConfig.form.button.padding.m,
      ...(config.form?.button?.padding.m ?? {}),
    };
    defaultConfig.form.button.padding.s = {
      ...defaultConfig.form.button.padding.s,
      ...(config.form?.button?.padding.s ?? {}),
    };
    defaultConfig.form.button.gap = {
      ...defaultConfig.form.button.gap,
      ...(config.form?.button?.gap ?? {}),
    };

    defaultConfig.form.label.fontFamily = config.form?.label?.fontFamily
      ?? defaultConfig.form.label.fontFamily;
    defaultConfig.form.label.desktop = {
      ...defaultConfig.form.label.desktop,
      ...(config.form?.label?.desktop ?? {}),
    };
    defaultConfig.form.label.mobile = {
      ...defaultConfig.form.label.mobile,
      ...(config.form?.label?.mobile ?? {}),
    };
    defaultConfig.form.label.gap = {
      ...defaultConfig.form.label.gap,
      ...(config.form?.label?.gap ?? {}),
    };

    defaultConfig.form.chips.borderWidth = config.form?.chips?.borderWidth
      ?? defaultConfig.form.chips.borderWidth;
    defaultConfig.form.chips.borderRadius = {
      ...defaultConfig.form.chips.borderRadius,
      ...(config.form?.chips?.borderRadius ?? {}),
    };
    defaultConfig.form.chips.padding.l = {
      ...defaultConfig.form.chips.padding.l,
      ...(config.form?.chips?.padding.l ?? {}),
    };
    defaultConfig.form.chips.padding.m = {
      ...defaultConfig.form.chips.padding.m,
      ...(config.form?.chips?.padding.m ?? {}),
    };
    defaultConfig.form.chips.padding.s = {
      ...defaultConfig.form.chips.padding.s,
      ...(config.form?.chips?.padding.s ?? {}),
    };
    defaultConfig.form.chips.gap = {
      ...defaultConfig.form.chips.gap,
      ...(config.form?.chips?.gap ?? {}),
    };

    defaultConfig.form.textInput.borderWidth = config.form?.textInput?.borderWidth
      ?? defaultConfig.form.textInput.borderWidth;
    defaultConfig.form.textInput.borderRadius = {
      ...defaultConfig.form.textInput.borderRadius,
      ...(config.form?.textInput?.borderRadius ?? {}),
    };

    defaultConfig.form.select.borderWidth = config.form?.select?.borderWidth
      ?? defaultConfig.form.select.borderWidth;
    defaultConfig.form.select.borderRadius = {
      ...defaultConfig.form.select.borderRadius,
      ...(config.form?.select?.borderRadius ?? {}),
    };

    defaultConfig.form.checkbox.borderWidth = config.form?.checkbox?.borderWidth
      ?? defaultConfig.form.checkbox.borderWidth;
    defaultConfig.form.checkbox.borderRadius = {
      ...defaultConfig.form.checkbox.borderRadius,
      ...(config.form?.checkbox?.borderRadius ?? {}),
    };

    return defaultConfig;
  };
}

export default DesignSystem;
