<template>
  <LayoutWithSidebar
    :title="state.localisation.title"
  >
    <template
      #content
    >
      <div
        class="services-page"
      >
        <PartnerLevelInfo
          :service-list="partnerLevelInfo.serviceList"
          :text="partnerLevelInfo.text"
          :title="partnerLevelInfo.title"
        />

        <div
          class="services-page__service"
        >
          <SHeader
            :size="3"
          >
            {{ state.localisation.serviceTitle }}
          </SHeader>
          <div
            class="services-page__service-list"
          >
            <ServiceBanner
              v-for="(service, index) in partnerLevelInfo.serviceList"
              :key="`service-item-${index}`"
              :service="service"
            />
          </div>
        </div>
      </div>
    </template>
  </LayoutWithSidebar>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
  reactive,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';

import EVendorPartnerProgramLevel from '@/entities/Enum/EVendorPartnerProgramLevel';

import IServicesPageState from '@/pages/HomePage/Model/IServicesPageState';
import IPartnerLevelInfo from '@/pages/HomePage/Model/IPartnerLevelInfo';

import LayoutWithSidebar from '@/app/Component/Layout/LayoutWithToolbar/LayoutWithSidebar.vue';
import PartnerLevelInfo from '@/pages/HomePage/Module/PartnerLevelInfo.vue';
import ServicePage from '@/pages/HomePage/Module/ServicePage';
import ServiceBanner from '@/pages/HomePage/Module/ServiceBanner.vue';
import SHeader from '@/shared/DesignSystem/Components/Simple/Text/SHeader.vue';

export default defineComponent({
  components: {
    SHeader,
    ServiceBanner,
    LayoutWithSidebar,
    PartnerLevelInfo,
  },
  props: {
    partnerProgramLevel: {
      type: String as PropType<EVendorPartnerProgramLevel>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const state = reactive<IServicesPageState>({
      localisation: {
        title: t('pages.home.services.title'),
        serviceTitle: t('pages.home.services.service_title'),
      },
    });

    const servicePage = new ServicePage(t);

    const partnerLevelInfo = computed<IPartnerLevelInfo>(
      () => servicePage.getPartnerLevelInfo(props.partnerProgramLevel),
    );

    return {
      state,
      partnerLevelInfo,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.services-page {
  display: flex;
  flex-direction: column;
  gap: 44px;

  &__service {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    &__service {
      &-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__service {
      &-list {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
</style>
