import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a023c2f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-page" }
const _hoisted_2 = { class: "error-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SButton = _resolveComponent("SButton")!
  const _component_CStatusWindow = _resolveComponent("CStatusWindow")!
  const _component_LayoutWithoutSidebar = _resolveComponent("LayoutWithoutSidebar")!
  const _component_LayoutWithSidebar = _resolveComponent("LayoutWithSidebar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.vendor.levelPartnerProgram === null)
      ? (_openBlock(), _createBlock(_component_LayoutWithoutSidebar, {
          key: 0,
          width: "lg"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_CStatusWindow, {
                subtitle: _ctx.statusWindowComponent.subtitle,
                title: _ctx.statusWindowComponent.title
              }, {
                buttons: _withCtx(() => [
                  _createVNode(_component_SButton, {
                    text: _ctx.buttonHome.text,
                    variant: _ctx.buttonHome.variant,
                    onClick: _ctx.buttonHome.handler
                  }, {
                    "icon-before": _withCtx(() => [
                      _createVNode(_component_SIcon, {
                        color: _ctx.buttonHome.icon.color,
                        name: _ctx.buttonHome.icon.name
                      }, null, 8, ["color", "name"])
                    ]),
                    _: 1
                  }, 8, ["text", "variant", "onClick"])
                ]),
                _: 1
              }, 8, ["subtitle", "title"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_LayoutWithSidebar, { width: "lg" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CStatusWindow, {
            subtitle: _ctx.statusWindowComponent.subtitle,
            title: _ctx.statusWindowComponent.title
          }, {
            buttons: _withCtx(() => [
              _createVNode(_component_SButton, {
                text: _ctx.buttonHome.text,
                variant: _ctx.buttonHome.variant,
                onClick: _ctx.buttonHome.handler
              }, {
                "icon-before": _withCtx(() => [
                  _createVNode(_component_SIcon, {
                    color: _ctx.buttonHome.icon.color,
                    name: _ctx.buttonHome.icon.name
                  }, null, 8, ["color", "name"])
                ]),
                _: 1
              }, 8, ["text", "variant", "onClick"])
            ]),
            _: 1
          }, 8, ["subtitle", "title"])
        ])
      ]),
      _: 1
    })
  ], 64))
}