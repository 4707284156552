import {
  EValidatorType,
  IValidator,
  IValidatorPlugin,
  StatusCodeEnum,

} from '@/shared/Validator';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import FormBlockTypeEnum from '@/widgets/FormComponent/lib/Enum/FormBlockTypeEnum';
import IHandlerService from '@/pages/Service/About/AboutFormPage/Contract/IHandlerService';
import IState from '@/pages/Service/About/AboutFormPage/Model/IState';
import IValidationHandler from '@/pages/Service/About/AboutFormPage/Contract/Handler/IValidationHandler';
import EFieldName from '@/pages/Service/About/AboutFormPage/Enum/EFieldName';
import IFormData from '@/pages/Service/About/AboutFormPage/Model/IFormData';
import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';

class ValidationHandler implements IValidationHandler {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly handlerService: IHandlerService;

  private readonly validatorPlugin: IValidatorPlugin;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
    validatorPlugin: IValidatorPlugin,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
    this.validatorPlugin = validatorPlugin;
  }

  validateField = (fieldName: EFieldName, formData: IFormData): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const validator = this.getValidator(fieldName);

    if (!validator) {
      return;
    }

    validator.validate(formData[fieldName], formData);

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    validationStatusRegistry[fieldName] = this.validatorPlugin.getEmptyFieldStatusHandler().getStatus(validator);

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = this.handlerService.formConfigHandler.get(this.state);
  };

  validateFrom = (formData: IFormData): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    this.state.formConfig.forEach((block) => {
      if (block.type !== FormBlockTypeEnum.DELIMITER) {
        block.fields.forEach((field) => {
          const validator = this.getValidator(field.fieldName as EFieldName);

          if (!validator) {
            return;
          }

          if (field.conditionList) {
            if (!this.serviceContainer.utilsService.checkCondition.resolveAndCondition(field.conditionList, formData)) {
              validationStatusRegistry[field.fieldName as EFieldName] = {
                code: StatusCodeEnum.DEFAULT,
              };

              return;
            }
          }

          validator.validate(
            formData[field.fieldName as EFieldName],
            formData,
          );

          validationStatusRegistry[field.fieldName as EFieldName] = this.validatorPlugin
            .getSimpleFieldStatusHandler().getStatus(validator);
        });
      }
    });

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = this.handlerService.formConfigHandler.get(this.state);
  };

  private getValidator = (fieldName: EFieldName): IValidator | null => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const map: Partial<Record<EFieldName, IValidator>> = {};
    map[EFieldName.DESCRIPTION] = this.validatorPlugin.getValidator();
    map[EFieldName.DESCRIPTION]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.DESCRIPTION]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: 3000 }),
      3000,
    );

    map[EFieldName.IMAGE] = this.validatorPlugin.getValidator();
    map[EFieldName.IMAGE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.IMAGE]?.addValidator(
      EValidatorType.FILE,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    return map[fieldName] ?? null;
  };
}

export default ValidationHandler;
