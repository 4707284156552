<template>
  <div
    :class="classes"
  >
    <div
      v-if="icon"
      class="c-info-block__icon"
    >
      <SIcon
        :name="icon.name"
        :color="icon.color"
        :size="icon.size"
      />
    </div>

    <div
      class="c-info-block__info"
    >
      <STextElement
        v-if="title"
        class="c-info-block__title"
        variant="medium"
      >
        {{ title }}
      </STextElement>

      <STextElement
        v-if="text"
        class="c-info-block__text"
      >
        {{ text }}
      </STextElement>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import ColorEnum from '../../../lib/Enum/ColorEnum';
import SIconPropsInterface from '../../../lib/Model/Components/Simple/Icon/SIconPropsInterface';

import SIcon from '../../Simple/Icon/SIcon.vue';
import STextElement from '../../Simple/Text/STextElement.vue';

export default defineComponent({
  name: 'CInfoBlock',
  components: {
    SIcon,
    STextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: Object as PropType<SIconPropsInterface>,
      default: null,
    },
    color: {
      type: String as PropType<ColorEnum>,
      default: ColorEnum.PRIMARY,
    },
  },
  setup(props) {
    const classes = computed(() => [
      'c-info-block',
      `c-info-block_color_${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.c-info-block {
  display: flex;
  gap: 12px;
  padding: 16px;
  border-radius: 5px;

  &_color {
    &_primary {
      background-color: var(--color-background-primary);
    }

    &_warn {
      background-color: var(--color-background-warn);
    }

    &_danger {
      background-color: var(--color-background-danger);
    }

    &_gray {
      background-color: var(--color-background-gray);
    }

    &_success {
      background-color: var(--color-background-success);
    }
  }

  &__title + &__text {
    margin-top: 8px;
  }
}
</style>
