<template>
  <div
    v-html="safeHtml"
    class="content-raw-html"
  />
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dangerTagsRegexp = /<(\/?(html|script))/g;

    const safeHtml = computed(() => props.content
      .replaceAll(dangerTagsRegexp, '\t&lt;$1'));

    return {
      safeHtml,
    };
  }
  ,
});
</script>

<style
  lang="scss"
>
.content-raw-html {
  font-family: var(--text-font-family);
  font-size: var(--text-variant-desktop-regular-size);
  font-weight: var(--text-variant-desktop-regular-weight);
  line-height: var(--text-variant-desktop-regular-line-height);

  h1 {
    margin: 56px 0 32px;
    font-family: var(--header-font-family);
    font-size: var(--h1-variant-desktop-size);
    font-weight: var(--h1-variant-desktop-weight);
    line-height: var(--h1-variant-desktop-line-height);

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    margin: 56px 0 24px;
    font-family: var(--header-font-family);
    font-size: var(--h2-variant-desktop-size);
    font-weight: var(--h2-variant-desktop-weight);
    line-height: var(--h2-variant-desktop-line-height);

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin: 56px 0 16px;
    font-family: var(--header-font-family);
    font-size: var(--h3-variant-desktop-size);
    font-weight: var(--h3-variant-desktop-weight);
    line-height: var(--h3-variant-desktop-line-height);

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    margin: 32px 0 16px;
    font-family: var(--header-font-family);
    font-size: var(--text-variant-desktop-regular-size);
    line-height: var(--text-variant-desktop-subheader-line-height);
    font-weight: var(--text-variant-desktop-subheader-weight);

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin: 0 0 16px;
  }

  a {
    font-family: var(--text-font-family);
    font-size: var(--text-variant-desktop-regular-size);
    font-weight: var(--text-variant-desktop-regular-weight);
    line-height: var(--text-variant-desktop-regular-line-height);
    text-decoration: none;
    color: var(--color-linked);
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: var(--color-linked-hover);
    }

    &:active {
      text-decoration: none;
      color: var(--color-linked-active);
    }

    &:focus {
      text-decoration: none;
      color: var(--color-linked-focus);
    }
  }

  table {
    overflow: hidden;
    width: 100%;
    margin: 0 0 24px;
    border-collapse: collapse;
    border: none;
    border-radius: 12px;
    box-shadow: 0 0 0 1px var(--color-surface);

    > :first-child {
      > tr {
        &:first-child {
          td,
          th {
            border-top: none;
          }

          > :first-child {
            border-top-left-radius: 12px;
          }

          > :last-child {
            border-top-right-radius: 12px;
          }
        }
      }
    }

    > :last-child {
      > tr {
        &:last-child {
          td,
          th {
            border-bottom: none;
          }

          > :first-child {
            border-bottom-left-radius: 12px;
          }

          > :last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    th {
      padding: 12px;
      font-size: var(--text-variant-desktop-regular-size);
      font-weight: var(--text-variant-desktop-regular-weight);
      line-height: var(--text-variant-desktop-regular-line-height);
      border: 1px solid var(--color-surface);
      background-color: var(--color-background-gray);
    }

    td {
      padding: 12px;
      border: 1px solid var(--color-surface);
    }

    tr {
      > :first-child {
        border-left: none;
      }

      > :last-child {
        border-right: none;
      }
    }
  }
}
</style>
