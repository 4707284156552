import CChipsGroupFieldConfigInterface from './lib/Model/Fields/CChipsGroupFieldConfigInterface';
import CustomInfoBlockConfigInterface from './lib/Model/Fields/CustomInfoBlockConfigInterface';
import DateFieldConfigInterface from './lib/Model/Fields/DateFieldConfigInterface';
import FieldConfigType from './lib/Model/Fields/FieldConfigType';
import FieldTypeCheckHandlerInterface from './lib/Contract/FieldTypeCheckHandlerInterface';
import FieldTypeEnum from './lib/Enum/FieldTypeEnum';
import FileUploadFieldConfigInterface from './lib/Model/Fields/FileUploadFieldConfigInterface';
import MaskedInputFieldConfigInterface from './lib/Model/Fields/MaskedInputFieldConfigInterface';
import MultipleSelectFieldConfigInterface from './lib/Model/Fields/MultipleSelectFieldConfigInterface';
import TextAreaFieldConfigInterface from './lib/Model/Fields/TextAreaFieldConfigInterface';
import TextInputFieldConfigInterface from './lib/Model/Fields/TextInputFieldConfigInterface';
import TextInputListFieldConfigInterface from './lib/Model/Fields/TextInputListConfigInterface';

class FieldTypeCheckHandler implements FieldTypeCheckHandlerInterface {
  isMultipleSelect(
    fieldConfig: FieldConfigType,
  ): fieldConfig is MultipleSelectFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.MULTIPLE_SELECT;
  }

  isTextInput(
    fieldConfig: FieldConfigType,
  ): fieldConfig is TextInputFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.TEXT_INPUT;
  }

  isCustomInfoBlock(
    fieldConfig: FieldConfigType,
  ): fieldConfig is CustomInfoBlockConfigInterface {
    return fieldConfig.type === FieldTypeEnum.CUSTOM_INFO_BLOCK;
  }

  isMaskedInput(
    fieldConfig: FieldConfigType,
  ): fieldConfig is MaskedInputFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.MASKED_INPUT;
  }

  isDateField(
    fieldConfig: FieldConfigType,
  ): fieldConfig is DateFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.DATE_FIELD;
  }

  isTextInputList(
    fieldConfig: FieldConfigType,
  ): fieldConfig is TextInputListFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.TEXT_INPUT_LIST;
  }

  isTextArea(
    fieldConfig: FieldConfigType,
  ): fieldConfig is TextAreaFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.TEXT_AREA;
  }

  isChipsGroup(
    fieldConfig: FieldConfigType,
  ): fieldConfig is CChipsGroupFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.CHIPS_GROUP;
  }

  isFileUpload(
    fieldConfig: FieldConfigType,
  ): fieldConfig is FileUploadFieldConfigInterface {
    return fieldConfig.type === FieldTypeEnum.FILE_UPLOAD;
  }
}

export default FieldTypeCheckHandler;
