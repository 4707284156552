import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "setup-page" }
const _hoisted_2 = { class: "setup-page__steps" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_SetupStep = _resolveComponent("SetupStep")!
  const _component_SalesBanner = _resolveComponent("SalesBanner")!
  const _component_InternetAcquiring = _resolveComponent("InternetAcquiring")!
  const _component_LayoutWithoutSidebar = _resolveComponent("LayoutWithoutSidebar")!

  return (_openBlock(), _createBlock(_component_LayoutWithoutSidebar, {
    title: _ctx.state.localisation.title
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ProgressBar, {
          "current-step": _ctx.statusStepNumber,
          "step-count": 12,
          onClickButton: _ctx.handleClickProgressBarButton
        }, null, 8, ["current-step", "onClickButton"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SHeader, { size: 3 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.localisation.stepListTitle), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
            return (_openBlock(), _createBlock(_component_SetupStep, {
              key: `progress-bar-step-${index}`,
              step: step,
              onClickButton: _ctx.handleClickStepButton
            }, null, 8, ["step", "onClickButton"]))
          }), 128))
        ]),
        _createVNode(_component_SalesBanner),
        _createVNode(_component_InternetAcquiring, { onClick: _ctx.handleClickInternetAcquiring }, null, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}