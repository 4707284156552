enum SmallLongLineTextEnum {
  SIZE_DESKTOP = '--text-variant-desktop-small-long-line-size',
  LINE_HEIGHT_DESKTOP = '--text-variant-desktop-small-long-line-line-height',
  WEIGHT_DESKTOP = '--text-variant-desktop-small-long-line-weight',
  SIZE_MOBILE = '--text-variant-mobile-small-long-line-size',
  LINE_HEIGHT_MOBILE = '--text-variant-mobile-small-long-line-line-height',
  WEIGHT_MOBILE = '--text-variant-mobile-small-long-line-weight',
}

export default SmallLongLineTextEnum;
