<template>
  <router-link
    v-if="isRouterLink"
    :class="classes"
    :to="url"
    @click="$emit('click', $event)"
  >
    <span
      v-if="isIconBeforeSlot"
      class="s-link__icon"
    >
      <slot
        name="icon-before"
      />
    </span>
    <template
      v-if="isDefaultSlot"
    >
      <slot/>
    </template>
    <STextElement
      v-else
      :color="color"
      :variant="variant"
      class="s-link__text"
    >
      {{ title }}
    </STextElement>
    <span
      v-if="isIconAfterSlot"
      class="s-link__icon"
    >
      <slot
        name="icon-after"
      />
    </span>
  </router-link>
  <a
    v-else
    :class="classes"
    :download="download"
    :href="url"
    :target="target"
    @click="$emit('click', $event)"
  >
    <span
      v-if="isIconBeforeSlot"
      class="s-link__icon"
    >
      <slot
        name="icon-before"
      />
    </span>
    <template
      v-if="isDefaultSlot"
    >
      <slot/>
    </template>
    <STextElement
      v-else
      :color="color"
      :variant="variant"
      class="s-link__text"
    >
      {{ title }}
    </STextElement>
    <span
      v-if="isIconAfterSlot"
      class="s-link__icon"
    >
      <slot
        name="icon-after"
      />
    </span>
  </a>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import FontVariantEnum from '../../../lib/Enum/FontVariantEnum';
import ColorEnum from '../../../lib/Enum/ColorEnum';

import STextElement from './STextElement.vue';

export default defineComponent({
  name: 'SLink',
  components: {
    STextElement,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<FontVariantEnum>,
      default: FontVariantEnum.REGULAR,
    },
    color: {
      type: String as PropType<ColorEnum>,
      default: ColorEnum.LINKED,
    },
    target: {
      type: String,
      default: '_self',
    },
    isRouterLink: {
      type: Boolean,
      default: true,
    },
    download: {
      type: String,
      default: null,
    },
  },
  setup(props, { slots }) {
    const isIconAfterSlot = computed(() => !!slots['icon-after']);
    const isIconBeforeSlot = computed(() => !!slots['icon-before']);
    const isDefaultSlot = computed(() => !!slots.default);

    const classes = computed(() => [
      's-link',
      isIconAfterSlot.value || isIconBeforeSlot.value ? 's-link_with-icon' : null,
    ]);

    return {
      isIconAfterSlot,
      isIconBeforeSlot,
      isDefaultSlot,
      classes,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.s-link {
  text-decoration: none;
  cursor: pointer;

  &_with-icon {
    .s-link {
      &__icon,
      &__text {
        display: inline-block;
        vertical-align: middle;
      }

      &__icon {
        &:first-child {
          margin-right: 8px;
        }

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &:active {
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
