import {
  IValidatorPlugin,
} from '@/shared/Validator';

import IFormConfigHandler
  from '@/pages/Service/PriceList/PriceListFormPage/Contract/Handler/IFormConfigHandler';
import IFormFieldHandler
  from '@/pages/Service/PriceList/PriceListFormPage/Contract/Handler/IFormFieldHandler';
import IHandlerService from '@/pages/Service/PriceList/PriceListFormPage/Contract/IHandlerService';
import ISendDataHandler
  from '@/pages/Service/PriceList/PriceListFormPage/Contract/Handler/ISendDataHandler';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import IState from '@/pages/Service/PriceList/PriceListFormPage/Model/IState';
import IValidationHandler
  from '@/pages/Service/PriceList/PriceListFormPage/Contract/Handler/IValidationHandler';
import FormConfigHandler
  from '@/pages/Service/PriceList/PriceListFormPage/Handler/FormConfigHandler';
import ValidationHandler
  from '@/pages/Service/PriceList/PriceListFormPage/Handler/ValidationHandler';
import FormFieldHandler from '@/pages/Service/PriceList/PriceListFormPage/Handler/FormFieldHandler';
import SendDataHandler from '@/pages/Service/PriceList/PriceListFormPage/Handler/SendDataHandler';

class HandlerService implements IHandlerService {
  private _formConfigHandler?: IFormConfigHandler;

  private _formFieldEventHandler?: IFormFieldHandler;

  private _sendDataHandler?: ISendDataHandler;

  private _validationHandler?: IValidationHandler;

  private state: IState;

  private serviceContainer: IServiceContainer;

  private validatorPlugin?: IValidatorPlugin;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
  }

  get formConfigHandler(): IFormConfigHandler {
    if (!this._formConfigHandler) {
      this._formConfigHandler = new FormConfigHandler();
    }

    return this._formConfigHandler;
  }

  get formFieldHandler(): IFormFieldHandler {
    if (!this._formFieldEventHandler) {
      this._formFieldEventHandler = new FormFieldHandler(
        this.state,
        this.serviceContainer,
        this,
      );
    }

    return this._formFieldEventHandler;
  }

  get sendDataHandler(): ISendDataHandler {
    if (!this._sendDataHandler) {
      this._sendDataHandler = new SendDataHandler(
        this.state,
        this.serviceContainer,
        this,
      );
    }

    return this._sendDataHandler;
  }

  get validationHandler(): IValidationHandler {
    if (!this.validatorPlugin) {
      throw new Error('validatorPlugin not set. Run init method first.');
    }

    if (!this._validationHandler) {
      this._validationHandler = new ValidationHandler(
        this.state,
        this.serviceContainer,
        this,
        this.validatorPlugin,
      );
    }

    return this._validationHandler;
  }

  init = (validatorPlugin: IValidatorPlugin): IHandlerService => {
    this.validatorPlugin = validatorPlugin;

    return this;
  }
}

export default HandlerService;
