import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8e73fe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "service-banner__icon" }
const _hoisted_2 = { class: "service-banner__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: "service-banner",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickBanner && _ctx.handleClickBanner(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SIcon, {
        name: _ctx.service.icon,
        color: "linked"
      }, null, 8, ["name"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_STextElement, { variant: "subheader" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.service.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_STextElement, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.service.text), 1)
        ]),
        _: 1
      })
    ])
  ]))
}