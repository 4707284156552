enum EValidationMessageTokens {
  FILE_LOAD = 'validation_messages.file.load_error',
  FILE_MAX_SIZE = 'validation_messages.file.max_size',
  FILE_MAX_TOTAL_SIZE = 'validation_messages.file.invalid_total_size',
  FILE_MIME_TYPE = 'validation_messages.file.invalid_mime_type',
  FILE_NAME = 'validation_messages.file.invalid_name',
  FILE_NO_ZERO_SIZE = 'validation_messages.file.no_zero_size',
  END_DATE_GREATER_START_DATE = 'validation_messages.end_date_greater_start_date',
  START_DATE_LESS_END_DATE = 'validation_messages.start_date_less_end_date',
  INN = 'validation_messages.inn',
  MAX_LENGTH = 'validation_messages.max_length',
  MIN_LENGTH = 'validation_messages.min_length',
  ONLY_CYRILLIC_SYMBOLS = 'validation_messages.only_cyrillic_symbols',
  ONLY_NUMBERS = 'validation_messages.only_numbers',
  REQUIRED = 'validation_messages.required',
  URL_FORMAT = 'validation_messages.url_format',
}

export default EValidationMessageTokens;
