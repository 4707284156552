import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ea614cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SLabel = _resolveComponent("SLabel")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SLabel, { class: "form-field__label" }, _createSlots({
      control: _withCtx(({ id }) => [
        _renderSlot(_ctx.$slots, "default", {
          color: _ctx.controlColor,
          id: id
        }, undefined, true)
      ]),
      _: 2
    }, [
      (_ctx.label)
        ? {
            name: "label",
            fn: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    (_ctx.description)
      ? (_openBlock(), _createBlock(_component_STextElement, {
          key: 0,
          variant: "small",
          color: "stroke"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.description), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.status.message)
      ? (_openBlock(), _createBlock(_component_STextElement, {
          key: 1,
          color: _ctx.statusCodeColor,
          variant: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.status.message), 1)
          ]),
          _: 1
        }, 8, ["color"]))
      : _createCommentVNode("", true)
  ]))
}