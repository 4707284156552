import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70bd6b4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "partner-level-info" }
const _hoisted_2 = { class: "partner-level-info__service-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SChips = _resolveComponent("SChips")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SHeader, {
      size: 3,
      class: "partner-level-info__title"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_STextElement, { class: "partner-level-info__text" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.text), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `service-item-${index}`,
          class: "partner-level-info__service-item"
        }, [
          _createVNode(_component_SChips, {
            size: _ctx.chipsConfig.size,
            color: _ctx.chipsConfig.color,
            "model-value": true,
            "state-type": _ctx.chipsConfig.stateType,
            variant: _ctx.chipsConfig.variant,
            icon: _ctx.chipsConfig.icon,
            "icon-first": _ctx.chipsConfig.iconFirst,
            onClick: ($event: any) => (_ctx.handleClickChips(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.chipsTitle), 1)
            ]),
            _: 2
          }, 1032, ["size", "color", "state-type", "variant", "icon", "icon-first", "onClick"])
        ]))
      }), 128))
    ])
  ]))
}