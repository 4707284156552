import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59e02338"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-status-window" }
const _hoisted_2 = {
  key: 2,
  class: "c-status-window__buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", {
      class: _normalizeClass(_ctx.sectionClasses)
    }, [
      _createVNode(_component_SIcon, {
        color: _ctx.icon.color,
        name: _ctx.icon.name,
        size: _ctx.icon.size,
        class: "c-status-window__icon"
      }, null, 8, ["color", "name", "size"]),
      _createVNode(_component_SHeader, {
        size: 3,
        class: "c-status-window__title"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }),
      (_ctx.hasSubtitleSlot)
        ? (_openBlock(), _createBlock(_component_STextElement, {
            key: 0,
            class: "c-status-window__text",
            color: "stroke",
            variant: "regular"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
            ]),
            _: 3
          }))
        : (_ctx.subtitle)
          ? (_openBlock(), _createBlock(_component_STextElement, {
              key: 1,
              class: "c-status-window__text",
              color: "stroke",
              variant: "regular"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
      (_ctx.hasButtonsSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}