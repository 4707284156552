<template>
  <div
    class="service-info"
  >
    <SButton
      v-if="button"
      :size="button.props.size"
      :text="button.props.text"
      :variant="button.props.variant"
      @click="button.handler"
    />

    <ContentRawHTML
      v-if="content"
      :content="content"
    />
  </div>
</template>

<script
  lang="ts"
>

import {
  defineComponent,
  PropType,
} from 'vue';

import IButtonConfig from '@/app/Model/UI/IButtonConfig';

import ContentRawHTML from '@/shared/Component/ContentRawHTML/ContentRawHTML.vue';

export default defineComponent({
  components: {
    ContentRawHTML,
  },
  props: {
    button: {
      type: Object as PropType<IButtonConfig>,
      default: () => null,
    },
    content: {
      type: String,
      default: '',
    },
  },
});
</script>

<style
  scoped
  lang="scss"
>
.service-info {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__button {

  }

  &__content {

  }
}

</style>
