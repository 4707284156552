import {
  EValidatorType,
  IValidator,
  IValidatorPlugin,
  StatusCodeEnum,
} from '@/shared/Validator';

import {
  ColorEnum,
  CTextInputListItemInterface,
  CTextInputListItemStatusInterface,
} from '@/shared/DesignSystem';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

import {
  FormBlockTypeEnum,
} from '@/widgets/FormComponent';

import EFieldName from '@/pages/Service/Promo/PromoFormPage/Enum/EFieldName';
import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';

import IFormData from '@/pages/Service/Promo/PromoFormPage/Model/IFormData';
import IHandlerService from '@/pages/Service/Promo/PromoFormPage/Contract/IHandlerService';
import IState from '@/pages/Service/Promo/PromoFormPage/Model/IState';
import IValidationHandler from '@/pages/Service/Promo/PromoFormPage/Contract/Handler/IValidationHandler';

class ValidationHandler implements IValidationHandler {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly handlerService: IHandlerService;

  private readonly validatorPlugin: IValidatorPlugin;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
    validatorPlugin: IValidatorPlugin,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
    this.validatorPlugin = validatorPlugin;
  }

  validateField = (
    fieldName: Exclude<EFieldName, EFieldName.PROMOTION_INFO>,
    formData: IFormData,
  ): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const validator = this.getValidator(fieldName);

    if (!validator) {
      return;
    }

    validator.validate(formData[fieldName], formData);

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    validationStatusRegistry[fieldName] = this.validatorPlugin.getEmptyFieldStatusHandler().getStatus(validator);

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = this.handlerService.formConfigHandler.get(this.state);
  };

  validateFrom = (formData: IFormData): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    this.state.formConfig.forEach((block) => {
      if (block.type !== FormBlockTypeEnum.DELIMITER) {
        block.fields.forEach((field) => {
          const name = field.fieldName as Exclude<EFieldName, EFieldName.PROMOTION_INFO>;
          const validator = this.getValidator(name);

          if (!validator) {
            return;
          }

          if (field.conditionList) {
            if (!this.serviceContainer.utilsService.checkCondition.resolveAndCondition(field.conditionList, formData)) {
              validationStatusRegistry[name] = {
                code: StatusCodeEnum.DEFAULT,
              };

              return;
            }
          }

          validator.validate(
            formData[name],
            formData,
          );

          validationStatusRegistry[name] = this.validatorPlugin
            .getSimpleFieldStatusHandler().getStatus(validator);
        });
      }
    });

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = this.handlerService.formConfigHandler.get(this.state);
  };

  private getValidator = (fieldName: EFieldName): IValidator | null => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const { utilsService } = this.serviceContainer;

    const textInputMaxLength = 255;
    const textAreaMaxLength = 3000;

    const map: Partial<Record<EFieldName, IValidator>> = {};
    map[EFieldName.ACTIVE_TYPE] = this.validatorPlugin.getValidator();
    map[EFieldName.ACTIVE_TYPE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.PROMOTION_TYPE] = this.validatorPlugin.getValidator();
    map[EFieldName.PROMOTION_TYPE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.PROMOTION_NAME] = this.validatorPlugin.getValidator();
    map[EFieldName.PROMOTION_NAME]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.PROMOTION_NAME]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textInputMaxLength }),
      textInputMaxLength,
    );

    map[EFieldName.DESCRIPTION_FOR_CUSTOMERS] = this.validatorPlugin.getValidator();
    map[EFieldName.DESCRIPTION_FOR_CUSTOMERS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.DESCRIPTION_FOR_CUSTOMERS]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.ACTIVATION_DATE] = this.validatorPlugin.getValidator();
    map[EFieldName.ACTIVATION_DATE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.ACTIVATION_DATE]?.addValidator(
      EValidatorType.LESS_THAN,
      this.state.t(EValidationMessageTokens.START_DATE_LESS_END_DATE),
      this.state.formData.expirationDate,
    );

    map[EFieldName.EXPIRATION_DATE] = this.validatorPlugin.getValidator();
    map[EFieldName.EXPIRATION_DATE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.EXPIRATION_DATE]?.addValidator(
      EValidatorType.GREATER_THAN,
      this.state.t(EValidationMessageTokens.END_DATE_GREATER_START_DATE),
      this.state.formData.activationDate,
    );

    map[EFieldName.VARIANT_AMOUNT_DISCOUNT] = this.validatorPlugin.getValidator();
    map[EFieldName.VARIANT_AMOUNT_DISCOUNT]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.APPLICATION] = this.validatorPlugin.getValidator();
    map[EFieldName.APPLICATION]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.PRODUCTS] = this.validatorPlugin.getValidator();
    map[EFieldName.PRODUCTS]?.addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      (value: CTextInputListItemInterface[]): boolean => {
        let haveValidField = false;

        value.forEach((item) => {
          haveValidField = haveValidField || item.value.length > 0;
        });

        return haveValidField;
      },
    );
    map[EFieldName.PRODUCTS]?.addValidator(
      EValidatorType.CUSTOM,
      '',
      (value: CTextInputListItemInterface[]): boolean => {
        const statusList: CTextInputListItemStatusInterface[] = [];
        let haveError = false;

        value.forEach((item) => {
          let status: CTextInputListItemStatusInterface = {
            id: item.id,
            color: ColorEnum.PRIMARY,
          };

          if (item.value.length > textInputMaxLength) {
            status = {
              id: item.id,
              color: ColorEnum.DANGER,
              text: this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textInputMaxLength }),
            };

            haveError = true;
          }

          statusList.push(status);
        });

        this.state.productsValidationStatusRegistry = utilsService.data.cloneImmutable(statusList);

        return !haveError;
      },
    );

    map[EFieldName.AMOUNT_DISCOUNT] = this.validatorPlugin.getValidator();
    map[EFieldName.AMOUNT_DISCOUNT]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.PARTICIPANTS_PROMOTION] = this.validatorPlugin.getValidator();
    map[EFieldName.PARTICIPANTS_PROMOTION]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.BUYERS_PARTICIPANTS_PROMOTION] = this.validatorPlugin.getValidator();
    map[EFieldName.BUYERS_PARTICIPANTS_PROMOTION]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.CATEGORIES_PARTICIPANTS] = this.validatorPlugin.getValidator();
    map[EFieldName.CATEGORIES_PARTICIPANTS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.WHICH_CATEGORY] = this.validatorPlugin.getValidator();
    map[EFieldName.WHICH_CATEGORY]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.PROMOTION_NAME]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.DISCOUNT_REFLECTED] = this.validatorPlugin.getValidator();
    map[EFieldName.DISCOUNT_REFLECTED]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.BANNER] = this.validatorPlugin.getValidator();
    map[EFieldName.BANNER]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.BANNER]?.addValidator(
      EValidatorType.FILE,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.USE_STICKER] = this.validatorPlugin.getValidator();
    map[EFieldName.USE_STICKER]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.TEXT_STICKER] = this.validatorPlugin.getValidator();
    map[EFieldName.TEXT_STICKER]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.TEXT_STICKER]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textInputMaxLength }),
      textInputMaxLength,
    );

    map[EFieldName.DESCRIPTION_BUYERS] = this.validatorPlugin.getValidator();
    map[EFieldName.DESCRIPTION_BUYERS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.DESCRIPTION_BUYERS]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.COUPON_VALIDITY] = this.validatorPlugin.getValidator();
    map[EFieldName.COUPON_VALIDITY]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.MECHANICS_PROMOTION] = this.validatorPlugin.getValidator();
    map[EFieldName.MECHANICS_PROMOTION]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.MECHANICS_PROMOTION]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.VARIANT_ARTICLE_KIT] = this.validatorPlugin.getValidator();
    map[EFieldName.VARIANT_ARTICLE_KIT]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[EFieldName.ARTICLE_KIT] = this.validatorPlugin.getValidator();
    map[EFieldName.ARTICLE_KIT]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.ARTICLE_KIT]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textInputMaxLength }),
      textInputMaxLength,
    );

    return map[fieldName] ?? null;
  };
}

export default ValidationHandler;
