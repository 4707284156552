enum StatusWindowEnum {
  BORDER_RADIUS = '--status-window-border-radius',
  PADDING_L_TOP = '--status-window-padding-l-top',
  PADDING_L_RIGHT = '--status-window-padding-l-right',
  PADDING_L_BOTTOM = '--status-window-padding-l-bottom',
  PADDING_L_LEFT = '--status-window-padding-l-left',
  PADDING_M_TOP = '--status-window-padding-m-top',
  PADDING_M_RIGHT = '--status-window-padding-m-right',
  PADDING_M_BOTTOM = '--status-window-padding-m-bottom',
  PADDING_M_LEFT = '--status-window-padding-m-left',
  XS_PADDING_L_TOP = '--status-window-xs-padding-l-top',
  XS_PADDING_L_RIGHT = '--status-window-xs-padding-l-right',
  XS_PADDING_L_BOTTOM = '--status-window-xs-padding-l-bottom',
  XS_PADDING_L_LEFT = '--status-window-xs-padding-l-left',
  XS_PADDING_M_TOP = '--status-window-xs-padding-m-top',
  XS_PADDING_M_RIGHT = '--status-window-xs-padding-m-right',
  XS_PADDING_M_BOTTOM = '--status-window-xs-padding-m-bottom',
  XS_PADDING_M_LEFT = '--status-window-xs-padding-m-left',
  MARGIN_AFTER_ICON = '--status-window-margin-after-icon',
  MARGIN_AFTER_TITLE = '--status-window-margin-after-title',
  MARGIN_AFTER_TEXT = '--status-window-margin-after-text',
  BUTTON_GAP = '--status-window-button-gap',
}

export default StatusWindowEnum;
