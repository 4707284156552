<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      d="M4.5 4C3.96957 4 3.46086 4.21071 3.08579 4.58579C2.71071 4.96086 2.5 5.46957 2.5 6V9.5C2.5 9.77614 2.72727 9.99384 2.99482 10.0622C3.33983 10.1503 3.65816 10.3297 3.91421 10.5858C4.28929 10.9609 4.5 11.4696 4.5 12C4.5 12.5304 4.28929 13.0391 3.91421 13.4142C3.65816 13.6703 3.33983 13.8497 2.99482 13.9378C2.72727 14.0062 2.5 14.2239 2.5 14.5V18C2.5 18.5304 2.71071 19.0391 3.08579 19.4142C3.46086 19.7893 3.96957 20 4.5 20H20.5C21.0304 20 21.5391 19.7893 21.9142 19.4142C22.2893 19.0391 22.5 18.5304 22.5 18V14.5C22.5 14.2239 22.2727 14.0062 22.0052 13.9378C21.6602 13.8497 21.3418 13.6703 21.0858 13.4142C20.7107 13.0391 20.5 12.5304 20.5 12C20.5 11.4696 20.7107 10.9609 21.0858 10.5858C21.3418 10.3297 21.6602 10.1503 22.0052 10.0622C22.2727 9.99384 22.5 9.77614 22.5 9.5V6C22.5 5.46957 22.2893 4.96086 21.9142 4.58579C21.5391 4.21071 21.0304 4 20.5 4H4.5ZM15.25 7.75C15.6642 7.33579 16.3358 7.33579 16.75 7.75C17.1642 8.16421 17.1642 8.83579 16.75 9.25L9.75 16.25C9.33579 16.6642 8.66421 16.6642 8.25 16.25C7.83579 15.8358 7.83579 15.1642 8.25 14.75L15.25 7.75ZM9.31 7.04C10.29 7.04 11.08 7.83 11.08 8.81C11.08 9.27943 10.8935 9.72964 10.5616 10.0616C10.2296 10.3935 9.77943 10.58 9.31 10.58C8.33 10.58 7.54 9.79 7.54 8.81C7.54 8.34057 7.72648 7.89036 8.05842 7.55842C8.39036 7.22648 8.84057 7.04 9.31 7.04ZM15.69 13.42C16.67 13.42 17.46 14.21 17.46 15.19C17.46 15.6594 17.2735 16.1096 16.9416 16.4416C16.6096 16.7735 16.1594 16.96 15.69 16.96C14.71 16.96 13.92 16.17 13.92 15.19C13.92 14.7206 14.1065 14.2704 14.4384 13.9384C14.7704 13.6065 15.2206 13.42 15.69 13.42Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconCoupon',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-coupon_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.s-icon-coupon {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

}
</style>
