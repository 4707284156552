<template>
  <LayoutWithoutToolbar
    :title="pageTitle"
  >
    <template
      #control-left
    >
      <SButton
        :text="buttonTexts.cancel"
        variant="transparent"
        @click="handleCancelClick"
      >
        <template
          #icon-before
        >
          <SIcon
            color="primary"
            name="cross"
          />
        </template>
      </SButton>
    </template>
    <template
      #control-right
    >
      <SButton
        :text="buttonTexts.send"
        :disabled="isSendDisabled"
        @click="handleSend"
      >
      </SButton>
    </template>
    <template
      #content
    >
      <FormComponent
        :form-config="state.formConfig"
        :form-data="state.formData"
        @string-field-blur="formFieldHandler.handleStringBlur"
        @multiple-select-input="formFieldHandler.handleMultipleSelectInput"
        @chips-group-change="formFieldHandler.handleChipsGroupChange"
        @date-input="formFieldHandler.handleDateInput"
        @file-upload-input="formFieldHandler.handleFileUploadInput"
        @delete-file="formFieldHandler.handleDeleteFile"
        @download-file="formFieldHandler.handleDownloadFile"
      />
      <div
        class="privacy-policy"
      >
        <div
          class="privacy-policy__field"
        >
          <SCheckbox
            class="privacy-policy__checkbox"
            v-model="state.isPrivacyPolicyChecked"
          />
          <STextElement>
            <span
              v-html="privacyPolicy.label"
            />
          </STextElement>
        </div>

        <STextElement
          color="stroke"
        >
          <span
            v-html="privacyPolicy.comment"
          />
        </STextElement>
      </div>
    </template>
  </LayoutWithoutToolbar>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  watch,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';
import {
  useRouter,
} from 'vue-router';

import {
  IValidatorPlugin,
} from '@/shared/Validator';

import ScoringFormPageServiceInterface from '@/pages/ScoringFormPage/lib/Contract/Service/ScoringFormPageServiceInterface';
import StateModelInterface from '@/pages/ScoringFormPage/lib/Model/StateModelInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import EDefaultRouteName from '@/app/Enum/router/EDefaultRouteName';
import EFormName from '@/app/Enum/EFormName';
import FormDataInterface from '@/pages/ScoringFormPage/lib/Model/FormDataInterface';

import FormComponent from '@/widgets/FormComponent/Component/FormComponent.vue';
import LayoutWithoutToolbar from '@/app/Component/Layout/LayoutWithoutToolbar/LayoutWithoutToolbar.vue';

export default defineComponent({
  components: {
    LayoutWithoutToolbar,
    FormComponent,
  },
  setup() {
    const pageService = inject<ScoringFormPageServiceInterface>('scoringFormPage');
    const serviceContainer = inject<IServiceContainer>('serviceContainer');
    const validatorPlugin = inject<IValidatorPlugin>('validatorPlugin');
    const router = useRouter();

    if (!pageService) {
      throw new Error('scoringFormPage service not found. Check is plugin ScoringFormPage/index.ts installed.');
    }

    if (!validatorPlugin) {
      throw new Error('validatorPlugin service not found. Check is plugin validatorPlugin installed.');
    }

    const { t } = useI18n();

    const state: StateModelInterface = pageService.initState(t);

    const pageTitle = computed(() => t('pages.scoring_form.title'));

    pageService.handlerService.init(validatorPlugin);

    const {
      formFieldHandler,
      formConfigHandler,
      sendDataHandler,
    } = pageService.handlerService;

    const buttonTexts = computed(() => ({
      cancel: t('common.cancel'),
      send: t('common.send'),
    }));

    const privacyPolicy = {
      label: t('pages.scoring_form.privacy_policy.label'),
      comment: t('pages.scoring_form.privacy_policy.comment'),
    };

    function handleCancelClick() {
      router.push({ name: EDefaultRouteName.HOME });
    }

    function handleSend() {
      state.isSendButtonPressed = true;

      sendDataHandler.handle()
        .then(() => router.push({ name: EDefaultRouteName.HOME }))
        .catch((e) => console.log(e));
    }

    onMounted(async () => {
      if (!serviceContainer) {
        throw new Error('ServiceContainer not defined.');
      }

      state.scoringParams = await serviceContainer.apiService.scoringParamsApi.get();

      const draftData = await serviceContainer.apiService.formDraftApi.get<FormDataInterface>(EFormName.SCORING);

      if (draftData && draftData.formName === EFormName.SCORING && Object.keys(draftData.formData).length > 0) {
        state.formData = serviceContainer.utilsService.data.cloneImmutable({
          ...state.formData,
          ...draftData.formData,
        });
      }

      state.formConfig = formConfigHandler.get(state);
    });

    watch(() => state.formData, (newValue) => {
      serviceContainer?.apiService.formDraftApi.create<FormDataInterface>({
        formName: EFormName.SCORING,
        formData: newValue,
      });
    });

    const isSendDisabled = computed(() => {
      const isNotPrivacyPolicyChecked = !state.isPrivacyPolicyChecked;

      return state.isLoading || isNotPrivacyPolicyChecked;
    });

    return {
      state,
      buttonTexts,
      formFieldHandler,
      sendDataHandler,
      isSendDisabled,
      handleCancelClick,
      handleSend,
      pageTitle,
      privacyPolicy,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.max-width {
  max-width: 560px;
  margin: 0 auto;
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__field {
    display: flex;
    gap: 8px;
    align-items: flex-start;
  }

  :deep(a)
   {
    color: var(--color-linked);
    text-decoration: none;
    font-family: var(--text-font-family);
    font-size: var(--text-variant-desktop-regular-size);
    line-height: var(--text-variant-desktop-regular-line-height);
    font-weight: var(--text-variant-desktop-regular-weight);

    &:hover {
      color: var(--color-linked);
      text-decoration: none;
    }

    &:active {
      color: var(--color-linked);
      text-decoration: none;
    }

    &:visited {
      color: var(--color-linked);
      text-decoration: none;
    }
  }

  &__checkbox {
    flex: auto 0 0;
    margin-top: 2px;
  }

  &__text {
    font-family: var(--text-font-family);
    font-size: var(--text-variant-desktop-regular-size);
    line-height: var(--text-variant-desktop-regular-line-height);
    font-weight: var(--text-variant-desktop-regular-weight);
  }
}
</style>
