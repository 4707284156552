<template>
  <svg
    aria-hidden="true"
    role="img"
    :width="width"
    :height="height"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
      opacity=".5"
      fill="#CDEBFF"
    />
    <path
      d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
      fill="#4397CB"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 12 12"
        to="360 12 12"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'SPreloaderComponent',
  props: {
    // @todo перевести на Number
    width: {
      type: String,
      default: '24px',
    },
    height: {
      type: String,
      default: '24px',
    },
  },
});
</script>
