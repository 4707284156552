import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-888444de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "service-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SButton = _resolveComponent("SButton")!
  const _component_ContentRawHTML = _resolveComponent("ContentRawHTML")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.button)
      ? (_openBlock(), _createBlock(_component_SButton, {
          key: 0,
          size: _ctx.button.props.size,
          text: _ctx.button.props.text,
          variant: _ctx.button.props.variant,
          onClick: _ctx.button.handler
        }, null, 8, ["size", "text", "variant", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.content)
      ? (_openBlock(), _createBlock(_component_ContentRawHTML, {
          key: 1,
          content: _ctx.content
        }, null, 8, ["content"]))
      : _createCommentVNode("", true)
  ]))
}