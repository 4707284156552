import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutWithoutToolbarControl = _resolveComponent("LayoutWithoutToolbarControl")!
  const _component_LayoutWithoutToolbarContainer = _resolveComponent("LayoutWithoutToolbarContainer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.haveControl)
      ? (_openBlock(), _createBlock(_component_LayoutWithoutToolbarControl, {
          key: 0,
          class: "layout-without-toolbar__control"
        }, _createSlots({ _: 2 }, [
          (_ctx.isControlLeftSlot)
            ? {
                name: "control-left",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "control-left", {}, undefined, true)
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.isControlRightSlot)
            ? {
                name: "control-right",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "control-right", {}, undefined, true)
                ]),
                key: "1"
              }
            : undefined
        ]), 1024))
      : _createCommentVNode("", true),
    _createVNode(_component_LayoutWithoutToolbarContainer, {
      title: _ctx.title,
      width: _ctx.width,
      class: "layout-without-toolbar__container"
    }, {
      content: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["title", "width"])
  ], 2))
}