import { App } from '@vue/runtime-core';
import ValidatorPlugin from './lib/ValidatorPlugin';
import StatusCodeEnum from './lib/Enum/StatusCodeEnum';
import FieldStatusInterface from './lib/Model/FieldStatusInterface';
import IValidator from './lib/Contract/IValidator';
import IValidatorPlugin from './lib/Contract/IValidatorPlugin';
import EValidatorType from './lib/Enum/EValidatorType';

export default {
  install: (app: App) => {
    app.provide('validatorPlugin', new ValidatorPlugin());
  },
};

export {
  StatusCodeEnum,
  EValidatorType,
  FieldStatusInterface,
  IValidator,
  IValidatorPlugin,
};
