import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32250894"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "services-page" }
const _hoisted_2 = { class: "services-page__service" }
const _hoisted_3 = { class: "services-page__service-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerLevelInfo = _resolveComponent("PartnerLevelInfo")!
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_ServiceBanner = _resolveComponent("ServiceBanner")!
  const _component_LayoutWithSidebar = _resolveComponent("LayoutWithSidebar")!

  return (_openBlock(), _createBlock(_component_LayoutWithSidebar, {
    title: _ctx.state.localisation.title
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PartnerLevelInfo, {
          "service-list": _ctx.partnerLevelInfo.serviceList,
          text: _ctx.partnerLevelInfo.text,
          title: _ctx.partnerLevelInfo.title
        }, null, 8, ["service-list", "text", "title"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SHeader, { size: 3 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.localisation.serviceTitle), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerLevelInfo.serviceList, (service, index) => {
              return (_openBlock(), _createBlock(_component_ServiceBanner, {
                key: `service-item-${index}`,
                service: service
              }, null, 8, ["service"]))
            }), 128))
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}