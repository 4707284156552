import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0be0371e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  fill: "none",
  height: "24",
  viewBox: "0 0 24 24",
  width: "24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      "clip-rule": "evenodd",
      d: "M12.8435 15.9997H11.1566L10.9853 16.639C10.3353 19.0647 7.84206 20.5042 5.41641 19.8542C2.99077 19.2043 1.55128 16.711 2.20123 14.2853L3.92093 7.86734C4.57088 5.44169 7.06414 4.00221 9.48979 4.65216C10.5422 4.93416 11.409 5.56317 12.0001 6.3831C12.5911 5.56317 13.4579 4.93416 14.5103 4.65216C16.936 4.00221 19.4292 5.44169 20.0792 7.86734L21.7989 14.2853C22.4488 16.711 21.0094 19.2043 18.5837 19.8542C16.1581 20.5042 13.6648 19.0647 13.0148 16.639L12.8435 15.9997ZM17.4564 18.1049C18.9631 18.1049 20.1846 16.8375 20.1846 15.2741C20.1846 13.7107 18.9631 12.4433 17.4564 12.4433C15.9497 12.4433 14.7282 13.7107 14.7282 15.2741C14.7282 16.8375 15.9497 18.1049 17.4564 18.1049ZM6.54371 18.1049C8.05044 18.1049 9.27189 16.8375 9.27189 15.2741C9.27189 13.7107 8.05044 12.4433 6.54371 12.4433C5.03698 12.4433 3.81554 13.7107 3.81554 15.2741C3.81554 16.8375 5.03698 18.1049 6.54371 18.1049Z",
      "fill-rule": "evenodd"
    }, null, 2)
  ]))
}