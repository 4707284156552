<template>
  <div
    class="layout-without-toolbar-container-header"
  >
    <SHeader>
      {{ title }}
    </SHeader>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>

</style>
