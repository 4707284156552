enum RegularTextEnum {
  SIZE_DESKTOP = '--text-variant-desktop-regular-size',
  LINE_HEIGHT_DESKTOP = '--text-variant-desktop-regular-line-height',
  WEIGHT_DESKTOP = '--text-variant-desktop-regular-weight',
  SIZE_MOBILE = '--text-variant-mobile-regular-size',
  LINE_HEIGHT_MOBILE = '--text-variant-mobile-regular-line-height',
  WEIGHT_MOBILE = '--text-variant-mobile-regular-weight',
}

export default RegularTextEnum;
