import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54dc32c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-file-list__item-icon"
}
const _hoisted_2 = { class: "c-file-list__item-attributes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SPreloaderComponent = _resolveComponent("SPreloaderComponent")!
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: "c-file-list",
    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.list, (file, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `cFileListItem${index}`
      }, [
        _createElementVNode("div", {
          class: "c-file-list__item",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          (file.status !== 'default')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (file.status === 'loading' || file.status === 'deleting')
                  ? (_openBlock(), _createBlock(_component_SPreloaderComponent, { key: 0 }))
                  : (file.status === 'success')
                    ? (_openBlock(), _createBlock(_component_SIcon, {
                        key: 1,
                        color: "success",
                        name: "checkCircle"
                      }))
                    : (file.status === 'error')
                      ? (_openBlock(), _createBlock(_component_SIcon, {
                          key: 2,
                          color: "danger",
                          name: "crossCircle"
                        }))
                      : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "c-file-list__item-text",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode(_component_STextElement, {
              class: _normalizeClass(_ctx.fileNameClasses),
              onClick: _withModifiers(($event: any) => (_ctx.handleClickLink($event, file)), ["stop"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(file.fileName), 1)
              ]),
              _: 2
            }, 1032, ["class", "onClick"]),
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "icon-slot", { file: file }, undefined, true),
              (_ctx.isDeleteIcon(file, _ctx.enableDelete))
                ? (_openBlock(), _createBlock(_component_SIcon, {
                    key: 0,
                    class: "c-file-list__item-close",
                    color: "stroke",
                    name: "cross",
                    size: "s",
                    onClick: _withModifiers(($event: any) => (_ctx.handleClickDelete(file)), ["stop"])
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        (file.errors.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: `cFileListItemErrors${index}`,
              class: "c-file-list__item-errors"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(file.errors, (error) => {
                return (_openBlock(), _createBlock(_component_STextElement, {
                  key: `${file.fileName}${error.type}`,
                  class: "c-expandable-list__comment",
                  color: "danger",
                  variant: "small"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(error.message), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (index < _ctx.list.length - 1)
          ? (_openBlock(), _createElementBlock("hr", {
              key: `cFileListDelimiter${index}`,
              class: "c-file-list__delimiter"
            }))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}