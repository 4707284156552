import ISSOLinkApi from '@/shared/Api/Contract/Api/ISSOLinkApi';
import ISSOLogoutLinkResponse from '@/shared/Api/Model/SSOLinkApi/ISSOLogoutLinkResponse';
import ISSOSinginLinkResponse from '@/shared/Api/Model/SSOLinkApi/ISSOSinginLinkResponse';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';

class SSOLinkApi implements ISSOLinkApi {
  private client: IAxiosClientService;

  constructor(client: IAxiosClientService) {
    this.client = client;
  }

  getLogoutLink = async (): Promise<ISSOLogoutLinkResponse> => this.client.instance
    .get<ISSOLogoutLinkResponse>('/auth/sso/link/logout')
    .then((response) => response.data);

  getSignInLink = async (): Promise<ISSOSinginLinkResponse> => this.client.instance
    .get<ISSOSinginLinkResponse>('/auth/sso/link/signin')
    .then((response) => response.data);
}

export default SSOLinkApi;
