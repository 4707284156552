enum LabelEnum {
  L_GAP = '--label-l-gap',
  M_GAP = '--label-m-gap',
  S_GAP = '--label-s-gap',
  SIZE_DESKTOP = '--label-desktop-size',
  LINE_HEIGHT_DESKTOP = '--label-desktop-line-height',
  WEIGHT_DESKTOP = '--label-desktop-weight',
  SIZE_MOBILE = '--label-mobile-size',
  LINE_HEIGHT_MOBILE = '--label-mobile-line-height',
  WEIGHT_MOBILE = '--label-mobile-weight',
}

export default LabelEnum;
