<template>
  <div
    class="cookie-modal"
  >
    <div
      class="cookie-modal__left"
    >
      <STextElement
        color="white"
        variant="medium"
      >
        <span
          v-html="text"
        />
      </STextElement>
    </div>
    <div
      class="cookie-modal__right"
    >
      <SButton
        :text="button.text"
        @click="button.handler"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';

export default defineComponent({
  name: 'CookieModal',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const { t } = useI18n();

    const button = {
      handler: () => emit('ok-click'),
      text: t('common.ok'),
    };

    return {
      button,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.cookie-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 32px;
  border-radius: 8px;
  background-color: var(--color-toolbar);
  gap: 21px;
}
</style>
