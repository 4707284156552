<template>
  <div
    :class="itemClasses"
  >
    <STextElement
      :color="textElementConfig.color"
      :variant="textElementConfig.variant"
    >
      {{ item.title }}
    </STextElement>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  PropType,
} from 'vue';

import ColorEnum from '../../../../lib/Enum/ColorEnum';
import FontVariantEnum from '../../../../lib/Enum/FontVariantEnum';
import SidebarMenuItemInterface
  from '../../../../lib/Model/Components/Complex/SideBar/SidebarMenuItemInterface';

import STextElement from '../../../Simple/Text/STextElement.vue';

export default defineComponent({
  name: 'CMenuSubItem',
  components: {
    STextElement,
  },
  props: {
    item: {
      type: Object as PropType<SidebarMenuItemInterface>,
      required: true,
    },
  },
  setup(props) {
    const itemClasses = [
      'c-menu-sub-item',
      props.item.active ? 'c-menu-sub-item_active' : null,
      props.item.disabled ? 'c-menu-sub-item_disabled' : null,
    ];

    function getColor(): ColorEnum {
      if (props.item.disabled) {
        return ColorEnum.GRAY;
      }

      if (props.item.active) {
        return ColorEnum.WHITE;
      }

      return ColorEnum.GRAY;
    }

    const textElementConfig = {
      variant: FontVariantEnum.REGULAR,
      color: getColor(),
    };

    return {
      itemClasses,
      textElementConfig,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.c-menu-sub-item {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 7px 16px 7px 52px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: 4px solid transparent;
  cursor: pointer;
  gap: 12px;

  &:hover:not(&_disabled) {
    background-color: var(--color-toolbar-hover);
  }

  &_active {
    border-left-color: var(--color-white);
    background-color: var(--toolbar-active);
  }

  &_disabled {
    cursor: default;
  }
}
</style>
