enum HeaderTextEnum {
  H1_SIZE_DESKTOP = '--h1-variant-desktop-size',
  H1_LINE_HEIGHT_DESKTOP = '--h1-variant-desktop-line-height',
  H1_WEIGHT_DESKTOP = '--h1-variant-desktop-weight',
  H1_SIZE_MOBILE = '--h1-variant-mobile-size',
  H1_LINE_HEIGHT_MOBILE = '--h1-variant-mobile-line-height',
  H1_WEIGHT_MOBILE = '--h1-variant-mobile-weight',
  H2_SIZE_DESKTOP = '--h2-variant-desktop-size',
  H2_LINE_HEIGHT_DESKTOP = '--h2-variant-desktop-line-height',
  H2_WEIGHT_DESKTOP = '--h2-variant-desktop-weight',
  H2_SIZE_MOBILE = '--h2-variant-mobile-size',
  H2_LINE_HEIGHT_MOBILE = '--h2-variant-mobile-line-height',
  H2_WEIGHT_MOBILE = '--h3-variant-mobile-weight',
  H3_SIZE_DESKTOP = '--h3-variant-desktop-size',
  H3_LINE_HEIGHT_DESKTOP = '--h3-variant-desktop-line-height',
  H3_WEIGHT_DESKTOP = '--h3-variant-desktop-weight',
  H3_SIZE_MOBILE = '--h3-variant-mobile-size',
  H3_LINE_HEIGHT_MOBILE = '--h3-variant-mobile-line-height',
  H3_WEIGHT_MOBILE = '--h3-variant-mobile-weight',
}

export default HeaderTextEnum;
