<template>
  <aside
    :class="sidebarClasses"
  >
    <div
      class="c-sidebar__background"
      @click.stop="handleClickBackground"
    >
      <div
        class="c-sidebar__close"
        @click.stop="handleClickClose"
      >
        <SIcon
          :name="closeIconConfig.name"
          :color="closeIconConfig.color"
        />
      </div>
    </div>
    <div
      class="c-sidebar__area"
    >
      <nav
        v-if="menuList.length > 0"
        class="c-sidebar__menu"
      >
        <CMenuMainItem
          v-for="item in menuList"
          :key="item.title"
          :ic-sidebar-collapsed="isCollapsed"
          :item="item"
          class="c-sidebar__item"
        />
      </nav>
    </div>
  </aside>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import ColorEnum from '../../../lib/Enum/ColorEnum';
import IconNameEnum from '../../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import SIconPropsInterface from '../../../lib/Model/Components/Simple/Icon/SIconPropsInterface';
import SidebarMenuMainItemInterface
  from '../../../lib/Model/Components/Complex/SideBar/SidebarMenuMainItemInterface';

import CMenuMainItem from './Module/CMenuMainItem.vue';
import SIcon from '../../Simple/Icon/SIcon.vue';

export default defineComponent({
  name: 'CSideBar',
  components: {
    SIcon,
    CMenuMainItem,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    menuList: {
      type: Array as PropType<SidebarMenuMainItemInterface[]>,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const sidebarClasses = computed(() => [
      'c-sidebar',
      props.isCollapsed ? 'c-sidebar_collapsed' : null,
    ]);

    const closeIconConfig: SIconPropsInterface = {
      name: IconNameEnum.CROSS,
      color: ColorEnum.WHITE,
    };

    function handleClickBackground() {
      emit('click-background');
    }

    function handleClickClose() {
      emit('click-close');
    }

    return {
      sidebarClasses,
      closeIconConfig,
      handleClickBackground,
      handleClickClose,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "../../../assets/breakpoints";

.c-sidebar {
  position: relative;

  &__background {
    display: none;
  }

  &__area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 255px;
    height: 100%;
    border-top: 1px solid var(--color-toolbar-active);
    background-color: var(--color-toolbar);
    transition: width .2s ease-in;
  }

  &_collapsed &__area {
    width: 64px;
  }

  &__menu {
    display: flex;
    flex-direction: column;
  }

  &__bottom {
    margin-top: auto;
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__background {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition: background-color .2s ease-in, z-index .2s 0s ease-in;
    }

    &__close {
      position: absolute;
      top: 71px;
      left: 265px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      background-color: var(--color-toolbar);
    }

    &__area {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      min-height: calc(100vh - 55px);
      transition: left .2s ease-in;
    }

    &_collapsed &__background {
      z-index: -5;
      background-color: rgba(0, 0, 0, 0.0);
    }

    &_collapsed &__area {
      left: -260px;
      width: 255px;
    }
  }
}
</style>
