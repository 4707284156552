import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52038504"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  fill: "none",
  height: "24",
  viewBox: "0 0 24 24",
  width: "24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      "clip-rule": "evenodd",
      d: "M19.3636 18.2726L19.363 19.363L20.4545 19.3635V21.5453H3V19.3635L4.09 19.363L4.09091 18.2726H19.3636ZM18.2738 9.5508V17.1872H16.092V9.5508H18.2738ZM12.8193 9.54535V17.1817H10.6375V9.54535H12.8193ZM7.36473 9.54098V17.1773H5.18291V9.54098H7.36473ZM11.7275 3L20.4548 6.27273V8.45455H3.00022V6.27273L11.7275 3Z",
      "fill-rule": "evenodd"
    }, null, 2)
  ]))
}