import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4403b590"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  fill: "none",
  viewBox: "0 0 32 32",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M16.0008 2C9.8761 10.75 9.07253 18.5046 16.0739 29.8796M16.0008 2C22.1255 10.75 23.0013 18.625 16 30M16.0008 2C8.26881 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30M16.0008 2C23.7328 2 30 8.26801 30 16C30 23.732 23.732 30 16 30M3.87315 9L28.1273 9M2.875 16.875L29.125 16.875M5.5 24.75L26.9294 24.75",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 2)
  ]))
}