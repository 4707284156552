import {
  createStore,
} from 'vuex';
import * as authStore from '@/app/Store/authStore';
import * as systemRegisterStore from '@/app/Store/systemRegisterStore';
import * as vendorStore from '@/app/Store/vendorStore';
import ELocale from '@/app/Enum/ELocale';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import ELocalStorageKeys from '@/app/Service/LocalStorage/Enum/ELocalStorageKeys';

export const store = (serviceContainer: IServiceContainer) => {
  const store = createStore({
    state: {
      locale: ELocale.RU,
    },
    getters: {
      getLocale(state) {
        return state.locale;
      },
    },
    mutations: {
      setLocale(state, payload: ELocale) {
        state.locale = payload;
      },
    },
    actions: {
      updateLocale({ commit }, locale: ELocale) {
        commit('setLocale', locale);

        serviceContainer.localStorageService.write(ELocalStorageKeys.LOCALE, locale);
      },
    },
  });

  store.registerModule('authModule', authStore.authStore);
  store.registerModule('systemRegisterModule', systemRegisterStore.systemRegisterStore);
  store.registerModule('vendorModule', vendorStore.vendorStore(serviceContainer));

  return store;
};
