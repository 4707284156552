<template>
  <div
    :class="classes"
    @click="handleCLick"
  >
    <SIcon
      v-if="icon && iconFirst"
      :color="icon.color"
      :size="icon.size"
      :name="icon.name"
      class="s-chips__icon"
      @click.stop="handleIconCLick"
    />
    <STextElement
      :color="textColor"
      :variant="textVariant"
    >
      <slot />
    </STextElement>
    <SIcon
      v-if="icon && !iconFirst"
      :color="icon.color"
      :size="icon.size"
      :name="icon.name"
      class="s-chips__icon"
      @click.stop="handleIconCLick"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';

import FontVariantEnum from '../../../lib/Enum/FontVariantEnum';
import SIconPropsInterface from '../../../lib/Model/Components/Simple/Icon/SIconPropsInterface';
import ColorEnum from '../../../lib/Enum/ColorEnum';
import SizeEnum from '../../../lib/Enum/SizeEnum';
import StateTypeEnum from '../../../lib/Enum/StateTypeEnum';
import ChipsVariantEnum from '../../../lib/Enum/Components/Simple/UI/ChipsVariantEnum';

import SIcon from '../Icon/SIcon.vue';
import STextElement from '../Text/STextElement.vue';

export default defineComponent({
  name: 'SChips',
  components: {
    STextElement,
    SIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String as PropType<Exclude<ColorEnum, ColorEnum.TOOLBAR | ColorEnum.DISABLED>>,
      default: () => ColorEnum.PRIMARY,
    },
    size: {
      type: String as PropType<SizeEnum>,
      default: () => SizeEnum.M,
    },
    variant: {
      type: String as PropType<ChipsVariantEnum>,
      default: () => ChipsVariantEnum.FILLED,
    },
    stateType: {
      type: String as PropType<StateTypeEnum>,
      default: () => StateTypeEnum.INNER,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    icon: {
      type: Object as PropType<SIconPropsInterface>,
      default: () => null,
    },
    iconFirst: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      active: false,
    });

    onMounted(() => {
      state.active = props.modelValue;
    });

    watch(() => props.modelValue, (newValue: boolean) => {
      state.active = newValue;
    });

    const classes = computed(() => {
      let color: string = props.color as string;

      if (props.disabled) {
        color = ColorEnum.DISABLED;
      }

      return [
        's-chips',
        state.active ? 's-chips_active' : null,
        props.disabled ? 's-chips_disabled' : null,
        `s-chips_variant-${props.variant}`,
        `s-chips_color-${color}`,
        `s-chips_size-${props.size}`,
      ];
    });

    const textColor = computed(() => {
      if (props.disabled) {
        return ColorEnum.DISABLED;
      }

      if (props.variant === ChipsVariantEnum.BORDERED) {
        return ColorEnum.BLACK;
      }

      if (state.active) {
        return props.color;
      }

      return ColorEnum.BLACK;
    });

    const textVariant = computed(
      () => (props.size === SizeEnum.XS ? FontVariantEnum.SMALL : FontVariantEnum.REGULAR),
    );

    function handleCLick(event: PointerEvent) {
      const value = state.active;

      if (props.stateType === StateTypeEnum.INNER) {
        state.active = !value;
      }

      emit('update:modelValue', !value);
      emit('chips-click', event, !value);
    }

    function handleIconCLick() {
      emit('icon-click', !state.active);
    }

    return {
      classes,
      textColor,
      textVariant,
      handleCLick,
      handleIconCLick,
    };
  },
});

</script>

<style
  scoped
  lang="scss"
>
.s-chips {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  border: var(--chips-border-width) solid var(--color-surface);

  &_size-l {
    gap: var(--chips-l-gap);
    padding:
      var(--chips-l-padding-top)
      var(--chips-l-padding-right)
      var(--chips-l-padding-bottom)
      var(--chips-l-padding-left);
    border-radius:
      var(--chips-l-border-radius-top-left)
      var(--chips-l-border-radius-top-right)
      var(--chips-l-border-radius-bottom-right)
      var(--chips-l-border-radius-bottom-left);
  }

  &_size-m {
    gap: var(--chips-m-gap);
    padding:
      var(--chips-m-padding-top)
      var(--chips-m-padding-right)
      var(--chips-m-padding-bottom)
      var(--chips-m-padding-left);
    border-radius:
      var(--chips-m-border-radius-top-left)
      var(--chips-m-border-radius-top-right)
      var(--chips-m-border-radius-bottom-right)
      var(--chips-m-border-radius-bottom-left);
  }

  &_size-s {
    gap: var(--chips-s-gap);
    padding:
      var(--chips-s-padding-top)
      var(--chips-s-padding-right)
      var(--chips-s-padding-bottom)
      var(--chips-s-padding-left);
    border-radius:
      var(--chips-s-border-radius-top-left)
      var(--chips-s-border-radius-top-right)
      var(--chips-s-border-radius-bottom-right)
      var(--chips-s-border-radius-bottom-left);
  }

  &_size-xs {
    gap: var(--chips-xs-gap);
    padding:
      var(--chips-xs-padding-top)
      var(--chips-xs-padding-right)
      var(--chips-xs-padding-bottom)
      var(--chips-xs-padding-left);
    border-radius:
      var(--chips-xs-border-radius-top-left)
      var(--chips-xs-border-radius-top-right)
      var(--chips-xs-border-radius-bottom-right)
      var(--chips-xs-border-radius-bottom-left);
  }

  &_disabled {
    pointer-events: none;
  }

  &_color-disabled {
    background-color: var(--color-secondary);
  }

  &_color-primary.s-chips_active {
    border-color: var(--color-primary);

    &.s-chips_variant-filled {
      background-color: var(--color-background-primary);
    }
  }

  &_color-danger.s-chips_active {
    border-color: var(--color-danger);

    &.s-chips_variant-filled {
      background-color: var(--color-background-danger);
    }
  }

  &_color-success.s-chips_active {
    border-color: var(--color-success);

    &.s-chips_variant-filled {
      background-color: var(--color-background-success);
    }
  }

  &_color-warn.s-chips_active {
    border-color: var(--color-warn);

    &.s-chips_variant-filled {
      background-color: var(--color-background-warn);
    }
  }

  &__icon {
    flex: 0 0 auto;
  }
}

</style>
