import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fb410a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  fill: "none",
  height: "100%",
  viewBox: "0 0 24 24",
  width: "100%",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      "clip-rule": "evenodd",
      d: "M16 18V15.16C16 15.0671 16.0259 14.9761 16.0747 14.8971C16.1235 14.8181 16.1933 14.7543 16.2764 14.7128C16.3594 14.6713 16.4524 14.6537 16.5449 14.662C16.6374 14.6704 16.7257 14.7043 16.8 14.76L21.727 18.44C21.7895 18.4865 21.8402 18.547 21.8752 18.6166C21.9101 18.6863 21.9283 18.7631 21.9283 18.841C21.9283 18.9189 21.9101 18.9957 21.8752 19.0654C21.8402 19.135 21.7895 19.1955 21.727 19.242L16.799 22.922C16.7247 22.9774 16.6365 23.0111 16.5442 23.0193C16.4518 23.0275 16.359 23.0098 16.2762 22.9684C16.1933 22.9269 16.1236 22.8631 16.0748 22.7843C16.026 22.7055 16.0001 22.6147 16 22.522V20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V7C2 6.20435 2.31607 5.44129 2.87868 4.87868C3.44129 4.31607 4.20435 4 5 4H19C19.7956 4 20.5587 4.31607 21.1213 4.87868C21.6839 5.44129 22 6.20435 22 7V16.148L20 14.654V12H4V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H16ZM20 8V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7V8H20Z",
      "fill-rule": "evenodd"
    }, null, 2)
  ]))
}