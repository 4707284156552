enum FileExtensionsEnum {
  JPEG = 'jpeg',
  JPG = 'jpg',
  DOC = 'doc',
  DOCX = 'docx',
  PDF = 'pdf',
  PNG = 'png',
  XLS = 'xls',
  XLSX = 'xlsx',
  GIF = 'gif',
  PPT = 'ppt',
  PPTX = 'pptx',
  PSD = 'psd',
  EPS = 'eps',
  TIFF = 'tiff',
}

export default FileExtensionsEnum;
