import { Module } from 'vuex';
import IAuthState from '@/app/Model/State/IAuthState';

export const authStore: Module<IAuthState, unknown> = {
  state: {
    accessToken: undefined,
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
  },
  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
  },
  actions: {
    updateAccessToken({ commit }, value) {
      commit('setAccessToken', value);
    },
  },
};
