<template>
  <div
    :class="parentClasses"
  >
    <PerfectScrollbar
      ref="scrollbar"
      class="s-textarea__scrollbar"
      @touchmove.stop.passive
    >
      <textarea
        :id="id"
        ref="textarea"
        v-model="state.value"
        :placeholder="placeholder"
        :rows="minRowsCount"
        class="s-textarea__field"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="handleInput"
        @keydown="handleKeydown"
      />
    </PerfectScrollbar>
    <STextElement
      v-if="maxLength > 0"
      class="s-textarea__counter"
      color="stroke"
      variant="small"
    >
      {{ charsLeft }}
    </STextElement>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  onMounted,
  PropType,
  reactive,
  watch,
  nextTick,
  inject,
  ref,
  defineComponent,
} from 'vue';

import {
  IUtilsService,
} from '@/app/Service/UtilsService';

import ColorEnum from '../../../lib/Enum/ColorEnum';

import STextElement from '../Text/STextElement.vue';

export default defineComponent({
  name: 'STextArea',
  components: {
    STextElement,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    minRowsCount: {
      type: Number,
      default: 2,
    },
    maxRowsCount: {
      type: Number,
      default: 5,
    },
    color: {
      type: String as PropType<Exclude<ColorEnum, ColorEnum.TOOLBAR>>,
      default: ColorEnum.DISABLED,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const utilsService = inject<IUtilsService>('utilsService');

    if (!utilsService) {
      throw new Error('UtilsService not defined.');
    }

    const state = reactive<{
      value: string;
      isEditing: boolean;
      isFocused: boolean,
    }>({
      value: '',
      isEditing: false,
      isFocused: false,
    });

    const scrollbar = ref();
    const textarea = ref();

    const parentClasses = computed(() => [
      's-textarea__block',
      `s-textarea__block_color-${props.color}`,
      state.isFocused ? 's-text-input_focused' : null,
      props.disabled ? 's-textarea__block_disabled' : null,
    ]);

    const charsLeft = computed(() => props.maxLength - state.value.length);

    function setMaxHeight() {
      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      if (textarea?.value) {
        const textAreaStyles = utilsService.style.getComputedStyle(textarea.value);
        const lineHeight = utilsService.style.clearUnit(textAreaStyles.lineHeight);
        const paddingTop = utilsService.style.clearUnit(textAreaStyles.paddingTop);
        const paddingBottom = utilsService.style.clearUnit(textAreaStyles.paddingBottom);

        const rowsTotalHeight = lineHeight * props.maxRowsCount;
        const scrollbarMaxHeight = paddingTop + rowsTotalHeight + paddingBottom;

        if (scrollbar?.value) {
          scrollbar.value.$el.style.maxHeight = `${scrollbarMaxHeight}px`;
        }
      }
    }

    function updateHeight() {
      if (textarea?.value) {
        const textareaElement = textarea.value;
        textareaElement.style.height = 'auto';
        textareaElement.style.height = `${textareaElement.scrollHeight}px`;
      }
    }

    onMounted(() => {
      state.value = props.modelValue;
      setMaxHeight();
      nextTick(() => updateHeight());
    });

    watch(() => props.modelValue, (newValue) => {
      state.value = newValue ?? '';
      nextTick(() => updateHeight());
    });

    function handleInput() {
      emit('input', state.value);
      emit('update:modelValue', state.value);

      nextTick(() => updateHeight());
    }

    function handleKeydown(event: InputEvent) {
      state.isEditing = true;

      emit('keyup', event);
    }

    function handleFocus(event: FocusEvent) {
      state.isEditing = true;
      state.isFocused = false;

      emit('focus', event);
    }

    function handleBlur(event: FocusEvent) {
      state.isEditing = false;
      state.isFocused = false;

      emit('blur', event, state.value);
    }

    return {
      state,
      textarea,
      scrollbar,
      parentClasses,
      charsLeft,
      handleInput,
      handleBlur,
      handleFocus,
      handleKeydown,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@mixin font-styles {
  font-family: var(--text-font-family);
  font-size: var(--text-variant-desktop-regular-size);
  line-height: var(--text-variant-desktop-regular-line-height);
  font-weight: var(--text-variant-desktop-regular-weight);
}

.s-textarea {
  &__block {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    border-width: var(--input-border-width);
    border-style: solid;
    border-radius: var(--text-input-border-radius-top-left)
      var(--text-input-border-radius-top-right)
      var(--text-input-border-radius-bottom-right)
      var(--text-input-border-radius-bottom-left);
    outline: none;
    background-color: var(--color-white);

    &_color {
      &-disabled {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-disabled-focus);
        }
      }

      &-danger {
        border-color: var(--color-danger);

        &.s-textarea_focused {
          border-color: var(--color-danger-focus);
        }
      }

      &-gray {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-gray-focus);
        }
      }

      &-linked {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-linked-focus);
        }
      }

      &-navigation {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-navigation-focus);
        }
      }

      &-primary {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-primary-focus);
        }
      }

      &-secondary {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-secondary-focus);
        }
      }

      &-success {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-success-focus);
        }
      }

      &-warn {
        border-color: var(--color);

        &.s-textarea_focused {
          border-color: var(--color-warn-focus);
        }
      }

      &-white {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-white-focus);
        }
      }

      &-black {
        border-color: var(--color-disabled);

        &.s-textarea_focused {
          border-color: var(--color-black-focus);
        }
      }
    }

    &_disabled {
      pointer-events: none;
      border-color: var(--color-disabled);
    }
  }

  &__field {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 11px 14px;
    border: none;
    outline: none;
    background-color: transparent;
    resize: none;
    transition: all linear .2s;

    @include font-styles;

    &::-webkit-scrollbar {
      width: 12px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid #ffffff;
      border-radius: 6px;
      background-color: #999999;
      cursor: pointer;
    }

    &::-webkit-input-placeholder {
      @include font-styles;
      color: var(--color-disabled);
    }

    &::-ms-input-placeholder {
      @include font-styles;
      color: var(--color-disabled);
    }

    &::placeholder {
      @include font-styles;
      color: var(--color-disabled);
    }
  }

  &__counter {
    position: absolute;
    right: 15px;
    bottom: 12px;
  }
}
</style>
