import ICookieService from '@/app/Service/CookieService/Contract/ICookieService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import ECookieKeys from '@/app/Service/CookieService/Enum/ECookieKeys';

class CookieService implements ICookieService {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  read = <Value>(key: ECookieKeys): Value | undefined => {
    const { utilsService } = this.serviceContainer;

    const storageValue = this.extractValue(key);
    if (storageValue === 'null') {
      return null as unknown as Value;
    }

    if (storageValue === 'undefined') {
      return undefined;
    }

    if (utilsService.typeCheck.isNull(storageValue)) {
      return undefined as unknown as Value;
    }

    if (utilsService.typeCheck.isUndefined(storageValue)) {
      return undefined as unknown as Value;
    }

    if (storageValue === 'true') {
      return true as unknown as Value;
    }

    if (storageValue === 'false') {
      return false as unknown as Value;
    }

    try {
      return utilsService.json.from<Value>(storageValue);
    } catch (e) {
      return storageValue as unknown as Value;
    }
  };

  write = <Value>(key: ECookieKeys, value: Value, maxAgeInDays = 0): void => {
    const { utilsService } = this.serviceContainer;

    if (utilsService.typeCheck.isString(value)) {
      document.cookie = this.prepareCookie(key, value, maxAgeInDays);
    } else if (utilsService.typeCheck.isNumber(value)) {
      document.cookie = this.prepareCookie(key, `${value}`, maxAgeInDays);
    } else if (utilsService.typeCheck.isNull(value)) {
      document.cookie = this.prepareCookie(key, 'null', maxAgeInDays);
    } else if (utilsService.typeCheck.isObject(value)) {
      document.cookie = this.prepareCookie(key, utilsService.json.to(value), maxAgeInDays);
    } else if (utilsService.typeCheck.isArray(value)) {
      document.cookie = this.prepareCookie(key, utilsService.json.to(value), maxAgeInDays);
    } else if (utilsService.typeCheck.isSet(value)) {
      document.cookie = this.prepareCookie(key, `${value}`, maxAgeInDays);
    } else {
      document.cookie = this.prepareCookie(key, 'undefined', maxAgeInDays);
    }
  };

  private prepareCookie(key: ECookieKeys, value: string, maxAgeInDays: number): string {
    let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

    if (maxAgeInDays > 0) {
      cookieString += `;max-age=${maxAgeInDays * 24 * 60 * 60};`;
    }

    return cookieString;
  }

  private extractValue(key: ECookieKeys) {
    const cookieRegistry: Partial<Record<ECookieKeys, string>> = document.cookie.split(';')
      .map((value) => value.trim())
      .reduce((result: Partial<Record<ECookieKeys, string>>, pair: string) => {
        const parts: string[] = pair.split('=').map((item) => decodeURIComponent(item));
        const name: ECookieKeys = parts[0] as ECookieKeys;

        // eslint-disable-next-line prefer-destructuring
        result[name] = parts[1];

        return result;
      }, {});

    return cookieRegistry[key];
  }
}

export default CookieService;
