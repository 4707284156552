<template>
  <div
    :class="classes"
  >
    <slot />
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import FontVariantEnum from '../../../lib/Enum/FontVariantEnum';
import ColorEnum from '../../../lib/Enum/ColorEnum';

export default defineComponent({
  name: 'STextElement',
  props: {
    variant: {
      type: String as PropType<FontVariantEnum>,
      default: FontVariantEnum.REGULAR,
    },
    color: {
      type: String as PropType<Exclude<ColorEnum, ColorEnum.TOOLBAR>>,
      default: ColorEnum.BLACK,
    },
  },
  setup(props) {
    const classes = computed(() => [
      's-text-element',
      `s-text-element_variant-${props.variant}`,
      `s-text-element_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-text-element {
  font-family: var(--text-font-family);

  &_variant-italic {
    font-size: var(--text-variant-desktop-italic-size);
    line-height: var(--text-variant-desktop-italic-line-height);
    font-weight: var(--text-variant-desktop-italic-weight);
    font-style: italic;
  }

  &_variant-medium {
    font-size: var(--text-variant-desktop-medium-size);
    line-height: var(--text-variant-desktop-medium-line-height);
    font-weight: var(--text-variant-desktop-medium-weight);
  }

  &_variant-regular {
    font-size: var(--text-variant-desktop-regular-size);
    line-height: var(--text-variant-desktop-regular-line-height);
    font-weight: var(--text-variant-desktop-regular-weight);
  }

  &_variant-small {
    font-size: var(--text-variant-desktop-small-size);
    line-height: var(--text-variant-desktop-small-line-height);
    font-weight: var(--text-variant-desktop-small-weight);
  }

  &_variant-small-long-line {
    font-size: var(--text-variant-desktop-small-long-line-size);
    line-height: var(--text-variant-desktop-small-long-line-line-height);
    font-weight: var(--text-variant-desktop-small-long-line-weight);
  }

  &_variant-small-medium {
    font-size: var(--text-variant-desktop-small-medium-size);
    line-height: var(--text-variant-desktop-small-medium-line-height);
    font-weight: var(--text-variant-desktop-small-medium-weight);
  }

  &_variant-subheader {
    font-size: var(--text-variant-desktop-subheader-size);
    line-height: var(--text-variant-desktop-subheader-line-height);
    font-weight: var(--text-variant-desktop-subheader-weight);
  }

  &_variant-x-small-medium {
    font-size: var(--text-variant-desktop-x-small-medium-size);
    line-height: var(--text-variant-desktop-x-small-medium-line-height);
    font-weight: var(--text-variant-desktop-x-small-medium-weight);
  }

  &_color-disabled {
    color: var(--color-disabled);
  }

  &_color-danger {
    color: var(--color-danger);
  }

  &_color-gray {
    color: var(--color-gray);
  }

  &_color-linked {
    color: var(--color-linked);
  }

  &_color-navigation {
    color: var(--color-navigation);
  }

  &_color-primary {
    color: var(--color-primary);
  }

  &_color-secondary {
    color: var(--color-secondary);
  }

  &_color-success {
    color: var(--color-success);
  }

  &_color-warn {
    color: var(--color-warn);
  }

  &_color-white {
    color: var(--color-white);
  }

  &_color-black {
    color: var(--color-black);
  }

  &_color-stroke {
    color: var(--color-stroke);
  }
}
</style>
