import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import IDataManagerRequestModel from '@/shared/Api/Model/Service/Portfolio/IDataManagerRequestModel';
import IDataManagerApi from '@/shared/Api/Contract/Api/IDataManagerApi';

class AboutApi implements IDataManagerApi {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  create(data: IDataManagerRequestModel): Promise<void> {
    return this.clientService.instance.post('/service/data-manager', data);
  }
}

export default AboutApi;
