import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.itemClasses)
  }, [
    _createVNode(_component_STextElement, {
      color: _ctx.textElementConfig.color,
      variant: _ctx.textElementConfig.variant
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.title), 1)
      ]),
      _: 1
    }, 8, ["color", "variant"])
  ], 2))
}