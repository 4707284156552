<template>
  <div
    class="c-top-bar"
  >
    <div
      class="c-top-bar__left-side"
    >
      <slot
        name="left-side"
      />
    </div>
    <div
      class="c-top-bar__right-side"
    >
      <slot
        name="right-side"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'CTopBar',
});
</script>

<style
  lang="scss"
  scoped
>
@import "../../../assets/breakpoints";

.c-top-bar {
  z-index: 5;
  display: flex;
  align-items: center;
  width: 100%;
  height: 68px;
  padding: 14px 24px;
  background-color: var(--color-toolbar);
  gap: 16px;

  &__left-side {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;
    gap: 24px;
  }

  &__right-side {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 24px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    height: 55px;
    padding: 14px 16px;
  }
}
</style>
