<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5 21.0002H19.5C19.8978 21.0002 20.2794 20.8422 20.5607 20.5609C20.842 20.2796 21 19.8981 21 19.5002V14.5002C21 14.1024 20.842 13.7209 20.5607 13.4396C20.2794 13.1583 19.8978 13.0002 19.5 13.0002H14.5C14.1022 13.0002 13.7206 13.1583 13.4393 13.4396C13.158 13.7209 13 14.1024 13 14.5002V19.5002C13 19.8981 13.158 20.2796 13.4393 20.5609C13.7206 20.8422 14.1022 21.0002 14.5 21.0002ZM11 19.5002V14.5002C11 14.1024 10.842 13.7209 10.5607 13.4396C10.2794 13.1583 9.89782 13.0002 9.5 13.0002H4.5C4.10218 13.0002 3.72064 13.1583 3.43934 13.4396C3.15804 13.7209 3 14.1024 3 14.5002V19.5002C3 19.8981 3.15804 20.2796 3.43934 20.5609C3.72064 20.8422 4.10218 21.0002 4.5 21.0002H9.5C9.89782 21.0002 10.2794 20.8422 10.5607 20.5609C10.842 20.2796 11 19.8981 11 19.5002ZM4.5 11.0002H9.5C9.89782 11.0002 10.2794 10.8422 10.5607 10.5609C10.842 10.2796 11 9.89808 11 9.50025V4.50025C11 4.10243 10.842 3.72089 10.5607 3.43959C10.2794 3.15829 9.89782 3.00025 9.5 3.00025H4.5C4.10218 3.00025 3.72064 3.15829 3.43934 3.43959C3.15804 3.72089 3 4.10243 3 4.50025V9.50025C3 9.89808 3.15804 10.2796 3.43934 10.5609C3.72064 10.8422 4.10218 11.0002 4.5 11.0002ZM12.06 8.40025L15.6 11.9403C15.8813 12.2212 16.2625 12.3789 16.66 12.3789C17.0575 12.3789 17.4387 12.2212 17.72 11.9403L21.26 8.40225C21.3995 8.26293 21.5102 8.09747 21.5858 7.91532C21.6613 7.73318 21.7002 7.53793 21.7002 7.34075C21.7002 7.14357 21.6613 6.94832 21.5858 6.76618C21.5102 6.58403 21.3995 6.41857 21.26 6.27925L17.72 2.74825C17.4387 2.46735 17.0575 2.30957 16.66 2.30957C16.2625 2.30957 15.8813 2.46735 15.6 2.74825L12.06 6.27925C11.9205 6.41857 11.8098 6.58403 11.7342 6.76618C11.6587 6.94832 11.6198 7.14357 11.6198 7.34075C11.6198 7.53793 11.6587 7.73318 11.7342 7.91532C11.8098 8.09747 11.9205 8.26293 12.06 8.40225V8.40025Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconServices',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-services_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-services {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

  &_color-stroke {
    fill: var(--color-stroke);
  }
}
</style>
