import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

import {
  CTextInputListItemInterface,
  FileInterface,
} from '@/shared/DesignSystem';

import FieldConfigType from './lib/Model/Fields/FieldConfigType';
import FieldValueHandlerInterface from './lib/Contract/FieldValueHandlerInterface';

class FieldValueHandler implements FieldValueHandlerInterface {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  getStringValue(field: FieldConfigType, formData: Record<string, unknown>): string {
    return formData[field.fieldName] as string ?? '';
  }

  getArrayValue(field: FieldConfigType, formData: Record<string, unknown>): Array<unknown> {
    return formData[field.fieldName] as Array<unknown> ?? [];
  }

  getTextInputListValue(field: FieldConfigType, formData: Record<string, unknown>): Array<CTextInputListItemInterface> {
    return formData[field.fieldName] as Array<CTextInputListItemInterface> ?? [];
  }

  updateFormData = (
    formData: Record<string, unknown>,
    field: FieldConfigType,
    value: unknown,
  ): Record<string, unknown> => {
    const { data } = this.serviceContainer.utilsService;

    const localFormData = data.cloneImmutable<Record<string, unknown>>(formData);
    localFormData[field.fieldName] = value;

    return localFormData;
  };

  getFileListValue(field: FieldConfigType, formData: Record<string, unknown>): Array<FileInterface> {
    return formData[field.fieldName] as Array<FileInterface> ?? [];
  }
}

export default FieldValueHandler;
