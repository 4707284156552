<template>
  <div
    :class="classes"
  >
    <div
      class="setup-step__title"
    >
      <SIcon
        v-if="step.isComplete"
        color="success"
        name="checkCircled"
        size="l"
      />
      <div
        v-else
        class="setup-step__number"
      >
        <STextElement
          variant="subheader"
        >
          {{ step.number }}
        </STextElement>
      </div>
      <STextElement
        variant="medium"
      >
        {{ step.title }}
      </STextElement>
      <SButton
        v-if="step.button && !isMobile"
        :size="step.button.size"
        :text="step.button.text"
        :variant="step.button.variant"
        class="setup-step__button"
        @click="handleClickButton(step)"
      />
    </div>

    <div
      v-if="hasItems"
      class="setup-step__list"
    >
      <div
        v-for="(item, index) in step.items"
        :key="`step-${step.number}-item-${index}`"
        class="setup-step__item"
      >
        <SIcon
          :color="getItemIconColor(item)"
          :name="getItemIconName(item)"
          class="setup-step__icon"
        />
        <STextElement>
          {{ item.title }}
        </STextElement>
      </div>
    </div>

    <CInfoBlock
      v-if="step.info"
      :text="step.info"
    />

    <SButton
      v-if="step.button && isMobile"
      :size="step.button.size"
      :stretch="true"
      :text="step.button.text"
      :variant="step.button.variant"
      class="setup-step__button"
      @click="handleClickButton(step)"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  PropType,
} from 'vue';

import {
  ColorEnum,
  IconNameEnum,
} from '@/shared/DesignSystem';
import IStep from '@/pages/HomePage/Model/IStep';
import IStepItem from '@/pages/HomePage/Model/IStepItem';

import STextElement from '@/shared/DesignSystem/Components/Simple/Text/STextElement.vue';
import SIcon from '@/shared/DesignSystem/Components/Simple/Icon/SIcon.vue';
import SButton from '@/shared/DesignSystem/Components/Simple/UI/SButton.vue';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

export default defineComponent({
  components: {
    SButton,
    SIcon,
    STextElement,
  },
  props: {
    step: {
      type: Object as PropType<IStep>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const { store } = serviceContainer;

    const isMobile = computed(() => store.getters.getIsMobile);

    const classes = computed(() => [
      'setup-step',
      props.step.isComplete ? 'setup-step_complete' : null,
    ]);

    const hasItems = computed(() => props.step.items && props.step.items.length > 0);

    function getItemIconName(item: IStepItem): IconNameEnum {
      return item.isComplete ? IconNameEnum.CHECK : IconNameEnum.CLOCK;
    }

    function getItemIconColor(item: IStepItem): ColorEnum {
      return item.isComplete ? ColorEnum.SUCCESS : ColorEnum.GRAY;
    }

    function handleClickButton(step: IStep) {
      emit('click-button', step);
    }

    return {
      classes,
      hasItems,
      isMobile,
      getItemIconName,
      getItemIconColor,
      handleClickButton,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.setup-step {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 2px solid var(--color-surface);
  border-radius: 8px;
  gap: 24px;

  &_complete {
    border-color: var(--color-success);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__button {
    margin-left: auto;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 2px solid var(--color-primary);
    border-radius: 50%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__icon {
    flex: 0 0 auto;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding: 16px;
  }
}
</style>
