<template>
  <div
    class="internet-acquiring"
  >
    <SHeader
      :size="3"
    >
      {{ $t('pages.home.setup.internet_acquiring.title') }}
    </SHeader>
    <div
      class="internet-acquiring__banner"
    >
      <SIcon
        :color="banner.icon.color"
        :name="banner.icon.name"
        :size="banner.icon.size"
        class="sales-banner__item-icon"
      />
      <SHeader
        :size="3"
      >
        {{ banner.title }}
      </SHeader>
      <STextElement
        variant="small"
      >
        {{ banner.text }}
      </STextElement>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { ColorEnum, IconNameEnum } from '@/shared/DesignSystem';
import SizeEnum from '@/shared/DesignSystem/lib/Enum/SizeEnum';
import SIcon from '@/shared/DesignSystem/Components/Simple/Icon/SIcon.vue';
import SHeader from '@/shared/DesignSystem/Components/Simple/Text/SHeader.vue';

export default defineComponent({
  components: { SHeader, SIcon },
  setup() {
    const { t } = useI18n();

    const banner = computed(() => ({
      icon: {
        name: IconNameEnum.GLOBE,
        color: ColorEnum.LINKED,
        size: SizeEnum.L,
      },
      title: t('pages.home.setup.internet_acquiring.banner.title'),
      text: t('pages.home.setup.internet_acquiring.banner.text'),
    }));

    return {
      banner,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.internet-acquiring {
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;

  &__banner {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding: 32px;
    border-radius: 8px;
    background-color: var(--color-background-primary);
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__banner {
      padding: 16px;
    }
  }
}
</style>
