<template>
  <component
    :is="name"
    :class="classes"
    :color="color"
  />
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import IconNameEnum from '../../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import ColorEnum from '../../../lib/Enum/ColorEnum';
import SizeEnum from '../../../lib/Enum/SizeEnum';

import SCheckCircleIcon from './Icons/SCheckCircleIcon.vue';
import SCrossCircleIcon from './Icons/SCrossCircleIcon.vue';
import SIconAlert from './Icons/SIconAlert.vue';
import SIconArrowDown from './Icons/SIconArrowDown.vue';
import SIconArrowLeft from './Icons/SIconArrowLeft.vue';
import SIconArrowLeftDouble from './Icons/SIconArrowLeftDouble.vue';
import SIconArrowLeftTailed from './Icons/SIconArrowLeftTailed.vue';
import SIconArrowReturn from './Icons/SIconArrowReturn.vue';
import SIconArrowRight from './Icons/SIconArrowRight.vue';
import SIconArrowRightTailed from './Icons/SIconArrowRightTailed.vue';
import SIconBank from './Icons/SIconBank.vue';
import SIconBankCard from './Icons/SIconBankCard.vue';
import SIconBill from './Icons/SIconBill.vue';
import SIconBinoculars from './Icons/SIconBinoculars.vue';
import SIconBook from './Icons/SIconBook.vue';
import SIconBurger from './Icons/SIconBurger.vue';
import SIconCalendar from './Icons/SIconCalendar.vue';
import SIconCheck from './Icons/SIconCheck.vue';
import SIconCheckboxOn from './Icons/SIconCheckboxOn.vue';
import SIconCheckCircled from './Icons/SIconCheckCircled.vue';
import SIconClock from './Icons/SIconClock.vue';
import SIconCode from './Icons/SIconCode.vue';
import SIconCoupon from './Icons/SIconCoupon.vue';
import SIconCross from './Icons/SIconCross.vue';
import SIconDots from './Icons/SIconDots.vue';
import SIconGlobe from './Icons/SIconGlobe.vue';
import SIconHome from './Icons/SIconHome.vue';
import SIconInfo from './Icons/SIconInfo.vue';
import SIconKey from './Icons/SIconKey.vue';
import SIconMail from './Icons/SIconMail.vue';
import SIconPaperClip from './Icons/SIconPaperClip.vue';
import SIconPlus from './Icons/SIconPlus.vue';
import SIconProfile from './Icons/SIconProfile.vue';
import SIconPromotions from './Icons/SIconPromotions.vue';
import SIconQuestion from './Icons/SIconQuestion.vue';
import SIconSearch from './Icons/SIconSearch.vue';
import SIconServices from './Icons/SIconServices.vue';
import SIconTrash from './Icons/SIconTrash.vue';
import SIconUpload from './Icons/SIconUpload.vue';

export default defineComponent({
  name: 'SIcon',
  components: {
    [IconNameEnum.ALERT]: SIconAlert,
    [IconNameEnum.ARROW_DOWN]: SIconArrowDown,
    [IconNameEnum.ARROW_LEFT]: SIconArrowLeft,
    [IconNameEnum.ARROW_LEFT_DOUBLE]: SIconArrowLeftDouble,
    [IconNameEnum.ARROW_LEFT_TAILED]: SIconArrowLeftTailed,
    [IconNameEnum.ARROW_RETURN]: SIconArrowReturn,
    [IconNameEnum.ARROW_RIGHT]: SIconArrowRight,
    [IconNameEnum.ARROW_RIGHT_TAILED]: SIconArrowRightTailed,
    [IconNameEnum.BANK]: SIconBank,
    [IconNameEnum.BANK_CARD]: SIconBankCard,
    [IconNameEnum.BILL]: SIconBill,
    [IconNameEnum.BINOCULARS]: SIconBinoculars,
    [IconNameEnum.BOOK]: SIconBook,
    [IconNameEnum.BURGER]: SIconBurger,
    [IconNameEnum.CALENDAR]: SIconCalendar,
    [IconNameEnum.CHECK]: SIconCheck,
    [IconNameEnum.CHECK_CIRCLE]: SCheckCircleIcon,
    [IconNameEnum.CHECK_CIRCLED]: SIconCheckCircled,
    [IconNameEnum.CHECKBOX_ON]: SIconCheckboxOn,
    [IconNameEnum.CHECKBOX_ON]: SIconCheckboxOn,
    [IconNameEnum.CLOCK]: SIconClock,
    [IconNameEnum.CODE]: SIconCode,
    [IconNameEnum.COUPON]: SIconCoupon,
    [IconNameEnum.CROSS]: SIconCross,
    [IconNameEnum.CROSS]: SIconCross,
    [IconNameEnum.CROSS_CIRCLE]: SCrossCircleIcon,
    [IconNameEnum.DOTS]: SIconDots,
    [IconNameEnum.GLOBE]: SIconGlobe,
    [IconNameEnum.HOME]: SIconHome,
    [IconNameEnum.INFO]: SIconInfo,
    [IconNameEnum.KEY]: SIconKey,
    [IconNameEnum.MAIL]: SIconMail,
    [IconNameEnum.PAPER_CLIP]: SIconPaperClip,
    [IconNameEnum.PLUS]: SIconPlus,
    [IconNameEnum.PROFILE]: SIconProfile,
    [IconNameEnum.PROMOTIONS]: SIconPromotions,
    [IconNameEnum.QUESTION]: SIconQuestion,
    [IconNameEnum.SEARCH]: SIconSearch,
    [IconNameEnum.SEARCH]: SIconSearch,
    [IconNameEnum.SERVICES]: SIconServices,
    [IconNameEnum.TRASH]: SIconTrash,
    [IconNameEnum.UPLOAD]: SIconUpload,
  },
  props: {
    name: {
      type: String as PropType<IconNameEnum>,
      required: true,
    },
    color: {
      type: String as PropType<ColorEnum>,
      default: ColorEnum.DISABLED,
    },
    size: {
      type: String as PropType<SizeEnum>,
      default: SizeEnum.M,
    },
  },
  setup(props) {
    const classes = computed(() => [
      's-icon',
      `s-icon_size-${props.size}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.s-icon {
  display: flex;
  align-items: center;

  &_size-s {
    width: var(--icon-s-width);
    height: var(--icon-s-height);
  }

  &_size-m {
    width: var(--icon-m-width);
    height: var(--icon-m-height);
  }

  &_size-l {
    width: var(--icon-l-width);
    height: var(--icon-l-height);
  }

  &_size-xl {
    width: var(--icon-xl-width);
    height: var(--icon-xl-height);
  }

  &_size-xxl {
    width: var(--icon-xxl-width);
    height: var(--icon-xxl-height);
  }
}
</style>
