import {
  useRouter,
} from 'vue-router';
import ISidebarService from '@/app/Service/SidebarService/model/ISidebarService';
import {
  IconNameEnum,
  SidebarMenuMainItemInterface,
} from '@/shared/DesignSystem';

class SidebarService implements ISidebarService {
  getMenuList(): SidebarMenuMainItemInterface[] {
    const router = useRouter();
    const routeList = Object.values(router.options.routes);
    let routerItemsForMenu = routeList.filter((item) => item?.meta?.showInSidebar);
    const menuOrders: number[] = routerItemsForMenu.map((item) => item.meta?.menuOrder as number ?? 0);
    let maxMenuOrder: number = Math.max(...menuOrders);
    routerItemsForMenu = routerItemsForMenu.map((item) => {
      if (item.meta && !item.meta?.menuOrder) {
        item.meta.menuOrder = maxMenuOrder;
        maxMenuOrder += 1;
      }

      return item;
    });

    routerItemsForMenu.sort((item1, item2) => {
      const itemMenuOrder1 = item1.meta?.menuOrder;
      const itemMenuOrder2 = item2.meta?.menuOrder;

      if (typeof itemMenuOrder1 === 'number' && typeof itemMenuOrder2 === 'number') {
        return itemMenuOrder1 - itemMenuOrder2;
      }

      return 0;
    });

    return routerItemsForMenu.map((item) => {
      const menuItem: SidebarMenuMainItemInterface = {
        title: item.meta?.title as string ?? '',
        icon: item.meta?.icon as IconNameEnum,
        active: item.name === router.currentRoute.value.name,
      };

      if (item.path) {
        menuItem.href = item.path;
      }

      if (item.meta?.disabled) {
        menuItem.disabled = !!item.meta?.disabled;
      }

      return menuItem;
    });
  }
}

export default SidebarService;
