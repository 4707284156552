import CompanyInfoResponseModelInterface
  from '@/shared/Api/Model/CompanyInfoApi/CompanyInfoResponseModelInterface';
import IndividualInfoResponseModelInterface
  from '@/shared/Api/Model/CompanyInfoApi/IndividualInfoResponseModelInterface';
import CompanyInfoApiInterface from '@/shared/Api/Contract/Api/CompanyInfoApiInterface';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';

class CompanyInfoApi implements CompanyInfoApiInterface {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  getCompanyInfo = (inn: string): Promise<CompanyInfoResponseModelInterface> => this.clientService.instance
    .get<CompanyInfoResponseModelInterface>('/company-info', {
      params: {
        inn,
        type: 'company',
      },
    })
    .then((response) => response.data);

  getIndividualInfo = (inn: string): Promise<IndividualInfoResponseModelInterface> => this.clientService.instance
    .get<IndividualInfoResponseModelInterface>('/company-info', {
      params: {
        inn,
        type: 'individual',
      },
    })
    .then((response) => response.data);
}

export default CompanyInfoApi;
