<template>
  <div
    v-click-outside="handleClickOutside"
    class="s-date-picker"
  >
    <STextInput
      :id="id"
      :color="color"
      :model-value="stringValue"
      :readonly="true"
      @click="handleToggleDropdown"
    >
      <template
        #before
      >
        <SIcon
          name="calendar"
        />
      </template>
    </STextInput>

    <STextElement
      v-if="showTimeZone"
      class="s-date-picker__offset"
      color="stroke"
    >
      {{ baseTimeZone.name }} {{ timeZoneOffset }}
    </STextElement>

    <div
      v-if="state.isMenuOpened"
      :class="dropdownClasses"
    >
      <vDatePicker
        ref="datePickerRef"
        v-model="state.value"
        :attributes="attributes"
        :is24hr="is24hr"
        :locale="localeSetting"
        :max-date="maxDate"
        :min-date="minDate"
        :mode="mode"
        class="s-date-picker__calendar"
        trim-weeks
        @dayclick="handleDayClick"
      />

      <div
        class="s-date-picker__controls"
      >
        <SButton
          :text="buttons.ok"
          class="s-date-picker__button date-picker__button_submit"
          size="s"
          variant="transparent"
          @click="handleSubmitClick"
        />
        <div
          class="s-date-picker__controls_divider"
        />
        <SButton
          :text="buttons.cancel"
          class="s-date-picker__button date-picker__button_cancel"
          color="danger"
          size="s"
          variant="transparent"
          @click="handleCancelClick"
        />
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';

import {
  DatePicker as vDatePicker,
} from 'v-calendar';
import 'v-calendar/style.css';

import {
  IUtilsService,
} from '@/app/Service/UtilsService';

import ColorEnum from '../../../lib/Enum/ColorEnum';
import DropdownPositionEnum from '../../../lib/Enum/DropdownPositionEnum';
import SDatePickerModeEnum from '../../../lib/Enum/Components/Simple/UI/SDatePickerModeEnum';

import SButton from './SButton.vue';
import SIcon from '../Icon/SIcon.vue';
import STextElement from '../Text/STextElement.vue';
import STextInput from './STextInput.vue';

export default defineComponent({
  name: 'SDatePicker',
  components: {
    STextElement,
    SIcon,
    SButton,
    STextInput,
    vDatePicker,
  },
  props: {
    modelValue: {
      type: Date,
      default: () => new Date(),
    },
    shortDate: {
      type: Boolean,
      default: false,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    mode: {
      type: String as PropType<SDatePickerModeEnum>,
      default: SDatePickerModeEnum.DATE,
    },
    is24hr: {
      type: Boolean,
      default: true,
    },
    buttons: {
      type: Object as PropType<{ ok: string; cancel: string; }>,
      default: () => ({
        ok: 'Ok',
        cancel: 'Cancel',
      }),
    },
    color: {
      type: String as PropType<Exclude<ColorEnum, ColorEnum.TOOLBAR>>,
      default: ColorEnum.DISABLED,
    },
    id: {
      type: String,
      default: null,
    },
    baseTimeZone: {
      type: Object as PropType<{ name: string; offset: number; }>,
      default: () => ({
        name: 'UTC',
        offset: 0,
      }),
    },
    dropdownPosition: {
      type: String as PropType<DropdownPositionEnum>,
      default: DropdownPositionEnum.BOTTOM,
    },
  },
  setup(props, { emit }) {
    const utilsService = inject<IUtilsService>('utilsService');

    if (!utilsService) {
      throw new Error('UtilsService not defined.');
    }

    const datePickerRef = ref<HTMLElement>();

    const state = reactive({
      value: new Date(),
      isMenuOpened: false,
    });

    const dropdownClasses = computed(() => ([
      's-date-picker__dropdown',
      `s-date-picker__dropdown_position_${props.dropdownPosition}`,
    ]));

    const localeSetting = computed(() => ({
      id: 'ru',
      masks: {
        weekdays: 'WW',
      },
    }));

    const attributes = computed(() => [
      {
        dates: state.value,
      },
    ]);

    const stringValue = computed(() => {
      const formatMap: Record<SDatePickerModeEnum, string> = {
        [SDatePickerModeEnum.DATE]: 'MMMM DD, YYYY',
        [SDatePickerModeEnum.TIME]: 'HH:mm',
        [SDatePickerModeEnum.DATE_TIME]: 'MMMM DD, YYYY HH:mm',
      };

      return utilsService.date.getFormat(state.value, formatMap[props.mode]);
    });

    const timeZoneOffset = computed(() => {
      const userOffsetInHours = -(new Date().getTimezoneOffset() / 60);
      const diffOffset = userOffsetInHours - props.baseTimeZone.offset;

      if (diffOffset > 0) {
        return `+${diffOffset}`;
      }

      return `${diffOffset}`;
    });

    const showTimeZone = computed(() => [SDatePickerModeEnum.TIME, SDatePickerModeEnum.DATE_TIME].includes(props.mode));

    onMounted(() => {
      state.value = props.modelValue;
    });

    watch(() => props.modelValue, (newValue) => {
      state.value = newValue;
    });

    watch(() => state.isMenuOpened, (newValue) => {
      emit(newValue ? 'dropdown-shown' : 'dropdown-hidden');
    });

    function handleDayClick(value: { date: Date; }) {
      state.value = value.date;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // datePickerRef.value?.focusDate(value);
    }

    function handleSubmitClick() {
      emit('update:modelValue', state.value);
      emit('date-input', state.value);

      state.isMenuOpened = false;
    }

    function handleCancelClick() {
      state.value = props.modelValue;
      state.isMenuOpened = false;
    }

    async function handleToggleDropdown() {
      state.value = props.modelValue;
      state.isMenuOpened = !state.isMenuOpened;
    }

    async function handleClickOutside() {
      state.value = props.modelValue;

      if (state.isMenuOpened) {
        state.isMenuOpened = false;
      }
    }

    return {
      state,
      dropdownClasses,
      localeSetting,
      attributes,
      stringValue,
      showTimeZone,
      timeZoneOffset,
      datePickerRef,
      handleDayClick,
      handleSubmitClick,
      handleCancelClick,
      handleToggleDropdown,
      handleClickOutside,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.s-date-picker {
  position: relative;
  display: flex;
  column-gap: 20px;
  align-items: center;

  &__offset {
    flex: 0 0 auto;
    white-space: nowrap;
  }

  &__dropdown {
    position: absolute;
    background-color: var(--color-white);
    box-shadow: 0 4px 24px 0 #0000001a;
    z-index: 2;

    &_position {
      &_top {
        bottom: calc(100% + 10px);
      }

      &_bottom {
        top: calc(100% + 10px);
      }
    }
  }

  :deep(.vc-container) {
    font-family: var(--text-font-family);
    width: 260px !important;
    border-color: var(--color-disabled);
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  :deep(.vc-disabled) {
    pointer-events: none;
  }

  :deep(.vc-time-select-group select) {
    border-color: transparent;
  }

  &__controls {
    position: relative;
    display: flex;
    align-items: center;
    width: 260px !important;
    border: 1px solid var(--color-disabled);
    border-top: 0;
    border-radius: 0 0 var(--text-input-border-radius-bottom-right) var(--text-input-border-radius-bottom-left);
    gap: 1px;

    &_divider {
      position: absolute;
      left: 50%;
      width: 1px;
      height: 100%;
      background: var(--color-disabled);
    }
  }

  &__button {
    width: 50%;

    &_cancel {
      border-left: 1px solid var(--color-disabled);
    }
  }
}
</style>
