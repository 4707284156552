<template>
  <SetupPage
    v-if="vendor.levelPartnerProgram === null"
    :vendor="vendor"
  />
  <div
    v-else
  >
    <ServicesPage
      :partner-program-level="vendor.levelPartnerProgram"
    />
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  computed,
  inject,
} from 'vue';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import IVendorModel from '@/entities/Model/IVendorModel';

import ServicesPage from '@/pages/HomePage/Module/ServicesPage.vue';
import SetupPage from '@/pages/HomePage/Module/SetupPage.vue';

export default defineComponent({
  components: {
    ServicesPage,
    SetupPage,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const {
      store,
    } = serviceContainer;

    const vendor = computed<IVendorModel>(() => store.getters.getVendor);

    return {
      vendor,
    };
  },
});
</script>
