import ConfigInterface from './lib/Model/ConfigInterface';

const config: ConfigInterface = {
  text: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    italic: {
      desktop: {
        weight: 400,
        size: 16,
        lineHeight: 22,
      },
      mobile: {
        weight: 400,
        size: 16,
        lineHeight: 22,
      },
    },
    medium: {
      desktop: {
        weight: 500,
        size: 16,
        lineHeight: 22,
      },
      mobile: {
        weight: 500,
        size: 16,
        lineHeight: 22,
      },
    },
    regular: {
      desktop: {
        weight: 400,
        size: 16,
        lineHeight: 22,
      },
      mobile: {
        weight: 400,
        size: 16,
        lineHeight: 22,
      },
    },
    small: {
      desktop: {
        weight: 400,
        size: 14,
        lineHeight: 16,
      },
      mobile: {
        weight: 400,
        size: 14,
        lineHeight: 16,
      },
    },
    smallLongLine: {
      desktop: {
        weight: 400,
        size: 14,
        lineHeight: 20,
      },
      mobile: {
        weight: 400,
        size: 14,
        lineHeight: 20,
      },
    },
    smallMedium: {
      desktop: {
        weight: 500,
        size: 14,
        lineHeight: 16,
      },
      mobile: {
        weight: 500,
        size: 14,
        lineHeight: 16,
      },
    },
    subheader: {
      desktop: {
        weight: 600,
        size: 20,
        lineHeight: 28,
      },
      mobile: {
        weight: 600,
        size: 20,
        lineHeight: 28,
      },
    },
    xSmallMedium: {
      desktop: {
        weight: 500,
        size: 11,
        lineHeight: 12,
      },
      mobile: {
        weight: 500,
        size: 11,
        lineHeight: 12,
      },
    },
  },
  header: {
    fontFamily: 'Proxima Nova, sans-serif',
    h1: {
      desktop: {
        weight: 600,
        size: 48,
        lineHeight: 58,
      },
      mobile: {
        weight: 600,
        size: 40,
        lineHeight: 49,
      },
    },
    h2: {
      desktop: {
        weight: 600,
        size: 32,
        lineHeight: 39,
      },
      mobile: {
        weight: 600,
        size: 32,
        lineHeight: 39,
      },
    },
    h3: {
      desktop: {
        weight: 600,
        size: 24,
        lineHeight: 32,
      },
      mobile: {
        weight: 600,
        size: 24,
        lineHeight: 32,
      },
    },
  },
  color: {
    disabled: '#d9d9d9',
    disabledHover: '#d9d9d9',
    disabledActive: '#d9d9d9',
    disabledFocus: '#d9d9d9',
    danger: '#ff4444',
    dangerHover: '#ff4444',
    dangerActive: '#ff4444',
    dangerFocus: '#ff4444',
    gray: '#abacac',
    grayHover: '#abacac',
    grayActive: '#abacac',
    grayFocus: '#abacac',
    linked: '#4397cb',
    linkedHover: '#4397cb',
    linkedActive: '#4397cb',
    linkedFocus: '#4397cb',
    navigation: '#c70d45',
    navigationHover: '#c70d45',
    navigationActive: '#c70d45',
    navigationFocus: '#c70d45',
    primary: '#4397cb',
    primaryHover: '#2273a5',
    primaryActive: '#388fc5',
    primaryFocus: '#388fc5',
    secondary: '#f5f5f5',
    secondaryHover: '#f0f0f0',
    secondaryActive: '#f0f0f0',
    secondaryFocus: '#f0f0f0',
    success: '#27ae60',
    successHover: '#27ae60',
    successActive: '#27ae60',
    successFocus: '#27ae60',
    toolbar: '#282828',
    toolbarHover: '#333333',
    toolbarActive: '#333333',
    toolbarFocus: '#333333',
    warn: '#f2994a',
    warnHover: '#f2994a',
    warnActive: '#f2994a',
    warnFocus: '#f2994a',
    white: '#ffffff',
    whiteHover: '#ffffff',
    whiteActive: '#ffffff',
    whiteFocus: '#ffffff',
    black: '#000000',
    blackHover: '#000000',
    blackActive: '#000000',
    blackFocus: '#000000',
    stroke: '#888888',
    surface: '#e5e5e5',
    backgroundPrimary: '#ebf7ff',
    backgroundSuccess: '#f1feed',
    backgroundWarn: '#fef0e4',
    backgroundDanger: '#fee4e4',
    backgroundGray: '#f8f8fa',
  },
  form: {
    label: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      desktop: {
        weight: 400,
        size: 16,
        lineHeight: 22,
      },
      mobile: {
        weight: 400,
        size: 16,
        lineHeight: 22,
      },
      gap: {
        l: 8,
        m: 8,
        s: 8,
      },
    },
    button: {
      borderWidth: 0,
      borderRadius: {
        topLeft: 8,
        topRight: 8,
        bottomRight: 8,
        bottomLeft: 8,
      },
      padding: {
        l: {
          top: 13,
          right: 24,
          bottom: 13,
          left: 24,
        },
        m: {
          top: 13,
          right: 16,
          bottom: 13,
          left: 16,
        },
        s: {
          top: 7,
          right: 12,
          bottom: 7,
          left: 12,
        },
      },
      gap: {
        l: 8,
        m: 8,
        s: 8,
      },
    },
    chips: {
      borderWidth: 1,
      borderRadius: {
        l: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
        },
        m: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
        },
        s: {
          topLeft: 8,
          topRight: 8,
          bottomRight: 8,
          bottomLeft: 8,
        },
        xs: {
          topLeft: 16,
          topRight: 16,
          bottomRight: 16,
          bottomLeft: 16,
        },
      },
      padding: {
        l: {
          top: 8,
          right: 12,
          bottom: 8,
          left: 12,
        },
        m: {
          top: 8,
          right: 12,
          bottom: 8,
          left: 12,
        },
        s: {
          top: 8,
          right: 12,
          bottom: 8,
          left: 12,
        },
        xs: {
          top: 6,
          right: 11,
          bottom: 6,
          left: 11,
        },
      },
      gap: {
        l: 8,
        m: 8,
        s: 8,
        xs: 8,
      },
    },
    textInput: {
      borderWidth: 2,
      borderRadius: {
        topLeft: 8,
        topRight: 8,
        bottomRight: 8,
        bottomLeft: 8,
      },
      padding: {
        l: {
          top: 0,
          right: 14,
          bottom: 0,
          left: 14,
        },
        m: {
          top: 0,
          right: 14,
          bottom: 0,
          left: 14,
        },
        s: {
          top: 0,
          right: 14,
          bottom: 0,
          left: 14,
        },
      },
      gap: {
        l: 14,
        m: 14,
        s: 14,
      },
      height: {
        l: 48,
        m: 48,
        s: 48,
      },
    },
    textArea: {
      borderWidth: 2,
      borderRadius: {
        topLeft: 8,
        topRight: 8,
        bottomRight: 8,
        bottomLeft: 8,
      },
      padding: {
        top: 11,
        right: 14,
        bottom: 11,
        left: 14,
      },
    },
    select: {
      borderWidth: 2,
      borderRadius: {
        topLeft: 8,
        topRight: 8,
        bottomRight: 8,
        bottomLeft: 8,
      },
    },
    checkbox: {
      borderWidth: 1,
      borderRadius: {
        topLeft: 4,
        topRight: 4,
        bottomRight: 4,
        bottomLeft: 4,
      },
      size: {
        s: {
          width: 18,
          height: 18,
        },
        m: {
          width: 18,
          height: 18,
        },
        l: {
          width: 18,
          height: 18,
        },
      },
    },
  },
  icon: {
    s: {
      width: 18,
      height: 18,
    },
    m: {
      width: 24,
      height: 24,
    },
    l: {
      width: 32,
      height: 32,
    },
    xl: {
      width: 48,
      height: 48,
    },
    xxl: {
      width: 96,
      height: 96,
    },
  },
  statusWindow: {
    borderRadius: 24,
    padding: {
      l: {
        top: 175,
        right: 60,
        bottom: 175,
        left: 60,
      },
      m: {
        top: 30,
        right: 10,
        bottom: 30,
        left: 10,
      },
    },
    margin: {
      afterIcon: 16,
      afterTitle: 4,
      afterText: 32,
    },
    buttonGap: 16,
    xs: {
      padding: {
        l: {
          top: 156,
          right: 38,
          bottom: 156,
          left: 38,
        },
        m: {
          top: 38,
          right: 38,
          bottom: 38,
          left: 38,
        },
      },
    },
  },
};

export default config;
