import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a84875f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-card" }
const _hoisted_2 = { class: "c-card__top" }
const _hoisted_3 = { class: "c-card__title" }
const _hoisted_4 = {
  key: 0,
  class: "c-card__icon"
}
const _hoisted_5 = { class: "c-card__body" }
const _hoisted_6 = {
  key: 0,
  class: "c-card__bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SLink = _resolveComponent("SLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_STextElement, { variant: "medium" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        })
      ]),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_SIcon, {
              color: _ctx.iconConfig.color,
              name: _ctx.iconConfig.name
            }, null, 8, ["color", "name"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_STextElement, { color: "stroke" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.link)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_SLink, {
            color: _ctx.link.color,
            download: _ctx.link.download,
            isRouterLink: _ctx.link.isRouterLink,
            target: _ctx.link.target,
            title: _ctx.link.title,
            url: _ctx.link.url,
            variant: _ctx.link.variant
          }, {
            "icon-after": _withCtx(() => [
              _createVNode(_component_SIcon, {
                color: _ctx.linkIconConfig.color,
                name: _ctx.linkIconConfig.name
              }, null, 8, ["color", "name"])
            ]),
            _: 1
          }, 8, ["color", "download", "isRouterLink", "target", "title", "url", "variant"])
        ]))
      : _createCommentVNode("", true)
  ]))
}