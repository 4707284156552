<template>
  <div
    class="sales-banner"
  >
    <SHeader
      :size="3"
    >
      {{ $t('pages.home.setup.sales_banner.title') }}
    </SHeader>
    <div
      class="sales-banner__banner"
    >
      <div
        class="sales-banner__banner-title"
      >
        <STextElement
          color="white"
          variant="subheader"
        >
          {{ $t('pages.home.setup.sales_banner.text') }}
        </STextElement>
      </div>
      <div
        class="sales-banner__list"
      >
        <div
          v-for="(item, index) in list"
          :key="`sales-banner-item-${index}`"
          class="sales-banner__item"
        >
          <STextElement
            v-if="item.title"
            class="sales-banner__item-title"
            color="white"
            variant="medium"
          >
            {{ item.title }}
          </STextElement>
          <div
            v-else-if="item.icon"
            class="sales-banner__item-title"
          >
            <SIcon
              :color="item.icon.color"
              :name="item.icon.name"
              :size="item.icon.size"
              class="sales-banner__item-icon"
            />
          </div>
          <STextElement
            color="white"
          >
            {{ item.text }}
          </STextElement>
        </div>
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';

import {
  ColorEnum,
  SIconPropsInterface,
  IconNameEnum,
} from '@/shared/DesignSystem';
import SizeEnum from '@/shared/DesignSystem/lib/Enum/SizeEnum';

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const list = computed<{
      title?: string;
      text: string;
      icon?: SIconPropsInterface;
    }[]>(() => [
      {
        title: t('pages.home.setup.sales_banner.item1.title'),
        text: t('pages.home.setup.sales_banner.item1.text'),
      },
      {
        title: t('pages.home.setup.sales_banner.item2.title'),
        text: t('pages.home.setup.sales_banner.item2.text'),
      },
      {
        icon: {
          name: IconNameEnum.GLOBE,
          color: ColorEnum.WHITE,
          size: SizeEnum.XL,
        },
        text: t('pages.home.setup.sales_banner.item3.text'),
      },
    ]);

    return {
      list,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.sales-banner {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__banner {
    display: flex;
    flex-direction: column;
    padding: 35px 35px 48px;
    border-radius: 8px;
    background: linear-gradient(90deg, #3e208d 0%, #3269b7 100%);
    gap: 90px;
  }

  &__banner-title {

  }

  &__list {
    display: flex;
    gap: 56px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__item-title {
    display: flex;
    align-items: center;
    height: 84px;
    font-size: 64px;
    line-height: normal;
    white-space: nowrap;
  }

  &__item-icon {
    width: 64px;
    height: 64px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    &__banner {
      gap: 32px;
    }

    &__list {
      flex-direction: column;
      gap: 32px;
    }

    &__item-title {
      height: 49px;
      font-size: 40px;
    }

    &__item-icon {
      width: 44px;
      height: 44px;
    }
  }
}
</style>
