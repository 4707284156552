enum XSmallMediumTextEnum {
  SIZE_DESKTOP = '--text-variant-desktop-x-small-medium-size',
  LINE_HEIGHT_DESKTOP = '--text-variant-desktop-x-small-medium-line-height',
  WEIGHT_DESKTOP = '--text-variant-desktop-x-small-medium-weight',
  SIZE_MOBILE = '--text-variant-mobile-x-small-medium-size',
  LINE_HEIGHT_MOBILE = '--text-variant-mobile-x-small-medium-line-height',
  WEIGHT_MOBILE = '--text-variant-mobile-x-small-medium-weight',
}

export default XSmallMediumTextEnum;
