import {
  createApp,
} from 'vue';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import LocalisationInit from '@/app/providers/i18n/LocalisationInit';
import designSystemPlugin from '@/shared/DesignSystem/index';
import formComponentPlugin from '@/widgets/FormComponent/index';
import validatorPlugin from '@/shared/Validator/index';
import scoringFormPage from '@/pages/ScoringFormPage/index';
import App from './App.vue';
import {
  getRouter,
  getServiceContainer,
} from './providers';

import 'normalize.css';
import './style/main.scss';
import '../shared/DesignSystem/assets/fonts.scss';

const serviceContainer = getServiceContainer();

const localisationInit = new LocalisationInit(serviceContainer);

localisationInit.init().then((i18n) => {
  const { t } = i18n.global;
  const router = getRouter(serviceContainer, t);

  createApp(App)
    .provide('serviceContainer', serviceContainer)
    .use(serviceContainer.store)
    .use(router)
    .use(scoringFormPage, { serviceContainer })
    .use(PerfectScrollbar)
    .use(designSystemPlugin)
    .use(validatorPlugin)
    .use(formComponentPlugin, { serviceContainer })
    .use(i18n)
    .mount('#app');
});
