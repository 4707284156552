import BlockTypeCheckHandlerInterface from './Contract/BlockTypeCheckHandlerInterface';

import FormBlockInterface from './Model/FormBlockInterface';
import FormBlockType from './Model/FormBlockType';
import FormDelimiterInterface from './Model/FormDelimiterInterface';

import FormBlockTypeEnum from './Enum/FormBlockTypeEnum';

class BlockTypeCheckHandler implements BlockTypeCheckHandlerInterface {
  isBlock(block: FormBlockType): block is FormBlockInterface {
    return block.type === FormBlockTypeEnum.BLOCK;
  }

  isDelimiter(block: FormBlockType): block is FormDelimiterInterface {
    return block.type === FormBlockTypeEnum.DELIMITER;
  }
}

export default BlockTypeCheckHandler;
