<template>
  <div
    class="c-chips-group"
  >
    <SChips
      v-for="chips in state.list"
      :key="chips.id"
      :color="getColor(chips)"
      :variant="variant"
      stateType="outer"
      :modelValue="chips.checked"
      @chips-click="handleClick($event, chips)"
    >
      {{ chips.title }}
    </SChips>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';
import ChipsVariantEnum from '../../lib/Enum/Components/Simple/UI/ChipsVariantEnum';
import SChipsItemInterface from '../../lib/Model/Components/Complex/SChipsItemInterface';
import ColorEnum from '../../lib/Enum/ColorEnum';
import CChipsGroupModeEnum from '../../lib/Enum/Components/Complex/CChipsGroupModeEnum';

import SChips from '../Simple/UI/SChips.vue';

export default defineComponent({
  name: 'CChipsGroup',
  components: {
    SChips,
  },
  props: {
    list: {
      type: Array as PropType<Array<SChipsItemInterface>>,
      default: () => [],
    },
    color: {
      type: String as PropType<ColorEnum>,
      default: ColorEnum.PRIMARY,
    },
    mode: {
      type: String as PropType<CChipsGroupModeEnum>,
      default: CChipsGroupModeEnum.MULTIPLE,
    },
  },
  setup(props, { emit }) {
    const state = reactive<{ list: Array<SChipsItemInterface> }>({
      list: [],
    });

    onMounted(() => {
      state.list = props.list;
    });

    watch(() => props.list, (newValue) => {
      state.list = newValue;
    });

    function getColor(chips: SChipsItemInterface) {
      return chips.checked ? ColorEnum.PRIMARY : props.color;
    }

    const variant = computed(
      () => (props.color === ColorEnum.DANGER ? ChipsVariantEnum.BORDERED : ChipsVariantEnum.FILLED),
    );

    function handleClick(event: PointerEvent, chips: SChipsItemInterface) {
      state.list = state.list.map((item) => {
        if (item.id === chips.id) {
          item.checked = props.mode === CChipsGroupModeEnum.SINGLE && item.checked ? true : !item.checked;
        } else if (props.mode === CChipsGroupModeEnum.SINGLE) {
          item.checked = false;
        }

        return item;
      });

      emit('change-selection', state.list);
    }

    return {
      state,
      getColor,
      variant,
      handleClick,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.c-chips-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
