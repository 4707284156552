import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import BlockTypeCheckHandlerInterface
  from './lib/Contract/BlockTypeCheckHandlerInterface';
import BlockTypeCheckHandler from './lib/BlockTypeCheckHandler';
import FieldValueHandlerInterface from './lib/Contract/FieldValueHandlerInterface';
import FieldValueHandler from './FieldValueHandler';
import FieldTypeCheckHandlerInterface from './lib/Contract/FieldTypeCheckHandlerInterface';
import FormComponentServiceInterface from './lib/Contract/FormComponentInterface';
import CheckConditionTool from './lib/CheckConditionTool';
import FieldTypeCheckHandler from './FieldTypeCheckHandler';
import ICheckConditionTool
  from './lib/Contract/ICheckConditionTool';

class FormComponentService implements FormComponentServiceInterface {
  private readonly serviceContainer: IServiceContainer;

  private _fieldTypeCheckHandler?: FieldTypeCheckHandlerInterface;

  private _blockTypeCheckHandler?: BlockTypeCheckHandlerInterface;

  private _valueHandler?: FieldValueHandlerInterface;

  private _checkConditionTool?: ICheckConditionTool;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get fieldTypeCheckHandler(): FieldTypeCheckHandlerInterface {
    if (!this._fieldTypeCheckHandler) {
      this._fieldTypeCheckHandler = new FieldTypeCheckHandler();
    }

    return this._fieldTypeCheckHandler;
  }

  get blockTypeCheckHandler(): BlockTypeCheckHandlerInterface {
    if (!this._blockTypeCheckHandler) {
      this._blockTypeCheckHandler = new BlockTypeCheckHandler();
    }

    return this._blockTypeCheckHandler;
  }

  get fieldValueHandler(): FieldValueHandlerInterface {
    if (!this._valueHandler) {
      this._valueHandler = new FieldValueHandler(this.serviceContainer);
    }

    return this._valueHandler;
  }

  get checkConditionTool(): ICheckConditionTool {
    if (!this._checkConditionTool) {
      this._checkConditionTool = new CheckConditionTool(this.serviceContainer.utilsService);
    }

    return this._checkConditionTool;
  }
}

export default FormComponentService;
