import { Module } from 'vuex';
import ISystemRegisterState from '@/app/Model/State/ISystemRegisterState';

export const systemRegisterStore: Module<ISystemRegisterState, unknown> = {
  state: {
    isSidebarCollapsed: false,
    isMobile: false,
  },
  getters: {
    getIsSidebarCollapsed(state) {
      return state.isSidebarCollapsed;
    },
    getIsMobile(state) {
      return state.isMobile;
    },
  },
  mutations: {
    setIsSidebarCollapsed(state, payload) {
      state.isSidebarCollapsed = payload;
    },
    setIsMobile(state, payload) {
      state.isMobile = payload;
    },
  },
  actions: {
    updateIsSidebarCollapsed({ commit }, value) {
      commit('setIsSidebarCollapsed', value);
    },
    updateIsMobile({ commit }, value) {
      commit('setIsMobile', value);
    },
  },
};
