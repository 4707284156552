<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.00017 6.98073C1.99997 6.99172 1.99995 7.0027 2.00011 7.01367V17C2.00011 17.7956 2.31618 18.5587 2.87879 19.1213C3.44139 19.6839 4.20446 20 5.00011 20H19.0001C19.7958 20 20.5588 19.6839 21.1214 19.1213C21.684 18.5587 22.0001 17.7957 22.0001 17V7.01368C22.0003 7.0027 22.0002 6.99172 22 6.98072C21.995 6.19203 21.6795 5.43674 21.1214 4.87868C20.5588 4.31607 19.7958 4 19.0001 4H5.00011C4.20446 4 3.4414 4.31607 2.87879 4.87868C2.32072 5.43675 2.00523 6.19204 2.00017 6.98073ZM4.293 6.29289C4.48054 6.10536 4.73489 6 5.00011 6H19.0001C19.2653 6 19.5197 6.10536 19.7072 6.29289C19.7804 6.36608 19.8411 6.44944 19.8878 6.53965L12.0001 11.7981L4.11236 6.53965C4.15914 6.44944 4.21981 6.36608 4.293 6.29289ZM20.0001 8.86852V17C20.0001 17.2652 19.8948 17.5196 19.7072 17.7071C19.5197 17.8946 19.2653 18 19.0001 18H5.00011C4.73489 18 4.48054 17.8946 4.293 17.7071C4.10546 17.5196 4.00011 17.2652 4.00011 17V8.86852L11.4454 13.8321C11.7813 14.056 12.2189 14.056 12.5548 13.8321L20.0001 8.86852Z"
      :class="classes"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconMail',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-mail_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-mail {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

  &_color-stroke {
    fill: var(--color-stroke);
  }
}
</style>
