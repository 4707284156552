import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02b279e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "internet-acquiring" }
const _hoisted_2 = { class: "internet-acquiring__banner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SHeader, { size: 3 }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('pages.home.setup.internet_acquiring.title')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SIcon, {
        color: _ctx.banner.icon.color,
        name: _ctx.banner.icon.name,
        size: _ctx.banner.icon.size,
        class: "sales-banner__item-icon"
      }, null, 8, ["color", "name", "size"]),
      _createVNode(_component_SHeader, { size: 3 }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.banner.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_STextElement, { variant: "small" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.banner.text), 1)
        ]),
        _: 1
      })
    ])
  ]))
}