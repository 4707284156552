import { InternalAxiosRequestConfig } from 'axios';
import { Store } from 'vuex';
import IAuthorizationService from '@/app/Service/Authorization/Contract/IAuthorizationService';
import IApiService from '@/shared/Api/Contract/IApiService';
import ILocalStorageService from '@/app/Service/LocalStorage/Contract/ILocalStorageService';
import ELocalStorageKeys from '@/app/Service/LocalStorage/Enum/ELocalStorageKeys';
import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';

class AuthorizationService implements IAuthorizationService {
  private readonly apiService: IApiService;

  private localStorageService: ILocalStorageService;

  private readonly store: Store<unknown>;

  private utilsService: IUtilsService;

  private authorizationAttempts = 0;

  constructor(
    apiService: IApiService,
    localStorageService: ILocalStorageService,
    store: Store<unknown>,
    utilsService: IUtilsService,
  ) {
    this.utilsService = utilsService;
    this.store = store;
    this.localStorageService = localStorageService;
    this.apiService = apiService;
  }

  auth = async (ssoCode?: string | number): Promise<boolean> => {
    if (
      this.utilsService.typeCheck.isUndefined(ssoCode)
      || !this.utilsService.typeCheck.isString(ssoCode)
    ) {
      return this.logout();
    }

    try {
      const response = await this.apiService.authorizationApi.auth({
        code: ssoCode,
        redirectUri: this.getRedirectUri(),
      });

      this.localStorageService.write(ELocalStorageKeys.AUTH_TOKEN, response.token);

      await this.store.dispatch('updateAccessToken', response.token);

      this.authorizationAttempts = 0;

      return Promise.resolve(true);
    } catch (e) {
      if (this.authorizationAttempts < 3) {
        this.authorizationAttempts += 1;

        return this.auth(ssoCode);
      }

      this.authorizationAttempts = 0;
      await this.logout();

      return Promise.resolve(false);
    }
  };

  goToSSO = async (): Promise<void> => {
    const { ssoLinkApi } = this.apiService;

    const response = await ssoLinkApi.getSignInLink();
    const { link } = response;

    window.location.href = `${link}&redirect_uri=${this.getRedirectUri()}`;
  };

  logout = async (): Promise<boolean> => {
    const { ssoLinkApi } = this.apiService;

    const response = await ssoLinkApi.getLogoutLink();
    const { link } = response;

    try {
      this.localStorageService.clear();

      window.location.href = `${link}?back=${window.location.origin}`;

      return Promise.resolve(true);
    } catch (e) {
      window.location.href = `${link}?back=${window.location.origin}`;
      return Promise.resolve(true);
    }
  };

  signRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.url !== '/auth/admin-login') {
      const authToken = this.localStorageService.read(ELocalStorageKeys.AUTH_TOKEN);

      config.headers.Authorization = authToken ? `Bearer ${authToken}` : null;
    }

    return config;
  };

  private getRedirectUri = (): string => `${window.location.origin}/ssoAuth`

  isAuthorised = (): boolean => !!this.localStorageService.read(
    ELocalStorageKeys.AUTH_TOKEN,
  );

  adminAuth = async (code: string | number): Promise<void> => {
    if (
      this.utilsService.typeCheck.isUndefined(code)
      || !this.utilsService.typeCheck.isString(code)
    ) {
      await this.logout();
      return Promise.resolve(undefined);
    }

    try {
      const response = await this.apiService.authorizationApi.adminAuth(code);

      this.localStorageService.write(ELocalStorageKeys.AUTH_TOKEN, response.token);

      await this.store.dispatch('updateAccessToken', response.token);
    } catch (e) {
      await this.logout();
    }

    return Promise.resolve(undefined);
  };
}

export default AuthorizationService;
