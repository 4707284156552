<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      d="M4 4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V9.5C2 9.77614 2.22727 9.99384 2.49482 10.0622C2.83983 10.1503 3.15816 10.3297 3.41421 10.5858C3.78929 10.9609 4 11.4696 4 12C4 12.5304 3.78929 13.0391 3.41421 13.4142C3.15816 13.6703 2.83983 13.8497 2.49482 13.9378C2.22727 14.0062 2 14.2239 2 14.5V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V14.5C22 14.2239 21.7727 14.0062 21.5052 13.9378C21.1602 13.8497 20.8418 13.6703 20.5858 13.4142C20.2107 13.0391 20 12.5304 20 12C20 11.4696 20.2107 10.9609 20.5858 10.5858C20.8418 10.3297 21.1602 10.1503 21.5052 10.0622C21.7727 9.99384 22 9.77614 22 9.5V6C22 5.46957 21.7893 4.96086 21.4142 4.58579C21.0391 4.21071 20.5304 4 20 4H4ZM14.75 7.75C15.1642 7.33579 15.8358 7.33579 16.25 7.75C16.6642 8.16421 16.6642 8.83579 16.25 9.25L9.25 16.25C8.83579 16.6642 8.16421 16.6642 7.75 16.25C7.33579 15.8358 7.33579 15.1642 7.75 14.75L14.75 7.75ZM8.81 7.04C9.79 7.04 10.58 7.83 10.58 8.81C10.58 9.27943 10.3935 9.72964 10.0616 10.0616C9.72964 10.3935 9.27943 10.58 8.81 10.58C7.83 10.58 7.04 9.79 7.04 8.81C7.04 8.34057 7.22648 7.89036 7.55842 7.55842C7.89036 7.22648 8.34057 7.04 8.81 7.04ZM15.19 13.42C16.17 13.42 16.96 14.21 16.96 15.19C16.96 15.6594 16.7735 16.1096 16.4416 16.4416C16.1096 16.7735 15.6594 16.96 15.19 16.96C14.21 16.96 13.42 16.17 13.42 15.19C13.42 14.7206 13.6065 14.2704 13.9384 13.9384C14.2704 13.6065 14.7206 13.42 15.19 13.42Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconPromotions',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-promotions_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-promotions {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

  &_color-stroke {
    fill: var(--color-stroke);
  }
}
</style>
