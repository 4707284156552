enum ECategoriesParticipants {
  ALL_CATEGORIES = 'all_categories',
  GOVERNMENT_INSTITUTIONS = 'government_institutions',
  EDUCATIONAL_INSTITUTIONS = 'educational_institutions',
  COMMERCIAL_INSTITUTIONS = 'commercial_institutions',
  MEDICAL_INSTITUTIONS = 'medical_institutions',
  OTHER = 'other',
}

export default ECategoriesParticipants;
