import FormBlockType from '@/widgets/FormComponent/lib/Model/FormBlockType';
import FormBlockTypeEnum from '@/widgets/FormComponent/lib/Enum/FormBlockTypeEnum';
import FieldTypeEnum from '@/widgets/FormComponent/lib/Enum/FieldTypeEnum';
import {
  ColorEnum,
  FileErrorMessagesType,
  FileErrorTypeEnum,
  FileMimeTypesEnum,
  getFileUploadOptions,
  IconNameEnum,
  SButtonVariantEnum,
  SizeEnum,
} from '@/shared/DesignSystem/index';

import IState from '@/pages/Service/Portfolio/PortfolioFormPage/Model/IState';
import IFormConfigHandler
  from '@/pages/Service/Portfolio/PortfolioFormPage/Contract/Handler/IFormConfigHandler';
import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';
import FileExtensionsEnum
  from '@/shared/DesignSystem/lib/Enum/Components/Complex/CFileUpload/FileExtensionsEnum';
import FieldConfigType from '@/widgets/FormComponent/lib/Model/Fields/FieldConfigType';
import EFieldName from '@/pages/Service/Portfolio/PortfolioFormPage/Enum/EFieldName';

class FormConfigHandler implements IFormConfigHandler {
  get = (
    state: IState,
  ): Array<FormBlockType> => {
    const publicNameCompany: FieldConfigType = {
      type: FieldTypeEnum.TEXT_INPUT,
      fieldName: EFieldName.PUBLIC_NAME_COMPANY,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.public_name_company.placeholder'),
        label: state.t('pages.portfolio.form.fields.public_name_company.label'),
        description: state.t('pages.portfolio.form.fields.public_name_company.description'),
      },
      status: state.validationStatusRegistry[EFieldName.PUBLIC_NAME_COMPANY],
    };

    const keywords: FieldConfigType = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.KEYWORDS,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.keywords.placeholder'),
        label: state.t('pages.portfolio.form.fields.keywords.label'),
        description: state.t('pages.portfolio.form.fields.keywords.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.KEYWORDS],
    };

    const website: FieldConfigType = {
      type: FieldTypeEnum.TEXT_INPUT,
      fieldName: EFieldName.WEBSITE,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.website.placeholder'),
        label: state.t('pages.portfolio.form.fields.website.label'),
        description: state.t('pages.portfolio.form.fields.website.description'),
      },
      status: state.validationStatusRegistry[EFieldName.WEBSITE],
    };

    const whoCanBeReplaced: FieldConfigType = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.WHO_CAN_BE_REPLACED,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.who_can_be_replaced.placeholder'),
        label: state.t('pages.portfolio.form.fields.who_can_be_replaced.label'),
        description: state.t('pages.portfolio.form.fields.who_can_be_replaced.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.WHO_CAN_BE_REPLACED],
    };

    const productDescriptionsFunctions: FieldConfigType = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.PRODUCT_DESCRIPTIONS_FUNCTIONS,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.product_descriptions_functions.placeholder'),
        label: state.t('pages.portfolio.form.fields.product_descriptions_functions.label'),
        description: state.t('pages.portfolio.form.fields.product_descriptions_functions.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.PRODUCT_DESCRIPTIONS_FUNCTIONS],
    };

    const listOfRegistries: FieldConfigType = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.LIST_OF_REGISTRIES,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.list_of_registries.placeholder'),
        label: state.t('pages.portfolio.form.fields.list_of_registries.label'),
        description: state.t('pages.portfolio.form.fields.list_of_registries.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.LIST_OF_REGISTRIES],
    };

    const listOfCertificationSystems: FieldConfigType = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.LIST_OF_CERTIFICATION_SYSTEMS,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.list_of_certification_systems.placeholder'),
        label: state.t('pages.portfolio.form.fields.list_of_certification_systems.label'),
        description: state.t('pages.portfolio.form.fields.list_of_certification_systems.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.LIST_OF_CERTIFICATION_SYSTEMS],
    };

    const directContacts: FieldConfigType = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.DIRECT_CONTACTS,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.direct_contacts.placeholder'),
        label: state.t('pages.portfolio.form.fields.direct_contacts.label'),
        description: state.t('pages.portfolio.form.fields.direct_contacts.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.DIRECT_CONTACTS],
    };

    const implementationCases: FieldConfigType = {
      type: FieldTypeEnum.TEXT_INPUT_LIST,
      fieldName: EFieldName.IMPLEMENTATION_CASES,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.implementation_cases.placeholder'),
        label: state.t('pages.portfolio.form.fields.implementation_cases.label'),
        description: state.t('pages.portfolio.form.fields.implementation_cases.description'),
        addButtonText: state.t('pages.portfolio.form.fields.implementation_cases.add_button'),
        minItems: 1,
        maxItems: 10,
        statuses: state.implementationValidationStatusRegistry,
      },
      status: state.validationStatusRegistry[EFieldName.IMPLEMENTATION_CASES],
    };

    const comment: FieldConfigType = {
      type: FieldTypeEnum.FILE_UPLOAD,
      fieldName: EFieldName.DOCUMENTS,
      config: {
        label: state.t('pages.portfolio.form.fields.documents.label'),
        options: this.getDocumentOptions(state),
      },
      status: state.validationStatusRegistry[EFieldName.DOCUMENTS],
    };

    const documents: FieldConfigType = {
      type: FieldTypeEnum.TEXT_AREA,
      fieldName: EFieldName.COMMENT,
      config: {
        placeholder: state.t('pages.portfolio.form.fields.comment.placeholder'),
        label: state.t('pages.portfolio.form.fields.comment.label'),
        description: state.t('pages.portfolio.form.fields.comment.description'),
        minRowsCount: 5,
        maxRowsCount: 10,
        maxLength: 3000,
      },
      status: state.validationStatusRegistry[EFieldName.COMMENT],
    };

    return [
      {
        type: FormBlockTypeEnum.BLOCK,
        title: state.t('pages.portfolio.form.blocks.about.title'),
        subTitle: state.t('pages.portfolio.form.blocks.about.subtitle'),
        fields: [
          publicNameCompany,
          keywords,
          website,
          whoCanBeReplaced,
          productDescriptionsFunctions,
          listOfRegistries,
          listOfCertificationSystems,
          directContacts,
        ],
      },
      {
        type: FormBlockTypeEnum.BLOCK,
        title: state.t('pages.portfolio.form.blocks.materials.title'),
        subTitle: state.t('pages.portfolio.form.blocks.materials.subtitle'),
        fields: [
          implementationCases,
          comment,
          documents,
        ],
      },
    ];
  };

  private getDocumentOptions(state: IState) {
    const maxFileSize = 100;
    const maxFilesSize = 100;

    const fileErrorMessages: FileErrorMessagesType = {
      [FileErrorTypeEnum.LOAD]: state.t(EValidationMessageTokens.FILE_LOAD),
      [FileErrorTypeEnum.MAX_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_SIZE,
        {
          number: maxFileSize,
        },
      ),
      [FileErrorTypeEnum.MAX_TOTAL_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_TOTAL_SIZE,
        {
          number: maxFilesSize,
        },
      ),
      [FileErrorTypeEnum.MIME_TYPE]: state.t(EValidationMessageTokens.FILE_MIME_TYPE),
      [FileErrorTypeEnum.NO_ZERO_SIZE]: state.t(EValidationMessageTokens.FILE_NO_ZERO_SIZE),
      [FileErrorTypeEnum.NAME]: state.t(EValidationMessageTokens.FILE_NAME),
    };

    const mimeTypes = [
      FileMimeTypesEnum.JPG,
      FileMimeTypesEnum.JPEG,
      FileMimeTypesEnum.PNG,
      FileMimeTypesEnum.PPT,
      FileMimeTypesEnum.PPTX,
      FileMimeTypesEnum.PDF,
      FileMimeTypesEnum.DOC,
      FileMimeTypesEnum.DOCX,
    ];

    const extensions = [
      FileExtensionsEnum.JPG,
      FileExtensionsEnum.JPEG,
      FileExtensionsEnum.PNG,
      FileExtensionsEnum.PPT,
      FileExtensionsEnum.PPTX,
      FileExtensionsEnum.PDF,
      FileExtensionsEnum.DOC,
      FileExtensionsEnum.DOCX,
    ];

    const fileUploadOptions = getFileUploadOptions(
      fileErrorMessages,
      {
        variant: SButtonVariantEnum.TERTIARY,
        text: state.t('pages.portfolio.form.fields.documents.upload_button'),
        size: SizeEnum.M,
      },
      {
        name: IconNameEnum.PAPER_CLIP,
        color: ColorEnum.PRIMARY,
      },
      state.t(
        'pages.portfolio.form.fields.documents.description',
        {
          extensions: extensions.join(', '),
          maxFileSize,
          maxFilesSize,
        },
      ),
      state.t(
        'pages.portfolio.form.fields.documents.placeholder',
        {
          extensions: extensions.join(', '),
          maxFileSize,
          maxFilesSize,
        },
      ),
      mimeTypes,
      maxFileSize,
      maxFilesSize,
      255,
      true,
    );

    return { ...fileUploadOptions };
  }
}

export default FormConfigHandler;
