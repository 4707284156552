<template>
  <div
    class="service-banner"
    @click="handleClickBanner"
  >
    <div
      class="service-banner__icon"
    >
      <SIcon
        :name="service.icon"
        color="linked"
      />
    </div>
    <div
      class="service-banner__info"
    >
      <STextElement
        variant="subheader"
      >
        {{ service.title }}
      </STextElement>
      <STextElement>
        {{ service.text }}
      </STextElement>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  PropType,
} from 'vue';
import {
  useRouter,
} from 'vue-router';

import IServiceItem from '@/pages/HomePage/Model/IServiceItem';

export default defineComponent({
  props: {
    service: {
      type: Object as PropType<IServiceItem>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    function handleClickBanner() {
      router.push(props.service.route);
    }

    return {
      handleClickBanner,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.service-banner {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px -6px rgba(0, 0, 0, 0.12), 0 6px 24px -4px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  gap: 16px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 12px;
    background-color: #ecf4fa;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
