import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutWithoutToolbarContentHeader = _resolveComponent("LayoutWithoutToolbarContentHeader")!
  const _component_LayoutWithoutToolbarContent = _resolveComponent("LayoutWithoutToolbarContent")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_LayoutWithoutToolbarContentHeader, {
          key: 0,
          title: _ctx.title,
          class: "layout-without-toolbar-container__header"
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_LayoutWithoutToolbarContent, null, {
      content: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ]),
      _: 3
    })
  ], 2))
}