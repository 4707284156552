<template>
  <LayoutWithoutToolbar
    :title="state.localisation.title"
    width="md"
  >
    <template
      #control-left
    >
      <SButton
        v-if="!state.isFormSent"
        :text="buttonCancel.text"
        :variant="buttonCancel.variant"
        @click="buttonCancel.handler"
      >
        <template
          #icon-before
        >
          <SIcon
            :color="buttonCancel.icon.color"
            :name="buttonCancel.icon.name"
          />
        </template>
      </SButton>
      <SButton
        v-else
        :text="buttonBack.text"
        :variant="buttonBack.variant"
        @click="buttonBack.handler"
      >
        <template
          #icon-before
        >
          <SIcon
            :color="buttonBack.icon.color"
            :name="buttonBack.icon.name"
          />
        </template>
      </SButton>
    </template>
    <template
      #control-right
    >
      <SButton
        v-if="!state.isFormSent"
        :size="buttonSave.size"
        :text="buttonSave.text"
        :variant="buttonSave.variant"
        @click="buttonSave.handler"
      >
      </SButton>
    </template>
    <template
      #content
    >
      <div
        class="promo-form-page"
      >
        <FormComponent
          v-if="!state.isFormSent"
          :form-config="state.formConfig"
          :form-data="state.formData"
          @string-field-blur="formFieldHandler.handleStringBlur"
          @text-input-list-add="formFieldHandler.handleTextInputListAddField"
          @text-input-list-delete="formFieldHandler.handleTextInputListDeleteField"
          @text-input-list-input="formFieldHandler.handleTextInputListInput"
          @date-input="formFieldHandler.handleDateInput"
          @chips-group-change="formFieldHandler.handleChipsGroupChange"
          @file-upload-input="formFieldHandler.handleFileUploadInput"
          @delete-file="formFieldHandler.handleDeleteFile"
          @download-file="formFieldHandler.handleDownloadFile"
        >
          <template
            #custom-info-block="{ field }"
          >
            <div
              v-if="field.fieldName === 'promotionInfo' && promotionInfo"
              class="promo-form-page__promotion-info"
            >
              <div
                v-if="promotionInfo.img"
                class="promo-form-page__promotion-img-wrapper"
              >
                <img
                  :src="promotionInfo.img"
                  alt=""
                  class="promo-form-page__promotion-img"
                >
              </div>
              <div
                v-if="promotionInfo.text"
                class="promo-form-page__promotion-text"
              >
                <STextElement>
                  {{ promotionInfo.text }}
                </STextElement>
              </div>
            </div>
          </template>
          <template
            v-if="isPersonalDiscount"
            #input-list-item-after-input="slotProps"
          >
            <div
              class="promo-form-page__product-discount"
            >
              <STextElement
                v-if="slotProps.index === 0"
                class="promo-form-page__product-discount-label"
                color="stroke"
              >
                {{ $t('pages.promo.form.fields.amount_discount.label') }}
              </STextElement>
              <SMaskedInput
                :mask-options="discountMaskOption"
                :model-value="getDiscountValue(slotProps.item)"
                class="promo-form-page__product-discount-input"
                @blur="(event, item) => formFieldHandler.handlePersonalDiscountBlur(event, item, slotProps.item)"
              />
            </div>
          </template>
        </FormComponent>
        <CStatusWindow
          v-else
          :icon="statusWindow.icon"
          :subtitle="statusWindow.subtitle"
          :title="statusWindow.title"
        />
      </div>
    </template>
  </LayoutWithoutToolbar>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';
import {
  useRouter,
} from 'vue-router';

import {
  ColorEnum,
  CTextInputListItemInterface,
  IconNameEnum,
  SButtonVariantEnum,
  SChipsItemInterface,
  SizeEnum,
  SMaskedInputMaskInterface,
} from '@/shared/DesignSystem';

import {
  IValidatorPlugin,
} from '@/shared/Validator';

import PromoFormPage from '@/pages/Service/Promo/PromoFormPage/PromoFormPage';

import EPromoRouteName from '@/app/Enum/router/Service/EPromoRouteName';
import EActiveType from '@/pages/Service/Promo/PromoFormPage/Enum/EActiveType';
import EApplication from '@/pages/Service/Promo/PromoFormPage/Enum/EApplication';
import EFormType from '@/pages/Service/Promo/PromoFormPage/Enum/EFormType';
import EPromotionType from '@/pages/Service/Promo/PromoFormPage/Enum/EPromotionType';
import EVariantAmountDiscount from '@/pages/Service/Promo/PromoFormPage/Enum/EVariantAmountDiscount';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

import FormComponent from '@/widgets/FormComponent/Component/FormComponent.vue';
import LayoutWithoutToolbar from '@/app/Component/Layout/LayoutWithoutToolbar/LayoutWithoutToolbar.vue';

export default defineComponent({
  components: {
    FormComponent,
    LayoutWithoutToolbar,
  },
  setup() {
    const serviceContainer = inject<IServiceContainer>('serviceContainer');
    if (!serviceContainer) {
      throw new Error('ServiceContainer not found. Check is plugin ServiceContainer/ServiceContainer.ts installed.');
    }

    const validatorPlugin = inject<IValidatorPlugin>('validatorPlugin');
    if (!validatorPlugin) {
      throw new Error('validatorPlugin service not found. Check is plugin validatorPlugin installed.');
    }

    const router = useRouter();
    const { t } = useI18n();

    const pageService = new PromoFormPage(serviceContainer);

    const state = pageService.initState(t);

    pageService.handlerService.init(validatorPlugin);

    const {
      formFieldHandler,
      formConfigHandler,
      sendDataHandler,
    } = pageService.handlerService;

    const buttonBack = computed(() => ({
      text: t('common.back'),
      variant: SButtonVariantEnum.TRANSPARENT,
      icon: {
        name: IconNameEnum.ARROW_LEFT_TAILED,
        color: ColorEnum.PRIMARY,
      },
      handler: () => router.push({ name: EPromoRouteName.OVERVIEW }),
    }));

    const buttonCancel = computed(() => ({
      text: t('common.cancel'),
      variant: SButtonVariantEnum.TRANSPARENT,
      icon: {
        name: IconNameEnum.CROSS,
        color: ColorEnum.PRIMARY,
      },
      handler: () => router.push({ name: EPromoRouteName.OVERVIEW }),
    }));

    const buttonSave = computed(() => ({
      size: SizeEnum.L,
      text: t('common.send'),
      variant: SButtonVariantEnum.FILLED,
      handler: () => {
        state.isSendButtonPressed = true;

        sendDataHandler.handle()
          .then(() => {
            state.isFormSent = true;
          })
          .catch((e) => console.log(e));
      },
    }));

    const promotionInfo = computed(() => {
      const textMap: Record<EFormType, string> = {
        [EFormType.STICKER]: t('pages.promo.form.fields.promotion_info.sticker'),
        [EFormType.DISCOUNT]: t('pages.promo.form.fields.promotion_info.discount'),
        [EFormType.PROMO_CODES]: t('pages.promo.form.fields.promotion_info.promo_codes'),
        [EFormType.TEXT_PROMOTION]: t('pages.promo.form.fields.promotion_info.text_promotion'),
        [EFormType.KIT]: t('pages.promo.form.fields.promotion_info.kit'),
      };

      const imgMap: Record<EFormType, string> = {
        [EFormType.STICKER]: '/promo/sticker.png',
        [EFormType.DISCOUNT]: '/promo/discount.png',
        [EFormType.PROMO_CODES]: '/promo/promoCodes.png',
        [EFormType.TEXT_PROMOTION]: '/promo/textPromotion.png',
        [EFormType.KIT]: '/promo/kit.png',
      };

      let text = '';
      let img = '';

      if (state.formData.formType) {
        text = textMap[state.formData.formType];
        img = imgMap[state.formData.formType];
      }

      return text.length > 0 && img.length > 0 ? {
        text,
        img,
      } : null;
    });

    const isPersonalDiscount = computed(() => {
      const promotionChips = state.formData.activeType.find(
        (item) => item.id === EActiveType.PROMOTION,
      );
      const isPromotion = promotionChips?.checked ?? false;

      const textPromotionChips = state.formData.promotionType.find(
        (item) => item.id === EPromotionType.TEXT_PROMOTION,
      );
      const isTextPromotion = textPromotionChips?.checked ?? false;

      const personalDiscountChips = state.formData.application.find(
        (item) => item.id === EApplication.EVERY_PRODUCTS,
      );
      const isPersonalDiscount = personalDiscountChips?.checked ?? false;

      return isPromotion && !isTextPromotion && isPersonalDiscount;
    });

    const discountPlaceholder = computed(() => {
      const discountInRubChips = state.formData.variantAmountDiscount.find(
        (item: SChipsItemInterface) => item.id === EVariantAmountDiscount.IN_RUB,
      );

      return discountInRubChips?.checked
        ? t('pages.promo.form.fields.amount_discount.placeholder.rub')
        : t('pages.promo.form.fields.amount_discount.placeholder.percent');
    });

    const discountMaskOption = computed<SMaskedInputMaskInterface>(() => {
      const discountInRubChips = state.formData.variantAmountDiscount.find(
        (item: SChipsItemInterface) => item.id === EVariantAmountDiscount.IN_RUB,
      );
      const isDiscountInRub = discountInRubChips?.checked ?? false;

      const mask: SMaskedInputMaskInterface = {
        mask: 'NumberConstructor',
        scale: 6,
        radix: ',',
        mapToRadix: ['.', ','],
        max: 99.999999,
        min: 1,
      };

      if (isDiscountInRub) {
        mask.scale = 2;
        mask.thousandsSeparator = ' ';
        mask.max = 999999.99;
      }

      return mask;
    });

    function getDiscountValue(item: CTextInputListItemInterface): string {
      return item?.additionalData?.discount as string ?? '';
    }

    const statusWindow = {
      icon: {
        name: IconNameEnum.CHECK_CIRCLE,
        color: ColorEnum.SUCCESS,
        size: SizeEnum.XXL,
      },
      title: t('pages.promo.form.success.title'),
      subtitle: t('pages.promo.form.success.subtitle'),
    };

    onMounted(() => {
      state.formConfig = formConfigHandler.get(state);
    });

    return {
      state,
      buttonBack,
      buttonCancel,
      buttonSave,
      discountMaskOption,
      discountPlaceholder,
      getDiscountValue,
      isPersonalDiscount,
      promotionInfo,
      statusWindow,
      formFieldHandler,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.promo-form-page {
  &__promotion-info {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 325px;
  }

  &__promotion-img-wrapper {
    max-width: 50%;
    flex: 0 0 auto;
  }

  &__promotion-img {
    display: block;
    max-width: 100%;
  }

  &__promotion-text {

  }

  &__product-discount {
    position: relative;
    flex: 0 0 auto;
    width: 150px;

    &-label {
      position: absolute;
      bottom: calc(100% + 8px);
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__promotion-info {
      flex-direction: column;
    }

    &__promotion-img-wrapper {
      max-width: 100%;
    }
  }
}
</style>
