enum IconNameEnum {
  ALERT = 'alert',
  ARROW_DOWN = 'arrowDown',
  ARROW_LEFT = 'arrowLeft',
  ARROW_LEFT_DOUBLE = 'arrowLeftDouble',
  ARROW_LEFT_TAILED = 'arrowLeftTailed',
  ARROW_RETURN = 'arrowReturn',
  ARROW_RIGHT = 'arrowRight',
  ARROW_RIGHT_TAILED = 'arrowRightTailed',
  BANK = 'bank',
  BANK_CARD = 'bankCard',
  BILL = 'bill',
  BINOCULARS = 'binoculars',
  BOOK = 'book',
  BURGER = 'burger',
  CALENDAR = 'calendar',
  CHECK = 'check',
  CHECK_CIRCLE = 'checkCircle',
  CHECK_CIRCLED = 'CheckCircled',
  CHECKBOX_ON = 'checkboxOn',
  CLOCK = 'clock',
  CODE = 'code',
  COUPON = 'coupon',
  CROSS = 'cross',
  CROSS_CIRCLE = 'crossCircle',
  DOTS = 'dots',
  GLOBE = 'globe',
  HOME = 'home',
  INFO = 'info',
  KEY = 'key',
  MAIL = 'mail',
  PAPER_CLIP = 'paperClip',
  PLUS = 'plus',
  PROFILE = 'profile',
  PROMOTIONS = 'promotions',
  QUESTION = 'question',
  SEARCH = 'search',
  SERVICES = 'services',
  TRASH = 'trash',
  UPLOAD = 'upload',
}

export default IconNameEnum;
