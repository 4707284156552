<template>
  <svg
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 30C12.287 30 8.72601 28.525 6.1005 25.8995C3.475 23.274 2 19.713 2 16C2 12.287 3.475 8.72601 6.1005 6.1005C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30Z"
    />
    <path d="M23.6631 10.9757C23.5661 10.8779 23.4507 10.8002 23.3235 10.7472C23.1963 10.6943 23.0599 10.667 22.9222 10.667C22.7844 10.667 22.648 10.6943 22.5209 10.7472C22.3937 10.8002 22.2783 10.8779 22.1813 10.9757L14.4069 18.7605L11.1406 15.4838C11.0399 15.3865 10.921 15.31 10.7907 15.2586C10.6604 15.2073 10.5212 15.1821 10.3812 15.1845C10.2412 15.1869 10.103 15.2169 9.97459 15.2727C9.84615 15.3286 9.72997 15.4091 9.63267 15.5099C9.53537 15.6106 9.45886 15.7295 9.40752 15.8598C9.35617 15.9901 9.33099 16.1292 9.33341 16.2692C9.33583 16.4092 9.36581 16.5474 9.42164 16.6759C9.47746 16.8043 9.55803 16.9205 9.65876 17.0178L13.666 21.025C13.763 21.1228 13.8784 21.2004 14.0056 21.2534C14.1327 21.3064 14.2691 21.3337 14.4069 21.3337C14.5446 21.3337 14.681 21.3064 14.8082 21.2534C14.9354 21.2004 15.0508 21.1228 15.1478 21.025L23.6631 12.5097C23.769 12.412 23.8536 12.2934 23.9114 12.1613C23.9692 12.0293 23.999 11.8868 23.999 11.7427C23.999 11.5986 23.9692 11.456 23.9114 11.324C23.8536 11.192 23.769 11.0734 23.6631 10.9757Z"
      fill="white"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconCheckCircled',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-alert_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-alert {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

}
</style>
