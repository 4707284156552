<template>
  <div
    class="c-card"
  >
    <div
      class="c-card__top"
    >
      <div
        class="c-card__title"
      >
        <STextElement
          variant="medium"
        >
          {{ title }}
        </STextElement>
      </div>
      <div
        v-if="icon"
        class="c-card__icon"
      >
        <SIcon
          :color="iconConfig.color"
          :name="iconConfig.name"
        />
      </div>
    </div>

    <div
      class="c-card__body"
    >
      <STextElement
        color="stroke"
      >
        {{ text }}
      </STextElement>
    </div>

    <div
      v-if="link"
      class="c-card__bottom"
    >
      <SLink
        :color="link.color"
        :download="link.download"
        :isRouterLink="link.isRouterLink"
        :target="link.target"
        :title="link.title"
        :url="link.url"
        :variant="link.variant"
      >
        <template
          #icon-after
        >
          <SIcon
            :color="linkIconConfig.color"
            :name="linkIconConfig.name"
          />
        </template>
      </SLink>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import SLink from '../../Simple/Text/SLink.vue';
import SIcon from '../../Simple/Icon/SIcon.vue';
import STextElement from '../../Simple/Text/STextElement.vue';

import ColorEnum from '../../../lib/Enum/ColorEnum';
import SIconPropsInterface from '../../../lib/Model/Components/Simple/Icon/SIconPropsInterface';
import IconNameEnum from '../../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import SLinkConfigInterface from '../../../lib/Model/Components/Simple/Text/SLinkConfigInterface';

export default defineComponent({
  name: 'CCard',
  components: {
    SLink,
    STextElement,
    SIcon,
  },
  props: {
    icon: {
      type: String as PropType<SIconPropsInterface['name']>,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    link: {
      type: Object as PropType<SLinkConfigInterface>,
    },
  },
  setup(props) {
    const iconConfig = computed<SIconPropsInterface>(() => ({
      name: props.icon,
      color: ColorEnum.WHITE,
    }));
    const linkIconConfig = computed<SIconPropsInterface>(() => ({
      name: IconNameEnum.ARROW_RIGHT_TAILED,
      color: ColorEnum.LINKED,
    }));

    return {
      iconConfig,
      linkIconConfig,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "../../../assets/breakpoints";

.c-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-white);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.10);
  gap: 16px;

  &__top {
    position: relative;
    display: flex;
  }

  &__title {
    padding-right: 50px;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 15px;
    background-color: var(--color-navigation);
  }

  &__body {
    padding-right: 50px;
  }

  &__bottom {
    margin-top: auto;
  }

  @media screen and (max-width: $breakpoint-md) {
    &__top {
      flex-direction: column-reverse;
      gap: 8px;
    }

    &__title {
      padding-right: 0;
    }

    &__icon {
      position: relative;
    }

    &__body {
      padding-right: 0;
    }
  }
}

</style>
