enum EValidatorType {
  CUSTOM = 'custom',
  CUSTOM_NOT_EMPTY = 'customEmpty',
  EMAIL = 'email',
  FILE = 'file',
  MATCH = 'match',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan',
  MAX_LENGTH = 'maxLength',
  MIN_LENGTH = 'minLength',
  NOT_EMPTY = 'notEmpty',
  ONLY_NUMBERS = 'onlyNumbers',
  REGEX = 'regex',
  REQUIRED = 'required',
  URL = 'url',
}

export default EValidatorType;
