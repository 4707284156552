import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CFileList = _resolveComponent("CFileList")!
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SButton = _resolveComponent("SButton")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SFileUploadArea = _resolveComponent("SFileUploadArea")!

  return (_openBlock(), _createElementBlock("div", {
    class: "c-file-upload-area",
    onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createVNode(_component_SFileUploadArea, {
      multiple: _ctx.options.multiple,
      id: _ctx.id,
      ref: "dsFileUploadAreaRef",
      onFileInput: _ctx.handleInput,
      onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
    }, {
      before: _withCtx(() => [
        _createVNode(_component_CFileList, {
          list: _ctx.state.files,
          "enable-delete": true,
          onDeleteFile: _ctx.handleDeleteFile,
          onDownloadFile: _ctx.handleDownloadFile,
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, null, 8, ["list", "onDeleteFile", "onDownloadFile"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "c-file-upload-area__button-area",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(_component_SButton, {
            text: _ctx.options.button.text,
            variant: _ctx.options.button.variant,
            size: _ctx.options.button.size,
            class: "c-file-upload-area__button",
            onClick: _withModifiers(_ctx.handleButtonCLick, ["stop"])
          }, _createSlots({ _: 2 }, [
            (_ctx.options.icon)
              ? {
                  name: "icon-before",
                  fn: _withCtx(() => [
                    _createVNode(_component_SIcon, {
                      name: _ctx.options.icon.name,
                      size: _ctx.options.icon.size,
                      color: _ctx.options.icon.color,
                      class: "c-file-upload-area__button-icon"
                    }, null, 8, ["name", "size", "color"])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["text", "variant", "size", "onClick"]),
          (_ctx.options && _ctx.options.allowedMimeTypes)
            ? (_openBlock(), _createBlock(_component_STextElement, {
                key: 0,
                variant: "small",
                color: "stroke",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.fileExtensionsText), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      after: _withCtx(() => [
        (_ctx.options && _ctx.options.description)
          ? (_openBlock(), _createBlock(_component_STextElement, {
              key: 0,
              variant: "small",
              color: "stroke",
              onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.options.description), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["multiple", "id", "onFileInput"])
  ]))
}