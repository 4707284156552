import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a0d96b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.classes),
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "icon-before", {}, undefined, true),
    (_ctx.text)
      ? (_openBlock(), _createBlock(_component_STextElement, {
          key: 0,
          variant: "medium",
          color: _ctx.textColor
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ]),
          _: 1
        }, 8, ["color"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "icon-after", {}, undefined, true)
  ], 10, _hoisted_1))
}