import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41d5fdba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-chips-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SChips = _resolveComponent("SChips")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.list, (chips) => {
      return (_openBlock(), _createBlock(_component_SChips, {
        key: chips.id,
        color: _ctx.getColor(chips),
        variant: _ctx.variant,
        stateType: "outer",
        modelValue: chips.checked,
        onChipsClick: ($event: any) => (_ctx.handleClick($event, chips))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(chips.title), 1)
        ]),
        _: 2
      }, 1032, ["color", "variant", "modelValue", "onChipsClick"]))
    }), 128))
  ]))
}