import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b5c6786"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-bar" }
const _hoisted_2 = { class: "progress-bar__top" }
const _hoisted_3 = { class: "progress-bar__text" }
const _hoisted_4 = { class: "progress-bar__bottom" }
const _hoisted_5 = ["max", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SHeader, { size: 2 }, {
          default: _withCtx(() => [
            _createTextVNode(" 2/3 ")
          ]),
          _: 1
        }),
        _createVNode(_component_STextElement, { variant: "medium" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('pages.home.setup.progressbar.text')), 1)
          ]),
          _: 1
        })
      ]),
      (_ctx.currentStep === 4 && !_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_SButton, {
            key: 0,
            size: _ctx.buttonConfig.size,
            text: _ctx.buttonConfig.text,
            variant: _ctx.buttonConfig.variant,
            onClick: _ctx.handleClick
          }, null, 8, ["size", "text", "variant", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_STextElement, {
        color: _ctx.stepText.color,
        variant: _ctx.stepText.variant
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.stepText.text), 1)
        ]),
        _: 1
      }, 8, ["color", "variant"]),
      _createElementVNode("progress", {
        max: _ctx.stepCount,
        value: _ctx.currentStep,
        class: "progress-bar__progress-bar"
      }, null, 8, _hoisted_5)
    ]),
    (_ctx.currentStep === 4 && _ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_SButton, {
          key: 0,
          size: _ctx.buttonConfig.size,
          stretch: true,
          text: _ctx.buttonConfig.text,
          variant: _ctx.buttonConfig.variant,
          onClick: _ctx.handleClick
        }, null, 8, ["size", "text", "variant", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}