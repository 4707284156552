import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99ea6d88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sales-banner" }
const _hoisted_2 = { class: "sales-banner__banner" }
const _hoisted_3 = { class: "sales-banner__banner-title" }
const _hoisted_4 = { class: "sales-banner__list" }
const _hoisted_5 = {
  key: 1,
  class: "sales-banner__item-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SIcon = _resolveComponent("SIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SHeader, { size: 3 }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('pages.home.setup.sales_banner.title')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_STextElement, {
          color: "white",
          variant: "subheader"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('pages.home.setup.sales_banner.text')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `sales-banner-item-${index}`,
            class: "sales-banner__item"
          }, [
            (item.title)
              ? (_openBlock(), _createBlock(_component_STextElement, {
                  key: 0,
                  class: "sales-banner__item-title",
                  color: "white",
                  variant: "medium"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1024))
              : (item.icon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_SIcon, {
                      color: item.icon.color,
                      name: item.icon.name,
                      size: item.icon.size,
                      class: "sales-banner__item-icon"
                    }, null, 8, ["color", "name", "size"])
                  ]))
                : _createCommentVNode("", true),
            _createVNode(_component_STextElement, { color: "white" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.text), 1)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ])
    ])
  ]))
}