import FormBlockType from '@/widgets/FormComponent/lib/Model/FormBlockType';
import FormBlockTypeEnum from '@/widgets/FormComponent/lib/Enum/FormBlockTypeEnum';
import FieldTypeEnum from '@/widgets/FormComponent/lib/Enum/FieldTypeEnum';
import {
  ColorEnum,
  FileErrorTypeEnum,
  FileMimeTypesEnum,
  getFileUploadOptions,
  IconNameEnum,
  FileErrorMessagesType,
} from '@/shared/DesignSystem/index';
import SButtonVariantEnum
  from '@/shared/DesignSystem/lib/Enum/Components/Simple/UI/SButtonVariantEnum';
import SizeEnum from '@/shared/DesignSystem/lib/Enum/SizeEnum';
import EFieldName from '@/pages/Service/About/AboutFormPage/Enum/EFieldName';
import IState from '@/pages/Service/About/AboutFormPage/Model/IState';
import IFormConfigHandler
  from '@/pages/Service/About/AboutFormPage/Contract/Handler/IFormConfigHandler';
import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';
import FileExtensionsEnum
  from '@/shared/DesignSystem/lib/Enum/Components/Complex/CFileUpload/FileExtensionsEnum';

class FormConfigHandler implements IFormConfigHandler {
  get = (
    state: IState,
  ): Array<FormBlockType> => [
    {
      type: FormBlockTypeEnum.BLOCK,
      subTitle: null,
      title: null,
      fields: [
        {
          type: FieldTypeEnum.FILE_UPLOAD,
          fieldName: EFieldName.IMAGE,
          config: {
            label: state.t('pages.about.form.fields.image.label'),
            options: this.getDocumentOptions(state),
          },
          status: state.validationStatusRegistry[EFieldName.IMAGE],
        },
        {
          type: FieldTypeEnum.TEXT_AREA,
          fieldName: EFieldName.DESCRIPTION,
          config: {
            placeholder: state.t('pages.about.form.fields.description.placeholder'),
            label: state.t('pages.about.form.fields.description.label'),
            description: state.t('pages.about.form.fields.description.description'),
            minRowsCount: 10,
            maxRowsCount: 20,
            maxLength: 3000,
          },
          status: state.validationStatusRegistry[EFieldName.DESCRIPTION],
        },
      ],
    },
  ];

  private getDocumentOptions(state: IState) {
    const maxFileSize = 100;
    const maxFilesSize = 0;

    const fileErrorMessages: FileErrorMessagesType = {
      [FileErrorTypeEnum.LOAD]: state.t(EValidationMessageTokens.FILE_LOAD),
      [FileErrorTypeEnum.MAX_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_SIZE,
        {
          number: maxFileSize,
        },
      ),
      [FileErrorTypeEnum.MAX_TOTAL_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_TOTAL_SIZE,
        {
          number: maxFilesSize,
        },
      ),
      [FileErrorTypeEnum.MIME_TYPE]: state.t(EValidationMessageTokens.FILE_MIME_TYPE),
      [FileErrorTypeEnum.NO_ZERO_SIZE]: state.t(EValidationMessageTokens.FILE_NO_ZERO_SIZE),
      [FileErrorTypeEnum.NAME]: state.t(EValidationMessageTokens.FILE_NAME),
    };

    const mimeTypes = [
      FileMimeTypesEnum.JPG,
      FileMimeTypesEnum.JPEG,
      FileMimeTypesEnum.PNG,
    ];

    const extensions = [
      FileExtensionsEnum.JPG,
      FileExtensionsEnum.JPEG,
      FileExtensionsEnum.PNG,
    ];

    const fileUploadOptions = getFileUploadOptions(
      fileErrorMessages,
      {
        variant: SButtonVariantEnum.TERTIARY,
        text: state.t('pages.about.form.fields.image.upload_button'),
        size: SizeEnum.M,
      },
      {
        name: IconNameEnum.PAPER_CLIP,
        color: ColorEnum.PRIMARY,
      },
      state.t(
        'pages.about.form.fields.image.description',
        {
          extensions: extensions.join(', '),
          maxFileSize,
          maxFilesSize,
        },
      ),
      state.t(
        'pages.about.form.fields.image.placeholder',
        {
          extensions: extensions.join(', '),
          maxFileSize,
          maxFilesSize,
        },
      ),
      mimeTypes,
      maxFileSize,
      maxFilesSize,
      255,
      false,
    );

    return { ...fileUploadOptions };
  }
}

export default FormConfigHandler;
