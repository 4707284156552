<template>
  <div
    class="c-file-upload-area"
    @click.stop
  >
    <SFileUploadArea
      :multiple="options.multiple"
      :id="id"
      ref="dsFileUploadAreaRef"
      @file-input="handleInput"
      @click.stop
    >
      <template
        #before
      >
        <CFileList
          :list="state.files"
          :enable-delete="true"
          @delete-file="handleDeleteFile"
          @download-file="handleDownloadFile"
          @click.stop
        />
      </template>
      <template
        #default
      >
        <div
          class="c-file-upload-area__button-area"
          @click.stop
        >
          <SButton
            :text="options.button.text"
            :variant="options.button.variant"
            :size="options.button.size"
            class="c-file-upload-area__button"
            @click.stop="handleButtonCLick"
          >
            <template
              v-if="options.icon"
              #icon-before
            >
              <SIcon
                :name="options.icon.name"
                :size="options.icon.size"
                :color="options.icon.color"
                class="c-file-upload-area__button-icon"
              />
            </template>
          </SButton>
          <STextElement
            v-if="options && options.allowedMimeTypes"
            variant="small"
            color="stroke"
            @click.stop
          >
            {{ fileExtensionsText }}
          </STextElement>
        </div>
      </template>
      <template
        #after
      >
        <STextElement
          v-if="options && options.description"
          variant="small"
          color="stroke"
          @click.stop
        >
          {{ options.description }}
        </STextElement>
      </template>
    </SFileUploadArea>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';

import { IUtilsService } from '@/app/Service/UtilsService';
import FileInterface from '../../../lib/Model/Components/Complex/CFileUpload/FileInterface';
import FileMimeTypesEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileMimeTypesEnum';
import FileExtensionsEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileExtensionsEnum';
import FileStatusEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileStatusEnum';
import CFileUploadOptionsInterface from '../../../lib/Model/Components/Complex/CFileUpload/CFileUploadOptionsInterface';

import SFileUploadArea from '../../Simple/UI/SFileUploadArea.vue';
import CFileList from './CFileList.vue';
import SButton from '../../Simple/UI/SButton.vue';
import SIcon from '../../Simple/Icon/SIcon.vue';
import STextElement from '../../Simple/Text/STextElement.vue';

interface IStateCFileUploadArea {
  files: FileInterface[];
}

export default defineComponent({
  name: 'CFileUploadArea',
  components: {
    STextElement,
    SIcon,
    SButton,
    CFileList,
    SFileUploadArea,
  },
  props: {
    options: {
      type: Object as PropType<CFileUploadOptionsInterface>,
      required: true,
    },
    files: {
      type: Array as PropType<FileInterface[]>,
      default: () => [],
    },
    status: {
      type: Object as PropType<{ code: string; text: string; }>,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const utilsService = inject<IUtilsService>('utilsService');

    if (!utilsService) {
      throw new Error('UtilsService not defined.');
    }

    const state: IStateCFileUploadArea = reactive<IStateCFileUploadArea>({
      files: [],
    });
    const dsFileUploadAreaRef = ref();

    const fileExtensionsMap = {
      [FileMimeTypesEnum.JPG]: [
        FileExtensionsEnum.JPG,
        FileExtensionsEnum.JPEG,
      ],
      [FileMimeTypesEnum.PDF]: [
        FileExtensionsEnum.PDF,
      ],
      [FileMimeTypesEnum.JPEG]: [
        FileExtensionsEnum.JPG,
        FileExtensionsEnum.JPEG,
      ],
      [FileMimeTypesEnum.DOC]: [
        FileExtensionsEnum.DOC,
      ],
      [FileMimeTypesEnum.DOCX]: [
        FileExtensionsEnum.DOCX,
      ],
      [FileMimeTypesEnum.PNG]: [
        FileExtensionsEnum.PNG,
      ],
      [FileMimeTypesEnum.XLS]: [
        FileExtensionsEnum.XLS,
      ],
      [FileMimeTypesEnum.XLSX]: [
        FileExtensionsEnum.XLSX,
      ],
      [FileMimeTypesEnum.GIF]: [
        FileExtensionsEnum.GIF,
      ],
      [FileMimeTypesEnum.PPT]: [
        FileExtensionsEnum.PPT,
      ],
      [FileMimeTypesEnum.PPTX]: [
        FileExtensionsEnum.PPTX,
      ],
      [FileMimeTypesEnum.PSD]: [
        FileExtensionsEnum.PSD,
      ],
      [FileMimeTypesEnum.EPS]: [
        FileExtensionsEnum.EPS,
      ],
      [FileMimeTypesEnum.TIFF]: [
        FileExtensionsEnum.TIFF,
      ],
    };

    const fileExtensionsText = computed(() => {
      if (props.options.extensionsString) {
        return props.options.extensionsString;
      }

      const mimeTypes = (props.options.allowedMimeTypes ?? [])
        .reduce((mimeTypesSet, mimeType) => {
          fileExtensionsMap[mimeType].forEach((mimeType) => mimeTypesSet.add(mimeType));

          return mimeTypesSet;
        }, new Set());

      return Array
        .from(mimeTypes)
        .map((mimeType) => `.${mimeType}`)
        .join(', ');
    });

    function mapFiles(fileList: FileList): FileInterface[] {
      const fileNames = state.files.map((file) => file.fileName);
      const list: File[] = Array.from(fileList);

      if (!utilsService) {
        throw new Error('UtilsService not defined.');
      }

      return list.map((localFile) => {
        let file = localFile;
        if (fileNames.includes(localFile.name)) {
          file = utilsService.file.changeFileName(
            localFile,
            utilsService.file.getFileName(localFile.name, fileNames),
          );
        }

        return {
          file,
          fileName: file?.name ?? '',
          status: FileStatusEnum.DEFAULT,
          errors: [],
        };
      });
    }

    function handleInput(event: FileList) {
      const files = mapFiles(event);

      if (props.options.multiple) {
        state.files = [...state.files, ...files];
      } else {
        state.files = [...files];
      }

      emit('input-file', state.files);
    }

    function handleDeleteFile(file: FileInterface) {
      emit('delete-file', file);
    }

    function handleDownloadFile(file: FileInterface) {
      emit('download-file', file);
    }

    function handleButtonCLick() {
      dsFileUploadAreaRef.value?.$refs?.element?.click();
    }

    onMounted(() => {
      state.files = props.files.map((file) => utilsService.data.cloneImmutable(file));
    });

    watch(() => props.files, (newValue) => {
      state.files = newValue.map((file) => utilsService.data.cloneImmutable(file));
    });

    return {
      handleInput,
      handleDeleteFile,
      handleDownloadFile,
      handleButtonCLick,
      fileExtensionsText,
      state,
      dsFileUploadAreaRef,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.c-file-upload-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &__button-area {
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
  }

  &__button {
    flex: 0 0 auto;
    max-width: 50%;
  }

  &__button-icon {
    flex: 0 0 auto;
  }
}

@media screen and (max-width: 440px){
  .c-file-upload-area {
    &__button-area {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }

    &__button {
      max-width: 100%;
    }
  }
}
</style>
