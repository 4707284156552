import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de01485e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app__help-widget" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CookieModal = _resolveComponent("CookieModal")!
  const _component_HelpWidget = _resolveComponent("HelpWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_ctx.cookieModal)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.cookieModal.classes)
        }, [
          (!!_ctx.cookieModal.text)
            ? (_openBlock(), _createBlock(_component_CookieModal, {
                key: 0,
                text: _ctx.cookieModal.text,
                onOkClick: _ctx.cookieModal.handler
              }, null, 8, ["text", "onOkClick"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HelpWidget)
    ])
  ], 64))
}