import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createSlots as _createSlots, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-555c1521"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-without-sidebar" }
const _hoisted_2 = { class: "layout-without-sidebar__main-section" }
const _hoisted_3 = { class: "layout-without-sidebar__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SoftlineLogoLink = _resolveComponent("SoftlineLogoLink")!
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_CUserMenu = _resolveComponent("CUserMenu")!
  const _component_CTopBar = _resolveComponent("CTopBar")!
  const _component_LayoutWithSidebarContainer = _resolveComponent("LayoutWithSidebarContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CTopBar, null, {
      "left-side": _withCtx(() => [
        _createVNode(_component_SoftlineLogoLink),
        _createVNode(_component_router_link, {
          class: "layout-without-sidebar__top-bar-title",
          to: "/"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SHeader, {
              size: 3,
              color: "white"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.state.localisation.topBarTitle), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_SIcon, {
              name: _ctx.dotIcon.name
            }, null, 8, ["name"])
          ]),
          _: 1
        })
      ]),
      "right-side": _withCtx(() => [
        _createVNode(_component_CUserMenu, {
          logout: _ctx.logoutUserMenuItem,
          user: _ctx.userUserMenuItem
        }, {
          default: _withCtx(() => [
            (_ctx.state.partnerLevel)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.state.partnerLevel.classes)
                }, [
                  _createVNode(_component_STextElement, {
                    color: "secondary",
                    variant: "x-small-medium"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.state.partnerLevel?.title), 1)
                    ]),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["logout", "user"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LayoutWithSidebarContainer, { title: _ctx.title }, _createSlots({ _: 2 }, [
          (_ctx.isHeaderLeftSlot)
            ? {
                name: "header-left",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header-left", {}, undefined, true)
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.isHeaderRightSlot)
            ? {
                name: "header-right",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header-right", {}, undefined, true)
                ]),
                key: "1"
              }
            : undefined,
          (_ctx.isContentSlot)
            ? {
                name: "content",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                ]),
                key: "2"
              }
            : undefined
        ]), 1032, ["title"])
      ])
    ])
  ]))
}