<template>
  <LayoutWithoutToolbar
    :title="state.localisation.title"
  >
    <template
      #content
    >
      <div
        class="contact-form-page"
      >
        contact form page
      </div>
    </template>
  </LayoutWithoutToolbar>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  reactive,
} from 'vue';
import { useI18n } from 'vue-i18n';

import LayoutWithoutToolbar
  from '@/app/Component/Layout/LayoutWithoutToolbar/LayoutWithoutToolbar.vue';

export default defineComponent({
  components: {
    LayoutWithoutToolbar,
  },
  setup() {
    const { t } = useI18n();

    const state = reactive({
      localisation: {
        title: t('pages.contact.form.title'),
      },
    });

    return {
      state,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>

</style>
