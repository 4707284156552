<template>
  <div
    class="c-file-list"
    @click.stop
  >
    <template
      v-for="(file, index) in state.list"
      :key="`cFileListItem${index}`"
    >
      <div
        class="c-file-list__item"
        @click.stop
      >
        <div
          v-if="file.status !== 'default'"
          class="c-file-list__item-icon"
        >
          <SPreloaderComponent
            v-if="file.status === 'loading' || file.status === 'deleting'"
          />
          <SIcon
            v-else-if="file.status === 'success'"
            color="success"
            name="checkCircle"
          />
          <SIcon
            v-else-if="file.status === 'error'"
            color="danger"
            name="crossCircle"
          />
        </div>
        <div
          class="c-file-list__item-text"
          @click.stop
        >
          <STextElement
            :class="fileNameClasses"
            @click.stop="handleClickLink($event, file)"
          >
            {{ file.fileName }}
          </STextElement>
          <div
            class="c-file-list__item-attributes"
          >
            <slot
              :file="file"
              name="icon-slot"
            />
            <SIcon
              v-if="isDeleteIcon(file, enableDelete)"
              class="c-file-list__item-close"
              color="stroke"
              name="cross"
              size="s"
              @click.stop="handleClickDelete(file)"
            />
          </div>
        </div>
      </div>
      <div
        v-if="file.errors.length > 0"
        :key="`cFileListItemErrors${index}`"
        class="c-file-list__item-errors"
      >
        <STextElement
          v-for="error in file.errors"
          :key="`${file.fileName}${error.type}`"
          class="c-expandable-list__comment"
          color="danger"
          variant="small"
        >
          {{ error.message }}
        </STextElement>
      </div>
      <hr
        v-if="index < list.length - 1"
        :key="`cFileListDelimiter${index}`"
        class="c-file-list__delimiter"
      >
    </template>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  watch,
} from 'vue';

import { IUtilsService } from '@/app/Service/UtilsService';
import FileStatusEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileStatusEnum';
import FileInterface from '../../../lib/Model/Components/Complex/CFileUpload/FileInterface';

import SPreloaderComponent from '../../Simple/SPreloaderComponent.vue';
import SIcon from '../../Simple/Icon/SIcon.vue';
import STextElement from '../../Simple/Text/STextElement.vue';

export default defineComponent({
  name: 'CFileList',
  components: {
    STextElement,
    SIcon,
    SPreloaderComponent,
  },
  props: {
    list: {
      type: Array as PropType<FileInterface[]>,
      default: () => [],
    },
    enableDelete: {
      type: Boolean,
      default: () => true,
    },
    enableLink: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const utilsService = inject<IUtilsService>('utilsService');

    if (!utilsService) {
      throw new Error('UtilsService not defined.');
    }

    const state: { list: FileInterface[] } = reactive({
      list: [],
    });

    const fileNameClasses = computed(() => [
      'c-file-list__file-name',
      props.enableLink ? 'c-file-list__file-name_link' : null,
    ]);

    function handleClickDelete(file: FileInterface) {
      emit('delete-file', file);
    }

    function handleClickLink(event: PointerEvent, file: FileInterface) {
      emit('download-file', file);
    }

    function isDeleteIcon(file: FileInterface, enableDelete: boolean) {
      if (!enableDelete) {
        return false;
      }

      return file.status === FileStatusEnum.ERROR || file.status === FileStatusEnum.SUCCESS;
    }

    onMounted(() => {
      state.list = props.list.map((file) => utilsService.data.cloneImmutable(file));
    });

    watch(() => props.list, (newValue) => {
      state.list = newValue.map((file) => utilsService.data.cloneImmutable(file));
    });

    return {
      handleClickDelete,
      handleClickLink,
      isDeleteIcon,
      fileNameClasses,
      state,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>

.c-file-list {
  width: 100%;

  &__file-name {
    word-break: break-all;

    &_link {
      cursor: pointer;
    }
  }

  &__item-attributes {
    flex-shrink: 0;
    min-width: 18px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 11px 8px;
    gap: 12px;
  }

  &__item-icon {
    width: 24px;
    height: 24px;
  }

  &__item-text {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    justify-content: space-between;
    gap: 24px;
  }

  &__item-errors {
    display: flex;
    flex-direction: column;
    padding: 0 8px 11px;
    gap: 8px;
  }

  &__item-close {
    cursor: pointer;
    margin: 3px 0;
  }

  &__delimiter {
    width: 100%;
    margin: 0;
    border: none;
    border-top: 1px solid var(--color-stroke);
  }
}

@media screen and (max-width: 440px) {
  .c-file-list {
    &__item {
      align-items: flex-start;
      flex-direction: column;
    }

    &__item-text {
      width: 100%;
      gap: 19px;
    }
  }
}
</style>
