enum TextInputEnum {
  BORDER_WIDTH = '--input-border-width',
  BORDER_RADIUS_TOP_RIGHT = '--text-input-border-radius-top-right',
  BORDER_RADIUS_TOP_LEFT = '--text-input-border-radius-top-left',
  BORDER_RADIUS_BOTTOM_LEFT = '--text-input-border-radius-bottom-left',
  BORDER_RADIUS_BOTTOM_RIGHT = '--text-input-border-radius-bottom-right',
  L_HEIGHT = '--text-input-l-height',
  M_HEIGHT = '--text-input-m-height',
  S_HEIGHT = '--text-input-s-height',
  L_GAP = '--text-input-l-gap',
  M_GAP = '--text-input-m-gap',
  S_GAP = '--text-input-s-gap',
  L_PADDING_TOP = '--text-input-l-padding-top',
  L_PADDING_RIGHT = '--text-input-l-padding-right',
  L_PADDING_BOTTOM = '--text-input-l-padding-bottom',
  L_PADDING_LEFT = '--text-input-l-padding-left',
  M_PADDING_TOP = '--text-input-m-padding-top',
  M_PADDING_RIGHT = '--text-input-m-padding-right',
  M_PADDING_BOTTOM = '--text-input-m-padding-bottom',
  M_PADDING_LEFT = '--text-input-m-padding-left',
  S_PADDING_TOP = '--text-input-s-padding-top',
  S_PADDING_RIGHT = '--text-input-s-padding-right',
  S_PADDING_BOTTOM = '--text-input-s-padding-bottom',
  S_PADDING_LEFT = '--text-input-s-padding-left',
}

export default TextInputEnum;
