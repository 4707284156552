<template>
  <LayoutWithoutSidebar
    width="lg"
  >
    <template
      #content
    >
      <div
        class="error-page"
      >
        <CStatusWindow
          :subtitle="statusWindowComponent.subtitle"
          :title="statusWindowComponent.title"
        />
      </div>
    </template>
  </LayoutWithoutSidebar>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
} from 'vue';
import {
  useI18n,
} from 'vue-i18n';

import LayoutWithoutSidebar from '@/app/Component/Layout/LayoutWithToolbar/LayoutWithoutSidebar.vue';

export default defineComponent({
  components: {
    LayoutWithoutSidebar,
  },
  setup() {
    const { t } = useI18n();

    const statusWindowComponent = computed(() => ({
      title: t('pages.cooperation_refused.title'),
      subtitle: t('pages.cooperation_refused.subtitle'),
    }));

    return {
      statusWindowComponent,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
