import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b889bee4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "placeholder", "rows"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PerfectScrollbar = _resolveComponent("PerfectScrollbar")!
  const _component_STextElement = _resolveComponent("STextElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.parentClasses)
  }, [
    _createVNode(_component_PerfectScrollbar, {
      ref: "scrollbar",
      class: "s-textarea__scrollbar",
      onTouchmovePassive: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("textarea", {
          id: _ctx.id,
          ref: "textarea",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.value) = $event)),
          placeholder: _ctx.placeholder,
          rows: _ctx.minRowsCount,
          class: "s-textarea__field",
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
          onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
          onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
          onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args)))
        }, null, 40, _hoisted_1), [
          [_vModelText, _ctx.state.value]
        ])
      ]),
      _: 1
    }, 512),
    (_ctx.maxLength > 0)
      ? (_openBlock(), _createBlock(_component_STextElement, {
          key: 0,
          class: "s-textarea__counter",
          color: "stroke",
          variant: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.charsLeft), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}