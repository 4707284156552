import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0e42e20"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM4 19V7H20L20.002 19H4Z"
    }, null, 2),
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M9.293 9.29297L5.586 13L9.293 16.707L10.707 15.293L8.414 13L10.707 10.707L9.293 9.29297ZM14.707 9.29297L13.293 10.707L15.586 13L13.293 15.293L14.707 16.707L18.414 13L14.707 9.29297Z"
    }, null, 2)
  ]))
}