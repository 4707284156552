<template>
  <svg
    width="119"
    height="26"
    viewBox="0 0 119 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      d="M1.43994 18.4845L7.51027 17.9321C7.75684 18.649 8.10908 19.1661 8.55526 19.4717C9.00143 19.7773 9.61199 19.9301 10.3634 19.9301C11.1853 19.9301 11.8194 19.7538 12.2656 19.4012C12.6178 19.1426 12.7939 18.8136 12.7939 18.4257C12.7939 17.9791 12.5591 17.6383 12.1012 17.4033C11.7724 17.227 10.8918 17.0154 9.47109 16.7686C7.34589 16.3925 5.87821 16.0517 5.04457 15.7344C4.22266 15.4171 3.52992 14.8882 2.95459 14.136C2.391 13.3839 2.1092 12.5259 2.1092 11.5622C2.1092 10.5045 2.41448 9.59955 3.02504 8.83563C3.63559 8.07171 4.48098 7.50759 5.54945 7.13151C6.61792 6.75542 8.05038 6.56738 9.85856 6.56738C11.7607 6.56738 13.1696 6.70841 14.0737 7.00223C14.9778 7.29604 15.741 7.74264 16.3398 8.35378C16.9504 8.96491 17.4435 9.78759 17.8545 10.8218L12.019 11.433C11.8663 10.9158 11.6198 10.528 11.2793 10.2812C10.7979 9.95213 10.2225 9.78759 9.55328 9.78759C8.87228 9.78759 8.3674 9.91687 8.06212 10.1519C7.7451 10.3987 7.59246 10.7043 7.59246 11.0451C7.59246 11.433 7.79206 11.7385 8.19127 11.9383C8.59048 12.1381 9.45935 12.3261 10.7979 12.4789C12.8291 12.714 14.3438 13.0313 15.3301 13.4426C16.3164 13.854 17.0796 14.4416 17.6079 15.2055C18.1363 15.9694 18.3946 16.8039 18.3946 17.7206C18.3946 18.649 18.1128 19.5422 17.5609 20.4119C17.0091 21.2816 16.1285 21.975 14.9309 22.4921C13.7332 23.0092 12.1012 23.2678 10.0464 23.2678C7.13454 23.2678 5.06805 22.8565 3.82346 22.022C2.5906 21.1876 1.79218 20.0006 1.43994 18.4727V18.4845Z"
      fill="#A7333E"
    />
    <path
      d="M26.0262 11.7503C26.6133 11.0569 27.353 10.7043 28.2454 10.7043C29.0908 10.7043 29.7953 11.0451 30.3706 11.7268C30.9459 12.4084 31.2277 13.4544 31.2277 14.8529C31.2277 16.3573 30.9459 17.4503 30.3706 18.1319C29.807 18.8136 29.079 19.1544 28.1984 19.1544C27.3178 19.1544 26.6016 18.8136 26.0145 18.1202C25.4392 17.4268 25.1456 16.369 25.1456 14.9235C25.1456 13.5014 25.4392 12.4437 26.0145 11.7503H26.0262ZM21.9402 21.3286C23.5253 22.6332 25.6036 23.2795 28.1632 23.2795C31.0398 23.2795 33.2942 22.4921 34.9145 20.929C36.5466 19.3542 37.3567 17.3445 37.3567 14.8765C37.3567 12.6905 36.6992 10.8453 35.3842 9.35274C33.7521 7.49584 31.3334 6.56738 28.1397 6.56738C25.3452 6.56738 23.1261 7.35481 21.4823 8.9414C19.8385 10.528 19.0166 12.5377 19.0166 14.9705C19.0166 17.5913 19.9911 19.7068 21.9285 21.3286H21.9402Z"
      fill="#A7333E"
    />
    <path
      d="M45.5998 6.93162H48.5117V11.4329H45.5998V22.9151H39.4708V11.4329H37.1577V6.93162H39.4708V6.21471C39.4708 5.56832 39.5412 4.85142 39.6821 4.06399C39.823 3.28832 40.0813 2.64193 40.4688 2.14832C40.8563 1.65472 41.3964 1.25513 42.1009 0.949561C42.7819 0.643994 43.7799 0.491211 45.0949 0.491211H45.2241C46.2926 0.491211 47.8424 0.620489 49.8502 0.867293L49.1575 4.5576C48.4178 4.44008 47.8307 4.38131 47.3845 4.38131C46.8327 4.38131 46.4335 4.47533 46.1869 4.66337C45.9403 4.85142 45.7759 5.14523 45.6703 5.54482C45.6233 5.76812 45.5998 6.22647 45.5998 6.94337V6.93162Z"
      fill="#A7333E"
    />
    <path
      d="M56.5895 11.4328V17.1093C56.5895 17.791 56.6482 18.2376 56.7773 18.4609C56.9769 18.8017 57.3292 18.978 57.8223 18.978C58.2685 18.978 58.9025 18.8487 59.7127 18.5901L60.1588 22.7976C58.6442 23.1266 57.2352 23.2912 55.9202 23.2912C54.3938 23.2912 53.2784 23.0914 52.5504 22.7035C51.8342 22.3157 51.2941 21.7163 50.9536 20.9171C50.6131 20.118 50.4369 18.8369 50.4369 17.0506V11.4328H48.1943V6.93158H50.4369V3.99344L56.566 0.84375V11.4211L56.5895 11.4328Z"
      fill="#A7333E"
    />
    <path
      d="M61.5562 0.855469H67.6852V22.9268H61.5562V0.855469Z"
      fill="#B4B4B3"
    />
    <path
      d="M70.2681 22.9269H76.3971V6.93164H70.2681V22.9269Z"
      fill="#B4B4B3"
    />
    <path
      d="M70.2681 0.855469V5.05114H76.3971V0.855469H70.2681Z"
      fill="#B4B4B3"
    />
    <path
      d="M78.7339 6.93168H84.452V9.54075C85.2974 8.47127 86.1662 7.7191 87.0351 7.26075C87.904 6.8024 88.9725 6.5791 90.2171 6.5791C91.9078 6.5791 93.2346 7.08446 94.1857 8.09518C95.1367 9.1059 95.6181 10.6572 95.6181 12.7727V22.9387H89.4891V14.1478C89.4891 13.1488 89.3012 12.4319 88.9372 12.0205C88.5615 11.6092 88.0449 11.3977 87.3756 11.3977C86.6359 11.3977 86.0371 11.6797 85.5674 12.2438C85.1095 12.808 84.8747 13.8187 84.8747 15.2643V22.9504H78.7339V6.96694V6.93168Z"
      fill="#B4B4B3"
    />
    <path
      d="M103.285 13.5602C103.379 12.5965 103.614 11.8795 103.99 11.3859C104.565 10.6103 105.34 10.2107 106.314 10.2107C107.148 10.2107 107.829 10.4692 108.346 10.9864C108.862 11.5035 109.179 12.3614 109.297 13.5602H103.297H103.285ZM115.473 15.7814C115.473 13.6659 115.132 11.9501 114.439 10.6338C113.747 9.31749 112.749 8.30676 111.422 7.61336C110.095 6.91996 108.299 6.56738 106.021 6.56738C103.215 6.56738 101.007 7.34305 99.4104 8.88264C97.8136 10.434 97.0151 12.4554 97.0151 14.947C97.0151 16.6981 97.4143 18.2377 98.201 19.5305C98.9994 20.835 99.9975 21.7752 101.207 22.3746C102.416 22.974 104.072 23.2678 106.185 23.2678C108.616 23.2678 110.471 22.9152 111.774 22.2218C113.077 21.5284 114.181 20.3767 115.097 18.7666L109.085 18.2142C108.71 18.696 108.357 19.0369 108.029 19.2249C107.489 19.5305 106.925 19.6715 106.338 19.6715C105.399 19.6715 104.635 19.3307 104.048 18.6373C103.637 18.1554 103.367 17.4268 103.262 16.4513H115.473V15.7579V15.7814Z"
      fill="#B4B4B3"
    />
    <path
      d="M29.5608 3.00591C36.547 3.75808 39.3414 4.35746 45.8579 6.10859C51.2942 7.57766 59.9359 6.94302 59.9359 6.94302C59.9359 6.94302 59.9359 9.72838 59.9359 11.4443C56.5544 11.9144 49.2043 11.7498 43.0987 9.51683C32.1674 5.53271 27.2125 4.72179 21.3653 4.29869C13.4398 3.73457 3.99969 4.93333 3.99969 4.93333L3.90576 4.13416C3.90576 4.13416 17.5728 1.71313 29.5608 3.00591Z"
      fill="#B4B4B3"
    />
    <path
      d="M115.707 1.30176C117.163 1.30176 118.349 2.43 118.443 3.85206V4.25165C118.338 5.67371 117.152 6.80196 115.707 6.80196C114.193 6.80196 112.96 5.56794 112.96 4.05186C112.96 2.53578 114.193 1.30176 115.707 1.30176ZM115.707 6.16732C116.87 6.16732 117.821 5.21536 117.821 4.05186C117.821 2.88835 116.87 1.9364 115.707 1.9364C114.545 1.9364 113.594 2.88835 113.594 4.05186C113.594 5.21536 114.545 6.16732 115.707 6.16732ZM115.895 2.66506C116.518 2.66506 116.835 2.99413 116.835 3.42897C116.835 3.81681 116.553 4.02835 116.295 4.07536V4.08712C116.459 4.13413 116.565 4.33392 116.588 4.38093L117.046 5.47392H116.306L115.942 4.58072C115.931 4.54547 115.837 4.35743 115.578 4.35743H115.367V5.47392H114.686V2.67681H115.907L115.895 2.66506ZM115.355 3.82856H115.661C116.095 3.82856 116.142 3.60526 116.142 3.48774C116.142 3.18217 115.731 3.19392 115.661 3.19392H115.355V3.82856Z"
      fill="#B4B4B3"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>

</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';

export default defineComponent({});
</script>
