enum EServiceName {
  ABOUT = 'about',
  PRICE_LIST = 'priceList',
  PORTFOLIO = 'portfolio',
  PROMO = 'promo',
  CO_MARKETING = 'coMarketing',
  CONTACT = 'contact',
}

export default EServiceName;
