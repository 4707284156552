import FileStorageApiResponseModelInterface
  from '@/shared/Api/Model/FileStorage/FileStorageApiResponseModelInterface';
import FileStorageApiInterface from '@/shared/Api/Contract/Api/FileStorageApiInterface';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import {
  FileInterface,
} from '@/shared/DesignSystem';

class FileStorageApi implements FileStorageApiInterface {
  private clientService: IAxiosClientService;

  private serviceContainer: IServiceContainer;

  constructor(
    clientService: IAxiosClientService,
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
    this.clientService = clientService;
  }

  upload = (files: FileInterface[]): Promise<FileStorageApiResponseModelInterface[]> => {
    if (files.length === 0) {
      throw new Error('No file.');
    }

    const fileTool = this.serviceContainer.utilsService.file;

    const formDataFile = new FormData();
    files.forEach((file, index) => {
      if (!file.file) {
        return;
      }

      const key = index + 1;

      formDataFile.append(`file${key}`, fileTool.fileExtensionToLowerCase(file.file));
    });

    return this.clientService.instance
      .post('/files-storage', formDataFile)
      .then((response) => response.data);
  }

  download = (
    fileObject: FileStorageApiResponseModelInterface,
  ): Promise<FileStorageApiResponseModelInterface> => this.clientService.instance
    .get<Blob | MediaSource>(`/files-storage/${fileObject.s3ObjectKey}`, { responseType: 'blob' })
    .then((response) => {
      if (response.data) {
        const fileTool = this.serviceContainer.utilsService.file;
        fileTool.downloadFile(response.data, fileObject.fileName);
      }

      return fileObject;
    });

  delete = (
    fileObject: FileStorageApiResponseModelInterface,
  ): Promise<FileStorageApiResponseModelInterface> => this.clientService.instance
    .delete(`/files-storage/${fileObject.s3ObjectKey}`);
}

export default FileStorageApi;
