import HandlerServiceInterface
  from '@/pages/ScoringFormPage/lib/Contract/Service/HandlerServiceInterface';
import FormConfigHandlerInterface
  from '@/pages/ScoringFormPage/lib/Contract/Handler/FormConfigHandlerInterface';
import FormConfigHandler from '@/pages/ScoringFormPage/lib/Handler/FormConfigHandler';
import FormFieldHandlerInterface
  from '@/pages/ScoringFormPage/lib/Contract/Handler/FormFieldHandlerInterface';
import FormFieldHandler from '@/pages/ScoringFormPage/lib/Handler/FormFieldHandler';
import StateModelInterface from '@/pages/ScoringFormPage/lib/Model/StateModelInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import ScoringFormPageServiceInterface
  from '@/pages/ScoringFormPage/lib/Contract/Service/ScoringFormPageServiceInterface';
import SendDataHandlerInterface
  from '@/pages/ScoringFormPage/lib/Contract/Handler/SendDataHandlerInterface';
import SendDataHandler from '@/pages/ScoringFormPage/lib/Handler/SendDataHandler';
import ValidationHandlerInterface
  from '@/pages/ScoringFormPage/lib/Contract/Handler/ValidationHandlerInterface';
import ValidationHandler from '@/pages/ScoringFormPage/lib/Handler/ValidationHandler';
import { IValidatorPlugin } from '@/shared/Validator';

class HandlerService implements HandlerServiceInterface {
  private _formConfigHandler?: FormConfigHandlerInterface;

  private _formFieldEventHandler?: FormFieldHandlerInterface;

  private _sendDataHandler?: SendDataHandlerInterface;

  private _validationHandler?: ValidationHandlerInterface;

  private readonly state: StateModelInterface;

  private readonly serviceContainer: IServiceContainer;

  private readonly scoringFormPageService: ScoringFormPageServiceInterface;

  private validatorPlugin?: IValidatorPlugin;

  constructor(
    state: StateModelInterface,
    serviceContainer: IServiceContainer,
    scoringFormPageService: ScoringFormPageServiceInterface,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.scoringFormPageService = scoringFormPageService;
  }

  init = (validatorPlugin: IValidatorPlugin): HandlerServiceInterface => {
    this.validatorPlugin = validatorPlugin;

    return this;
  }

  get formConfigHandler(): FormConfigHandlerInterface {
    if (!this._formConfigHandler) {
      this._formConfigHandler = new FormConfigHandler();
    }

    return this._formConfigHandler;
  }

  get formFieldHandler(): FormFieldHandlerInterface {
    if (!this._formFieldEventHandler) {
      this._formFieldEventHandler = new FormFieldHandler(
        this.state,
        this.serviceContainer,
        this.scoringFormPageService,
      );
    }

    return this._formFieldEventHandler;
  }

  get sendDataHandler(): SendDataHandlerInterface {
    if (!this._sendDataHandler) {
      this._sendDataHandler = new SendDataHandler(
        this.state,
        this.serviceContainer,
        this.scoringFormPageService,
      );
    }

    return this._sendDataHandler;
  }

  get validationHandler(): ValidationHandlerInterface {
    if (!this.validatorPlugin) {
      throw new Error('validatorPlugin not set. Run init method');
    }

    if (!this._validationHandler) {
      this._validationHandler = new ValidationHandler(
        this.state,
        this.serviceContainer,
        this.scoringFormPageService,
        this.validatorPlugin,
      );
    }

    return this._validationHandler;
  }
}

export default HandlerService;
