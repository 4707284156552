<template>
  <div
    class="layout-without-toolbar-control"
  >
    <div
      class="layout-without-toolbar-control-block layout-without-toolbar-control-block_left"
    >
      <slot
        name="control-left"
      />
    </div>
    <div
      class="layout-without-toolbar-control-block layout-without-toolbar-control-block_right"
    >
      <slot
        name="control-right"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
>

</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.layout-without-toolbar-control {
  position: fixed;
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  &-block {
    position: absolute;
    top: 45px;
    height: 48px;

    &_left {
      left: var(--layout-without-toolbar-container-xxl-padding-left);
    }

    &_right {
      right: var(--layout-without-toolbar-container-xxl-padding-right);
    }
  }

  @media screen and (max-width: $breakpoint-xl) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    flex: 0 0 auto;
    padding-right: var(--layout-without-toolbar-container-xl-padding-right);
    padding-left: var(--layout-without-toolbar-container-xl-padding-left);
    border-bottom: 1px solid var(--color-surface);
    background-color: var(--color-white);

    &-block {
      position: relative;
      top: 0;
      height: 48px;

      &_left {
        left: unset;
      }

      &_right {
        right: unset;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-right: var(--layout-without-toolbar-container-sm-padding-right);
    padding-left: var(--layout-without-toolbar-container-sm-padding-left);
  }
}
</style>
