<template>
  <router-view/>
  <div
    v-if="cookieModal"
    :class="cookieModal.classes"
  >
    <CookieModal
      v-if="!!cookieModal.text"
      :text="cookieModal.text"
      @ok-click="cookieModal.handler"
    />
  </div>
  <div
    class="app__help-widget"
  >
    <HelpWidget />
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  reactive,
} from 'vue';
import {
  useRoute,
} from 'vue-router';

import { useI18n } from 'vue-i18n';

import EBreakpoint from '@/app/Enum/EBreakpoint';
import ECookieKeys from '@/app/Service/CookieService/Enum/ECookieKeys';
import EAboutRouteName from '@/app/Enum/router/Service/EAboutRouteName';
import EPriceListRouteName from '@/app/Enum/router/Service/EPriceListRouteName';
import EPortfolioRouteName from '@/app/Enum/router/Service/EPortfolioRouteName';
import EPromoRouteName from '@/app/Enum/router/Service/EPromoRouteName';
import ECoMarketingRouteName from '@/app/Enum/router/Service/ECoMarketingRouteName';
import EContactRouteName from '@/app/Enum/router/Service/EContactRouteName';
import EVendorRouteName from '@/app/Enum/router/EVendorRouteName';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

import CookieModal from '@/app/Component/CookieModal.vue';
import HelpWidget from '@/app/Component/HelpWidget.vue';

export default defineComponent({
  components: {
    HelpWidget,
    CookieModal,
  },
  setup() {
    const route = useRoute();

    const { t } = useI18n();

    const serviceContainer = inject<IServiceContainer>('serviceContainer') as IServiceContainer;
    const {
      store,
      cookieService,
    } = serviceContainer;

    const state = reactive({
      isCookieAccepted: !!cookieService.read(ECookieKeys.VS_COOKIE_ACCEPTED),
    });

    const vendor = computed(() => store.getters.getVendor);

    const isSidebarCollapsed = computed(
      () => store.getters.getIsSidebarCollapsed,
    );

    const cookieModal = computed(() => {
      if (state.isCookieAccepted || !(vendor.value.email.length > 0)) {
        return null;
      }

      const routeNames: string[] = [
        EVendorRouteName.SCORING,
        EAboutRouteName.FORM,
        EPriceListRouteName.FORM,
        EPortfolioRouteName.FORM,
        EPromoRouteName.FORM,
        ECoMarketingRouteName.FORM,
        EContactRouteName.FORM,
      ];

      let alignmentClass = 'app__cookie-wrapper_full-size-sidebar';
      if (routeNames.includes(route.name as string)) {
        alignmentClass = 'app__cookie-wrapper_center';
      } else if (isSidebarCollapsed.value) {
        alignmentClass = 'app__cookie-wrapper_minimized-sidebar';
      }

      return {
        classes: [
          'app__cookie-wrapper',
          alignmentClass,
        ],
        text: t('common.cookie_modal.text'),
        handler: () => {
          cookieService.write(ECookieKeys.VS_COOKIE_ACCEPTED, true, 400);
          state.isCookieAccepted = true;
        },
      };
    });

    onBeforeMount(() => {
      store.dispatch(
        'updateIsSidebarCollapsed',
        document.documentElement.clientWidth < EBreakpoint.LG,
      );
      store.dispatch(
        'updateIsMobile',
        document.documentElement.clientWidth < EBreakpoint.SM,
      );

      window.addEventListener('resize', () => {
        store.dispatch(
          'updateIsSidebarCollapsed',
          document.documentElement.clientWidth < EBreakpoint.LG,
        );
        store.dispatch(
          'updateIsMobile',
          document.documentElement.clientWidth < EBreakpoint.SM,
        );
      });
    });

    return {
      state,
      cookieModal,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";
.app {
  &__cookie-wrapper {
    position: fixed;
    z-index: 8;
    bottom: 32px;
    min-width: 768px;

    &_full-size-sidebar {
      left: calc(50% + 122px);
      transform: translateX(-50%);
    }

    &_minimized-sidebar {
      left: calc(50% + 32px);
      transform: translateX(-50%);
    }

    &_center {
      left: 50%;
      transform: translateX(-50%);
    }

    @media screen and (max-width: $breakpoint-lg) {
      min-width: 668px;
    }

    @media screen and (max-width: $breakpoint-md) {
      min-width: 568px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      min-width: auto;
      width: calc(100% - 32px);
      left: 50%;
    }
  }

  &__help-widget {
    position: fixed;
    bottom: 32px;
    right: 32px;
  }
}
</style>
