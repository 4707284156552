import ITranslationApi from '@/app/providers/i18n/api/Contract/ITranslationApi';
import ELocale from '@/app/Enum/ELocale';
import ILocalisation from '@/app/providers/i18n/ILocalisation';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

class TranslationApi implements ITranslationApi {
  private readonly client: IAxiosClientService;

  private readonly serviceContainer: IServiceContainer;

  constructor(
    client: IAxiosClientService,
    serviceContainer: IServiceContainer,
  ) {
    this.client = client;
    this.serviceContainer = serviceContainer;
  }

  async list(locale: ELocale): Promise<ILocalisation> {
    return this.client.instance.get<ILocalisation>(`common/translator/${locale}`)
      .then((response) => response.data);
  }
}

export default TranslationApi;
