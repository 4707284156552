<template>
  <div
    v-click-outside="handleClickOutside"
    class="c-menu-dropdown"
  >
    <div
      ref="toggler"
      class="c-menu-dropdown__toggler"
      @click="handleClick"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @focus="handleMouseEnter"
      @blur="handleMouseLeave"
    >
      <slot />
    </div>

    <div
      v-if="state.isOpenFullModal || state.isOpenHoverModal"
      :class="modalClasses"
    >
      <div
        :style="triangleStyle"
        class="c-menu-dropdown__triangle"
      />
      <div
        v-for="(list, listIndex) in currentMenuList"
        :key="`menu-block-${listIndex}`"
        :class="listClasses(listIndex)"
      >
        <template
          v-for="(item) in list"
        >
          <div
            v-if="item.type === 'link' && isLink(item)"
            :key="`menu-${item.title}`"
            class="c-menu-dropdown__item c-menu-dropdown__item_link"
            @click="handleClickLinkItem($event, item)"
            @mouseenter="handleItemMouseEnter(item)"
            @mouseleave="handleItemMouseLeave(item)"
            @focus="handleItemMouseEnter(item)"
            @blur="handleItemMouseLeave(item)"
          >
            <SLink
              :download="item.download"
              :is-router-link="getIsRouterLink(item)"
              :target="item.target"
              :title="item.title"
              :url="item.url"
              :variant="getTitleVariant(item)"
              class="c-menu-dropdown__text"
              color="white"
            >
              <template
                v-if="item.iconName"
                #icon-before
              >
                <SIcon
                  :color="getItemIconColor(item)"
                  :name="item.iconName"
                  size="xs"
                />
              </template>
            </SLink>
          </div>
          <div
            v-else-if="item.type === 'email'  && isEmail(item)"
            :key="`menu-${item.title}`"
            class="c-menu-dropdown__item"
          >
            <STextElement
              :variant="getTitleVariant(item)"
              class="c-menu-dropdown__title"
              color="white"
            >
              {{ item.title }}
            </STextElement>
            <SLink
              :is-router-link="false"
              :title="item.text"
              :url="item.url"
              :variant="getTextVariant(item)"
              class="c-menu-dropdown__text"
            >
              <template
                v-if="item.iconName"
                #icon-before
              >
                <SIcon
                  :color="getItemIconColor(item)"
                  :name="item.iconName"
                  size="xs"
                />
              </template>
            </SLink>
          </div>
          <div
            v-else-if="item.type === 'text' && isText(item)"
            :key="`menu-${item.title}`"
            class="c-menu-dropdown__item"
          >
            <STextElement
              :variant="getTitleVariant(item)"
              class="c-menu-dropdown__title"
              color="white"
            >
              {{ item.title }}
            </STextElement>
            <STextElement
              v-if="item.text"
              :variant="getTextVariant(item)"
              class="c-menu-dropdown__text"
              color="white"
            >
              {{ item.text }}
            </STextElement>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
} from 'vue';

import CDropdownMenuItemTypeEnum from '../../lib/Enum/Components/Complex/CDropdownMenu/CDropdownMenuItemTypeEnum';
import CDropdownMenuPositionEnum from '../../lib/Enum/Components/Complex/CDropdownMenu/CDropdownMenuPositionEnum';
import FontVariantEnum from '../../lib/Enum/FontVariantEnum';

import CDropdownMenuItemEmailInterface from '../../lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemEmailInterface';
import CDropdownMenuItemLinkInterface from '../../lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemLinkInterface';
import CDropdownMenuItemTextInterface from '../../lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemTextInterface';
import CDropdownMenuItemType from '../../lib/Model/Components/Complex/CDropdownMenu/CDropdownMenuItemType';

import SIcon from '../Simple/Icon/SIcon.vue';
import SLink from '../Simple/Text/SLink.vue';
import STextElement from '../Simple/Text/STextElement.vue';

export default defineComponent({
  name: 'CDropdownMenu',
  components: {
    SIcon,
    SLink,
    STextElement,
  },
  props: {
    menuItemListFull: {
      type: Array as PropType<Array<Array<CDropdownMenuItemType>>>,
      default: () => [],
    },
    menuItemListHover: {
      type: Array as PropType<Array<Array<CDropdownMenuItemType>>>,
      default: () => [],
    },
    position: {
      type: String as PropType<CDropdownMenuPositionEnum>,
      default: CDropdownMenuPositionEnum.BOTTOM_LEFT,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpenFullModal: false,
      isOpenHoverModal: false,
      iconColorMap: new Map(),
    });

    const toggler = ref();

    const currentMenuList = computed<Array<Array<CDropdownMenuItemType>>>(() => {
      let currentList = props.menuItemListFull;

      if (props.menuItemListFull.length > 0 && props.menuItemListHover.length > 0) {
        currentList = state.isOpenFullModal ? props.menuItemListFull : props.menuItemListHover;
      } else if (props.menuItemListHover.length > 0) {
        currentList = props.menuItemListHover;
      }

      return currentList;
    });

    const modalClasses = computed(() => [
      'c-menu-dropdown__modal',
      `c-menu-dropdown__modal_position_${props.position}`,
    ]);

    const triangleStyle = computed<Partial<CSSStyleDeclaration>>(() => {
      const halfOfParentsWidth = toggler.value.offsetWidth / 2;

      const styleMap: Record<CDropdownMenuPositionEnum, Partial<CSSStyleDeclaration>> = {
        [CDropdownMenuPositionEnum.TOP_LEFT]: {
          top: '100%',
          right: `${halfOfParentsWidth}px`,
          transform: 'translateX(50%)',
        },
        [CDropdownMenuPositionEnum.TOP_CENTER]: {
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        [CDropdownMenuPositionEnum.TOP_RIGHT]: {
          top: '100%',
          left: `${halfOfParentsWidth}px`,
          transform: 'translateX(-50%)',
        },
        [CDropdownMenuPositionEnum.CENTER_LEFT]: {
          top: '50%',
          transform: 'translateY(-50%)',
        },
        [CDropdownMenuPositionEnum.CENTER_RIGHT]: {
          top: '50%',
          transform: 'translateY(-50%)',
        },
        [CDropdownMenuPositionEnum.BOTTOM_LEFT]: {
          bottom: '100%',
          right: `${halfOfParentsWidth}px`,
          transform: 'translateX(50%) rotate(180deg)',
        },
        [CDropdownMenuPositionEnum.BOTTOM_CENTER]: {
          bottom: '100%',
          left: '50%',
          transform: 'translateX(-50%) rotate(180deg)',
        },
        [CDropdownMenuPositionEnum.BOTTOM_RIGHT]: {
          bottom: '100%',
          left: `${halfOfParentsWidth}px`,
          transform: 'translateX(-50%) rotate(180deg)',
        },
      };

      return styleMap[props.position];
    });

    function isLink(item: CDropdownMenuItemType): item is CDropdownMenuItemLinkInterface {
      return item.type === CDropdownMenuItemTypeEnum.LINK;
    }

    function isEmail(item: CDropdownMenuItemType): item is CDropdownMenuItemEmailInterface {
      return item.type === CDropdownMenuItemTypeEnum.EMAIL;
    }

    function isText(item: CDropdownMenuItemType): item is CDropdownMenuItemTextInterface {
      return item.type === CDropdownMenuItemTypeEnum.TEXT;
    }

    function listClasses(index: number) {
      return [
        'c-menu-dropdown__list',
        index > 0 ? 'c-menu-dropdown__list_bordered' : null,
      ];
    }

    function handleMouseEnter() {
      if (props.menuItemListHover.length === 0) {
        return;
      }

      state.isOpenHoverModal = true;
      emit('hoverMenuToggle', state.isOpenHoverModal);
    }

    function handleMouseLeave() {
      if (props.menuItemListHover.length === 0) {
        return;
      }

      state.isOpenHoverModal = false;
      emit('hoverMenuToggle', state.isOpenHoverModal);
    }

    function handleClick() {
      if (props.menuItemListFull.length === 0) {
        return;
      }

      state.isOpenFullModal = !state.isOpenFullModal;
      emit('fullMenuToggle', state.isOpenFullModal);
    }

    function handleClickOutside() {
      if (props.menuItemListFull.length > 0 && state.isOpenFullModal) {
        state.isOpenFullModal = false;
        emit('fullMenuToggle', false);
      }
    }

    function handleItemMouseEnter(item: CDropdownMenuItemType) {
      const iconColorMap = new Map(state.iconColorMap.entries());
      iconColorMap.set(item, 'menu-active');

      state.iconColorMap = iconColorMap;
    }

    function handleItemMouseLeave(item: CDropdownMenuItemType) {
      const iconColorMap = new Map(state.iconColorMap.entries());
      iconColorMap.set(item, 'surface-icons');

      state.iconColorMap = iconColorMap;
    }

    function handleClickLinkItem(event: PointerEvent, item: CDropdownMenuItemLinkInterface) {
      if (item.isRouterLink) {
        state.isOpenFullModal = false;
      }

      emit('linkClick', event, item);
    }

    function getItemIconColor(item: CDropdownMenuItemType) {
      return state.iconColorMap.get(item);
    }

    function getTitleVariant(item: CDropdownMenuItemType) {
      return item.titleVariant ?? FontVariantEnum.X_SMALL_MEDIUM;
    }

    function getTextVariant(item: CDropdownMenuItemEmailInterface | CDropdownMenuItemTextInterface) {
      return item.textVariant ?? FontVariantEnum.X_SMALL_MEDIUM;
    }

    function getIsRouterLink(item: CDropdownMenuItemLinkInterface) {
      return item.isRouterLink ?? false;
    }

    return {
      state,
      toggler,
      currentMenuList,
      modalClasses,
      triangleStyle,
      listClasses,
      isLink,
      isEmail,
      isText,
      handleMouseEnter,
      handleMouseLeave,
      handleClick,
      handleClickOutside,
      handleItemMouseEnter,
      handleItemMouseLeave,
      handleClickLinkItem,
      getItemIconColor,
      getTitleVariant,
      getTextVariant,
      getIsRouterLink,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "@/app/style/breakpoints";

.c-menu-dropdown {
  position: relative;

  &__modal {
    position: absolute;
    z-index: 5;
    padding: 8px 0;
    font-size: 12px;
    border-radius: 4px;
    background-color: var(--color-toolbar);
    box-shadow: 0 6px 24px -4px rgba(0, 0, 0, 0.08), 0 4px 12px -6px rgba(0, 0, 0, 0.12);

    &_position {
      &_top-left {
        right: 0;
        bottom: 100%;
        margin-bottom: 7px;
      }

      &_top-center {
        bottom: 100%;
        left: 50%;
        margin-bottom: 7px;
        transform: translateX(-50%);
      }

      &_top-right {
        bottom: 100%;
        left: 0;
        margin-bottom: 7px;
      }

      &_center-left {
        top: 50%;
        right: 100%;
        margin-right: 7px;
        transform: translateY(-50%);
      }

      &_center-right {
        top: 50%;
        left: 100%;
        margin-left: 7px;
        transform: translateY(-50%);
      }

      &_bottom-left {
        top: 100%;
        right: 0;
        margin-top: 18px;
      }

      &_bottom-center {
        top: 100%;
        left: 50%;
        margin-top: 18px;
        transform: translateX(-50%);
      }

      &_bottom-right {
        top: 100%;
        left: 0;
        margin-top: 18px;
      }
    }

    &_on-hover {
      display: none;
    }
  }

  &__triangle {
    position: absolute;
    display: block;
    border-top: 4px solid var(--color-toolbar);
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
  }

  &__list {
    padding: 0 12px;

    &_bordered {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  &__item {
    display: block;
    padding: 8px;
    white-space: nowrap;
    border-radius: 4px;

    &_link {
      transition: all linear .2s;

      &:hover {
        background-color: var(--color-linked);
      }
    }
  }

  &__title {
    display: block;
  }

  &__text {
    display: block;
  }

  &__title + &__text {
    margin-top: 4px;
  }
}

@media (hover: hover) and (pointer: fine) {
  .c-menu-dropdown {
    &__modal {
      &_on-hover {
        display: block;
      }
    }
  }
}
</style>
