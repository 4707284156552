import {
  FileInterface,
} from '@/shared/DesignSystem';

import IValidator from './Contract/IValidator';
import IValidatorConfig from './Model/IValidatorConfig';
import IValidatorMessages from './Model/IValidatorMessages';

import EProtocol from './Enum/EProtocol';
import EValidatorType from './Enum/EValidatorType';

import ValidationMethod from './ValidationMethod';

class Validator implements IValidator {
  private messages: IValidatorMessages[] = [];

  readonly validators: IValidatorConfig[] = [];

  addError = (type: EValidatorType, message: string) => {
    this.messages.push({
      type,
      message,
    });
  };

  hasErrors = (): boolean => Object.values(this.messages).length > 0;

  hasErrorsByTypeList = (errorTypes: EValidatorType[]): boolean => {
    const types = this.messages.map((message) => message.type);

    return errorTypes.reduce(
      (isIncludes, errorType) => types.includes(errorType) || isIncludes,
      false,
    ) as unknown as boolean;
  };

  getMessages = (): string[] => this.messages.map((message) => message.message);

  getMessageByType = (type: EValidatorType): string | null => {
    const message = this.messages.find((message) => message.type === type);
    return message?.message ?? null;
  };

  addValidator = (type: EValidatorType, message: string, params: unknown = null) => {
    this.validators.push({
      type,
      message,
      params,
    });
  };

  validate = (value: unknown, formData: unknown = null) => {
    if (this.validators.length === 0) {
      throw new Error('No validator set');
    }

    const validationMethod = new ValidationMethod();

    let result;

    this.validators.forEach((validator) => {
      if (validator.type === EValidatorType.REQUIRED) {
        result = validationMethod.requiredValidator(value);
      } else if (validator.type === EValidatorType.NOT_EMPTY) {
        result = validationMethod.notEmptyValidator(value);
      } else if (validator.type === EValidatorType.ONLY_NUMBERS) {
        result = validationMethod.onlyNumbersValidator(value as string);
      } else if (validator.type === EValidatorType.URL) {
        result = validationMethod.urlValidator(value as string, validator.params as unknown as EProtocol[]);
      } else if (validator.type === EValidatorType.MAX_LENGTH) {
        result = validationMethod.maxLengthValidator(value as string, validator.params as unknown as number);
      } else if (validator.type === EValidatorType.MIN_LENGTH) {
        result = validationMethod.minLengthValidator(value as string, validator.params as unknown as number);
      } else if (validator.type === EValidatorType.EMAIL) {
        result = validationMethod.emailValidator(value as string);
      } else if (validator.type === EValidatorType.REGEX) {
        result = validationMethod.regexValidator(value as string, validator.params as string);
      } else if (validator.type === EValidatorType.MATCH) {
        result = validationMethod.matchValidator(value, validator.params);
      } else if (validator.type === EValidatorType.GREATER_THAN) {
        result = validationMethod.greaterThanValidator(value, validator.params);
      } else if (validator.type === EValidatorType.LESS_THAN) {
        result = validationMethod.lessThanValidator(value, validator.params);
      } else if (validator.type === EValidatorType.FILE) {
        result = validationMethod.fileValidator(value as FileInterface[]);
      } else if (validator.type === EValidatorType.CUSTOM) {
        result = validationMethod.customValidator(
          value,
          formData,
          validator.params as (value: unknown, formData?: unknown) => boolean,
        );
      } else if (validator.type === EValidatorType.CUSTOM_NOT_EMPTY) {
        result = validationMethod.customValidator(
          value,
          formData,
          validator.params as (value: unknown, formData?: unknown) => boolean,
        );
      } else {
        throw new Error(`Validator type '${validator.type}' not exists.`);
      }

      if (!result) {
        this.addError(validator.type, validator.message);
      }
    });
  };

  clear = ():void => {
    this.messages = [];
  };
}

export default Validator;
