enum EFieldName {
  ACTIVE_TYPE = 'activeType',
  PROMOTION_TYPE = 'promotionType',
  PROMOTION_INFO = 'promotionInfo',
  PROMOTION_NAME = 'promotionName',
  DESCRIPTION_FOR_CUSTOMERS = 'descriptionForCustomers',
  ACTIVATION_DATE = 'activationDate',
  EXPIRATION_DATE = 'expirationDate',
  VARIANT_AMOUNT_DISCOUNT = 'variantAmountDiscount',
  APPLICATION = 'application',
  PRODUCTS = 'products',
  AMOUNT_DISCOUNT = 'amountDiscount',
  PARTICIPANTS_PROMOTION = 'participantsPromotion',
  BUYERS_PARTICIPANTS_PROMOTION = 'buyersParticipantsPromotion',
  CATEGORIES_PARTICIPANTS = 'categoriesParticipants',
  WHICH_CATEGORY = 'whichCategory',
  DISCOUNT_REFLECTED = 'discountReflected',
  BANNER = 'banner',
  USE_STICKER = 'useSticker',
  TEXT_STICKER = 'textSticker',
  DESCRIPTION_BUYERS = 'descriptionBuyers',
  COUPON_VALIDITY = 'couponValidity',
  MECHANICS_PROMOTION = 'mechanicsPromotion',
  VARIANT_ARTICLE_KIT = 'variantArticleKit',
  ARTICLE_KIT = 'articleKit',
}

export default EFieldName;
