import { Module } from 'vuex';
import IVendorModel from '@/entities/Model/IVendorModel';
import ELocale from '@/app/Enum/ELocale';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import EVendorStatus from '@/entities/Enum/EVendorStatus';

export const vendorStore: (
  serviceContainer: IServiceContainer
) => Module<{ vendor: IVendorModel }, unknown> = (serviceContainer: IServiceContainer) => ({
  state: {
    vendor: {
      email: '',
      lang: ELocale.RU,
      status: EVendorStatus.SCORING_NOT_PASSED,
      levelPartnerProgram: null,
      checkFlag: null,
      website: '',
      companyName: '',
    },
  },
  getters: {
    getVendor(state) {
      return state.vendor;
    },
  },
  mutations: {
    setVendor(state, payload: IVendorModel) {
      state.vendor = payload;
    },
  },
  actions: {
    async refreshInfo({ commit, dispatch }) {
      const vendorInfoApi = serviceContainer.apiService.vendorApi;

      try {
        const response = await vendorInfoApi.get();
        const {
          email,
          lang,
          status,
          levelPartnerProgram,
          checkFlag,
          website,
          companyName,
        } = response;

        const vendor: IVendorModel = {
          email,
          lang,
          status,
          levelPartnerProgram,
          checkFlag,
          website,
          companyName,
        };

        commit('setVendor', vendor);

        if (vendor.lang) {
          dispatch('updateLocale', vendor.lang);
        }
      } catch (e) {
        throw new Error(`Can't refresh user info. ${e}`);
      }
    },
  },
});
