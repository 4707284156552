import {
  reactive,
} from 'vue';
import {
  VueI18nTranslation,
} from 'vue-i18n';

import moment from 'moment/moment';

import {
  StatusCodeEnum,
} from '@/shared/Validator';

import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

import EFormType from '@/pages/Service/Promo/PromoFormPage/Enum/EFormType';
import EActiveType from '@/pages/Service/Promo/PromoFormPage/Enum/EActiveType';
import EPromotionType from '@/pages/Service/Promo/PromoFormPage/Enum/EPromotionType';

import HandlerService from '@/pages/Service/Promo/PromoFormPage/Service/HandlerService';
import IHandlerService from '@/pages/Service/Promo/PromoFormPage/Contract/IHandlerService';
import IPromoFormPage from '@/pages/Service/Promo/PromoFormPage/Contract/IPromoFormPage';
import IState from '@/pages/Service/Promo/PromoFormPage/Model/IState';

class PromoFormPage implements IPromoFormPage {
  private readonly serviceContainer: IServiceContainer;

  private _handlerService?: IHandlerService;

  private state?: IState;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get handlerService(): IHandlerService {
    if (!this._handlerService) {
      this._handlerService = new HandlerService(
        this.getState(),
        this.serviceContainer,
      );
    }

    return this._handlerService;
  }

  initState(t: VueI18nTranslation): IState {
    if (!this.state) {
      const activationDate = new Date();
      activationDate.setHours(0);
      activationDate.setMinutes(0);

      const expirationDate = moment(activationDate);
      expirationDate.hours(23);
      expirationDate.minutes(59);
      expirationDate.add(1, 'months');

      this.state = reactive({
        localisation: {
          title: t('pages.promo.form.title'),
        },
        t,
        formConfig: [],
        formData: {
          formType: EFormType.DISCOUNT,
          activeType: [
            {
              id: EActiveType.PROMOTION,
              title: t('pages.promo.form.fields.active_type.values.promotion'),
              checked: true,
            },
            {
              id: EActiveType.STICKER,
              title: t('pages.promo.form.fields.active_type.values.sticker'),
              checked: false,
            },
          ],
          promotionType: [
            {
              id: EPromotionType.DISCOUNT,
              title: t('pages.promo.form.fields.promotion_type.values.discount'),
              checked: true,
            },
            {
              id: EPromotionType.PROMO_CODES,
              title: t('pages.promo.form.fields.promotion_type.values.promo_codes'),
              checked: false,
            },
            {
              id: EPromotionType.TEXT_PROMOTION,
              title: t('pages.promo.form.fields.promotion_type.values.text_promotion'),
              checked: false,
            },
            {
              id: EPromotionType.KIT,
              title: t('pages.promo.form.fields.promotion_type.values.kit'),
              checked: false,
            },
          ],
          promotionName: '',
          descriptionForCustomers: '',
          activationDate,
          expirationDate: expirationDate.toDate(),
          variantAmountDiscount: [],
          application: [],
          amountDiscount: '',
          products: [
            {
              id: 0,
              value: '',
            },
          ],
          participantsPromotion: [],
          buyersParticipantsPromotion: [],
          categoriesParticipants: [],
          whichCategory: '',
          discountReflected: [],
          banner: [],
          useSticker: [],
          textSticker: '',
          descriptionBuyers: '',
          couponValidity: [],
          mechanicsPromotion: '',
          variantArticleKit: [],
          articleKit: '',
        },
        isLoading: false,
        isSendButtonPressed: false,
        isFormSent: false,
        validationStatusRegistry: {
          activeType: {
            code: StatusCodeEnum.DEFAULT,
          },
          promotionType: {
            code: StatusCodeEnum.DEFAULT,
          },
          promotionName: {
            code: StatusCodeEnum.DEFAULT,
          },
          descriptionForCustomers: {
            code: StatusCodeEnum.DEFAULT,
          },
          activationDate: {
            code: StatusCodeEnum.DEFAULT,
          },
          expirationDate: {
            code: StatusCodeEnum.DEFAULT,
          },
          variantAmountDiscount: {
            code: StatusCodeEnum.DEFAULT,
          },
          application: {
            code: StatusCodeEnum.DEFAULT,
          },
          amountDiscount: {
            code: StatusCodeEnum.DEFAULT,
          },
          products: {
            code: StatusCodeEnum.DEFAULT,
          },
          participantsPromotion: {
            code: StatusCodeEnum.DEFAULT,
          },
          buyersParticipantsPromotion: {
            code: StatusCodeEnum.DEFAULT,
          },
          categoriesParticipants: {
            code: StatusCodeEnum.DEFAULT,
          },
          whichCategory: {
            code: StatusCodeEnum.DEFAULT,
          },
          discountReflected: {
            code: StatusCodeEnum.DEFAULT,
          },
          banner: {
            code: StatusCodeEnum.DEFAULT,
          },
          useSticker: {
            code: StatusCodeEnum.DEFAULT,
          },
          textSticker: {
            code: StatusCodeEnum.DEFAULT,
          },
          descriptionBuyers: {
            code: StatusCodeEnum.DEFAULT,
          },
          couponValidity: {
            code: StatusCodeEnum.DEFAULT,
          },
          mechanicsPromotion: {
            code: StatusCodeEnum.DEFAULT,
          },
          variantArticleKit: {
            code: StatusCodeEnum.DEFAULT,
          },
          articleKit: {
            code: StatusCodeEnum.DEFAULT,
          },
        },
        documentFiles: [],
        productsValidationStatusRegistry: [],
      });
    }

    return this.state;
  }

  getState(): IState {
    if (!this.state) {
      throw new Error('State not initialized. Call initState method first.');
    }

    return this.state;
  }
}

export default PromoFormPage;
