<template>
  <div
    class="c-status-window"
  >
    <section
      :class="sectionClasses"
    >
      <SIcon
        :color="icon.color"
        :name="icon.name"
        :size="icon.size"
        class="c-status-window__icon"
      />

      <SHeader
        :size="3"
        class="c-status-window__title"
      >
        {{ title }}
      </SHeader>
      <template
        v-if="hasSubtitleSlot"
      >
        <STextElement
          class="c-status-window__text"
          color="stroke"
          variant="regular"
        >
          <slot
            name="subtitle"
          />
        </STextElement>
      </template>
      <STextElement
        v-else-if="subtitle"
        class="c-status-window__text"
        color="stroke"
        variant="regular"
      >
        {{ subtitle }}
      </STextElement>

      <div
        v-if="hasButtonsSlot"
        class="c-status-window__buttons"
      >
        <slot
          name="buttons"
        />
      </div>
    </section>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import IconNameEnum from '../../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import SizeEnum from '../../../lib/Enum/SizeEnum';
import SIconPropsInterface from '../../../lib/Model/Components/Simple/Icon/SIconPropsInterface';
import ColorEnum from '../../../lib/Enum/ColorEnum';

import SIcon from '../../Simple/Icon/SIcon.vue';
import SHeader from '../../Simple/Text/SHeader.vue';
import STextElement from '../../Simple/Text/STextElement.vue';

export default defineComponent({
  name: 'CStatusWindow',
  components: {
    STextElement,
    SHeader,
    SIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<SizeEnum.L | SizeEnum.M>,
      default: SizeEnum.L,
    },
    icon: {
      type: Object as PropType<SIconPropsInterface>,
      default: () => ({
        name: IconNameEnum.ALERT,
        color: ColorEnum.GRAY,
        size: SizeEnum.XXL,
      }),
    },
  },
  setup(props, { slots }) {
    const sectionClasses = computed(() => [
      'c-status-window__section',
      `c-status-window__section_${props.size}`,
    ]);

    const hasSubtitleSlot = computed(() => !!slots.subtitle);
    const hasButtonsSlot = computed(() => !!slots.buttons);

    return {
      sectionClasses,
      hasSubtitleSlot,
      hasButtonsSlot,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
@import "../../../assets/breakpoints";

.c-status-window {
  width: 100%;

  &__section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: var(--status-window-border-radius);
    background-color: var(--color-background-gray);

    &_l {
      padding: var(--status-window-padding-l-top)
        var(--status-window-padding-l-right)
        var(--status-window-padding-l-bottom)
       var(--status-window-padding-l-left);
    }

    &_m {
      padding: var(--status-window-padding-m-top)
        var(--status-window-padding-m-right)
        var(--status-window-padding-m-bottom)
        var(--status-window-padding-m-left);
    }
  }

  &__icon {
    margin-bottom: var(--status-window-margin-after-icon);
  }

  &__title {
    margin-bottom: var(--status-window-margin-after-title);
    text-align: center;
  }

  &__text {
    margin-bottom: var(--status-window-margin-after-text);
    text-align: center;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: var(--status-window-button-gap);
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .c-status-window {
    &__section {
      &_l {
        padding: var(--status-window-xs-padding-l-top)
          var(--status-window-xs-padding-l-right)
          var(--status-window-xs-padding-l-bottom)
          var(--status-window-xs-padding-l-left);
      }

      &_m {
        padding: var(--status-window-xs-padding-m-top)
          var(--status-window-xs-padding-m-right)
          var(--status-window-xs-padding-m-bottom)
          var(--status-window-xs-padding-m-left);
      }
    }
  }
}
</style>
