import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e08b0f68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-sidebar__area" }
const _hoisted_2 = {
  key: 0,
  class: "c-sidebar__menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_CMenuMainItem = _resolveComponent("CMenuMainItem")!

  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(_ctx.sidebarClasses)
  }, [
    _createElementVNode("div", {
      class: "c-sidebar__background",
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClickBackground && _ctx.handleClickBackground(...args)), ["stop"]))
    }, [
      _createElementVNode("div", {
        class: "c-sidebar__close",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClickClose && _ctx.handleClickClose(...args)), ["stop"]))
      }, [
        _createVNode(_component_SIcon, {
          name: _ctx.closeIconConfig.name,
          color: _ctx.closeIconConfig.color
        }, null, 8, ["name", "color"])
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.menuList.length > 0)
        ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item) => {
              return (_openBlock(), _createBlock(_component_CMenuMainItem, {
                key: item.title,
                "ic-sidebar-collapsed": _ctx.isCollapsed,
                item: item,
                class: "c-sidebar__item"
              }, null, 8, ["ic-sidebar-collapsed", "item"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}