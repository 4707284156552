<template>
  <div
    class="partner-level-info"
  >
    <SHeader
      :size="3"
      class="partner-level-info__title"
    >
      {{ title }}
    </SHeader>
    <STextElement
      class="partner-level-info__text"
    >
      {{ text }}
    </STextElement>
    <div
      class="partner-level-info__service-list"
    >
      <div
        v-for="(item, index) in serviceList"
        :key="`service-item-${index}`"
        class="partner-level-info__service-item"
      >
        <SChips
          :size="chipsConfig.size"
          :color="chipsConfig.color"
          :model-value="true"
          :state-type="chipsConfig.stateType"
          :variant="chipsConfig.variant"
          :icon="chipsConfig.icon"
          :icon-first="chipsConfig.iconFirst"
          @click="handleClickChips(item)"
        >
          {{ item.chipsTitle }}
        </SChips>

      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
  PropType,
} from 'vue';
import { useRouter } from 'vue-router';

import IServiceItem from '@/pages/HomePage/Model/IServiceItem';
import {
  ChipsVariantEnum,
  ColorEnum,
  IconNameEnum,
  SChipsPropsInterface,
  SizeEnum,
  StateTypeEnum,
} from '@/shared/DesignSystem';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    serviceList: {
      type: Array as PropType<IServiceItem[]>,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter();

    const chipsConfig: SChipsPropsInterface = {
      size: SizeEnum.XS,
      color: ColorEnum.SUCCESS,
      stateType: StateTypeEnum.OUTER,
      variant: ChipsVariantEnum.BORDERED,
      icon: {
        name: IconNameEnum.CHECK_CIRCLE,
        color: ColorEnum.SUCCESS,
        size: SizeEnum.S,
      },
      iconFirst: true,
    };

    function handleClickChips(service: IServiceItem) {
      router.push(service.route);
    }

    return {
      chipsConfig,
      handleClickChips,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.partner-level-info {
  padding: 20px;
  border-radius: 8px;
  background: #F9F9FB;

  &__title {
    margin-bottom: 4px;
  }

  &__service-list {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 24px;
  }
}
</style>
