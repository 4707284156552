<template>
  <LayoutWithoutSidebar
    :title="state.localisation.title"
  >
    <template
      #content
    >
      <div
        class="setup-page"
      >
        <ProgressBar
          :current-step="statusStepNumber"
          :step-count="12"
          @click-button="handleClickProgressBarButton"
        />
        <div
          class="setup-page__steps"
        >
          <SHeader
            :size="3"
          >
            {{ state.localisation.stepListTitle }}
          </SHeader>

          <SetupStep
            v-for="(step, index) in steps"
            :key="`progress-bar-step-${index}`"
            :step="step"
            @click-button="handleClickStepButton"
          />
        </div>
        <SalesBanner/>
        <InternetAcquiring
          @click="handleClickInternetAcquiring"
        />
      </div>
    </template>
  </LayoutWithoutSidebar>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
} from 'vue';
import {
  useRouter,
} from 'vue-router';
import {
  useI18n,
} from 'vue-i18n';

import SButtonVariantEnum
  from '@/shared/DesignSystem/lib/Enum/Components/Simple/UI/SButtonVariantEnum';
import EVendorStatus from '@/entities/Enum/EVendorStatus';
import SizeEnum from '@/shared/DesignSystem/lib/Enum/SizeEnum';

import IProgressBarPageState from '@/pages/HomePage/Model/IProgressBarPageState';
import IStep from '@/pages/HomePage/Model/IStep';
import IVendorModel from '@/entities/Model/IVendorModel';

import LayoutWithoutSidebar
  from '@/app/Component/Layout/LayoutWithToolbar/LayoutWithoutSidebar.vue';
import ProgressBar from '@/pages/HomePage/Module/ProgressBar.vue';
import SalesBanner from '@/pages/HomePage/Module/SalesBanner.vue';
import SetupStep from '@/pages/HomePage/Module/SetupStep.vue';
import InternetAcquiring from '@/pages/HomePage/Module/InternetAcquiring.vue';

export default defineComponent({
  components: {
    InternetAcquiring,
    LayoutWithoutSidebar,
    ProgressBar,
    SalesBanner,
    SetupStep,
  },
  props: {
    vendor: {
      type: Object as PropType<IVendorModel>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const { t } = useI18n();

    const state = reactive<IProgressBarPageState>({
      localisation: {
        title: t('pages.home.setup.title'),
        stepListTitle: t('pages.home.setup.step_list.title'),
      },
      vendorStatus: EVendorStatus.SCORING_NOT_PASSED,
      vendorFlags: [],
    });

    const statusStepNumberMap: Record<Exclude<EVendorStatus, EVendorStatus.SCORING_REJECTED>, number> = {
      [EVendorStatus.SCORING_NOT_PASSED]: 4,
      [EVendorStatus.SCORING_COMPLETED]: 5,
      [EVendorStatus.SCORING_PASSED]: 6,
      [EVendorStatus.CONTRACT_IN_PROGRESS]: 7,
      [EVendorStatus.CONTRACT_SIGNED]: 8,
    };

    const statusStepNumber = computed<number>(() => statusStepNumberMap[state.vendorStatus] ?? 0);

    const steps = computed<IStep[]>(() => {
      const step1: IStep = {
        number: 1,
        title: t('pages.home.setup.step_list.step1.title'),
        isComplete: true,
      };

      const step2: IStep = {
        number: 2,
        title: t('pages.home.setup.step_list.step2.title'),
        isComplete: statusStepNumber.value === statusStepNumberMap[EVendorStatus.CONTRACT_SIGNED],
        items: [
          {
            title: t('pages.home.setup.step_list.step2.sub_step_list.fill_form'),
            isComplete: statusStepNumber.value >= statusStepNumberMap[EVendorStatus.SCORING_COMPLETED],
          },
          {
            title: t('pages.home.setup.step_list.step2.sub_step_list.wait_profile_verified'),
            isComplete: statusStepNumber.value >= statusStepNumberMap[EVendorStatus.SCORING_PASSED],
          },
          {
            title: t('pages.home.setup.step_list.step2.sub_step_list.sign_contract'),
            isComplete: statusStepNumber.value >= statusStepNumberMap[EVendorStatus.CONTRACT_IN_PROGRESS],
          },
          {
            title: t('pages.home.setup.step_list.step2.sub_step_list.wait_contract_verified'),
            isComplete: statusStepNumber.value === statusStepNumberMap[EVendorStatus.CONTRACT_SIGNED],
          },
        ],
      };

      if (statusStepNumber.value === statusStepNumberMap[EVendorStatus.SCORING_COMPLETED]
        || statusStepNumber.value === statusStepNumberMap[EVendorStatus.CONTRACT_IN_PROGRESS]) {
        step2.info = t('pages.home.setup.step_list.step2.verification_info');
      }

      if (statusStepNumber.value === statusStepNumberMap[EVendorStatus.SCORING_NOT_PASSED]) {
        step2.button = {
          variant: SButtonVariantEnum.FILLED,
          text: t('pages.home.setup.step_list.step2.button'),
          size: SizeEnum.M,
        };
      }

      const step3: IStep = {
        number: 3,
        title: t('pages.home.setup.step_list.step3.title'),
        isComplete: false,
        items: [
          {
            title: t('pages.home.setup.step_list.step3.sub_step_list.price_list'),
            isComplete: false,
          },
          {
            title: t('pages.home.setup.step_list.step3.sub_step_list.business_description'),
            isComplete: false,
          },
          {
            title: t('pages.home.setup.step_list.step3.sub_step_list.information_for_managers'),
            isComplete: false,
          },
          {
            title: t('pages.home.setup.step_list.step3.sub_step_list.publications_in_system'),
            isComplete: false,
          },
        ],
      };

      return [
        step1,
        step2,
        step3,
      ];
    });

    onMounted(() => {
      state.vendorStatus = props.vendor.status as Exclude<EVendorStatus, EVendorStatus.SCORING_REJECTED>;
      state.vendorFlags = props.vendor.checkFlag ?? [];
    });

    function handleClickStepButton(step: IStep) {
      if (step.number === 2) {
        router.push('/vendor/scoring').catch(() => null);
      }
    }

    function handleClickProgressBarButton() {
      router.push('/vendor/scoring').catch(() => null);
    }

    function handleClickInternetAcquiring() {
      window.open('https://ecommerce.softline.ru/');
    }

    return {
      state,
      steps,
      statusStepNumber,
      handleClickStepButton,
      handleClickProgressBarButton,
      handleClickInternetAcquiring,
    };
  },
});
</script>

<style
  lang="scss"
>
.setup-page {
  display: flex;
  flex-direction: column;
  gap: 44px;

  &__steps {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
</style>
