import moment, {
  Moment,
} from 'moment/moment';

import IDateTool from '@/app/Service/UtilsService/Contract/IDateTool';
import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';

class DateTool implements IDateTool {
  private readonly utilsService: IUtilsService;

  private defaultOptions = {
    deleteCurrentYear: false,
    isWeekDay: true,
    time: false,
    timeZone: false,
  };

  constructor(utilsService: IUtilsService) {
    this.utilsService = utilsService;
  }

  private getOptions = (options: object) => ({
    ...this.defaultOptions,
    ...options,
  });

  private getCurrentDate = (): Moment => moment();

  private getTime = (dateValue: string | Moment): string => moment(dateValue).format('HH:mm');

  private getTimeZone = (dateValue: string | Moment): string => {
    const utcOffset = moment(dateValue).utcOffset() / 60;

    return `GMT ${utcOffset > 0 ? '+' : ''}${utcOffset}`;
  };

  getWeekDay = (dateValue: string | Moment): string => {
    const dayOfWeekFormat = 'dd';
    const dayOfWeek = this.getFormat(dateValue, dayOfWeekFormat);

    return dayOfWeek.toUpperCase();
  };

  private format = (value: string | Moment, options = {}): string => {
    const dateValue = moment(value);
    const {
      deleteCurrentYear,
      isWeekDay,
      time,
      timeZone,
    } = this.getOptions(options);

    let mappedValue;
    if (deleteCurrentYear && this.getCurrentDate().year() === dateValue.year()) {
      mappedValue = this.formatWithoutYear(dateValue);
    } else {
      mappedValue = this.formatWithYear(dateValue);
    }

    const parts = [
      isWeekDay ? this.getWeekDay(dateValue) : null,
      mappedValue,
    ];

    const timeString = time ? ` ${this.getTime(dateValue)}` : ' ';

    const timeZoneValue = timeZone ? ` ${this.getTimeZone(dateValue)}` : '';

    return (parts.filter((part) => !!part).join(', ') + timeString + timeZoneValue).trim();
  };

  private postFormat = (dateValue: string | Moment): string => {
    let newDate = dateValue as string;

    newDate = this.utilsService.string.ucFirst(newDate);

    return newDate;
  };

  getFormat = (dateValue: string | Moment, format: string): string => {
    const formattedDate = moment(dateValue).locale('ru').format(format);

    return this.postFormat(formattedDate);
  };

  formatWithYear = (
    dateValue: string | Moment,
  ): string => this.getFormat(dateValue, 'MMM DD, YYYY');

  formatWithoutYear = (
    dateValue: string | Moment,
  ): string => this.getFormat(dateValue, 'MMM DD');

  formatSkipCurrentYear = (
    dateValue: string | Moment,
  ): string => this.format(dateValue, { deleteCurrentYear: true });

  formatSkipCurrentYearWithoutWeekDay = (
    dateValue: string | Moment,
  ): string => this.format(dateValue, {
    deleteCurrentYear: true,
    isWeekDay: false,
  });

  formatWithoutSkipCurrentYear = (
    dateValue: string | Moment,
  ): string => this.format(dateValue);
}

export default DateTool;
