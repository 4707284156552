import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91290abc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-with-sidebar-content-header" }
const _hoisted_2 = {
  key: 0,
  class: "layout-with-sidebar-content-header__block layout-with-sidebar-content-header__block_left"
}
const _hoisted_3 = {
  key: 1,
  class: "layout-with-sidebar-content-header__block layout-with-sidebar-content-header__block_right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeader = _resolveComponent("SHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isHeaderLeftSlot || _ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createBlock(_component_SHeader, {
                key: 0,
                class: "layout-with-sidebar-content-header__title",
                size: 1
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "header-left", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isHeaderRightSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "header-right", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}