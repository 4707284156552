import IAuthorizationApi from '@/shared/Api/Contract/Api/IAuthorizationApi';
import IAuthResponse from '@/shared/Api/Model/AuthorizationApi/IAuthResponse';
import IAuthRequest from '@/shared/Api/Model/AuthorizationApi/IAuthRequest';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';

class AuthorizationApi implements IAuthorizationApi {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  auth = async (params: IAuthRequest): Promise<IAuthResponse> => this.clientService.instance
    .post<IAuthResponse>('/auth/signin', params)
    .then((response) => response.data);

  adminAuth = async (code: string): Promise<IAuthResponse> => {
    const config = {
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    return this.clientService.instance
      .post<IAuthResponse>('/auth/admin-login', {}, config)
      .then((response) => response.data);
  };
}

export default AuthorizationApi;
