import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed2c7332"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cookie-modal" }
const _hoisted_2 = { class: "cookie-modal__left" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "cookie-modal__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_STextElement, {
        color: "white",
        variant: "medium"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", { innerHTML: _ctx.text }, null, 8, _hoisted_3)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SButton, {
        text: _ctx.button.text,
        onClick: _ctx.button.handler
      }, null, 8, ["text", "onClick"])
    ])
  ]))
}