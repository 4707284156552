import {
  EValidatorType,
  IValidator,
  IValidatorPlugin,
  StatusCodeEnum,
} from '@/shared/Validator';

import {
  SChipsItemInterface,
} from '@/shared/DesignSystem';

import {
  FormBlockTypeEnum,
} from '@/widgets/FormComponent';

import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';
import FieldNameEnum from '@/pages/ScoringFormPage/lib/Enum/FieldNameEnum';

import FormDataInterface from '@/pages/ScoringFormPage/lib/Model/FormDataInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import ScoringFormPageServiceInterface from '@/pages/ScoringFormPage/lib/Contract/Service/ScoringFormPageServiceInterface';
import StateModelInterface from '@/pages/ScoringFormPage/lib/Model/StateModelInterface';
import ValidationHandlerInterface from '@/pages/ScoringFormPage/lib/Contract/Handler/ValidationHandlerInterface';

class ValidationHandler implements ValidationHandlerInterface {
  private readonly state: StateModelInterface;

  private readonly serviceContainer: IServiceContainer;

  private readonly scoringFormPageService: ScoringFormPageServiceInterface;

  private readonly validatorPlugin: IValidatorPlugin;

  constructor(
    state: StateModelInterface,
    serviceContainer: IServiceContainer,
    scoringFormPageService: ScoringFormPageServiceInterface,
    validatorPlugin: IValidatorPlugin,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.scoringFormPageService = scoringFormPageService;
    this.validatorPlugin = validatorPlugin;
  }

  validateField = (fieldName: FieldNameEnum, formData: FormDataInterface): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const { handlerService } = this.scoringFormPageService;

    const validator = this.getValidator(fieldName);

    if (!validator) {
      return;
    }

    validator.validate(formData[fieldName], formData);

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    validationStatusRegistry[fieldName] = this.validatorPlugin.getEmptyFieldStatusHandler().getStatus(validator);

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = handlerService.formConfigHandler.get(this.state);
  };

  validateFrom = (formData: FormDataInterface): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const { handlerService } = this.scoringFormPageService;

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    this.state.formConfig.forEach((block) => {
      if (block.type !== FormBlockTypeEnum.DELIMITER) {
        block.fields.forEach((field) => {
          const fieldName = field.fieldName as FieldNameEnum;
          const validator = this.getValidator(fieldName);

          if (!validator) {
            return;
          }

          if (block.conditionList) {
            if (!this.serviceContainer.utilsService.checkCondition.resolveAndCondition(block.conditionList, formData)) {
              validationStatusRegistry[fieldName] = {
                code: StatusCodeEnum.DEFAULT,
              };

              return;
            }
          }

          if (field.conditionList) {
            if (!this.serviceContainer.utilsService.checkCondition.resolveAndCondition(field.conditionList, formData)) {
              validationStatusRegistry[fieldName] = {
                code: StatusCodeEnum.DEFAULT,
              };

              return;
            }
          }

          validator.validate(
            formData[fieldName],
            formData,
          );

          validationStatusRegistry[fieldName] = this.validatorPlugin
            .getSimpleFieldStatusHandler().getStatus(validator);
        });
      }
    });

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = handlerService.formConfigHandler.get(this.state);
  };

  private getValidator = (fieldName: FieldNameEnum): IValidator | null => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const map: Partial<Record<FieldNameEnum, IValidator>> = {};

    map[FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.COLLABORATION_PREFERENCE] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.COLLABORATION_PREFERENCE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.PRODUCTS_OFFER] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.PRODUCTS_OFFER]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.ACCEPTS_CONTRACT_CHANGES] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.ACCEPTS_CONTRACT_CHANGES]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.EXCLUSIVE_CONTRACT_WITH_COMPANY] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.EXCLUSIVE_CONTRACT_WITH_COMPANY]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.DOCUMENTS] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.DOCUMENTS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.DOCUMENTS]?.addValidator(
      EValidatorType.FILE,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.INN] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.INN]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.INN]?.addValidator(
      EValidatorType.CUSTOM,
      this.state.t(EValidationMessageTokens.INN),
      (value: string) => [10, 12].includes(value.length),
    );
    map[FieldNameEnum.INN]?.addValidator(
      EValidatorType.ONLY_NUMBERS,
      this.state.t(EValidationMessageTokens.ONLY_NUMBERS),
      null,
    );

    map[FieldNameEnum.COMPANY_NAME] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.COMPANY_NAME]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.COMPANY_NAME]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: 128 }),
      128,
    );
    map[FieldNameEnum.COMPANY_NAME]?.addValidator(
      EValidatorType.REGEX,
      this.state.t(EValidationMessageTokens.ONLY_CYRILLIC_SYMBOLS),
      '^([^a-zA-Z]+)$',
    );

    map[FieldNameEnum.VARIANT_FIND_COMPANY] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.VARIANT_FIND_COMPANY]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.NUMBER_CONTRACT] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.NUMBER_CONTRACT]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.NUMBER_CONTRACT]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: 128 }),
      128,
    );

    map[FieldNameEnum.DATE_CONTRACT] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.DATE_CONTRACT]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.LEGAL_ENTITY] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.LEGAL_ENTITY]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.LEGAL_ENTITY]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: 128 }),
      128,
    );
    map[FieldNameEnum.LEGAL_ENTITY]?.addValidator(
      EValidatorType.REGEX,
      this.state.t(EValidationMessageTokens.ONLY_CYRILLIC_SYMBOLS),
      '^([^a-zA-Z]+)$',
    );

    map[FieldNameEnum.CONTRACT_COPY] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.CONTRACT_COPY]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.CONTRACT_COPY]?.addValidator(
      EValidatorType.FILE,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.WEBSITE] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.WEBSITE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.WEBSITE]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: 255 }),
      255,
    );
    map[FieldNameEnum.WEBSITE]?.addValidator(
      EValidatorType.REGEX,
      this.state.t(EValidationMessageTokens.URL_FORMAT),
      '^.+\\..+$',
    );

    map[FieldNameEnum.ANNUAL_TURNOVER] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.ANNUAL_TURNOVER]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.EXPECTED_ANNUAL_TURNOVER] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.EXPECTED_ANNUAL_TURNOVER]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.NUMBER_OF_EMPLOYEES] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.NUMBER_OF_EMPLOYEES]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.WORK_DIRECTION] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.WORK_DIRECTION]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.WORK_DIRECTION]?.addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      (value: SChipsItemInterface[]) => value.filter(
        (item) => item.checked,
      ).length > 0,
    );

    map[FieldNameEnum.HAS_COPYRIGHT_DOCUMENTS] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.HAS_COPYRIGHT_DOCUMENTS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.PRODUCT_TYPE] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.PRODUCT_TYPE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.PRODUCT_TYPE]?.addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      (value: SChipsItemInterface[]) => value.filter(
        (item) => item.checked,
      ).length > 0,
    );

    map[FieldNameEnum.CUSTOMER_INTERACTION] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.CUSTOMER_INTERACTION]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.TARGET_AUDIENCE] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.TARGET_AUDIENCE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.INTERESTED_SERVICES] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.INTERESTED_SERVICES]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[FieldNameEnum.INTERESTED_SERVICES]?.addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      (value: SChipsItemInterface[]) => value.filter(
        (item) => item.checked,
      ).length > 0,
    );

    map[FieldNameEnum.HAS_PARTNER_PROGRAM] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.HAS_PARTNER_PROGRAM]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    map[FieldNameEnum.MARKETING_BUDGET] = this.validatorPlugin.getValidator();
    map[FieldNameEnum.MARKETING_BUDGET]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );

    return map[fieldName] ?? null;
  };
}

export default ValidationHandler;
