<template>
  <div
    class="c-text-input-list"
  >
    <div
      class="c-text-input-list__list"
    >
      <div
        v-for="(item, index) in state.list"
        :key="index"
        class="c-text-input-list__item"
      >
        <slot
          :index="index"
          :item="item"
          :status="getStatus(item)"
          name="before-item"
        />
        <div class="c-text-input-list__input">
          <slot
            :index="index"
            :item="item"
            :status="getStatus(item)"
            name="before-input"
          />
          <STextInput
            :model-value="item.value"
            :color="getFieldColor(item)"
            :placeholder="placeholder"
            :input-delay="inputDelay"
            @blur="handleBlur($event, item)"
          />
          <slot
            :index="index"
            :item="item"
            :status="getStatus(item)"
            name="after-input"
          />
          <SIcon
            v-if="state.list.length > minItems"
            class="c-text-input-list__icon"
            name="trash"
            size="l"
            @click="handleDeleteItem(item)"
          />
        </div>
        <slot
          :index="index"
          :item="item"
          :status="getStatus(item)"
          name="after-item"
        />
        <STextElement
          v-if="getStatusText(item)"
          :color="getFieldColor(item)"
          class="c-text-input-list__comment"
          variant="small"
        >
          {{ getStatusText(item) }}
        </STextElement>
      </div>
    </div>
    <div
      v-if="addAvailable"
      class="c-text-input-list__footer"
    >
      <SButton
        :text="buttonAdd.text"
        :variant="buttonAdd.variant"
        @click="buttonAdd.handler()"
      >
        <template
          #icon-before
        >
          <SIcon
            :color="buttonAdd.icon.color"
            :name="buttonAdd.icon.name"
          />
        </template>
      </SButton>
    </div>
  </div>
</template>

<script
  lang="ts"
>

import {
  computed, defineComponent, onMounted, PropType, reactive, watch,
} from 'vue';

import ColorEnum from '../../../lib/Enum/ColorEnum';
import CTextInputListItemInterface from '../../../lib/Model/Components/Complex/CTextInputList/CTextInputListItemInterface';
import CTextInputListItemStatusInterface from '../../../lib/Model/Components/Complex/CTextInputList/CTextInputListItemStatusInterface';
import IconNameEnum from '../../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import SButtonVariantEnum from '../../../lib/Enum/Components/Simple/UI/SButtonVariantEnum';

import SIcon from '../../Simple/Icon/SIcon.vue';
import SButton from '../../Simple/UI/SButton.vue';
import STextInput from '../../Simple/UI/STextInput.vue';
import STextElement from '../../Simple/Text/STextElement.vue';

export default defineComponent({
  name: 'CTextInputList',
  components: {
    STextElement,
    STextInput,
    SButton,
    SIcon,
  },
  props: {
    modelValue: {
      type: Array as PropType<CTextInputListItemInterface[]>,
      required: true,
      default: () => [],
    },
    minItems: {
      type: Number,
      default: 0,
    },
    maxItems: {
      type: Number,
      default: 0,
    },
    addButtonText: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    statuses: {
      type: Array as PropType<CTextInputListItemStatusInterface[]>,
      default: () => [],
    },
    inputDelay: {
      type: Number,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const state = reactive<{
      list: CTextInputListItemInterface[];
    }>({
      list: [],
    });

    const addAvailable = computed(() => props.maxItems === 0 || state.list.length < props.maxItems);

    const buttonAdd = computed(() => ({
      text: props.addButtonText,
      variant: SButtonVariantEnum.TRANSPARENT,
      icon: {
        name: IconNameEnum.PLUS,
        color: ColorEnum.PRIMARY,
      },
      handler: () => {
        const idList = state.list.map((item) => item.id) ?? [0];
        const id = Math.max(...idList) + 1;

        state.list.push({
          id,
          value: '',
        });
        emit('text-input-list-add', state.list);
      },
    }));

    watch(() => props.modelValue, (newValue) => {
      state.list = [...newValue];
    });

    onMounted(() => {
      state.list = props.modelValue.length > 0 ? [...props.modelValue] : state.list;
    });

    function getFieldColor(item: CTextInputListItemInterface): ColorEnum {
      return props.statuses.find((status) => status.id === item.id)?.color ?? ColorEnum.PRIMARY;
    }

    function getStatusText(item: CTextInputListItemInterface): string {
      return props.statuses.find((status) => status.id === item.id)?.text ?? '';
    }

    function getStatus(item: CTextInputListItemInterface): CTextInputListItemStatusInterface {
      return props.statuses.find((status) => status.id === item.id) ?? {} as CTextInputListItemStatusInterface;
    }

    function handleBlur(event: FocusEvent, currentItem: CTextInputListItemInterface) {
      const target = event.target as HTMLInputElement | undefined;
      const index = state.list.findIndex((item) => item.id === currentItem.id) ?? 0;

      state.list[index].value = target?.value ?? '';
      emit('text-input-list-input', state.list);
    }

    function handleDeleteItem(itemToDelete: CTextInputListItemInterface) {
      state.list = [...state.list.filter((item) => item.id !== itemToDelete.id)];
      emit('text-input-list-delete', state.list);
    }

    return {
      state,
      addAvailable,
      buttonAdd,
      getFieldColor,
      getStatus,
      getStatusText,
      handleBlur,
      handleDeleteItem,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.c-text-input-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 20px;

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    gap: 20px;
  }

  &__icon {
    flex: 0 0 auto;
    cursor: pointer;
  }

  &__comment {
    margin-top: 8px;
  }
}
</style>
