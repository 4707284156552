import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2122f084"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M6.336 12.7308L12.7008 6.36597C13.0073 6.05954 13.3712 5.81649 13.7716 5.65068C14.172 5.48487 14.6012 5.39956 15.0346 5.39962C15.468 5.39967 15.8972 5.48509 16.2976 5.651C16.698 5.81691 17.0618 6.06006 17.3682 6.36657C17.6746 6.67307 17.9177 7.03693 18.0835 7.43737C18.2493 7.83781 18.3346 8.26698 18.3345 8.70039C18.3345 9.1338 18.2491 9.56295 18.0832 9.96335C17.9173 10.3637 17.6741 10.7275 17.3676 11.034L9.7308 18.6708C9.4493 18.9523 9.0675 19.1104 8.6694 19.1104C8.2713 19.1104 7.8895 18.9523 7.608 18.6708C7.3265 18.3893 7.16835 18.0075 7.16835 17.6094C7.16835 17.2113 7.3265 16.8295 7.608 16.548L14.3964 9.75957C14.5554 9.58896 14.6419 9.3633 14.6378 9.13014C14.6337 8.89698 14.5392 8.67452 14.3743 8.50962C14.2094 8.34473 13.987 8.25027 13.7538 8.24616C13.5207 8.24204 13.295 8.32859 13.1244 8.48757L6.336 15.276C5.71698 15.895 5.36922 16.7345 5.36922 17.61C5.36922 18.4854 5.71698 19.325 6.336 19.944C6.95501 20.563 7.79458 20.9107 8.67 20.9107C9.54542 20.9107 10.385 20.563 11.004 19.944L18.6396 12.306C19.5776 11.3458 20.0993 10.0546 20.0915 8.71227C20.0837 7.36998 19.547 6.08488 18.5978 5.13572C17.6487 4.18656 16.3636 3.64987 15.0213 3.64205C13.679 3.63424 12.3877 4.15593 11.4276 5.09397L5.064 11.4576C4.90502 11.6282 4.81848 11.8538 4.82259 12.087C4.8267 12.3202 4.92116 12.5426 5.08605 12.7075C5.25095 12.8724 5.47341 12.9669 5.70657 12.971C5.93973 12.9751 6.16539 12.8885 6.336 12.7296V12.7308Z"
    }, null, 2)
  ]))
}