import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22e3d87e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "s-link__icon"
}
const _hoisted_2 = {
  key: 3,
  class: "s-link__icon"
}
const _hoisted_3 = ["download", "href", "target"]
const _hoisted_4 = {
  key: 0,
  class: "s-link__icon"
}
const _hoisted_5 = {
  key: 3,
  class: "s-link__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isRouterLink)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: _normalizeClass(_ctx.classes),
        to: _ctx.url,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
      }, {
        default: _withCtx(() => [
          (_ctx.isIconBeforeSlot)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _renderSlot(_ctx.$slots, "icon-before", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isDefaultSlot)
            ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
            : (_openBlock(), _createBlock(_component_STextElement, {
                key: 2,
                color: _ctx.color,
                variant: _ctx.variant,
                class: "s-link__text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }, 8, ["color", "variant"])),
          (_ctx.isIconAfterSlot)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _renderSlot(_ctx.$slots, "icon-after", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class", "to"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        class: _normalizeClass(_ctx.classes),
        download: _ctx.download,
        href: _ctx.url,
        target: _ctx.target,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click', $event)))
      }, [
        (_ctx.isIconBeforeSlot)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _renderSlot(_ctx.$slots, "icon-before", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isDefaultSlot)
          ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
          : (_openBlock(), _createBlock(_component_STextElement, {
              key: 2,
              color: _ctx.color,
              variant: _ctx.variant,
              class: "s-link__text"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }, 8, ["color", "variant"])),
        (_ctx.isIconAfterSlot)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _renderSlot(_ctx.$slots, "icon-after", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_3))
}