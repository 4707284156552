<template>
  <component
    :is="blockName"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import ColorEnum from '../../../lib/Enum/ColorEnum';

export default defineComponent({
  name: 'SHeader',
  props: {
    size: {
      type: Number,
      default: 1,
    },
    color: {
      type: String as PropType<Exclude<ColorEnum, ColorEnum.TOOLBAR>>,
      default: ColorEnum.BLACK,
    },
  },
  setup(props) {
    const blockName = computed(() => `h${props.size}`);

    const classes = computed(() => [
      's-header',
      `s-header_${blockName.value}`,
      `s-header_color-${props.color}`,
    ]);

    return {
      classes,
      blockName,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-header {
  margin: 0;
  font-family: var(--header-font-family);

  &_h1 {
    font-size: var(--h1-variant-desktop-size);
    line-height: var(--h1-variant-desktop-line-height);
    font-weight: var(--h1-variant-desktop-weight);
  }

  &_h2 {
    font-size: var(--h2-variant-desktop-size);
    line-height: var(--h2-variant-desktop-line-height);
    font-weight: var(--h2-variant-desktop-weight);
  }

  &_h3 {
    font-size: var(--h3-variant-desktop-size);
    line-height: var(--h3-variant-desktop-line-height);
    font-weight: var(--h3-variant-desktop-weight);
  }

  &_color-disabled {
    color: var(--color-disabled);
  }

  &_color-danger {
    color: var(--color-danger);
  }

  &_color-gray {
    color: var(--color-gray);
  }

  &_color-linked {
    color: var(--color-linked);
  }

  &_color-navigation {
    color: var(--color-navigation);
  }

  &_color-primary {
    color: var(--color-primary);
  }

  &_color-secondary {
    color: var(--color-secondary);
  }

  &_color-success {
    color: var(--color-success);
  }

  &_color-warn {
    color: var(--color-warn);
  }

  &_color-white {
    color: var(--color-white);
  }

  &_color-black {
    color: var(--color-black);
  }
}
</style>
