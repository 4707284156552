enum ColorEnum {
  DISABLED = 'disabled',
  DANGER = 'danger',
  GRAY = 'gray',
  LINKED = 'linked',
  NAVIGATION = 'navigation',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  TOOLBAR = 'toolbar',
  WARN = 'warn',
  WHITE = 'white',
  BLACK = 'black',
  STROKE = 'stroke',
}

export default ColorEnum;
