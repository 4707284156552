enum EFieldName {
  PUBLIC_NAME_COMPANY = 'publicNameCompany',
  KEYWORDS = 'keywords',
  WEBSITE = 'website',
  WHO_CAN_BE_REPLACED = 'whoCanBeReplaced',
  PRODUCT_DESCRIPTIONS_FUNCTIONS = 'productDescriptionsFunctions',
  LIST_OF_REGISTRIES = 'listOfRegistries',
  LIST_OF_CERTIFICATION_SYSTEMS = 'listOfCertificationSystems',
  DIRECT_CONTACTS = 'directContacts',
  IMPLEMENTATION_CASES = 'implementationCases',
  COMMENT = 'comment',
  DOCUMENTS = 'documents',
}

export default EFieldName;
