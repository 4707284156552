function lowerCaseProtocol(url: string): string {
  const parts: string[] = url.split(':');

  if (parts.length === 1) {
    return url;
  }

  return `${(parts.shift() ?? '').toLowerCase()}:${parts.join(':')}`;
}

export default lowerCaseProtocol;
