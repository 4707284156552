import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SetupPage = _resolveComponent("SetupPage")!
  const _component_ServicesPage = _resolveComponent("ServicesPage")!

  return (_ctx.vendor.levelPartnerProgram === null)
    ? (_openBlock(), _createBlock(_component_SetupPage, {
        key: 0,
        vendor: _ctx.vendor
      }, null, 8, ["vendor"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ServicesPage, {
          "partner-program-level": _ctx.vendor.levelPartnerProgram
        }, null, 8, ["partner-program-level"])
      ]))
}