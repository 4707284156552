<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      d="M19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 19C19 19.55 18.55 20 18 20H6C5.45 20 5 19.55 5 19V9H19V19ZM7 11H9V13H7V11ZM11 11H13V13H11V11ZM15 11H17V13H15V11Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconCalendar',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-calendar_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-calendar {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

  &_color-stroke {
    fill: var(--color-stroke);
  }
}
</style>
<style scoped>

</style>
