import FormBlockType from '@/widgets/FormComponent/lib/Model/FormBlockType';
import FormBlockTypeEnum from '@/widgets/FormComponent/lib/Enum/FormBlockTypeEnum';
import FieldTypeEnum from '@/widgets/FormComponent/lib/Enum/FieldTypeEnum';
import CChipsGroupModeEnum
  from '@/shared/DesignSystem/lib/Enum/Components/Complex/CChipsGroupModeEnum';
import {
  ColorEnum,
  FileErrorTypeEnum,
  FileMimeTypesEnum,
  getFileUploadOptions,
  IconNameEnum,
  SChipsItemInterface,
  FileErrorMessagesType,
} from '@/shared/DesignSystem/index';
import SButtonVariantEnum
  from '@/shared/DesignSystem/lib/Enum/Components/Simple/UI/SButtonVariantEnum';
import SizeEnum from '@/shared/DesignSystem/lib/Enum/SizeEnum';
import { ConditionTypeEnum } from '@/widgets/FormComponent';
import EPriceListVariant from '@/pages/Service/PriceList/PriceListFormPage/Enum/EPriceListVariant';
import EFieldName from '@/pages/Service/PriceList/PriceListFormPage/Enum/EFieldName';
import IState from '@/pages/Service/PriceList/PriceListFormPage/Model/IState';
import IFormConfigHandler
  from '@/pages/Service/PriceList/PriceListFormPage/Contract/Handler/IFormConfigHandler';

class FormConfigHandler implements IFormConfigHandler {
  get = (
    state: IState,
  ): Array<FormBlockType> => [
    {
      type: FormBlockTypeEnum.BLOCK,
      subTitle: null,
      title: null,
      fields: [
        {
          type: FieldTypeEnum.CHIPS_GROUP,
          fieldName: EFieldName.VARIANT,
          config: {
            label: state.t('pages.pricelist.form.fields.variant.label'),
            mode: CChipsGroupModeEnum.SINGLE,
            list: this.getList(state),
          },
          status: state.validationStatusRegistry[EFieldName.VARIANT],
        },
        {
          type: FieldTypeEnum.FILE_UPLOAD,
          fieldName: EFieldName.DOCUMENTS,
          config: {
            label: state.t('pages.pricelist.form.fields.documents.label'),
            options: this.getDocumentOptions(state),
          },
          status: state.validationStatusRegistry[EFieldName.DOCUMENTS],
          conditionList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.VARIANT,
                '(find:id=file)',
                'checked',
              ].join('.'),
            },
          ],
        },
        {
          type: FieldTypeEnum.TEXT_INPUT,
          fieldName: EFieldName.LINK,
          config: {
            placeholder: state.t('pages.pricelist.form.fields.link.placeholder'),
            label: state.t('pages.pricelist.form.fields.link.label'),
            description: state.t('pages.pricelist.form.fields.link.description'),
          },
          status: state.validationStatusRegistry[EFieldName.LINK],
          conditionList: [
            {
              type: ConditionTypeEnum.EQUALS,
              value: true,
              field: [
                EFieldName.VARIANT,
                '(find:id=link)',
                'checked',
              ].join('.'),
            },
          ],
        },
        {
          type: FieldTypeEnum.TEXT_AREA,
          fieldName: EFieldName.COMMENT,
          config: {
            placeholder: state.t('pages.pricelist.form.fields.comment.placeholder'),
            label: state.t('pages.pricelist.form.fields.comment.label'),
            description: state.t('pages.pricelist.form.fields.comment.description'),
            minRowsCount: 5,
            maxRowsCount: 10,
            maxLength: 3000,
          },
          status: state.validationStatusRegistry[EFieldName.COMMENT],
        },
      ],
    },
  ];

  private getList(
    state: IState,
  ): Array<SChipsItemInterface> {
    const defaultList = [
      {
        id: EPriceListVariant.FILE,
        title: state.t('pages.pricelist.form.fields.variant.values.file'),
        checked: false,
      },
      {
        id: EPriceListVariant.LINK,
        title: state.t('pages.pricelist.form.fields.variant.values.link'),
        checked: false,
      },
    ];

    const formDataVariant = (state.formData.variant ?? []) as Array<SChipsItemInterface>;

    return formDataVariant.length > 0 ? formDataVariant : defaultList;
  }

  private getDocumentOptions(state: IState) {
    const fileErrorMessagesTokens: Record<FileErrorTypeEnum, string> = {
      [FileErrorTypeEnum.MAX_SIZE]: 'pages.pricelist.form.fields.documents.errors.invalid_file_size',
      [FileErrorTypeEnum.MAX_TOTAL_SIZE]: 'pages.pricelist.form.fields.documents.errors.invalid_total_file_size',
      [FileErrorTypeEnum.NO_ZERO_SIZE]: 'pages.pricelist.form.fields.documents.errors.no_zero_file_size',
      [FileErrorTypeEnum.MIME_TYPE]: 'pages.pricelist.form.fields.documents.errors.invalid_file_mime_type',
      [FileErrorTypeEnum.LOAD]: 'pages.pricelist.form.fields.documents.errors.load_error',
      [FileErrorTypeEnum.NAME]: 'pages.pricelist.form.fields.documents.errors.invalid_file_name',
    };

    const fileErrorMessages: FileErrorMessagesType = {
      [FileErrorTypeEnum.MAX_SIZE]: state.t(fileErrorMessagesTokens[FileErrorTypeEnum.MAX_SIZE]),
      [FileErrorTypeEnum.MAX_TOTAL_SIZE]: state.t(fileErrorMessagesTokens[FileErrorTypeEnum.MAX_TOTAL_SIZE]),
      [FileErrorTypeEnum.NO_ZERO_SIZE]: state.t(fileErrorMessagesTokens[FileErrorTypeEnum.NO_ZERO_SIZE]),
      [FileErrorTypeEnum.MIME_TYPE]: state.t(fileErrorMessagesTokens[FileErrorTypeEnum.MIME_TYPE]),
      [FileErrorTypeEnum.LOAD]: state.t(fileErrorMessagesTokens[FileErrorTypeEnum.LOAD]),
      [FileErrorTypeEnum.NAME]: state.t(fileErrorMessagesTokens[FileErrorTypeEnum.NAME]),
    };

    const mimeTypes = [
      FileMimeTypesEnum.JPG,
      FileMimeTypesEnum.JPEG,
      FileMimeTypesEnum.DOC,
      FileMimeTypesEnum.DOCX,
      FileMimeTypesEnum.PDF,
      FileMimeTypesEnum.PNG,
      FileMimeTypesEnum.XLS,
      FileMimeTypesEnum.XLSX,
      FileMimeTypesEnum.GIF,
    ];

    const fileUploadOptions = getFileUploadOptions(
      fileErrorMessages,
      {
        variant: SButtonVariantEnum.TERTIARY,
        text: state.t('pages.pricelist.form.fields.documents.upload_button'),
        size: SizeEnum.M,
      },
      {
        name: IconNameEnum.PAPER_CLIP,
        color: ColorEnum.PRIMARY,
      },
      state.t('pages.pricelist.form.fields.documents.description'),
      state.t('pages.pricelist.form.fields.documents.placeholder'),
      mimeTypes,
      100,
      100,
      255,
      true,
    );

    return { ...fileUploadOptions };
  }
}

export default FormConfigHandler;
