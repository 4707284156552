<template>
  <svg
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      d="M16.0008 2C9.8761 10.75 9.07253 18.5046 16.0739 29.8796M16.0008 2C22.1255 10.75 23.0013 18.625 16 30M16.0008 2C8.26881 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30M16.0008 2C23.7328 2 30 8.26801 30 16C30 23.732 23.732 30 16 30M3.87315 9L28.1273 9M2.875 16.875L29.125 16.875M5.5 24.75L26.9294 24.75"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconGlobe',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-globe_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-globe {
  &_color-disabled {
    stroke: var(--color-disabled);
  }

  &_color-danger {
    stroke: var(--color-danger);
  }

  &_color-gray {
    stroke: var(--color-gray);
  }

  &_color-linked {
    stroke: var(--color-linked);
  }

  &_color-navigation {
    stroke: var(--color-navigation);
  }

  &_color-primary {
    stroke: var(--color-primary);
  }

  &_color-secondary {
    stroke: var(--color-secondary);
  }

  &_color-success {
    stroke: var(--color-success);
  }

  &_color-toolbar {
    stroke: var(--color-toolbar);
  }

  &_color-warn {
    stroke: var(--color-warn);
  }

  &_color-white {
    stroke: var(--color-white);
  }

  &_color-black {
    stroke: var(--color-black);
  }

}
</style>
