enum FontVariantEnum {
  SUBHEADER = 'subheader',
  MEDIUM = 'medium',
  REGULAR = 'regular',
  ITALIC = 'italic',
  SMALL = 'small',
  SMALL_MEDIUM = 'small-medium',
  SMALL_LONG_LINE = 'small-long-line',
  X_SMALL_MEDIUM = 'x-small-medium',
}

export default FontVariantEnum;
