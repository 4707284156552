<template>
  <div
    class="layout-with-sidebar-content-header"
  >
    <div
      v-if="isHeaderLeftSlot || title"
      class="layout-with-sidebar-content-header__block layout-with-sidebar-content-header__block_left"
    >
      <SHeader
        v-if="title"
        class="layout-with-sidebar-content-header__title"
        :size="1"
      >
        {{ title }}
      </SHeader>
      <slot
        name="header-left"
      />
    </div>
    <div
      v-if="isHeaderRightSlot"
      class="layout-with-sidebar-content-header__block layout-with-sidebar-content-header__block_right"
    >
      <slot
        name="header-right"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
} from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const isHeaderLeftSlot = computed(() => !!slots['header-left']);
    const isHeaderRightSlot = computed(() => !!slots['header-right']);

    return {
      isHeaderLeftSlot,
      isHeaderRightSlot,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.layout-with-sidebar-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__block {
    display: flex;
    align-items: center;
    gap: 10px;

    &_left {}

    &_right {
      justify-content: flex-end;
    }
  }
}
</style>
