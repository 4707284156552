import { App } from '@vue/runtime-core';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import utilsService from '@/app/Service/UtilsService';

import FormComponentService from './FormComponentService';

import ConditionTypeEnum from './lib/Enum/ConditionTypeEnum';
import FieldTypeEnum from './lib/Enum/FieldTypeEnum';
import FormBlockTypeEnum from './lib/Enum/FormBlockTypeEnum';

import CChipsGroupFieldConfigInterface from './lib/Model/Fields/CChipsGroupFieldConfigInterface';
import ChipsGroupChangeDataModelInterface from './lib/Model/ChipsGroupChangeDataModelInterface';
import ConditionModelType from './lib/Model/Condition/ConditionModelType';
import CustomInfoBlockConfigInterface from './lib/Model/Fields/CustomInfoBlockConfigInterface';
import DateFieldBlurDataModelInterface from './lib/Model/DateFieldBlurDataModelInterface';
import DateFieldConfigInterface from './lib/Model/Fields/DateFieldConfigInterface';
import FieldConfigInterface from './lib/Model/FieldConfigInterface';
import FileUploadFieldConfigInterface from './lib/Model/Fields/FileUploadFieldConfigInterface';
import FileUploadInputDataModelInterface from './lib/Model/FileUploadInputDataModelInterface';
import MaskedInputFieldConfigInterface from './lib/Model/Fields/MaskedInputFieldConfigInterface';
import MultipleSelectFieldConfigInterface from './lib/Model/Fields/MultipleSelectFieldConfigInterface';
import MultipleSelectInputDataModelInterface from './lib/Model/MultipleSelectInputDataModelInterface';
import StringFieldBlurDataModelInterface from './lib/Model/StringFieldBlurDataModelInterface';
import TextAreaFieldConfigInterface from './lib/Model/Fields/TextAreaFieldConfigInterface';
import TextInputFieldConfigInterface from './lib/Model/Fields/TextInputFieldConfigInterface';
import TextInputListFieldConfigInterface from './lib/Model/Fields/TextInputListConfigInterface';
import TextInputListInputDataModelInterface from './lib/Model/TextInputListInputDataModelInterface';

import FieldConfigType from './lib/Model/Fields/FieldConfigType';
import FormBlockType from './lib/Model/FormBlockType';

export default {
  install: (
    app: App,
    options: { serviceContainer: IServiceContainer },
  ) => {
    const formComponentService = new FormComponentService(options.serviceContainer);

    app.use(utilsService);

    app.config.globalProperties.$formComponent = formComponentService;

    app.provide('formComponentService', formComponentService);
  },
};

export {
  CChipsGroupFieldConfigInterface,
  ChipsGroupChangeDataModelInterface,
  ConditionModelType,
  ConditionTypeEnum,
  CustomInfoBlockConfigInterface,
  DateFieldBlurDataModelInterface,
  DateFieldConfigInterface,
  FieldConfigInterface,
  FieldConfigType,
  FieldTypeEnum,
  FileUploadFieldConfigInterface,
  FileUploadInputDataModelInterface,
  FormBlockType,
  FormBlockTypeEnum,
  MaskedInputFieldConfigInterface,
  MultipleSelectFieldConfigInterface,
  MultipleSelectInputDataModelInterface,
  StringFieldBlurDataModelInterface,
  TextAreaFieldConfigInterface,
  TextInputFieldConfigInterface,
  TextInputListFieldConfigInterface,
  TextInputListInputDataModelInterface,
};
