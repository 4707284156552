import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServiceInfo = _resolveComponent("ServiceInfo")!
  const _component_LayoutWithSidebar = _resolveComponent("LayoutWithSidebar")!

  return (_openBlock(), _createBlock(_component_LayoutWithSidebar, {
    title: _ctx.state.localisation.title
  }, {
    content: _withCtx(() => [
      _createVNode(_component_ServiceInfo, {
        content: _ctx.state.localisation.content
      }, null, 8, ["content"])
    ]),
    _: 1
  }, 8, ["title"]))
}