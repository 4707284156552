import FormConfigHandlerInterface from '@/pages/ScoringFormPage/lib/Contract/Handler/FormConfigHandlerInterface';
import FormBlockType from '@/widgets/FormComponent/lib/Model/FormBlockType';
import FormBlockTypeEnum from '@/widgets/FormComponent/lib/Enum/FormBlockTypeEnum';
import FieldTypeEnum from '@/widgets/FormComponent/lib/Enum/FieldTypeEnum';
import FieldNameEnum from '@/pages/ScoringFormPage/lib/Enum/FieldNameEnum';
import StateModelInterface from '@/pages/ScoringFormPage/lib/Model/StateModelInterface';
import {
  CChipsGroupModeEnum,
  ColorEnum,
  FileErrorMessagesType,
  FileErrorTypeEnum,
  FileExtensionsEnum,
  FileMimeTypesEnum,
  getFileUploadOptions,
  IconNameEnum,
  SButtonVariantEnum,
  SChipsItemInterface,
  SizeEnum,
} from '@/shared/DesignSystem/index';
import {
  ConditionModelType,
  ConditionTypeEnum,
} from '@/widgets/FormComponent';
import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';

class FormConfigHandler implements FormConfigHandlerInterface {
  get = (
    state: StateModelInterface,
  ): Array<FormBlockType> => {
    const isSupplierChips = state.formData.hasContractWithSoftline.find((item) => item.checked);
    const isSupplier = isSupplierChips?.id === 'supply_contract';
    const aboutCompanyTitle = isSupplier ? '' : state.t('pages.scoring_form.blocks.about_company');

    const conditionMap: Record<string, ConditionModelType> = {
      contractNotEqualSupplyContract: {
        type: ConditionTypeEnum.AND,
        conditionsList: [
          {
            type: ConditionTypeEnum.NOT_EQUALS,
            value: true,
            field: [
              FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
              '(find:id=supply_contract)',
              'checked',
            ].join('.'),
          },
          {
            type: ConditionTypeEnum.NOT_EMPTY,
            field: FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
          },
        ],
      },
      contractEqualSupplyContract: {
        type: ConditionTypeEnum.EQUALS,
        value: true,
        field: [
          FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
          '(find:id=supply_contract)',
          'checked',
        ].join('.'),
      },
    };

    return [
      {
        type: FormBlockTypeEnum.BLOCK,
        subTitle: null,
        title: state.t('pages.scoring_form.blocks.conclusion_of_an_agreement'),
        fields: [
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
            config: {
              label: state.t('pages.scoring_form.fields.has_contract_with_softline.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE),
            },
            status: state.validationStatusRegistry[FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.COLLABORATION_PREFERENCE,
            config: {
              label: state.t('pages.scoring_form.fields.collaboration_preference.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.COLLABORATION_PREFERENCE),
            },
            status: state.validationStatusRegistry[FieldNameEnum.COLLABORATION_PREFERENCE],
            conditionList: [
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
                  '(find:id=no_contract)',
                  'checked',
                ].join('.'),
              },
            ],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.PRODUCTS_OFFER,
            config: {
              label: state.t('pages.scoring_form.fields.products_offer.frontend.label'),
              mode: CChipsGroupModeEnum.MULTIPLE,
              list: this.getList(state, FieldNameEnum.PRODUCTS_OFFER),
            },
            status: state.validationStatusRegistry[FieldNameEnum.PRODUCTS_OFFER],
            conditionList: [
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
                  '(find:id=no_contract)',
                  'checked',
                ].join('.'),
              },
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.COLLABORATION_PREFERENCE,
                  '(find:id=softline)',
                  'checked',
                ].join('.'),
              },
            ],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.ACCEPTS_CONTRACT_CHANGES,
            config: {
              label: state.t('pages.scoring_form.fields.accepts_contract_changes.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.ACCEPTS_CONTRACT_CHANGES),
            },
            status: state.validationStatusRegistry[FieldNameEnum.ACCEPTS_CONTRACT_CHANGES],
            conditionList: [
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
                  '(find:id=no_contract)',
                  'checked',
                ].join('.'),
              },
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.COLLABORATION_PREFERENCE,
                  '(find:id=only_yours)',
                  'checked',
                ].join('.'),
              },
            ],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.EXCLUSIVE_CONTRACT_WITH_COMPANY,
            config: {
              label: state.t('pages.scoring_form.fields.exclusive_contract_with_company.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.EXCLUSIVE_CONTRACT_WITH_COMPANY),
            },
            status: state.validationStatusRegistry[FieldNameEnum.EXCLUSIVE_CONTRACT_WITH_COMPANY],
            conditionList: [
              conditionMap.contractNotEqualSupplyContract,
            ],
          },
          {
            type: FieldTypeEnum.FILE_UPLOAD,
            fieldName: FieldNameEnum.DOCUMENTS,
            config: {
              label: state.t('pages.scoring_form.fields.documents.frontend.label'),
              options: this.getDocumentOptions(state, FieldNameEnum.DOCUMENTS),
            },
            status: state.validationStatusRegistry[FieldNameEnum.DOCUMENTS],
            conditionList: [
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
                  '(find:id=no_contract)',
                  'checked',
                ].join('.'),
              },
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.COLLABORATION_PREFERENCE,
                  '(find:id=only_yours)',
                  'checked',
                ].join('.'),
              },
            ],
          },
        ],
      },
      {
        type: FormBlockTypeEnum.BLOCK,
        subTitle: null,
        title: aboutCompanyTitle,
        fields: [
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.VARIANT_FIND_COMPANY,
            config: {
              label: state.t('pages.scoring_form.fields.how_find_company.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.VARIANT_FIND_COMPANY),
            },
            status: state.validationStatusRegistry[FieldNameEnum.VARIANT_FIND_COMPANY],
            conditionList: [
              conditionMap.contractEqualSupplyContract,
            ],
          },
          {
            type: FieldTypeEnum.FILE_UPLOAD,
            fieldName: FieldNameEnum.CONTRACT_COPY,
            config: {
              label: state.t('pages.scoring_form.fields.contract_copy.frontend.label'),
              options: this.getDocumentOptions(state, FieldNameEnum.CONTRACT_COPY),
            },
            status: state.validationStatusRegistry[FieldNameEnum.CONTRACT_COPY],
            conditionList: [
              conditionMap.contractEqualSupplyContract,
              {
                type: ConditionTypeEnum.EQUALS,
                value: true,
                field: [
                  FieldNameEnum.VARIANT_FIND_COMPANY,
                  '(find:id=copy_contract)',
                  'checked',
                ].join('.'),
              },
            ],
          },
          {
            type: FieldTypeEnum.TEXT_INPUT,
            fieldName: FieldNameEnum.INN,
            config: {
              placeholder: state.t('pages.scoring_form.fields.inn.frontend.placeholder'),
              label: state.t('pages.scoring_form.fields.inn.frontend.label'),
              description: state.t('pages.scoring_form.fields.inn.frontend.description'),
            },
            status: state.validationStatusRegistry[FieldNameEnum.INN],
            conditionList: [
              {
                type: ConditionTypeEnum.OR,
                conditionsList: [
                  conditionMap.contractNotEqualSupplyContract,
                  {
                    type: ConditionTypeEnum.AND,
                    conditionsList: [
                      conditionMap.contractEqualSupplyContract,
                      {
                        type: ConditionTypeEnum.EQUALS,
                        value: true,
                        field: [
                          FieldNameEnum.VARIANT_FIND_COMPANY,
                          '(find:id=company_details)',
                          'checked',
                        ].join('.'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: FieldTypeEnum.TEXT_INPUT,
            fieldName: FieldNameEnum.COMPANY_NAME,
            config: {
              placeholder: state.t('pages.scoring_form.fields.company_name.frontend.placeholder'),
              label: state.t('pages.scoring_form.fields.company_name.frontend.label'),
            },
            status: state.validationStatusRegistry[FieldNameEnum.COMPANY_NAME],
            conditionList: [
              {
                type: ConditionTypeEnum.OR,
                conditionsList: [
                  conditionMap.contractNotEqualSupplyContract,
                  {
                    type: ConditionTypeEnum.AND,
                    conditionsList: [
                      conditionMap.contractEqualSupplyContract,
                      {
                        type: ConditionTypeEnum.EQUALS,
                        value: true,
                        field: [
                          FieldNameEnum.VARIANT_FIND_COMPANY,
                          '(find:id=company_details)',
                          'checked',
                        ].join('.'),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: FieldTypeEnum.TEXT_INPUT,
            fieldName: FieldNameEnum.NUMBER_CONTRACT,
            config: {
              placeholder: state.t('pages.scoring_form.fields.contract_number.frontend.placeholder'),
              label: state.t('pages.scoring_form.fields.contract_number.frontend.label'),
              description: state.t('pages.scoring_form.fields.contract_number.frontend.description'),
            },
            status: state.validationStatusRegistry[FieldNameEnum.NUMBER_CONTRACT],
            conditionList: [
              {
                type: ConditionTypeEnum.AND,
                conditionsList: [
                  conditionMap.contractEqualSupplyContract,
                  {
                    type: ConditionTypeEnum.EQUALS,
                    value: true,
                    field: [
                      FieldNameEnum.VARIANT_FIND_COMPANY,
                      '(find:id=company_details)',
                      'checked',
                    ].join('.'),
                  },
                ],
              },
            ],
          },
          {
            type: FieldTypeEnum.DATE_FIELD,
            fieldName: FieldNameEnum.DATE_CONTRACT,
            config: {
              placeholder: state.t('pages.scoring_form.fields.contract_date.frontend.placeholder'),
              label: state.t('pages.scoring_form.fields.contract_date.frontend.label'),
              description: state.t('pages.scoring_form.fields.contract_date.frontend.description'),
              maxDate: new Date(),
              buttons: {
                ok: state.t('common.apply'),
                cancel: state.t('common.cancel'),
              },
            },
            status: state.validationStatusRegistry[FieldNameEnum.DATE_CONTRACT],
            conditionList: [
              {
                type: ConditionTypeEnum.AND,
                conditionsList: [
                  conditionMap.contractEqualSupplyContract,
                  {
                    type: ConditionTypeEnum.EQUALS,
                    value: true,
                    field: [
                      FieldNameEnum.VARIANT_FIND_COMPANY,
                      '(find:id=company_details)',
                      'checked',
                    ].join('.'),
                  },
                ],
              },
            ],
          },
          {
            type: FieldTypeEnum.TEXT_INPUT,
            fieldName: FieldNameEnum.LEGAL_ENTITY,
            config: {
              placeholder: state.t('pages.scoring_form.fields.softline_contact.frontend.placeholder'),
              label: state.t('pages.scoring_form.fields.softline_contact.frontend.label'),
              description: state.t('pages.scoring_form.fields.softline_contact.frontend.description'),
            },
            status: state.validationStatusRegistry[FieldNameEnum.LEGAL_ENTITY],
            conditionList: [
              {
                type: ConditionTypeEnum.AND,
                conditionsList: [
                  conditionMap.contractEqualSupplyContract,
                  {
                    type: ConditionTypeEnum.EQUALS,
                    value: true,
                    field: [
                      FieldNameEnum.VARIANT_FIND_COMPANY,
                      '(find:id=company_details)',
                      'checked',
                    ].join('.'),
                  },
                ],
              },
            ],
          },
          {
            type: FieldTypeEnum.TEXT_INPUT,
            fieldName: FieldNameEnum.WEBSITE,
            config: {
              placeholder: state.t('pages.scoring_form.fields.website.frontend.placeholder'),
              label: state.t('pages.scoring_form.fields.website.frontend.label'),
            },
            status: state.validationStatusRegistry[FieldNameEnum.WEBSITE],
            conditionList: [
              conditionMap.contractNotEqualSupplyContract,
            ],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.ANNUAL_TURNOVER,
            config: {
              label: state.t('pages.scoring_form.fields.annual_turnover.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.ANNUAL_TURNOVER),
            },
            status: state.validationStatusRegistry[FieldNameEnum.ANNUAL_TURNOVER],
            conditionList: [
              conditionMap.contractNotEqualSupplyContract,
            ],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.EXPECTED_ANNUAL_TURNOVER,
            config: {
              label: state.t('pages.scoring_form.fields.expected_annual_turnover.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.EXPECTED_ANNUAL_TURNOVER),
            },
            status: state.validationStatusRegistry[FieldNameEnum.EXPECTED_ANNUAL_TURNOVER],
            conditionList: [
              conditionMap.contractNotEqualSupplyContract,
            ],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.NUMBER_OF_EMPLOYEES,
            config: {
              label: state.t('pages.scoring_form.fields.number_of_employees.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.NUMBER_OF_EMPLOYEES),
            },
            status: state.validationStatusRegistry[FieldNameEnum.NUMBER_OF_EMPLOYEES],
            conditionList: [
              conditionMap.contractNotEqualSupplyContract,
            ],
          },
        ],
        conditionList: [
          {
            type: ConditionTypeEnum.NOT_EMPTY,
            field: FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE,
          },
        ],
      },
      {
        type: FormBlockTypeEnum.BLOCK,
        subTitle: null,
        title: state.t('pages.scoring_form.blocks.work_direction'),
        fields: [
          {
            type: FieldTypeEnum.MULTIPLE_SELECT,
            fieldName: FieldNameEnum.WORK_DIRECTION,
            config: {
              label: state.t('pages.scoring_form.fields.work_direction.frontend.label'),
              list: this.getList(state, FieldNameEnum.WORK_DIRECTION),
            },
            status: state.validationStatusRegistry[FieldNameEnum.WORK_DIRECTION],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.HAS_COPYRIGHT_DOCUMENTS,
            config: {
              label: state.t('pages.scoring_form.fields.has_copyright_documents.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.HAS_COPYRIGHT_DOCUMENTS),
            },
            status: state.validationStatusRegistry[FieldNameEnum.HAS_COPYRIGHT_DOCUMENTS],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.PRODUCT_TYPE,
            config: {
              label: state.t('pages.scoring_form.fields.product_type.frontend.label'),
              mode: CChipsGroupModeEnum.MULTIPLE,
              list: this.getList(state, FieldNameEnum.PRODUCT_TYPE),
            },
            status: state.validationStatusRegistry[FieldNameEnum.PRODUCT_TYPE],
          },
        ],
        conditionList: [
          conditionMap.contractNotEqualSupplyContract,
        ],
      },
      {
        type: FormBlockTypeEnum.BLOCK,
        subTitle: null,
        title: state.t('pages.scoring_form.blocks.yours_clients'),
        fields: [
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.CUSTOMER_INTERACTION,
            config: {
              label: state.t('pages.scoring_form.fields.customer_interaction.frontend.label'),
              mode: CChipsGroupModeEnum.MULTIPLE,
              list: this.getList(state, FieldNameEnum.CUSTOMER_INTERACTION),
            },
            status: state.validationStatusRegistry[FieldNameEnum.CUSTOMER_INTERACTION],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.TARGET_AUDIENCE,
            config: {
              label: state.t('pages.scoring_form.fields.target_audience.frontend.label'),
              mode: CChipsGroupModeEnum.MULTIPLE,
              list: this.getList(state, FieldNameEnum.TARGET_AUDIENCE),
            },
            status: state.validationStatusRegistry[FieldNameEnum.TARGET_AUDIENCE],
          },
        ],
        conditionList: [
          conditionMap.contractNotEqualSupplyContract,
        ],
      },
      {
        type: FormBlockTypeEnum.BLOCK,
        subTitle: null,
        title: state.t('pages.scoring_form.blocks.partnership_expectations'),
        fields: [
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.INTERESTED_SERVICES,
            config: {
              label: state.t('pages.scoring_form.fields.interested_services.frontend.label'),
              mode: CChipsGroupModeEnum.MULTIPLE,
              list: this.getList(state, FieldNameEnum.INTERESTED_SERVICES),
            },
            status: state.validationStatusRegistry[FieldNameEnum.INTERESTED_SERVICES],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.HAS_PARTNER_PROGRAM,
            config: {
              label: state.t('pages.scoring_form.fields.has_partner_program.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.HAS_PARTNER_PROGRAM),
            },
            status: state.validationStatusRegistry[FieldNameEnum.HAS_PARTNER_PROGRAM],
          },
          {
            type: FieldTypeEnum.CHIPS_GROUP,
            fieldName: FieldNameEnum.MARKETING_BUDGET,
            config: {
              label: state.t('pages.scoring_form.fields.marketing_budget.frontend.label'),
              mode: CChipsGroupModeEnum.SINGLE,
              list: this.getList(state, FieldNameEnum.MARKETING_BUDGET),
            },
            status: state.validationStatusRegistry[FieldNameEnum.MARKETING_BUDGET],
          },
        ],
        conditionList: [
          conditionMap.contractNotEqualSupplyContract,
        ],
      },
    ];
  };

  private getList(
    state: StateModelInterface,
    field: Exclude<
      FieldNameEnum,
      FieldNameEnum.DOCUMENTS
      | FieldNameEnum.INN
      | FieldNameEnum.COMPANY_NAME
      | FieldNameEnum.WEBSITE
      | FieldNameEnum.NUMBER_CONTRACT
      | FieldNameEnum.DATE_CONTRACT
      | FieldNameEnum.LEGAL_ENTITY
      | FieldNameEnum.CONTRACT_COPY
    >,
  ): Array<SChipsItemInterface> {
    const listMap: { [key in Exclude<
      FieldNameEnum,
      FieldNameEnum.DOCUMENTS
      | FieldNameEnum.INN
      | FieldNameEnum.COMPANY_NAME
      | FieldNameEnum.WEBSITE
      | FieldNameEnum.NUMBER_CONTRACT
      | FieldNameEnum.DATE_CONTRACT
      | FieldNameEnum.LEGAL_ENTITY
      | FieldNameEnum.CONTRACT_COPY
    >
    ]: Array<SChipsItemInterface> } = {
      [FieldNameEnum.HAS_CONTRACT_WITH_SOFTLINE]: state.scoringParams?.hasContractWithSoftline.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.has_contract_with_softline.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.VARIANT_FIND_COMPANY]: [
        {
          id: 'company_details',
          title: state.t('pages.scoring_form.fields.how_find_company.frontend.values.requisites'),
          checked: false,
        },
        {
          id: 'copy_contract',
          title: state.t('pages.scoring_form.fields.how_find_company.frontend.values.contract_copy'),
          checked: false,
        },
      ],
      [FieldNameEnum.COLLABORATION_PREFERENCE]: [
        {
          id: 'softline',
          title: state.t('pages.scoring_form.fields.collaboration_preference.frontend.values.softline'),
          checked: false,
        },
        {
          id: 'only_yours',
          title: state.t('pages.scoring_form.fields.collaboration_preference.frontend.values.only_yours'),
          checked: false,
        },
      ],
      [FieldNameEnum.PRODUCTS_OFFER]: state.scoringParams?.productsOffer.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.products_offer.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.ACCEPTS_CONTRACT_CHANGES]: [
        {
          id: 'yes',
          title: state.t('pages.scoring_form.fields.accepts_contract_changes.frontend.values.yes'),
          checked: false,
        },
        {
          id: 'no',
          title: state.t('pages.scoring_form.fields.accepts_contract_changes.frontend.values.no'),
          checked: false,
        },
      ],
      [FieldNameEnum.EXCLUSIVE_CONTRACT_WITH_COMPANY]: [
        {
          id: 'yes',
          title: state.t('pages.scoring_form.fields.exclusive_contract_with_company.frontend.values.yes'),
          checked: false,
        },
        {
          id: 'no',
          title: state.t('pages.scoring_form.fields.exclusive_contract_with_company.frontend.values.no'),
          checked: false,
        },
      ],
      [FieldNameEnum.ANNUAL_TURNOVER]: state.scoringParams?.annualTurnover.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.annual_turnover.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.EXPECTED_ANNUAL_TURNOVER]: state.scoringParams?.expectedAnnualTurnover.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.expected_annual_turnover.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.NUMBER_OF_EMPLOYEES]: state.scoringParams?.numberOfEmployees.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.number_of_employees.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.WORK_DIRECTION]: state.scoringParams?.workDirection.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.work_direction.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.HAS_COPYRIGHT_DOCUMENTS]: [
        {
          id: 'yes',
          title: state.t('pages.scoring_form.fields.has_copyright_documents.frontend.values.yes'),
          checked: false,
        },
        {
          id: 'no',
          title: state.t('pages.scoring_form.fields.has_copyright_documents.frontend.values.no'),
          checked: false,
        },
      ],
      [FieldNameEnum.PRODUCT_TYPE]: state.scoringParams?.productType.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.product_type.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.CUSTOMER_INTERACTION]: state.scoringParams?.customerInteraction.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.customer_interaction.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.TARGET_AUDIENCE]: state.scoringParams?.targetAudience.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.target_audience.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.INTERESTED_SERVICES]: state.scoringParams?.interestedServices.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.interested_services.frontend.values.${item}`),
        checked: false,
      })) ?? [],
      [FieldNameEnum.HAS_PARTNER_PROGRAM]: [
        {
          id: 'yes',
          title: state.t('pages.scoring_form.fields.has_partner_program.frontend.values.yes'),
          checked: false,
        },
        {
          id: 'no',
          title: state.t('pages.scoring_form.fields.has_partner_program.frontend.values.no'),
          checked: false,
        },
      ],
      [FieldNameEnum.MARKETING_BUDGET]: state.scoringParams?.marketingBudget.map((item) => ({
        id: item,
        title: state.t(`pages.scoring_form.fields.marketing_budget.frontend.values.${item}`),
        checked: false,
      })) ?? [],
    };

    const formDataField = (state.formData[field] ?? []) as Array<SChipsItemInterface>;

    const mapElement: Array<SChipsItemInterface> = listMap[field] ?? [];

    return formDataField.length > 0 ? formDataField : mapElement;
  }

  private getDocumentOptions(
    state: StateModelInterface,
    field: FieldNameEnum.DOCUMENTS | FieldNameEnum.CONTRACT_COPY,
  ) {
    const maxFileSize = 100;
    const maxFilesSize = 100;

    const fileErrorMessages: FileErrorMessagesType = {
      [FileErrorTypeEnum.LOAD]: state.t(EValidationMessageTokens.FILE_LOAD),
      [FileErrorTypeEnum.MAX_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_SIZE,
        {
          number: maxFileSize,
        },
      ),
      [FileErrorTypeEnum.MAX_TOTAL_SIZE]: state.t(
        EValidationMessageTokens.FILE_MAX_TOTAL_SIZE,
        {
          number: maxFilesSize,
        },
      ),
      [FileErrorTypeEnum.MIME_TYPE]: state.t(EValidationMessageTokens.FILE_MIME_TYPE),
      [FileErrorTypeEnum.NO_ZERO_SIZE]: state.t(EValidationMessageTokens.FILE_NO_ZERO_SIZE),
      [FileErrorTypeEnum.NAME]: state.t(EValidationMessageTokens.FILE_NAME),
    };

    const mimeTypesMap: Record<FieldNameEnum.DOCUMENTS | FieldNameEnum.CONTRACT_COPY, FileMimeTypesEnum[]> = {
      [FieldNameEnum.DOCUMENTS]: [
        FileMimeTypesEnum.PDF,
        FileMimeTypesEnum.DOC,
        FileMimeTypesEnum.DOCX,
      ],
      [FieldNameEnum.CONTRACT_COPY]: [
        FileMimeTypesEnum.PDF,
        FileMimeTypesEnum.DOC,
        FileMimeTypesEnum.DOCX,
        FileMimeTypesEnum.PNG,
        FileMimeTypesEnum.JPG,
        FileMimeTypesEnum.JPEG,
      ],
    };

    const mimeTypes = mimeTypesMap[field];

    const extensionsMap: Record<FieldNameEnum.DOCUMENTS | FieldNameEnum.CONTRACT_COPY, FileExtensionsEnum[]> = {
      [FieldNameEnum.DOCUMENTS]: [
        FileExtensionsEnum.PDF,
        FileExtensionsEnum.DOC,
        FileExtensionsEnum.DOCX,
      ],
      [FieldNameEnum.CONTRACT_COPY]: [
        FileExtensionsEnum.PDF,
        FileExtensionsEnum.DOC,
        FileExtensionsEnum.DOCX,
        FileExtensionsEnum.PNG,
        FileExtensionsEnum.JPG,
        FileExtensionsEnum.JPEG,
      ],
    };

    const extensions = extensionsMap[field];

    const mapTokens = {
      [FieldNameEnum.DOCUMENTS]: {
        uploadButton: 'pages.scoring_form.fields.documents.frontend.upload_button',
        description: 'pages.scoring_form.fields.documents.frontend.description',
        placeholder: 'pages.scoring_form.fields.documents.frontend.placeholder',
      },
      [FieldNameEnum.CONTRACT_COPY]: {
        uploadButton: 'pages.scoring_form.fields.contract_copy.frontend.upload_button',
        description: 'pages.scoring_form.fields.contract_copy.frontend.description',
        placeholder: 'pages.scoring_form.fields.contract_copy.frontend.placeholder',
      },
    };

    const uploadButton = state.t(mapTokens[field].uploadButton);

    const description = state.t(
      mapTokens[field].description,
      {
        extensions: extensions.join(', '),
        maxFileSize,
        maxFilesSize,
      },
    );

    const placeholder = state.t(
      mapTokens[field].placeholder,
      {
        extensions: extensions.join(', '),
        maxFileSize,
        maxFilesSize,
      },
    );

    const fileUploadOptions = getFileUploadOptions(
      fileErrorMessages,
      {
        variant: SButtonVariantEnum.TERTIARY,
        text: uploadButton,
        size: SizeEnum.M,
      },
      {
        name: IconNameEnum.PAPER_CLIP,
        color: ColorEnum.PRIMARY,
      },
      description,
      placeholder,
      mimeTypes,
      maxFileSize,
      maxFilesSize,
      255,
      true,
    );

    return { ...fileUploadOptions };
  }
}

export default FormConfigHandler;
