<template>
  <div
    class="s-user-menu"
  >
    <div
      class="s-user-menu__button"
      @click.stop="handleClickProfileButton"
    >
      <div
        class="s-user-menu__icon"
      >
        <SIcon
          :name="profileIcon.name"
          :color="profileIcon.color"
        />
      </div>
    </div>

    <div
      v-if="state.isOpened"
      v-click-outside="handleClickOutside"
      class="s-user-menu__window"
    >
      <div
        v-if="user"
        class="s-user-menu__item"
      >
        <div
          :class="userClasses"
          @click="user.handler"
        >
          <STextElement
            v-if="user.title"
            variant="small-medium"
            color="white"
          >
            {{ user.title }}
          </STextElement>
          <STextElement
            v-if="user.text"
            variant="x-small-medium"
            color="stroke"
          >
            {{ user.text }}
          </STextElement>
        </div>
      </div>
      <div
        v-if="isDefaultSlot"
        class="s-user-menu__item"
      >
        <div
          class="s-user-menu__element"
        >
          <slot />
        </div>
      </div>
      <hr
        class="s-user-menu__divider"
      >
      <div
        v-if="logout"
        class="s-user-menu__item"
      >
        <div
          class="s-user-menu__element s-user-menu__element_active s-user-menu__element_logout"
          @click="logout.handler"
        >
          <SIcon
            v-if="logout.icon"
            :name="logout.icon"
            color="gray"
            size="s"
          />
          <STextElement
            variant="x-small-medium"
            color="white"
          >
            {{ logout.title }}
          </STextElement>
        </div>
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
  reactive,
} from 'vue';

import ColorEnum from '../../../../lib/Enum/ColorEnum';
import IconNameEnum from '../../../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import UserMenuItemInterface
  from '../../../../lib/Model/Components/Complex/TopBar/UserMenuItemInterface';

import SIcon from '../../../Simple/Icon/SIcon.vue';
import STextElement from '../../../Simple/Text/STextElement.vue';

export default defineComponent({
  name: 'CUserMenu',
  components: {
    STextElement,
    SIcon,
  },
  props: {
    user: {
      type: Object as PropType<UserMenuItemInterface>,
    },
    logout: {
      type: Object as PropType<UserMenuItemInterface>,
    },
  },
  setup(props, { slots }) {
    const state = reactive({
      isOpened: false,
    });

    const profileIcon = {
      name: IconNameEnum.PROFILE,
      color: ColorEnum.GRAY,
    };

    const userClasses = [
      's-user-menu__element',
      's-user-menu__element_user',
      props.user?.handler ? 's-user-menu__element_active' : null,
    ];

    const isDefaultSlot = computed(() => !!slots.default);

    function handleClickProfileButton() {
      state.isOpened = true;
    }

    function handleClickOutside() {
      state.isOpened = false;
    }

    return {
      state,
      profileIcon,
      userClasses,
      isDefaultSlot,
      handleClickProfileButton,
      handleClickOutside,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-user-menu {
  position: relative;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-toolbar-hover);
    }

    &:active {
      background-color: var(--color-toolbar-active);
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__window {
    min-width: 220px;
    padding: 10px 0;
    border-radius: 8px;
    position: absolute;
    top: calc(100% + 24px);
    right: 0;
    color: var(--color-white);
    background-color: var(--color-toolbar-active);
  }

  &__divider {
    opacity: 0.1;
    color: var(--color-white);
  }

  &__item {
    display: block;
    padding: 0 10px;
    white-space: nowrap;
  }

  &__element {
    padding: 5px 8px;
    border-radius: 4px;

    &_user {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &_logout {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &_active:hover {
      cursor: pointer;
      background-color: #4397cb;
    }
  }
}
</style>
