import IFieldStatusHandler from './Contract/IFieldStatusHandler';
import IValidator from './Contract/IValidator';
import IFieldStatus from './Model/FieldStatusInterface';
import StatusCodeEnum from './Enum/StatusCodeEnum';
import EValidatorType from './Enum/EValidatorType';

class EmptyFieldStatusHandler implements IFieldStatusHandler {
  getStatus = (
    validator: IValidator,
  ): IFieldStatus => {
    const hasErrors = validator.hasErrors();

    const status = {
      code: StatusCodeEnum.DEFAULT,
    } as IFieldStatus;

    if (hasErrors) {
      const isNotEmptyError = validator.hasErrorsByTypeList([
        EValidatorType.NOT_EMPTY,
        EValidatorType.CUSTOM_NOT_EMPTY,
      ]);
      status.code = isNotEmptyError ? StatusCodeEnum.EMPTY : StatusCodeEnum.ERROR;

      if (!isNotEmptyError) {
        // eslint-disable-next-line prefer-destructuring
        status.message = validator.getMessages()[0];
      }
    }

    return status;
  };
}

export default EmptyFieldStatusHandler;
