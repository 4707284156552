enum EVendorStatus {
  SCORING_REJECTED = 'scoring_rejected',
  SCORING_NOT_PASSED = 'scoring_not_passed',
  SCORING_COMPLETED = 'scoring_completed',
  SCORING_PASSED = 'scoring_passed',
  CONTRACT_IN_PROGRESS = 'contract_in_progress',
  CONTRACT_SIGNED = 'contract_signed',
}

export default EVendorStatus;
