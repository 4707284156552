<template>
  <div
    :class="mainClasses"
  >
    <div
      :class="itemClasses"
      @click="handleClick"
    >
      <SIcon
        :name="iconConfig.name"
        :color="iconConfig.color"
        class="c-menu-item__icon"
      />
      <STextElement
        :color="textElementConfig.color"
        :variant="textElementConfig.variant"
        class="c-menu-item__text"
      >
        {{ item.title }}
      </STextElement>
      <SIcon
        v-if="item.subList"
        :class="arrowClasses"
        :name="iconConfig.name"
        :color="iconConfig.color"
      />
    </div>
    <div
      v-if="item.subList"
      :class="subListClasses"
      ref="sublistRef"
    >
      <CMenuSubItem
        v-for="subItem in item.subList"
        :key="subItem.title"
        :item="subItem"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
>
// @TODO доделать мобильную версию
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
} from 'vue';
import {
  useRouter,
} from 'vue-router';

import ColorEnum from '../../../../lib/Enum/ColorEnum';
import FontVariantEnum from '../../../../lib/Enum/FontVariantEnum';
import SIconPropsInterface from '../../../../lib/Model/Components/Simple/Icon/SIconPropsInterface';
import IconNameEnum from '../../../../lib/Enum/Components/Simple/Icon/IconNameEnum';
import SidebarMenuMainItemInterface
  from '../../../../lib/Model/Components/Complex/SideBar/SidebarMenuMainItemInterface';

import SIcon from '../../../Simple/Icon/SIcon.vue';
import STextElement from '../../../Simple/Text/STextElement.vue';
import CMenuSubItem from './CMenuSubItem.vue';

export default defineComponent({
  name: 'CMenuMainItem',
  components: {
    SIcon,
    STextElement,
    CMenuSubItem,
  },
  props: {
    item: {
      type: Object as PropType<SidebarMenuMainItemInterface>,
      required: true,
    },
    isSidebarCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const state = reactive<{
      isSublistOpen: boolean;
    }>({
      isSublistOpen: false,
    });

    function checkIfItemHaveActiveChild(item: SidebarMenuMainItemInterface): boolean {
      const childList = item.subList ?? [];
      let haveActiveChild = false;

      if (childList.length > 0) {
        haveActiveChild = childList.findIndex((childItem) => childItem.active) !== -1;
      }

      return haveActiveChild;
    }

    const mainClasses = computed(() => [
      'c-menu-item',
      props.isSidebarCollapsed ? 'c-menu-item_collapsed' : null,
    ]);

    const itemClasses = computed(() => {
      const haveActiveChild = checkIfItemHaveActiveChild(props.item);
      const isActive = props.item.active
        || (haveActiveChild && !state.isSublistOpen)
        || (haveActiveChild && props.isSidebarCollapsed);

      return [
        'c-menu-item__item',
        isActive ? 'c-menu-item__item_active' : null,
        props.item.disabled ? 'c-menu-item__item_disabled' : null,
      ];
    });

    function getColor(): ColorEnum {
      if (props.item.disabled) {
        return ColorEnum.GRAY;
      }

      if (props.item.active) {
        return ColorEnum.WHITE;
      }

      return ColorEnum.GRAY;
    }

    const textElementConfig = {
      variant: FontVariantEnum.REGULAR,
      color: getColor(),
    };

    const iconConfig = computed<SIconPropsInterface>(() => ({
      name: props.item.icon,
      color: getColor(),
    }));

    const arrowClasses = computed(() => [
      'c-menu-item__arrow',
      state.isSublistOpen ? 'c-menu-item__arrow_active' : null,
    ]);
    const arrowIconConfig = computed<SIconPropsInterface>(() => ({
      name: IconNameEnum.ARROW_DOWN,
      color: getColor(),
    }));

    const subListClasses = computed(() => [
      'c-menu-item__sub-list',
      state.isSublistOpen ? 'c-menu-item__sub-list_open' : null,
    ]);

    const sublistRef = ref();

    function updateSubListMaxHeight(isOpen: boolean): void {
      const subList = props.item.subList ?? [];

      if (isOpen) {
        const itemHeight = 36;

        sublistRef.value.style.maxHeight = `${subList.length * itemHeight}px`;
      } else {
        sublistRef.value.style.maxHeight = 0;
      }
    }

    onMounted(() => {
      if (props.item.subList) {
        const isOpen = checkIfItemHaveActiveChild(props.item);

        updateSubListMaxHeight(isOpen);
        state.isSublistOpen = isOpen;
      }
    });

    function handleClick() {
      const { href, subList } = props.item;

      if (subList) {
        const isOpen = !state.isSublistOpen;

        updateSubListMaxHeight(isOpen);

        state.isSublistOpen = isOpen;
      } else if (href) {
        const isExternalLink = href.indexOf('http') === 0;

        if (isExternalLink) {
          window.open(href);
        } else {
          router.push(href).catch(() => null);
        }
      }
    }

    return {
      mainClasses,
      itemClasses,
      textElementConfig,
      iconConfig,
      arrowClasses,
      arrowIconConfig,
      subListClasses,
      sublistRef,
      handleClick,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.c-menu-item {
  $menu-item: &;

  &__item {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-left: 4px solid transparent;
    cursor: pointer;
    gap: 12px;
    transition: border .2s ease-in, background .2s ease-in;

    &:hover:not(&_disabled) {
      background-color: var(--color-toolbar-hover);
    }

    &_active {
      border-left-color: var(--color-white);
      background-color: var(--color-toolbar-active);
    }

    &_disabled {
      cursor: default;
    }
  }

  &_collapsed {
    #{$menu-item} {
      &__text {
        max-width: 0;
        text-overflow: clip;
      }

      &__sub-list {
        max-height: 0 !important;
      }
    }
  }

  &__icon {
    flex: 0 0 24px;
  }

  &__text {
    overflow: hidden;
    max-width: 183px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: max-width .2s ease-in;
  }

  &__arrow {
    margin-left: auto;
    transition: transform .2s ease-in;

    &_active {
      transform: rotate(180deg);
    }
  }

  &__sub-list {
    overflow: hidden;
    max-height: 0;
    transition: max-height .2s ease-in;
  }
}
</style>
