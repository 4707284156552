import IVendorApi from '@/shared/Api/Contract/Api/IVendorApi';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import IVendorResponse from '@/shared/Api/Model/Vendor/IVendorResponse';

class VendorApi implements IVendorApi {
  private clientService: IAxiosClientService;

  constructor(clientService: IAxiosClientService) {
    this.clientService = clientService;
  }

  get = (): Promise<IVendorResponse> => this.clientService.instance
    .get<IVendorResponse>('/vendor/info')
    .then((response) => response.data);
}

export default VendorApi;
