enum SmallTextEnum {
  SIZE_DESKTOP = '--text-variant-desktop-small-size',
  LINE_HEIGHT_DESKTOP = '--text-variant-desktop-small-line-height',
  WEIGHT_DESKTOP = '--text-variant-desktop-small-weight',
  SIZE_MOBILE = '--text-variant-mobile-small-size',
  LINE_HEIGHT_MOBILE = '--text-variant-mobile-small-line-height',
  WEIGHT_MOBILE = '--text-variant-mobile-small-weight',
}

export default SmallTextEnum;
