import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-multiple-select" }
const _hoisted_2 = {
  key: 0,
  class: "c-multiple-select__chips"
}
const _hoisted_3 = {
  key: 1,
  class: "c-multiple-select__dropdown"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextInput = _resolveComponent("STextInput")!
  const _component_SChips = _resolveComponent("SChips")!
  const _component_SCheckbox = _resolveComponent("SCheckbox")!
  const _component_SLabel = _resolveComponent("SLabel")!
  const _component_PerfectScrollbar = _resolveComponent("PerfectScrollbar")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_STextInput, {
      id: _ctx.id,
      modelValue: _ctx.state.filter,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.filter) = $event)),
      color: _ctx.color,
      placeholder: _ctx.placeholder,
      onFocus: _ctx.handleInputFocus
    }, {
      before: _withCtx(() => [
        _createVNode(_component_SIcon, {
          color: "disabled",
          name: "search"
        })
      ]),
      _: 1
    }, 8, ["id", "modelValue", "color", "placeholder", "onFocus"]),
    (_ctx.state.checkedList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.checkedList, (chips) => {
            return (_openBlock(), _createBlock(_component_SChips, {
              key: chips.id,
              icon: _ctx.state.chipsIcon,
              modelValue: true,
              color: "primary",
              stateType: "outer",
              variant: "filled",
              onIconClick: ($event: any) => (_ctx.handleChipsClick(chips))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(chips.title), 1)
              ]),
              _: 2
            }, 1032, ["icon", "onIconClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.state.active)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PerfectScrollbar, {
            settings: _ctx.state.scrollBarSettings,
            class: "c-multiple-select__dropdown-scroll-area"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredList, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "c-multiple-select__dropdown-item",
                  onClick: ($event: any) => (_ctx.handleItemCLick(item))
                }, [
                  _createVNode(_component_SLabel, {
                    class: "c-multiple-select__dropdown-label",
                    side: "right",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                  }, {
                    label: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    control: _withCtx(({ id }) => [
                      _createVNode(_component_SCheckbox, {
                        id: id,
                        modelValue: item.checked,
                        class: "c-multiple-select__dropdown-checkbox",
                        "onUpdate:modelValue": ($event: any) => (_ctx.handleItemCLick(item)),
                        onInput: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024)
                ], 8, _hoisted_4))
              }), 128))
            ]),
            _: 1
          }, 8, ["settings"])
        ])), [
          [_directive_click_outside, _ctx.handleClickOutside]
        ])
      : _createCommentVNode("", true)
  ]))
}