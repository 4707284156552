import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import ELocalStorageKeys from '@/app/Service/LocalStorage/Enum/ELocalStorageKeys';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';

class AxiosClientService implements IAxiosClientService {
  private _instance?: AxiosInstance;

  private retry = false;

  private serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  get instance(): AxiosInstance {
    if (!this._instance) {
      this._instance = axios.create({
        baseURL: `${this.getBackendHost()}/api`,
        headers: {
          accept: 'aplication/json',
        },
      });
    }

    return this._instance;
  }

  addRequestInterceptors = () => {
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers = {
          ...config.headers,
          Accept: 'aplication/json',
        } as AxiosRequestHeaders;

        config = this.serviceContainer.authorizationService.signRequest(config);

        return config;
      },
      async (error) => Promise.reject(error),
    );
  };

  addResponseInterceptors = () => {
    this.instance.interceptors.response.use(
      async (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (originalRequest.url === 'logout') {
          return Promise.resolve(error);
        }

        if (error.response) {
          const { status } = error.response;

          if ((status === 403 || status === 401) && !this.retry) {
            this.retry = true;

            return this.instance(originalRequest);
          }

          if (this.retry) {
            this.retry = false;

            this.serviceContainer.localStorageService.remove(ELocalStorageKeys.AUTH_TOKEN);

            await this.serviceContainer.authorizationService.logout();
          }
        }
        return Promise.reject(error);
      },
    );
  };

  private getBackendHost = (): string => {
    const envHost = this.serviceContainer.environmentService.getBackendHost();

    if (envHost.length === 0) {
      return window.location.origin;
    }

    return envHost;
  };
}

export default AxiosClientService;
