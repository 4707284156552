import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-595aef10"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "form-component",
  ref: "formComponentRef"
}
const _hoisted_2 = {
  key: 0,
  class: "form-component__delimiter"
}
const _hoisted_3 = {
  key: 1,
  class: "form-component__block"
}
const _hoisted_4 = {
  key: 0,
  class: "block__header"
}
const _hoisted_5 = {
  key: 0,
  class: "block__field"
}
const _hoisted_6 = {
  key: 1,
  class: "block__field"
}
const _hoisted_7 = {
  key: 2,
  class: "block__field"
}
const _hoisted_8 = {
  key: 3,
  class: "block__field"
}
const _hoisted_9 = {
  key: 4,
  class: "block__field"
}
const _hoisted_10 = {
  key: 5,
  class: "block__field"
}
const _hoisted_11 = {
  key: 6,
  class: "block__field"
}
const _hoisted_12 = {
  key: 7,
  class: "block__field"
}
const _hoisted_13 = {
  key: 8,
  class: "block__field"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SHeader = _resolveComponent("SHeader")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_STextInput = _resolveComponent("STextInput")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_SMaskedInput = _resolveComponent("SMaskedInput")!
  const _component_SDatePicker = _resolveComponent("SDatePicker")!
  const _component_CTextInputList = _resolveComponent("CTextInputList")!
  const _component_STextArea = _resolveComponent("STextArea")!
  const _component_CMultipleSelect = _resolveComponent("CMultipleSelect")!
  const _component_CChipsGroup = _resolveComponent("CChipsGroup")!
  const _component_CFileUploadArea = _resolveComponent("CFileUploadArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.formConfig, (block, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (_ctx.blockTypeCheckHandler.isDelimiter(block) && _ctx.checkCondition(block.conditionList))
          ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
          : (_ctx.blockTypeCheckHandler.isBlock(block) && _ctx.checkCondition(block.conditionList))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.blockTypeCheckHandler.isBlock(block) && (block.title || block.subTitle))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      (_ctx.blockTypeCheckHandler.isBlock(block) && block.title)
                        ? (_openBlock(), _createBlock(_component_SHeader, {
                            key: 0,
                            size: 3
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(block.title), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      (_ctx.blockTypeCheckHandler.isBlock(block) && block.subTitle)
                        ? (_openBlock(), _createBlock(_component_STextElement, { key: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(block.subTitle), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.blockTypeCheckHandler.isBlock(block))
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(block.fields, (field) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: `field-${field.fieldName}`
                      }, [
                        (_ctx.fieldTypeCheckHandler.isCustomInfoBlock(field) && _ctx.checkCondition(field.conditionList))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _renderSlot(_ctx.$slots, "custom-info-block", { field: field }, undefined, true)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.fieldTypeCheckHandler.isTextInput(field) && _ctx.checkCondition(field.conditionList))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _createVNode(_component_FormField, {
                                label: field.config.label,
                                description: field.config.description,
                                status: field.status
                              }, {
                                default: _withCtx(({ color, id }) => [
                                  _createVNode(_component_STextInput, {
                                    placeholder: field.config.placeholder,
                                    color: color,
                                    id: id,
                                    "model-value": _ctx.fieldValueHandler.getStringValue(field, _ctx.state.formData),
                                    onBlur: (event, value) => _ctx.handleStringBlur(event, value, field)
                                  }, null, 8, ["placeholder", "color", "id", "model-value", "onBlur"])
                                ]),
                                _: 2
                              }, 1032, ["label", "description", "status"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.fieldTypeCheckHandler.isMaskedInput(field) && _ctx.checkCondition(field.conditionList))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_component_FormField, {
                                label: field.config.label,
                                description: field.config.description,
                                status: field.status
                              }, {
                                default: _withCtx(({ color, id }) => [
                                  _createVNode(_component_SMaskedInput, {
                                    placeholder: field.config.placeholder,
                                    color: color,
                                    id: id,
                                    "mask-options": field.config.mask,
                                    "model-value": _ctx.fieldValueHandler.getStringValue(field, _ctx.state.formData),
                                    onBlur: (event, value) => _ctx.handleStringBlur(event, value, field)
                                  }, null, 8, ["placeholder", "color", "id", "mask-options", "model-value", "onBlur"])
                                ]),
                                _: 2
                              }, 1032, ["label", "description", "status"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.fieldTypeCheckHandler.isDateField(field) && _ctx.checkCondition(field.conditionList))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_FormField, {
                                label: field.config.label,
                                description: field.config.description,
                                status: field.status
                              }, {
                                default: _withCtx(({ color, id }) => [
                                  _createVNode(_component_SDatePicker, {
                                    placeholder: field.config.placeholder,
                                    "base-time-zone": field.config.baseTimeZone,
                                    color: color,
                                    buttons: field.config.buttons,
                                    "min-date": field.config.minDate,
                                    "max-date": field.config.maxDate,
                                    id: id,
                                    mode: field.config.mode,
                                    "model-value": _ctx.fieldValueHandler.getStringValue(field, _ctx.state.formData),
                                    "dropdown-position": _ctx.state.dropdownPosition,
                                    onDateInput: (value) => _ctx.handleDateInput(value, field),
                                    onDropdownShown: _ctx.handleShowDropdown
                                  }, null, 8, ["placeholder", "base-time-zone", "color", "buttons", "min-date", "max-date", "id", "mode", "model-value", "dropdown-position", "onDateInput", "onDropdownShown"])
                                ]),
                                _: 2
                              }, 1032, ["label", "description", "status"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.fieldTypeCheckHandler.isTextInputList(field) && _ctx.checkCondition(field.conditionList))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_FormField, {
                                label: field.config.label,
                                description: field.config.description,
                                status: field.status
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CTextInputList, {
                                    placeholder: field.config.placeholder,
                                    "min-items": field.config.minItems,
                                    "max-items": field.config.maxItems,
                                    "add-button-text": field.config.addButtonText,
                                    statuses: field.config.statuses,
                                    "input-delay": field.config.inputDelay,
                                    "model-value": _ctx.fieldValueHandler.getTextInputListValue(field, _ctx.state.formData),
                                    onTextInputListInput: (value) => _ctx.handleTextInputListInput(value, field),
                                    onTextInputListAdd: (value) => _ctx.handleTextInputListAddField(value, field),
                                    onTextInputListDelete: (value) => _ctx.handleTextInputListDeleteField(value, field)
                                  }, {
                                    "before-item": _withCtx((slotProps) => [
                                      _renderSlot(_ctx.$slots, "input-list-item-before-item", {
                                        index: slotProps.index,
                                        item: slotProps.item,
                                        status: slotProps.status
                                      }, undefined, true)
                                    ]),
                                    "before-input": _withCtx((slotProps) => [
                                      _renderSlot(_ctx.$slots, "input-list-item-before-input", {
                                        index: slotProps.index,
                                        item: slotProps.item,
                                        status: slotProps.status
                                      }, undefined, true)
                                    ]),
                                    "after-input": _withCtx((slotProps) => [
                                      _renderSlot(_ctx.$slots, "input-list-item-after-input", {
                                        index: slotProps.index,
                                        item: slotProps.item,
                                        status: slotProps.status
                                      }, undefined, true)
                                    ]),
                                    "after-item": _withCtx((slotProps) => [
                                      _renderSlot(_ctx.$slots, "input-list-item-after-item", {
                                        index: slotProps.index,
                                        item: slotProps.item,
                                        status: slotProps.status
                                      }, undefined, true)
                                    ]),
                                    _: 2
                                  }, 1032, ["placeholder", "min-items", "max-items", "add-button-text", "statuses", "input-delay", "model-value", "onTextInputListInput", "onTextInputListAdd", "onTextInputListDelete"])
                                ]),
                                _: 2
                              }, 1032, ["label", "description", "status"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.fieldTypeCheckHandler.isTextArea(field) && _ctx.checkCondition(field.conditionList))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(_component_FormField, {
                                label: field.config.label,
                                description: field.config.description,
                                status: field.status
                              }, {
                                default: _withCtx(({ color, id }) => [
                                  _createVNode(_component_STextArea, {
                                    placeholder: field.config.placeholder,
                                    "min-rows-count": field.config.minRowsCount,
                                    "max-rows-count": field.config.maxRowsCount,
                                    "max-length": field.config.maxLength,
                                    color: color,
                                    id: id,
                                    "model-value": _ctx.fieldValueHandler.getStringValue(field, _ctx.state.formData),
                                    onBlur: (event, value) => _ctx.handleStringBlur(event, value, field)
                                  }, null, 8, ["placeholder", "min-rows-count", "max-rows-count", "max-length", "color", "id", "model-value", "onBlur"])
                                ]),
                                _: 2
                              }, 1032, ["label", "description", "status"])
                            ]))
                          : (_ctx.fieldTypeCheckHandler.isMultipleSelect(field) && _ctx.checkCondition(field.conditionList))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createVNode(_component_FormField, {
                                  label: field.config.label,
                                  description: field.config.description,
                                  status: field.status
                                }, {
                                  default: _withCtx(({ color, id }) => [
                                    _createVNode(_component_CMultipleSelect, {
                                      placeholder: field.config.placeholder,
                                      color: color,
                                      list: field.config.list,
                                      id: id,
                                      onChangeItem: (value) => _ctx.handleMultipleSelectInput(value, field)
                                    }, null, 8, ["placeholder", "color", "list", "id", "onChangeItem"])
                                  ]),
                                  _: 2
                                }, 1032, ["label", "description", "status"])
                              ]))
                            : (_ctx.fieldTypeCheckHandler.isChipsGroup(field) && _ctx.checkCondition(field.conditionList))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  _createVNode(_component_FormField, {
                                    label: field.config.label,
                                    description: field.config.description,
                                    status: field.status
                                  }, {
                                    default: _withCtx(({ color }) => [
                                      _createVNode(_component_CChipsGroup, {
                                        color: color,
                                        list: field.config.list,
                                        mode: field.config.mode,
                                        onChangeSelection: (value) => _ctx.handleChipsGroupChange(value, field)
                                      }, null, 8, ["color", "list", "mode", "onChangeSelection"])
                                    ]),
                                    _: 2
                                  }, 1032, ["label", "description", "status"])
                                ]))
                              : (_ctx.fieldTypeCheckHandler.isFileUpload(field) && _ctx.checkCondition(field.conditionList))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createVNode(_component_FormField, {
                                      label: field.config.label,
                                      description: field.config.description,
                                      status: field.status
                                    }, {
                                      default: _withCtx(({ color, id }) => [
                                        _createVNode(_component_CFileUploadArea, {
                                          options: field.config.options,
                                          files: _ctx.fieldValueHandler.getFileListValue(field, _ctx.state.formData),
                                          color: color,
                                          id: id,
                                          onInputFile: (value) => _ctx.handleInputFile(value, field),
                                          onDeleteFile: (value) => _ctx.handleDeleteFile(value, field),
                                          onDownloadFile: (value) => _ctx.handleDownloadFile(value, field)
                                        }, null, 8, ["options", "files", "color", "id", "onInputFile", "onDeleteFile", "onDownloadFile"])
                                      ]),
                                      _: 2
                                    }, 1032, ["label", "description", "status"])
                                  ]))
                                : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 512))
}