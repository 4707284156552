<template>
  <svg
    fill="none"
    height="100%"
    viewBox="0 0 24 24"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      d="M8.65633 12.0003L15.7783 4.87826C15.9847 4.67192 16.1006 4.39206 16.1006 4.10026C16.1006 3.95577 16.0721 3.8127 16.0168 3.67921C15.9615 3.54572 15.8805 3.42443 15.7783 3.32226C15.6762 3.22009 15.5549 3.13905 15.4214 3.08375C15.2879 3.02846 15.1448 3 15.0003 3C14.7085 3 14.4287 3.11592 14.2223 3.32226L6.32233 11.2223C6.22013 11.3244 6.13906 11.4457 6.08374 11.5792C6.02843 11.7127 5.99996 11.8558 5.99996 12.0003C5.99996 12.1448 6.02843 12.2878 6.08374 12.4213C6.13906 12.5548 6.22013 12.6761 6.32233 12.7783L14.2223 20.6783C14.4287 20.8846 14.7085 21.0005 15.0003 21.0005C15.2921 21.0005 15.572 20.8846 15.7783 20.6783C15.9847 20.4719 16.1006 20.1921 16.1006 19.9003C16.1006 19.6084 15.9847 19.3286 15.7783 19.1223L8.65633 12.0003Z"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconArrowLeft',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-arrow-left_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.s-icon-arrow-left {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

}
</style>
