enum EBreakpoint {
  XXL = 1366,
  XL = 1200,
  LG = 992,
  MD = 768,
  SM = 670,
  XS = 440,
  XXS = 350,
}

export default EBreakpoint;
