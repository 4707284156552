<template>
  <svg
    fill="none"
    height="100%"
    viewBox="0 0 24 24"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      :class="classes"
      clip-rule="evenodd"
      d="M15 10.0002H8.5C8.10218 10.0002 7.72064 10.1582 7.43934 10.4395C7.15804 10.7208 7 11.1024 7 11.5002V15.5002C7 15.898 7.15804 16.2796 7.43934 16.5609C7.72064 16.8422 8.10218 17.0002 8.5 17.0002H13.5C13.8978 17.0002 14.2794 17.1582 14.5607 17.4395C14.842 17.7208 15 18.1024 15 18.5002C15 18.898 14.842 19.2796 14.5607 19.5609C14.2794 19.8422 13.8978 20.0002 13.5 20.0002H8C6.93913 20.0002 5.92172 19.5788 5.17157 18.8286C4.42143 18.0785 4 17.0611 4 16.0002V11.0002C4 9.93933 4.42143 8.92191 5.17157 8.17177C5.92172 7.42162 6.93913 7.0002 8 7.0002H15V4.6502C15 4.5202 15.0362 4.39277 15.1045 4.28218C15.1729 4.1716 15.2707 4.08223 15.387 4.0241C15.5032 3.96596 15.6334 3.94135 15.7629 3.95302C15.8923 3.9647 16.016 4.0122 16.12 4.0902L21.253 7.9402C21.3399 8.0054 21.4105 8.08995 21.4591 8.18715C21.5077 8.28435 21.533 8.39152 21.533 8.5002C21.533 8.60887 21.5077 8.71605 21.4591 8.81324C21.4105 8.91044 21.3399 8.99499 21.253 9.0602L16.12 12.9102C16.016 12.9882 15.8923 13.0357 15.7629 13.0474C15.6334 13.059 15.5032 13.0344 15.387 12.9763C15.2707 12.9182 15.1729 12.8288 15.1045 12.7182C15.0362 12.6076 15 12.4802 15 12.3502V10.0002Z"
      fill-rule="evenodd"
    />
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconArrowReturn',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-arrow-return_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.s-icon-arrow-return {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

}
</style>
