import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ba75600"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M18.2999 5.70002C18.1143 5.51438 17.8625 5.4101 17.5999 5.4101C17.3374 5.4101 17.0856 5.51438 16.8999 5.70002L11.9999 10.6L7.09992 5.70002C6.91363 5.51746 6.66282 5.41579 6.40199 5.41711C6.14116 5.41842 5.89139 5.52262 5.70696 5.70706C5.52252 5.8915 5.41832 6.14127 5.417 6.40209C5.41569 6.66292 5.51736 6.91373 5.69992 7.10002L10.5999 12L5.69992 16.9C5.51736 17.0863 5.41569 17.3371 5.417 17.598C5.41832 17.8588 5.52252 18.1086 5.70696 18.293C5.89139 18.4774 6.14116 18.5816 6.40199 18.5829C6.66282 18.5843 6.91363 18.4826 7.09992 18.3L11.9999 13.4L16.8999 18.3C17.0862 18.4826 17.337 18.5843 17.5978 18.5829C17.8587 18.5816 18.1084 18.4774 18.2929 18.293C18.4773 18.1086 18.5815 17.8588 18.5828 17.598C18.5842 17.3371 18.4825 17.0863 18.2999 16.9L13.3999 12L18.2999 7.10002C18.4856 6.91437 18.5898 6.66257 18.5898 6.40002C18.5898 6.13748 18.4856 5.88568 18.2999 5.70002V5.70002Z"
    }, null, 2)
  ]))
}