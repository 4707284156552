import ILocalStorageService from '@/app/Service/LocalStorage/Contract/ILocalStorageService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import ELocalStorageKeys from '@/app/Service/LocalStorage/Enum/ELocalStorageKeys';

class LocalStorageService implements ILocalStorageService {
  private readonly serviceContainer: IServiceContainer;

  constructor(
    serviceContainer: IServiceContainer,
  ) {
    this.serviceContainer = serviceContainer;
  }

  clear = (): void => {
    localStorage.clear();
  };

  remove = (key: ELocalStorageKeys): void => {
    localStorage.removeItem(key);
  };

  // TODO: решить вопрос с number значениями
  read = <Value>(key: ELocalStorageKeys): Value | undefined => {
    const { utilsService } = this.serviceContainer;

    const storageValue = localStorage.getItem(key);
    if (storageValue === 'null') {
      return null as unknown as Value;
    }

    if (storageValue === 'undefined') {
      return undefined;
    }

    if (utilsService.typeCheck.isNull(storageValue)) {
      return undefined as unknown as Value;
    }

    if (storageValue === 'true') {
      return true as unknown as Value;
    }

    if (storageValue === 'false') {
      return false as unknown as Value;
    }

    try {
      return utilsService.json.from<Value>(storageValue);
    } catch (e) {
      return storageValue as unknown as Value;
    }
  };

  write = <Value>(key: ELocalStorageKeys, value: Value): void => {
    const { utilsService } = this.serviceContainer;

    if (utilsService.typeCheck.isString(value)) {
      localStorage.setItem(key, value);
    } else if (utilsService.typeCheck.isNumber(value)) {
      localStorage.setItem(key, `${value}`);
    } else if (utilsService.typeCheck.isNull(value)) {
      localStorage.setItem(key, 'null');
    } else if (utilsService.typeCheck.isObject(value)) {
      localStorage.setItem(key, utilsService.json.to(value));
    } else if (utilsService.typeCheck.isArray(value)) {
      localStorage.setItem(key, utilsService.json.to(value));
    } else if (utilsService.typeCheck.isSet(value)) {
      localStorage.setItem(key, `${value}`);
    } else {
      localStorage.setItem(key, 'undefined');
    }
  };
}

export default LocalStorageService;
