import SButtonPropsInterface
  from '../../../lib/Model/Components/Simple/UI/SButtonPropsInterface';
import SIconPropsInterface from '../../../lib/Model/Components/Simple/Icon/SIconPropsInterface';
import SButtonVariantEnum
  from '../../../lib/Enum/Components/Simple/UI/SButtonVariantEnum';
import SizeEnum from '../../../lib/Enum/SizeEnum';
import FileMimeTypesEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileMimeTypesEnum';
import CFileUploadOptionsInterface from '../../../lib/Model/Components/Complex/CFileUpload/CFileUploadOptionsInterface';
import FileErrorMessagesType from '../../../lib/Model/Components/Complex/CFileUpload/FileErrorMessagesType';

export default function getFileUploadOptions(
  errorMessages: FileErrorMessagesType,
  button?: SButtonPropsInterface,
  icon?: SIconPropsInterface,
  description?: string,
  extensionsString?: string,
  allowedMimeTypes?: FileMimeTypesEnum[],
  maxFileSize?: number,
  maxFilesSize?: number,
  maxFileNameLength?: number,
  multiple = false,
): CFileUploadOptionsInterface {
  const buttonOption: SButtonPropsInterface = button ?? {
    variant: SButtonVariantEnum.TERTIARY,
    text: 'Text',
    size: SizeEnum.M,
  };

  const options = {
    multiple,
    button: buttonOption,
    maxFileSize: maxFileSize ?? 15,
    maxFilesSize: maxFilesSize ?? 100,
    errorMessages,
  } as CFileUploadOptionsInterface;

  if (icon) {
    options.icon = icon;
  }

  if (description) {
    options.description = description;
  }

  if (allowedMimeTypes) {
    options.allowedMimeTypes = allowedMimeTypes;
  }

  if (maxFileNameLength) {
    options.maxFileNameLength = maxFileNameLength;
  }

  if (extensionsString) {
    options.extensionsString = extensionsString;
  }

  return options;
}
