import { VueI18nTranslation } from 'vue-i18n';
import IServicesPage from '@/pages/HomePage/Contract/IServicesPage';
import IServiceItem from '@/pages/HomePage/Model/IServiceItem';
import EVendorPartnerProgramLevel from '@/entities/Enum/EVendorPartnerProgramLevel';
import IPartnerLevelInfo from '@/pages/HomePage/Model/IPartnerLevelInfo';
import { IconNameEnum } from '@/shared/DesignSystem';
import EServiceName from '@/pages/HomePage/Enum/EServiceName';
import EPriceListRoutePath from '@/app/Enum/router/Service/EPriceListRoutePath';
import EAboutRoutePath from '@/app/Enum/router/Service/EAboutRoutePath';
import EPortfolioRoutePath from '@/app/Enum/router/Service/EPortfolioRoutePath';
import EPromoRoutePath from '@/app/Enum/router/Service/EPromoRoutePath';
import ECoMarketingRoutePath from '@/app/Enum/router/Service/ECoMarketingRoutePath';
import EContactRoutePath from '@/app/Enum/router/Service/EContactRoutePath';

class ServicePage implements IServicesPage {
  private readonly t: VueI18nTranslation;

  constructor(
    t: VueI18nTranslation,
  ) {
    this.t = t;
  }

  getPartnerLevelInfo(partnerLevel: EVendorPartnerProgramLevel): IPartnerLevelInfo {
    const serviceList = this.getServiceList(partnerLevel);

    return {
      title: this.t(`pages.home.services.partner_program_level.${partnerLevel}.title`),
      text: this.t(`pages.home.services.partner_program_level.${partnerLevel}.text`),
      serviceList,
    };
  }

  getServiceList(partnerLevel: EVendorPartnerProgramLevel): IServiceItem[] {
    const serviceList: Record<EServiceName, IServiceItem> = {
      [EServiceName.ABOUT]: {
        chipsTitle: this.t('pages.home.services.service_list.about.chips_title'),
        title: this.t('pages.home.services.service_list.about.title'),
        text: this.t('pages.home.services.service_list.about.text'),
        icon: IconNameEnum.CODE,
        route: EAboutRoutePath.OVERVIEW,
      },
      [EServiceName.PRICE_LIST]: {
        chipsTitle: this.t('pages.home.services.service_list.pricelist.chips_title'),
        title: this.t('pages.home.services.service_list.pricelist.title'),
        text: this.t('pages.home.services.service_list.pricelist.text'),
        icon: IconNameEnum.BANK_CARD,
        route: EPriceListRoutePath.OVERVIEW,
      },
      [EServiceName.PORTFOLIO]: {
        chipsTitle: this.t('pages.home.services.service_list.portfolio.chips_title'),
        title: this.t('pages.home.services.service_list.portfolio.title'),
        text: this.t('pages.home.services.service_list.portfolio.text'),
        icon: IconNameEnum.INFO,
        route: EPortfolioRoutePath.OVERVIEW,
      },
      [EServiceName.PROMO]: {
        chipsTitle: this.t('pages.home.services.service_list.promo.chips_title'),
        title: this.t('pages.home.services.service_list.promo.title'),
        text: this.t('pages.home.services.service_list.promo.text'),
        icon: IconNameEnum.BINOCULARS,
        route: EPromoRoutePath.OVERVIEW,
      },
      [EServiceName.CO_MARKETING]: {
        chipsTitle: this.t('pages.home.services.service_list.co_marketing.chips_title'),
        title: this.t('pages.home.services.service_list.co_marketing.title'),
        text: this.t('pages.home.services.service_list.co_marketing.text'),
        icon: IconNameEnum.COUPON,
        route: ECoMarketingRoutePath.OVERVIEW,
      },
      [EServiceName.CONTACT]: {
        chipsTitle: this.t('pages.home.services.service_list.contact.chips_title'),
        title: this.t('pages.home.services.service_list.contact.title'),
        text: this.t('pages.home.services.service_list.contact.text'),
        icon: IconNameEnum.BOOK,
        route: EContactRoutePath.OVERVIEW,
      },
    };

    const serviceListMap = {
      [EVendorPartnerProgramLevel.AUTHORIZED]: [
        serviceList[EServiceName.ABOUT],
        serviceList[EServiceName.PRICE_LIST],
        serviceList[EServiceName.PORTFOLIO],
        serviceList[EServiceName.PROMO],
        // serviceList[EServiceName.CO_MARKETING],
        serviceList[EServiceName.CONTACT],
      ],
      [EVendorPartnerProgramLevel.FOCUSED]: [
        serviceList[EServiceName.ABOUT],
        serviceList[EServiceName.PRICE_LIST],
        serviceList[EServiceName.PORTFOLIO],
        serviceList[EServiceName.PROMO],
        // serviceList[EServiceName.CO_MARKETING],
        serviceList[EServiceName.CONTACT],
      ],
      [EVendorPartnerProgramLevel.KEY]: [
        serviceList[EServiceName.ABOUT],
        serviceList[EServiceName.PRICE_LIST],
        serviceList[EServiceName.PORTFOLIO],
        serviceList[EServiceName.PROMO],
        // serviceList[EServiceName.CO_MARKETING],
        serviceList[EServiceName.CONTACT],
      ],
      [EVendorPartnerProgramLevel.STRATEGIC]: [
        serviceList[EServiceName.ABOUT],
        serviceList[EServiceName.PRICE_LIST],
        serviceList[EServiceName.PORTFOLIO],
        serviceList[EServiceName.PROMO],
        // serviceList[EServiceName.CO_MARKETING],
        serviceList[EServiceName.CONTACT],
      ],
    };

    return serviceListMap[partnerLevel];
  }
}

export default ServicePage;
