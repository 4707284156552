<template>
  <div
    class="form-field"
  >
    <SLabel
      class="form-field__label"
    >
      <template
        v-if="label"
        #label
      >
        {{ label }}
      </template>
      <template
        #control="{ id }"
      >
        <slot
          :color="controlColor"
          :id="id"
        />
      </template>
    </SLabel>
    <STextElement
      v-if="description"
      variant="small"
      color="stroke"
    >
      {{ description }}
    </STextElement>
    <STextElement
      v-if="status.message"
      :color="statusCodeColor"
      variant="small"
    >
      {{ status.message }}
    </STextElement>
  </div>
</template>

<script
  lang="ts"
>
import { computed, defineComponent, PropType } from 'vue';
import { ColorEnum } from '@/shared/DesignSystem';
import { FieldStatusInterface, StatusCodeEnum } from '@/shared/Validator/index';

export default defineComponent({
  name: 'FormField',
  props: {
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    status: {
      type: Object as PropType<FieldStatusInterface>,
      default: () => ({ code: StatusCodeEnum.DEFAULT }),
    },
  },
  setup(props) {
    const statusCodeColor = computed<ColorEnum>(
      () => (props.status.code === StatusCodeEnum.ERROR ? ColorEnum.DANGER : ColorEnum.STROKE),
    );

    const controlColor = computed<ColorEnum>(
      () => (props.status.code === StatusCodeEnum.ERROR ? ColorEnum.DANGER : ColorEnum.PRIMARY),
    );

    return {
      statusCodeColor,
      controlColor,
    };
  },
});

</script>

<style
  scoped
  lang="scss"
>
.form-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
