import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77737f7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      "clip-rule": "evenodd",
      d: "M9.99967 14.0007H13.9997V20.0007H17.9997C18.2649 20.0007 18.5192 19.8954 18.7068 19.7079C18.8943 19.5203 18.9997 19.266 18.9997 19.0007V12.0007H20.6967C20.7979 12.0009 20.8968 11.9704 20.9804 11.9131C21.0639 11.8558 21.128 11.7746 21.1643 11.6801C21.2006 11.5855 21.2074 11.4822 21.1837 11.3838C21.16 11.2853 21.107 11.1964 21.0317 11.1287L12.3337 3.30175C12.2419 3.21938 12.123 3.17383 11.9997 3.17383C11.8764 3.17383 11.7574 3.21938 11.6657 3.30175L2.96767 11.1287C2.89234 11.1964 2.83932 11.2853 2.81563 11.3838C2.79195 11.4822 2.7987 11.5855 2.83502 11.6801C2.87133 11.7746 2.93548 11.8558 3.01899 11.9131C3.10249 11.9704 3.20142 12.0009 3.30267 12.0007H4.99967V19.0007C4.99967 19.266 5.10503 19.5203 5.29256 19.7079C5.4801 19.8954 5.73445 20.0007 5.99967 20.0007H9.99967V14.0007Z",
      "fill-rule": "evenodd"
    }, null, 2)
  ]))
}