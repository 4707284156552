import StringFieldBlurDataModelInterface
  from '@/widgets/FormComponent/lib/Model/StringFieldBlurDataModelInterface';
import FileUploadInputDataModelInterface
  from '@/widgets/FormComponent/lib/Model/FileUploadInputDataModelInterface';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import validateFiles from '@/shared/DesignSystem/Components/Complex/CFileUpload/validateFiles';
import fieldConfigInterface from '@/widgets/FormComponent/lib/Model/FieldConfigInterface';
import FileUploadFieldConfigInterface
  from '@/widgets/FormComponent/lib/Model/Fields/FileUploadFieldConfigInterface';
import FieldTypeEnum from '@/widgets/FormComponent/lib/Enum/FieldTypeEnum';
import {
  FileErrorTypeEnum,
  FileInterface,
  FileStatusEnum,
} from '@/shared/DesignSystem';
import IFormFieldHandler
  from '@/pages/Service/About/AboutFormPage/Contract/Handler/IFormFieldHandler';
import IHandlerService from '@/pages/Service/About/AboutFormPage/Contract/IHandlerService';
import IState from '@/pages/Service/About/AboutFormPage/Model/IState';
import IFormData from '@/pages/Service/About/AboutFormPage/Model/IFormData';
import EFieldName from '@/pages/Service/About/AboutFormPage/Enum/EFieldName';

class FormFieldHandler implements IFormFieldHandler {
  private state: IState;

  private readonly serviceContainer: IServiceContainer;

  private handlerService: IHandlerService;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
  }

  handleStringBlur = (event: FocusEvent, data: StringFieldBlurDataModelInterface): void => {
    this.state.formData = this.serviceContainer.utilsService.data.cloneImmutable(
      data.formData as unknown as IFormData,
    );

    this.handlerService.validationHandler.validateField(
      data.field.fieldName as EFieldName,
      this.state.formData,
    );
  }

  handleFileUploadInput = (data: FileUploadInputDataModelInterface): void => {
    const key = data.field.fieldName;

    if (!this.isFileUploadField(data.field) || key !== EFieldName.IMAGE) {
      return;
    }

    const {
      utilsService,
      apiService,
    } = this.serviceContainer;

    const files: FileInterface[] = data.value as unknown as FileInterface[];

    const formData = utilsService.data.cloneImmutable<IFormData>(
      data.formData as unknown as IFormData,
    );

    const successedFiles = files.filter((file) => file.status === 'success');
    const filesForValidate = files.filter((file) => file.status !== 'success');

    const successedFilesTotalSize = successedFiles.reduce(
      (totalSize: number, file) => totalSize + (file.file?.size ?? 0),
      0,
    );

    const validated = validateFiles(filesForValidate, data.field.config.options, successedFilesTotalSize);

    formData[key] = [...validated, ...successedFiles];

    this.state.formData = formData;

    validated.forEach((validatedFile) => {
      if (![FileStatusEnum.DEFAULT].includes(validatedFile.status)) {
        return;
      }
      const formData = utilsService.data.cloneImmutable(this.state.formData);

      formData[key] = formData[key]
        .map((file) => {
          if (file.fileName === validatedFile.fileName) {
            file.status = FileStatusEnum.LOADING;
          }

          return file;
        });

      this.state.formData = utilsService.data.cloneImmutable(formData);

      apiService.fileStorageApi.upload([validatedFile]).then((response) => {
        const formData = utilsService.data.cloneImmutable(this.state.formData);
        const fileIndex = formData[key]
          .findIndex((formFieldFile) => formFieldFile.fileName === validatedFile.fileName);

        const file = response[0];

        formData[key][fileIndex].id = file.s3ObjectKey;
        formData[key][fileIndex].status = FileStatusEnum.SUCCESS;

        this.state.documentFiles.push(file);

        this.state.formData = utilsService.data.cloneImmutable(formData);
      }).catch(() => {
        const formData = utilsService.data.cloneImmutable(this.state.formData);
        formData[key] = formData[key]
          .map((formFieldFile) => {
            if (formFieldFile.fileName === validatedFile.fileName) {
              formFieldFile.status = FileStatusEnum.ERROR;
              formFieldFile.errors = [
                {
                  type: FileErrorTypeEnum.LOAD,
                  message: this.state.t('validation_messages.file_load_error'),
                },
              ];
            }

            return formFieldFile;
          });

        this.state.formData = utilsService.data.cloneImmutable(formData);
      })
        .finally(() => {
          this.handlerService.validationHandler.validateField(
              data.field.fieldName as EFieldName,
              this.state.formData,
          );
        });
    });
  }

  handleDeleteFile = (data: FileUploadInputDataModelInterface): void => {
    const { apiService } = this.serviceContainer;
    const key = data.field.fieldName;

    if (
      !this.isFileUploadField(data.field)
      || key !== EFieldName.IMAGE
    ) {
      return;
    }

    const { utilsService } = this.serviceContainer;

    const formData = utilsService.data.cloneImmutable<IFormData>(
      data.formData as unknown as IFormData,
    );

    const file = data.value[0];

    if (!file) {
      return;
    }

    if (!file.id && file.status === FileStatusEnum.ERROR) {
      formData[key] = formData[key].filter((formDataFile) => formDataFile.fileName !== file.fileName);

      this.state.formData = formData;
      return;
    }

    apiService.fileStorageApi
      .delete({
        s3ObjectKey: `${file.id}`,
        fileName: file.fileName,
      })
      .finally(() => {
        formData[key] = formData[key].filter((formDataFile) => formDataFile.id !== file.id);

        this.state.documentFiles = this.state.documentFiles.filter(
          (stateFile) => stateFile.s3ObjectKey !== file.id,
        );

        this.state.formData = formData;
      });
  };

  handleDownloadFile = (data: FileUploadInputDataModelInterface): void => {
    const key = data.field.fieldName;

    if (
      !this.isFileUploadField(data.field)
      || key !== EFieldName.IMAGE
    ) {
      return;
    }

    const {
      utilsService,
      apiService,
    } = this.serviceContainer;

    const formData = utilsService.data.cloneImmutable<IFormData>(
      data.formData as unknown as IFormData,
    );

    const file = formData[key]?.[0];

    if (!file || !file.id) {
      return;
    }

    apiService.fileStorageApi.download({
      s3ObjectKey: `${file.id}`,
      fileName: file.fileName,
    });
  }

  private isFileUploadField(field: fieldConfigInterface): field is FileUploadFieldConfigInterface {
    return field.type === FieldTypeEnum.FILE_UPLOAD;
  }
}

export default FormFieldHandler;
