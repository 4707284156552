import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb368166"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "softline-logo-link",
  href: "https://softline.ru/",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SoftlineLogoIcon = _resolveComponent("SoftlineLogoIcon")!

  return (_openBlock(), _createElementBlock("a", _hoisted_1, [
    _createVNode(_component_SoftlineLogoIcon, { class: "softline-logo-link__img" })
  ]))
}