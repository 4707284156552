import ISSOLinkApi from '@/shared/Api/Contract/Api/ISSOLinkApi';
import IApiService from '@/shared/Api/Contract/IApiService';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import SSOLinkApi from '@/shared/Api/Api/SSOLinkApi';
import IAuthorizationApi from '@/shared/Api/Contract/Api/IAuthorizationApi';
import AuthorizationApi from '@/shared/Api/Api/AuthorizationApi';
import IFormDraftApi from '@/shared/Api/Contract/Api/IFormDraftApi';
import FormDraftApi from '@/shared/Api/Api/FormDraftApi';
import ITranslationApi from '@/app/providers/i18n/api/Contract/ITranslationApi';
import TranslationApi from '@/app/providers/i18n/api/TranslationApi';
import IVendorApi from '@/shared/Api/Contract/Api/IVendorApi';
import VendorApi from '@/shared/Api/Api/VendorApi';
import ScoringParamsApiInterface from '@/shared/Api/Contract/Api/ScoringParamsApiInterface';
import ScoringParamsApi from '@/shared/Api/Api/ScoringParamsApi';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import FileStorageApiInterface from '@/shared/Api/Contract/Api/FileStorageApiInterface';
import FileStorageApi from '@/shared/Api/Api/FileStorageApi';
import CompanyInfoApiInterface from '@/shared/Api/Contract/Api/CompanyInfoApiInterface';
import CompanyInfoApi from '@/shared/Api/Api/CompanyInfoApi';
import ScoringApiInterface from '@/shared/Api/Contract/Api/ScoringApiInterface';
import ScoringApi from '@/shared/Api/Api/ScoringApi';
import IPriceListApi from '@/shared/Api/Contract/Api/IPriceListApi';
import PriceListApi from '@/shared/Api/Api/PriceListApi';
import IAboutApi from '@/shared/Api/Contract/Api/IAboutApi';
import AboutApi from '@/shared/Api/Api/AboutApi';
import IDataManagerApi from '@/shared/Api/Contract/Api/IDataManagerApi';
import DataManagerApi from '@/shared/Api/Api/DataManagerApi';
import IPromotionApi from '@/shared/Api/Contract/Api/IPromotionApi';
import PromotionApi from '@/shared/Api/Api/PromotionApi';

class ApiService implements IApiService {
  private readonly client: IAxiosClientService;

  private readonly serviceContainer: IServiceContainer;

  private _aboutApi?: IAboutApi;

  private _dataManagerApi?: IDataManagerApi;

  private _ssoLinkApi?: ISSOLinkApi;

  private _authorizationApi?: IAuthorizationApi;

  private _formDraftApi?: IFormDraftApi;

  private _translation?: ITranslationApi

  private _vendorApi?: IVendorApi;

  private _scoringParamsApi?: ScoringParamsApiInterface;

  private _fileStorageApi?: FileStorageApiInterface;

  private _companyInfoApi?: CompanyInfoApiInterface;

  private _scoringApi?: ScoringApiInterface;

  private _priceListApi?: IPriceListApi;

  private _promotionApi?: IPromotionApi;

  constructor(
    client: IAxiosClientService,
    serviceContainer: IServiceContainer,
  ) {
    this.client = client;
    this.serviceContainer = serviceContainer;
  }

  get aboutApi(): IAboutApi {
    if (!this._aboutApi) {
      this._aboutApi = new AboutApi(this.client);
    }

    return this._aboutApi;
  }

  get dataManagerApi(): IDataManagerApi {
    if (!this._dataManagerApi) {
      this._dataManagerApi = new DataManagerApi(this.client);
    }

    return this._dataManagerApi;
  }

  get ssoLinkApi(): ISSOLinkApi {
    if (!this._ssoLinkApi) {
      this._ssoLinkApi = new SSOLinkApi(this.client);
    }

    return this._ssoLinkApi;
  }

  get authorizationApi(): IAuthorizationApi {
    if (!this._authorizationApi) {
      this._authorizationApi = new AuthorizationApi(this.client);
    }

    return this._authorizationApi;
  }

  get formDraftApi(): IFormDraftApi {
    if (!this._formDraftApi) {
      this._formDraftApi = new FormDraftApi(this.client);
    }

    return this._formDraftApi;
  }

  get translation(): ITranslationApi {
    if (!this._translation) {
      this._translation = new TranslationApi(this.client, this.serviceContainer);
    }

    return this._translation;
  }

  get vendorApi(): IVendorApi {
    if (!this._vendorApi) {
      this._vendorApi = new VendorApi(this.client);
    }

    return this._vendorApi;
  }

  get scoringParamsApi(): ScoringParamsApiInterface {
    if (!this._scoringParamsApi) {
      this._scoringParamsApi = new ScoringParamsApi(this.client);
    }

    return this._scoringParamsApi;
  }

  get fileStorageApi(): FileStorageApiInterface {
    if (!this._fileStorageApi) {
      this._fileStorageApi = new FileStorageApi(this.client, this.serviceContainer);
    }

    return this._fileStorageApi;
  }

  get companyInfoApi(): CompanyInfoApiInterface {
    if (!this._companyInfoApi) {
      this._companyInfoApi = new CompanyInfoApi(this.client);
    }

    return this._companyInfoApi;
  }

  get scoringApi(): ScoringApiInterface {
    if (!this._scoringApi) {
      this._scoringApi = new ScoringApi(this.client);
    }

    return this._scoringApi;
  }

  get priceListApi(): IPriceListApi {
    if (!this._priceListApi) {
      this._priceListApi = new PriceListApi(this.client);
    }

    return this._priceListApi;
  }

  get promotionApi(): IPromotionApi {
    if (!this._promotionApi) {
      this._promotionApi = new PromotionApi(this.client);
    }

    return this._promotionApi;
  }
}

export default ApiService;
