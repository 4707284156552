<template>
  <div
    :class="parentClasses"
    @click="handleParentClick"
  >
    <div
      v-if="hasBeforeSlot"
    >
      <slot
        name="before"
      />
    </div>
    <input
      class="s-text-input__field"
      ref="element"
      :id="id"
      :placeholder="placeholder"
      :readonly="readonly"
      type="text"
      v-model="state.value"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      @keydown="handleKeydown"
    />
    <div
      v-if="hasAfterSlot"
    >
      <slot
        name="after"
      />
    </div>
  </div>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';

import SizeEnum from '../../../lib/Enum/SizeEnum';
import ColorEnum from '../../../lib/Enum/ColorEnum';

export default defineComponent({
  name: 'STextInput',
  props: {
    size: {
      type: String as PropType<SizeEnum>,
      default: SizeEnum.M,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    color: {
      type: String as PropType<Exclude<ColorEnum, ColorEnum.TOOLBAR>>,
      default: ColorEnum.DISABLED,
    },
    inputDelay: {
      type: Number,
      default: null,
    },
    modelValue: {
      type: [String, Number] as PropType<string | number | undefined>,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit, slots }) {
    const state = reactive({
      value: '',
      delay: 0,
      isFocused: false,
      edited: false,
    });

    const element = ref<HTMLElement>();

    const parentClasses = computed(() => {
      let color: string = props.color as string;
      if (props.disabled || state.edited) {
        color = ColorEnum.DISABLED as string;
      }

      return [
        's-text-input',
        `s-text-input_size-${props.size}`,
        `s-text-input_color-${color}`,
        state.isFocused ? 's-text-input_focused' : null,
        props.borderless ? 's-text-input_borderless' : null,
        props.disabled ? 's-text-input_disabled' : null,
        props.readonly ? 's-text-input_readonly' : null,
      ];
    });

    const hasBeforeSlot = computed(() => !!slots.before);

    const hasAfterSlot = computed(() => !!slots.after);

    function handleParentClick() {
      element.value?.focus();
    }

    function handleInput() {
      if (props.inputDelay > 0) {
        clearTimeout(state.delay);
        state.delay = setTimeout(() => {
          emit('input', state.value);
          emit('update:modelValue', state.value);
        }, props.inputDelay);
      } else {
        emit('input', state.value);
        emit('update:modelValue', state.value);
      }
    }

    function handleBlur(event: FocusEvent) {
      state.isFocused = false;
      state.edited = false;

      emit('blur', event, state.value);
    }

    function handleFocus() {
      state.isFocused = true;
      state.edited = false;

      emit('focus');
    }

    function handleKeydown(event: InputEvent) {
      state.edited = true;

      emit('keydown', event);
    }

    watch(() => props.modelValue, (newValue) => {
      state.value = newValue as string;
    });

    onMounted(() => {
      const value = props.modelValue ?? '';

      state.value = `${value}`;
    });

    return {
      state,
      element,
      parentClasses,
      hasBeforeSlot,
      hasAfterSlot,
      handleParentClick,
      handleInput,
      handleBlur,
      handleFocus,
      handleKeydown,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-text-input {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border-width: var(--input-border-width);
  border-style: solid;
  -moz-border-radius: var(--text-input-border-radius-top-left)
    var(--text-input-border-radius-top-right)
    var(--text-input-border-radius-bottom-right)
    var(--text-input-border-radius-bottom-left);
  -webkit-border-radius: var(--text-input-border-radius-top-left)
    var(--text-input-border-radius-top-right)
    var(--text-input-border-radius-bottom-right)
    var(--text-input-border-radius-bottom-left);
  border-radius: var(--text-input-border-radius-top-left)
    var(--text-input-border-radius-top-right)
    var(--text-input-border-radius-bottom-right)
    var(--text-input-border-radius-bottom-left);

  &_disabled {
    pointer-events: none;
    border-color: var(--color-disabled);
  }

  &_size-s {
    height: var(--text-input-s-height);
    gap: var(--text-input-s-gap);
    padding: var(--text-input-s-padding-top)
      var(--text-input-s-padding-right)
      var(--text-input-s-padding-bottom)
      var(--text-input-s-padding-left);
  }

  &_size-m {
    height: var(--text-input-m-height);
    gap: var(--text-input-m-gap);
    padding: var(--text-input-m-padding-top)
      var(--text-input-m-padding-right)
      var(--text-input-m-padding-bottom)
      var(--text-input-m-padding-left);
  }

  &_size-l {
    height: var(--text-input-l-height);
    gap: var(--text-input-l-gap);
    padding: var(--text-input-l-padding-top)
      var(--text-input-l-padding-right)
      var(--text-input-l-padding-bottom)
      var(--text-input-l-padding-left);
  }

  &_color-disabled {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-disabled-focus);
    }
  }

  &_color-danger {
    border-color: var(--color-danger);

    &.s-text-input_focused {
      border-color: var(--color-danger-focus);
    }
  }
  &_color-gray {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-gray-focus);
    }
  }
  &_color-linked {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-linked-focus);
    }
  }
  &_color-navigation {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-navigation-focus);
    }
  }
  &_color-primary {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-primary-focus);
    }
  }
  &_color-secondary {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-secondary-focus);
    }
  }
  &_color-success {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-success-focus);
    }
  }
  &_color-warn {
    border-color: var(--color-warn);

    &.s-text-input_focused {
      border-color: var(--color-warn-focus);
    }
  }
  &_color-white {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-white-focus);
    }
  }
  &_color-black {
    border-color: var(--color-disabled);

    &.s-text-input_focused {
      border-color: var(--color-black-focus);
    }
  }

  &_borderless {
    border: none;
  }

  &_readonly,
  &_readonly &__field {
    cursor: pointer;
  }

  @mixin font-styles {
    font-family: var(--text-font-family);
    font-size: var(--text-variant-desktop-regular-size);
    line-height: var(--text-variant-desktop-regular-line-height);
    font-weight: var(--text-variant-desktop-regular-weight);
  }

  &__field {
    width: 100%;
    height: 100%;
    color: var(--color-black);
    border: none;
    outline: none;
    appearance: none;

    @include font-styles;

    &::-webkit-input-placeholder {
      @include font-styles;
      color: var(--color-disabled);
    }

    &::-ms-input-placeholder {
      @include font-styles;
      color: var(--color-disabled);
    }

    &::placeholder {
      @include font-styles;
      color: var(--color-disabled);
    }
  }
}
</style>
