import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0349f890"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id", "placeholder", "value"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_imask = _resolveDirective("imask")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.parentClasses),
    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleParentClick && _ctx.handleParentClick(...args)))
  }, [
    (_ctx.hasBeforeSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "before", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      ref: "element",
      placeholder: _ctx.placeholder,
      value: _ctx.state.value,
      class: "s-masked-input__field",
      type: "text",
      onAccept: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAccept && _ctx.handleAccept(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onComplete: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleComplete && _ctx.handleComplete(...args))),
      onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
      onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args)))
    }, null, 40, _hoisted_2), [
      [_directive_imask, _ctx.mappedMaskOptions]
    ]),
    (_ctx.hasAfterSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "after", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}