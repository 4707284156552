import IFileTool from '@/app/Service/UtilsService/Contract/IFileTool';

class FileTool implements IFileTool {
  changeFileName = (file: File, fileName: string): File => {
    const blob = file.slice(0, file.size, file.type);

    return new File([blob], fileName, { type: file.type });
  };

  getFileName = (fileName: string, fileNamesList: string[]): string => {
    if (!fileNamesList.includes(fileName)) {
      return fileName;
    }

    const name = this.extractFileName(fileName);
    const ext = this.extractFileExtension(fileName);

    const pattern = `^${name}\\s*(\\(\\d+\\))*.${ext}$`;
    const equalFileNames = fileNamesList.filter(
      (fileNameItem) => (new RegExp(pattern)).test(fileNameItem) && fileNameItem !== fileName,
    );

    if (equalFileNames.length === 1 && equalFileNames[0] === fileName) {
      return `${name} (1).${ext}`;
    }

    const maxNumber = equalFileNames.reduce((maxValue, fileNameItem) => {
      const number = fileNameItem
        .replace(`).${ext}`, '')
        .split('(')
        .pop();

      maxValue = maxValue > Number(number) ? maxValue : Number(number);

      return maxValue;
    }, 0);

    return `${name} (${maxNumber + 1}).${ext}`;
  };

  extractFileName = (fileName: string): string => {
    const parts: string[] = fileName.split('.');
    parts.pop();

    return parts.join('.');
  };

  extractFileExtension = (fileName: string): string => {
    if (fileName.indexOf('.') === -1) {
      return '';
    }

    const parts: string[] = fileName.split('.');

    if (parts.length === 2 && fileName.indexOf('.') === 0) {
      return '';
    }

    return parts.pop() as string;
  };

  fileExtensionToLowerCase = (file: File): File => {
    const name = this.extractFileName(file.name);
    const extension = this.extractFileExtension(file.name);

    return this.changeFileName(file, `${name}.${extension.toLowerCase()}`);
  };

  downloadFile(href: Blob | MediaSource, fileName: string): void {
    const url = URL.createObjectURL(href);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}

export default FileTool;
