enum SubheaderTextEnum {
  SIZE_DESKTOP = '--text-variant-desktop-subheader-size',
  LINE_HEIGHT_DESKTOP = '--text-variant-desktop-subheader-line-height',
  WEIGHT_DESKTOP = '--text-variant-desktop-subheader-weight',
  SIZE_MOBILE = '--text-variant-mobile-subheader-size',
  LINE_HEIGHT_MOBILE = '--text-variant-mobile-subheader-line-height',
  WEIGHT_MOBILE = '--text-variant-mobile-subheader-weight',
}

export default SubheaderTextEnum;
