<template>
  <a
    class="softline-logo-link"
    href="https://softline.ru/"
    target="_blank"
  >
    <SoftlineLogoIcon
      class="softline-logo-link__img"
    />
  </a>
</template>

<script
  lang="ts"
>
import {
  defineComponent,
} from 'vue';
import SoftlineLogoIcon from '@/shared/UI/Icon/SoftlineLogoIcon.vue';

export default defineComponent({
  components: {
    SoftlineLogoIcon,
  },
});
</script>

<style
  lang="scss"
  scoped
>
.softline-logo-link {
  display: block;

  &__img {
    display: block;
  }
}
</style>
