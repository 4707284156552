import FileInterface from '../../../lib/Model/Components/Complex/CFileUpload/FileInterface';
import CFileUploadOptionsInterface from '../../../lib/Model/Components/Complex/CFileUpload/CFileUploadOptionsInterface';
import FileMimeTypesEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileMimeTypesEnum';
import FileStatusEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileStatusEnum';
import FileErrorTypeEnum from '../../../lib/Enum/Components/Complex/CFileUpload/FileErrorTypeEnum';

function isValidMimeType(file: FileInterface, options: CFileUploadOptionsInterface): boolean {
  if (!file.file) {
    return true;
  }
  if (!options.allowedMimeTypes || options.allowedMimeTypes.length === 0) {
    return true;
  }

  return options.allowedMimeTypes.includes(file.file.type as FileMimeTypesEnum);
}

function validateFiles(
  files: FileInterface[],
  options: CFileUploadOptionsInterface,
  successedFilesTotalSize: number,
) {
  const maxFilesSize = options.maxFilesSize ?? 5;
  const maxFileSize = options.maxFileSize ?? 5;
  const maxFileNameLength = options.maxFileNameLength ?? 255;

  return files.map((file) => {
    if (!file.file || file.status !== FileStatusEnum.DEFAULT) {
      return file;
    }

    const fileSize = file.file.size;
    const totalFilesSize = fileSize + successedFilesTotalSize;

    if (options.multiple && totalFilesSize > maxFilesSize * 1000 * 1000) {
      file.status = FileStatusEnum.ERROR;
      file.errors.push({
        type: FileErrorTypeEnum.MAX_TOTAL_SIZE,
        message: options.errorMessages[FileErrorTypeEnum.MAX_TOTAL_SIZE],
      });
    }

    if (fileSize > maxFileSize * 1000 * 1000) {
      file.status = FileStatusEnum.ERROR;
      file.errors.push({
        type: FileErrorTypeEnum.MAX_SIZE,
        message: options.errorMessages[FileErrorTypeEnum.MAX_SIZE],
      });
    }
    if (fileSize === 0) {
      file.status = FileStatusEnum.ERROR;
      file.errors.push({
        type: FileErrorTypeEnum.NO_ZERO_SIZE,
        message: options.errorMessages[FileErrorTypeEnum.NO_ZERO_SIZE],
      });
    }
    if (file.fileName.length > maxFileNameLength) {
      file.status = FileStatusEnum.ERROR;
      file.errors.push({
        type: FileErrorTypeEnum.NAME,
        message: options.errorMessages[FileErrorTypeEnum.NAME],
      });
    }
    if (!isValidMimeType(file, options)) {
      file.status = FileStatusEnum.ERROR;
      file.errors.push({
        type: FileErrorTypeEnum.MIME_TYPE,
        message: options.errorMessages[FileErrorTypeEnum.MIME_TYPE],
      });
    }

    return file;
  });
}

export default validateFiles;
