import IStyleTool from '@/app/Service/UtilsService/Contract/IStyleTool';
import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';

class StyleTool implements IStyleTool {
  private readonly utilsService: IUtilsService;

  constructor(
    utilsService: IUtilsService,
  ) {
    this.utilsService = utilsService;
  }

  getComputedStyle(node: Element): CSSStyleDeclaration {
    return window.getComputedStyle(node);
  }

  clearUnit(val: string): number {
    const unitList = /(cm|mm|Q|in|pc|pt|px|em|ex|ch|rem|lh|rlh|vw|vh|vmin|vmax|vb|vi|svw|svh|lvw|lvh|dvw|dvh|%)/;

    return Number(this.utilsService.string.replace(val, unitList, ''));
  }
}

export default StyleTool;
