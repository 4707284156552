import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-144501c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setup-step__title" }
const _hoisted_2 = {
  key: 1,
  class: "setup-step__number"
}
const _hoisted_3 = {
  key: 0,
  class: "setup-step__list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SButton = _resolveComponent("SButton")!
  const _component_CInfoBlock = _resolveComponent("CInfoBlock")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.step.isComplete)
        ? (_openBlock(), _createBlock(_component_SIcon, {
            key: 0,
            color: "success",
            name: "checkCircled",
            size: "l"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_STextElement, { variant: "subheader" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.step.number), 1)
              ]),
              _: 1
            })
          ])),
      _createVNode(_component_STextElement, { variant: "medium" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.step.title), 1)
        ]),
        _: 1
      }),
      (_ctx.step.button && !_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_SButton, {
            key: 2,
            size: _ctx.step.button.size,
            text: _ctx.step.button.text,
            variant: _ctx.step.button.variant,
            class: "setup-step__button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickButton(_ctx.step)))
          }, null, 8, ["size", "text", "variant"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hasItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step.items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `step-${_ctx.step.number}-item-${index}`,
              class: "setup-step__item"
            }, [
              _createVNode(_component_SIcon, {
                color: _ctx.getItemIconColor(item),
                name: _ctx.getItemIconName(item),
                class: "setup-step__icon"
              }, null, 8, ["color", "name"]),
              _createVNode(_component_STextElement, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.step.info)
      ? (_openBlock(), _createBlock(_component_CInfoBlock, {
          key: 1,
          text: _ctx.step.info
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    (_ctx.step.button && _ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_SButton, {
          key: 2,
          size: _ctx.step.button.size,
          stretch: true,
          text: _ctx.step.button.text,
          variant: _ctx.step.button.variant,
          class: "setup-step__button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClickButton(_ctx.step)))
        }, null, 8, ["size", "text", "variant"]))
      : _createCommentVNode("", true)
  ], 2))
}