import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15218f6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M16.535 15.1195L20.707 19.2925C20.8892 19.4811 20.99 19.7337 20.9877 19.9959C20.9854 20.2581 20.8803 20.509 20.6948 20.6944C20.5094 20.8798 20.2586 20.9849 19.9964 20.9872C19.7342 20.9895 19.4816 20.8887 19.293 20.7065L15.121 16.5345C13.6561 17.6414 11.8258 18.1512 9.99956 17.961C8.17336 17.7708 6.48734 16.8949 5.28188 15.5099C4.07643 14.125 3.44134 12.3343 3.50483 10.4993C3.56832 8.66432 4.32567 6.92178 5.62397 5.62348C6.92227 4.32518 8.66481 3.56783 10.4998 3.50434C12.3348 3.44085 14.1255 4.07594 15.5104 5.28139C16.8953 6.48685 17.7713 8.17287 17.9615 9.99907C18.1517 11.8253 17.6419 13.6556 16.535 15.1205V15.1195ZM10.75 15.9995C12.1424 15.9995 13.4778 15.4464 14.4623 14.4619C15.4469 13.4773 16 12.1419 16 10.7495C16 9.35716 15.4469 8.0218 14.4623 7.03723C13.4778 6.05267 12.1424 5.49954 10.75 5.49954C9.35765 5.49954 8.02229 6.05267 7.03772 7.03723C6.05315 8.0218 5.50003 9.35716 5.50003 10.7495C5.50003 12.1419 6.05315 13.4773 7.03772 14.4619C8.02229 15.4464 9.35765 15.9995 10.75 15.9995Z"
    }, null, 2)
  ]))
}