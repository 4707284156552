import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-951e2d1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M6.30177 12.4816L10.7678 17.8416C10.8556 17.9419 10.9224 18.0588 10.9644 18.1854C11.0063 18.312 11.0225 18.4456 11.012 18.5786C11.0016 18.7115 10.9646 18.841 10.9033 18.9594C10.8421 19.0779 10.7578 19.1829 10.6553 19.2682C10.5529 19.3536 10.4344 19.4176 10.3069 19.4565C10.1793 19.4954 10.0453 19.5084 9.91266 19.4947C9.78002 19.4811 9.65145 19.441 9.53452 19.3769C9.41759 19.3128 9.31465 19.226 9.23177 19.1216L4.23177 13.1216C4.0821 12.9419 4.00015 12.7154 4.00015 12.4816C4.00015 12.2477 4.0821 12.0213 4.23177 11.8416L9.23177 5.84156C9.31465 5.73711 9.41759 5.65028 9.53452 5.5862C9.65145 5.52211 9.78002 5.48206 9.91265 5.46839C10.0453 5.45473 10.1793 5.46772 10.3069 5.50663C10.4344 5.54553 10.5529 5.60954 10.6553 5.6949C10.7578 5.78027 10.8421 5.88526 10.9033 6.00369C10.9646 6.12213 11.0016 6.25162 11.012 6.38455C11.0225 6.51748 11.0063 6.65116 10.9644 6.77773C10.9224 6.9043 10.8556 7.0212 10.7678 7.12156L6.30177 12.4816Z"
    }, null, 2),
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      d: "M14.8096 12.4816L19.2756 17.8416C19.3634 17.9419 19.4302 18.0588 19.4722 18.1854C19.5141 18.312 19.5303 18.4456 19.5199 18.5786C19.5094 18.7115 19.4724 18.841 19.4112 18.9594C19.3499 19.0779 19.2656 19.1829 19.1631 19.2682C19.0607 19.3536 18.9422 19.4176 18.8147 19.4565C18.6871 19.4954 18.5531 19.5084 18.4205 19.4947C18.2878 19.4811 18.1593 19.441 18.0423 19.3769C17.9254 19.3128 17.8225 19.226 17.7396 19.1216L12.7396 13.1216C12.5899 12.9419 12.508 12.7154 12.508 12.4816C12.508 12.2477 12.5899 12.0213 12.7396 11.8416L17.7396 5.84156C17.8225 5.73711 17.9254 5.65028 18.0423 5.5862C18.1593 5.52211 18.2878 5.48206 18.4205 5.46839C18.5531 5.45473 18.6871 5.46772 18.8147 5.50663C18.9422 5.54553 19.0607 5.60954 19.1631 5.6949C19.2656 5.78027 19.3499 5.88526 19.4112 6.00369C19.4724 6.12213 19.5094 6.25162 19.5199 6.38455C19.5303 6.51748 19.5141 6.65116 19.4722 6.77773C19.4302 6.9043 19.3634 7.0212 19.2756 7.12156L14.8096 12.4816Z"
    }, null, 2)
  ]))
}