enum MediumTextEnum {
  SIZE_DESKTOP = '--text-variant-desktop-medium-size',
  LINE_HEIGHT_DESKTOP = '--text-variant-desktop-medium-line-height',
  WEIGHT_DESKTOP = '--text-variant-desktop-medium-weight',
  SIZE_MOBILE = '--text-variant-mobile-medium-size',
  LINE_HEIGHT_MOBILE = '--text-variant-mobile-medium-line-height',
  WEIGHT_MOBILE = '--text-variant-mobile-medium-weight',
}

export default MediumTextEnum;
