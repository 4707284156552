<template>
  <div
    class="s-file-upload-area"
    @dragover.prevent
    @dragleave.prevent
    @drop.prevent="handleDrop"
  >
    <div
      v-if="hasBeforeSlot"
      class="s-file-upload-area__before"
    >
      <slot
        name="before"
      />
    </div>
    <div
      v-if="hasDefaultSlot"
      class="s-file-upload-area__content"
    >
      <slot />
    </div>
    <div
      v-if="hasAfterSlot"
      class="s-file-upload-area__after"
    >
      <slot
        name="after"
      />
    </div>
  </div>
  <input
    ref="element"
    type="file"
    :id="id"
    :multiple="multiple"
    :hidden="true"
    @change.prevent="handleInputChange"
  />
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

export default defineComponent({
  name: 'SFileUploadArea',
  props: {
    value: {
      type: Object as PropType<FileList>,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { slots, emit }) {
    const element = ref<HTMLInputElement>();

    const hasBeforeSlot = computed(() => !!slots.before);
    const hasAfterSlot = computed(() => !!slots.after);
    const hasDefaultSlot = computed(() => !!slots.default);

    function handleDrop(event: DragEvent) {
      event.preventDefault();
      const fileList = event.dataTransfer?.files ?? [];

      emit('file-input', Array.from(fileList));
    }

    function handleInputChange(event: Event) {
      const target = event.target as HTMLInputElement | undefined;

      if (!target) {
        return;
      }

      const fileList = Array.from(target.files ?? []);
      target.value = '';

      emit('file-input', fileList);
    }

    return {
      hasAfterSlot,
      hasBeforeSlot,
      hasDefaultSlot,
      handleDrop,
      handleInputChange,
      element,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-file-upload-area {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;
  border-radius: 8px;
  border: 1px dashed #abacac;
}
</style>
