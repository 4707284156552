import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39cef0e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CStatusWindow = _resolveComponent("CStatusWindow")!
  const _component_LayoutWithoutSidebar = _resolveComponent("LayoutWithoutSidebar")!

  return (_openBlock(), _createBlock(_component_LayoutWithoutSidebar, { width: "lg" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CStatusWindow, {
          subtitle: _ctx.statusWindowComponent.subtitle,
          title: _ctx.statusWindowComponent.title
        }, null, 8, ["subtitle", "title"])
      ])
    ]),
    _: 1
  }))
}