enum FileMimeTypesEnum {
  JPG = 'image/jpeg',
  JPEG = 'image/jpeg',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  PNG = 'image/png',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  GIF = 'image/gif',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PSD = 'image/vnd.adobe.photoshop',
  EPS = 'application/postscript',
  TIFF = 'image/tiff',
}

export default FileMimeTypesEnum;
