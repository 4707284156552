import IDataTool from '@/app/Service/UtilsService/Contract/IDataTool';
import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';

class DataTool implements IDataTool {
  private readonly utils: IUtilsService;

  constructor(utils: IUtilsService) {
    this.utils = utils;
  }

  isEmpty(value: unknown): boolean {
    if (this.utils.typeCheck.isArray(value)) {
      return this.utils.array.isEmpty(value as unknown[]);
    }

    if (this.utils.typeCheck.isObject(value)) {
      return this.utils.object.isEmpty(value as object);
    }

    return !value;
  }

  cloneImmutable = <T>(value: T): T => {
    if (this.utils.typeCheck.isArray(value)) {
      return value.map((item) => this.cloneImmutable(item)) as unknown as T;
    }
    if (this.utils.typeCheck.isObject(value)) {
      const fileType = value.constructor?.name;

      if (fileType !== 'Object') {
        return value as T;
      }

      const currentValue = value;
      return Object.keys(currentValue).reduce((data, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data[key] = this.cloneImmutable<unknown>(currentValue[key]);

        return data;
      }, {} as { [key in string]: unknown }) as T;
    }

    return value as unknown as T;
  };
}

export default DataTool;
