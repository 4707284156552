<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable vue/max-len max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 2H7C5.2536 2 4 3.30584 4 5V19C4 20.6942 5.2536 22 7 22H19C19.6418 22 20 21.6269 20 21V20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18H20V3C20 2.3731 19.6418 2 19 2Z"
      :class="classes"
    />
    <mask
      id="mask0_40_946"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="4"
      y="2"
      width="16"
      height="20"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 2H7C5.2536 2 4 3.30584 4 5V19C4 20.6942 5.2536 22 7 22H19C19.6418 22 20 21.6269 20 21V20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18H20V3C20 2.3731 19.6418 2 19 2Z"
        fill="white"
      />
    </mask>
    <g
      mask="url(#mask0_40_946)"
    ></g>
    <!-- eslint-enable vue/max-len max-len -->
  </svg>
</template>

<script
  lang="ts"
>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SIconBook',
  props: {
    color: {
      type: String,
    },
  },
  setup(props) {
    const classes = computed(() => [
      `s-icon-book_color-${props.color}`,
    ]);

    return {
      classes,
    };
  },
});
</script>

<style
  scoped
  lang="scss"
>
.s-icon-book {
  &_color-disabled {
    fill: var(--color-disabled);
  }

  &_color-danger {
    fill: var(--color-danger);
  }

  &_color-gray {
    fill: var(--color-gray);
  }

  &_color-linked {
    fill: var(--color-linked);
  }

  &_color-navigation {
    fill: var(--color-navigation);
  }

  &_color-primary {
    fill: var(--color-primary);
  }

  &_color-secondary {
    fill: var(--color-secondary);
  }

  &_color-success {
    fill: var(--color-success);
  }

  &_color-toolbar {
    fill: var(--color-toolbar);
  }

  &_color-warn {
    fill: var(--color-warn);
  }

  &_color-white {
    fill: var(--color-white);
  }

  &_color-black {
    fill: var(--color-black);
  }

  &_color-stroke {
    fill: var(--color-stroke);
  }
}
</style>
