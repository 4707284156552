import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66cbb560"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "100%",
  fill: "none",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      class: _normalizeClass(_ctx.classes),
      "clip-rule": "evenodd",
      d: "M13 11H19.5C19.7652 11 20.0196 11.1054 20.2071 11.2929C20.3946 11.4804 20.5 11.7348 20.5 12C20.5 12.2652 20.3946 12.5196 20.2071 12.7071C20.0196 12.8946 19.7652 13 19.5 13H13V19.5C13 19.7652 12.8946 20.0196 12.7071 20.2071C12.5196 20.3946 12.2652 20.5 12 20.5C11.7348 20.5 11.4804 20.3946 11.2929 20.2071C11.1054 20.0196 11 19.7652 11 19.5V13H4.5C4.23478 13 3.98043 12.8946 3.79289 12.7071C3.60536 12.5196 3.5 12.2652 3.5 12C3.5 11.7348 3.60536 11.4804 3.79289 11.2929C3.98043 11.1054 4.23478 11 4.5 11H11V4.5C11 4.23478 11.1054 3.98043 11.2929 3.79289C11.4804 3.60536 11.7348 3.5 12 3.5C12.2652 3.5 12.5196 3.60536 12.7071 3.79289C12.8946 3.98043 13 4.23478 13 4.5V11Z",
      "fill-rule": "evenodd"
    }, null, 2)
  ]))
}