import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bea4d31"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-text-input-list" }
const _hoisted_2 = { class: "c-text-input-list__list" }
const _hoisted_3 = { class: "c-text-input-list__input" }
const _hoisted_4 = {
  key: 0,
  class: "c-text-input-list__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_STextInput = _resolveComponent("STextInput")!
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _component_SButton = _resolveComponent("SButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "c-text-input-list__item"
        }, [
          _renderSlot(_ctx.$slots, "before-item", {
            index: index,
            item: item,
            status: _ctx.getStatus(item)
          }, undefined, true),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "before-input", {
              index: index,
              item: item,
              status: _ctx.getStatus(item)
            }, undefined, true),
            _createVNode(_component_STextInput, {
              "model-value": item.value,
              color: _ctx.getFieldColor(item),
              placeholder: _ctx.placeholder,
              "input-delay": _ctx.inputDelay,
              onBlur: ($event: any) => (_ctx.handleBlur($event, item))
            }, null, 8, ["model-value", "color", "placeholder", "input-delay", "onBlur"]),
            _renderSlot(_ctx.$slots, "after-input", {
              index: index,
              item: item,
              status: _ctx.getStatus(item)
            }, undefined, true),
            (_ctx.state.list.length > _ctx.minItems)
              ? (_openBlock(), _createBlock(_component_SIcon, {
                  key: 0,
                  class: "c-text-input-list__icon",
                  name: "trash",
                  size: "l",
                  onClick: ($event: any) => (_ctx.handleDeleteItem(item))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _renderSlot(_ctx.$slots, "after-item", {
            index: index,
            item: item,
            status: _ctx.getStatus(item)
          }, undefined, true),
          (_ctx.getStatusText(item))
            ? (_openBlock(), _createBlock(_component_STextElement, {
                key: 0,
                color: _ctx.getFieldColor(item),
                class: "c-text-input-list__comment",
                variant: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getStatusText(item)), 1)
                ]),
                _: 2
              }, 1032, ["color"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    (_ctx.addAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_SButton, {
            text: _ctx.buttonAdd.text,
            variant: _ctx.buttonAdd.variant,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.buttonAdd.handler()))
          }, {
            "icon-before": _withCtx(() => [
              _createVNode(_component_SIcon, {
                color: _ctx.buttonAdd.icon.color,
                name: _ctx.buttonAdd.icon.name
              }, null, 8, ["color", "name"])
            ]),
            _: 1
          }, 8, ["text", "variant"])
        ]))
      : _createCommentVNode("", true)
  ]))
}