const beforeMount = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      event.stopPropagation();

      if (!event.composedPath().includes(el)) {
        binding.value(event, el);
      }
    };
    document.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.removeEventListener('mousedown', el.clickOutsideEvent);
  },
};

export default beforeMount;
