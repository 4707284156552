import IValidatorPlugin from './Contract/IValidatorPlugin';
import IValidator from './Contract/IValidator';
import Validator from './Validator';
import lowerCaseProtocol from './Utils/lowerCaseProtocol';
import IFieldStatusHandler from './Contract/IFieldStatusHandler';
import EmptyFieldStatusHandler from './EmptyFieldStatusHandler';
import SimpleFieldStatusHandler from './SimpleFieldStatusHandler';

class ValidatorPlugin implements IValidatorPlugin {
  private emptyFieldStatusHandler?: IFieldStatusHandler;

  private simpleFieldStatusHandler?: IFieldStatusHandler;

  getValidator(): IValidator {
    return new Validator();
  }

  getEmptyFieldStatusHandler = (): IFieldStatusHandler => {
    if (!this.emptyFieldStatusHandler) {
      this.emptyFieldStatusHandler = new EmptyFieldStatusHandler();
    }

    return this.emptyFieldStatusHandler;
  };

  getSimpleFieldStatusHandler = (): IFieldStatusHandler => {
    if (!this.simpleFieldStatusHandler) {
      this.simpleFieldStatusHandler = new SimpleFieldStatusHandler();
    }

    return this.simpleFieldStatusHandler;
  };

  lowerCaseProtocol = (url: string): string => lowerCaseProtocol(url);
}

export default ValidatorPlugin;
