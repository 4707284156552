import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import { StatusCodeEnum } from '@/shared/Validator';
import IState from '@/pages/Service/Portfolio/PortfolioFormPage/Model/IState';
import IHandlerService from '@/pages/Service/Portfolio/PortfolioFormPage/Contract/IHandlerService';
import ISendDataHandler from '@/pages/Service/Portfolio/PortfolioFormPage/Contract/Handler/ISendDataHandler';
import IDataManagerRequestModel from '@/shared/Api/Model/Service/Portfolio/IDataManagerRequestModel';

class SendDataHandler implements ISendDataHandler {
  private state: IState;

  private readonly serviceContainer: IServiceContainer;

  private handlerService: IHandlerService;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
  }

  handle = async (): Promise<void> => {
    this.handlerService.validationHandler.validateFrom(this.state.formData);

    const isFormValid = Object
      .values(this.state.validationStatusRegistry)
      .filter((status) => status.code === StatusCodeEnum.ERROR).length === 0;

    if (!isFormValid) {
      return Promise.reject();
    }

    this.state.isLoading = true;

    const data = this.getData();

    try {
      await this.serviceContainer.apiService.dataManagerApi.create(data);
    } catch (e) {
      this.state.isLoading = false;

      return Promise.reject(e);
    }

    this.state.isLoading = false;

    return Promise.resolve();
  };

  private getData = (): IDataManagerRequestModel => {
    const formData = this.serviceContainer.utilsService.data.cloneImmutable(this.state.formData);
    const implementationCases = formData.implementationCases.map((item) => item.value);

    return {
      publicNameCompany: formData.publicNameCompany,
      keywords: formData.keywords,
      website: formData.website,
      whoCanBeReplaced: formData.whoCanBeReplaced,
      productDescriptionsFunctions: formData.productDescriptionsFunctions,
      listOfRegistries: formData.listOfRegistries,
      listOfCertificationSystems: formData.listOfCertificationSystems,
      directContacts: formData.directContacts,
      implementationCases: implementationCases.filter((item) => item.length > 0),
      comment: formData.comment,
      documents: this.state.documentFiles,
    };
  };
}

export default SendDataHandler;
