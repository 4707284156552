import ScoringParamsApiInterface from '@/shared/Api/Contract/Api/ScoringParamsApiInterface';
import IAxiosClientService from '@/app/Service/AxiosClientService/Contract/IAxiosClientService';
import ScoringParamsResponseModelInterface
  from '@/shared/Api/Model/Scoring/ScoringParamsResponseModelInterface';

class ScoringParamsApi implements ScoringParamsApiInterface {
  private client: IAxiosClientService;

  constructor(client: IAxiosClientService) {
    this.client = client;
  }

  get = (): Promise<ScoringParamsResponseModelInterface> => this.client.instance
    .get<ScoringParamsResponseModelInterface>('/scoring/parameters')
    .then((response) => response.data);
}

export default ScoringParamsApi;
