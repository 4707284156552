enum IconEnum {
  S_WIDTH = '--icon-s-width',
  S_HEIGHT = '--icon-s-height',
  M_WIDTH = '--icon-m-width',
  M_HEIGHT = '--icon-m-height',
  L_WIDTH = '--icon-l-width',
  L_HEIGHT = '--icon-l-height',
  XL_WIDTH = '--icon-xl-width',
  XL_HEIGHT = '--icon-xl-height',
  XXL_WIDTH = '--icon-xxl-width',
  XXL_HEIGHT = '--icon-xxl-height',
}

export default IconEnum;
