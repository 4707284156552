import {
  EValidatorType,
  IValidator,
  IValidatorPlugin,
  StatusCodeEnum,
} from '@/shared/Validator';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import FormBlockTypeEnum from '@/widgets/FormComponent/lib/Enum/FormBlockTypeEnum';
import IHandlerService from '@/pages/Service/Portfolio/PortfolioFormPage/Contract/IHandlerService';
import IState from '@/pages/Service/Portfolio/PortfolioFormPage/Model/IState';
import IValidationHandler from '@/pages/Service/Portfolio/PortfolioFormPage/Contract/Handler/IValidationHandler';
import EFieldName from '@/pages/Service/Portfolio/PortfolioFormPage/Enum/EFieldName';
import IFormData from '@/pages/Service/Portfolio/PortfolioFormPage/Model/IFormData';
import EValidationMessageTokens from '@/app/Enum/EValidationMessageTokens';
import {
  ColorEnum,
  CTextInputListItemInterface,
  CTextInputListItemStatusInterface,
} from '@/shared/DesignSystem';

class ValidationHandler implements IValidationHandler {
  private readonly state: IState;

  private readonly serviceContainer: IServiceContainer;

  private readonly handlerService: IHandlerService;

  private readonly validatorPlugin: IValidatorPlugin;

  constructor(
    state: IState,
    serviceContainer: IServiceContainer,
    handlerService: IHandlerService,
    validatorPlugin: IValidatorPlugin,
  ) {
    this.state = state;
    this.serviceContainer = serviceContainer;
    this.handlerService = handlerService;
    this.validatorPlugin = validatorPlugin;
  }

  validateField = (fieldName: EFieldName, formData: IFormData): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const validator = this.getValidator(fieldName);

    if (!validator) {
      return;
    }

    validator.validate(formData[fieldName], formData);

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    validationStatusRegistry[fieldName] = this.validatorPlugin.getEmptyFieldStatusHandler().getStatus(validator);

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = this.handlerService.formConfigHandler.get(this.state);
  };

  validateFrom = (formData: IFormData): void => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const validationStatusRegistry = this.serviceContainer.utilsService.data.cloneImmutable(
      this.state.validationStatusRegistry,
    );

    this.state.formConfig.forEach((block) => {
      if (block.type !== FormBlockTypeEnum.DELIMITER) {
        block.fields.forEach((field) => {
          const validator = this.getValidator(field.fieldName as EFieldName);

          if (!validator) {
            return;
          }

          if (field.conditionList) {
            if (!this.serviceContainer.utilsService.checkCondition.resolveAndCondition(field.conditionList, formData)) {
              validationStatusRegistry[field.fieldName as EFieldName] = {
                code: StatusCodeEnum.DEFAULT,
              };

              return;
            }
          }

          validator.validate(
            formData[field.fieldName as EFieldName],
            formData,
          );

          validationStatusRegistry[field.fieldName as EFieldName] = this.validatorPlugin
            .getSimpleFieldStatusHandler().getStatus(validator);
        });
      }
    });

    this.state.validationStatusRegistry = validationStatusRegistry;

    this.state.formConfig = this.handlerService.formConfigHandler.get(this.state);
  };

  private getValidator = (fieldName: EFieldName): IValidator | null => {
    if (!this.validatorPlugin) {
      throw new Error('Validator plugin not set. Call setValidatorPlugin method.');
    }

    const textInputMaxLength = 256;
    const textAreaMaxLength = 3000;

    const map: Partial<Record<EFieldName, IValidator>> = {};

    map[EFieldName.PUBLIC_NAME_COMPANY] = this.validatorPlugin.getValidator();
    map[EFieldName.PUBLIC_NAME_COMPANY]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.PUBLIC_NAME_COMPANY]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textInputMaxLength }),
      textInputMaxLength,
    );

    map[EFieldName.KEYWORDS] = this.validatorPlugin.getValidator();
    map[EFieldName.KEYWORDS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.KEYWORDS]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.WEBSITE] = this.validatorPlugin.getValidator();
    map[EFieldName.WEBSITE]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.WEBSITE]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textInputMaxLength }),
      textInputMaxLength,
    );
    map[EFieldName.WEBSITE]?.addValidator(
      EValidatorType.REGEX,
      this.state.t(EValidationMessageTokens.URL_FORMAT),
      '^.+\\..+$',
    );

    map[EFieldName.WHO_CAN_BE_REPLACED] = this.validatorPlugin.getValidator();
    map[EFieldName.WHO_CAN_BE_REPLACED]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.WHO_CAN_BE_REPLACED]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.PRODUCT_DESCRIPTIONS_FUNCTIONS] = this.validatorPlugin.getValidator();
    map[EFieldName.PRODUCT_DESCRIPTIONS_FUNCTIONS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.PRODUCT_DESCRIPTIONS_FUNCTIONS]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.LIST_OF_REGISTRIES] = this.validatorPlugin.getValidator();
    map[EFieldName.LIST_OF_REGISTRIES]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.LIST_OF_CERTIFICATION_SYSTEMS] = this.validatorPlugin.getValidator();
    map[EFieldName.LIST_OF_CERTIFICATION_SYSTEMS]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.DIRECT_CONTACTS] = this.validatorPlugin.getValidator();
    map[EFieldName.DIRECT_CONTACTS]?.addValidator(
      EValidatorType.NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      null,
    );
    map[EFieldName.DIRECT_CONTACTS]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    map[EFieldName.IMPLEMENTATION_CASES] = this.validatorPlugin.getValidator();
    map[EFieldName.IMPLEMENTATION_CASES]?.addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      this.state.t(EValidationMessageTokens.REQUIRED),
      (value: CTextInputListItemInterface[]) => value.length > 0,
    );
    map[EFieldName.IMPLEMENTATION_CASES]?.addValidator(
      EValidatorType.CUSTOM_NOT_EMPTY,
      '',
      (value: CTextInputListItemInterface[]) => {
        const statuses: CTextInputListItemStatusInterface[] = [];
        // eslint-disable-next-line prefer-regex-literals,max-len
        const regex = new RegExp('^.+\\..+$');
        let hasFilledValue = false;

        value.forEach((item) => {
          if (item.value.length !== 0) {
            hasFilledValue = true;
            if (item.value.length > textInputMaxLength) {
              statuses.push({
                id: item.id,
                color: ColorEnum.DANGER,
                text: this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textInputMaxLength }),
              });
            } else if (!regex.test(item.value)) {
              statuses.push({
                id: item.id,
                color: ColorEnum.DANGER,
                text: this.state.t(EValidationMessageTokens.URL_FORMAT),
              });
            }
          }
        });

        if (!hasFilledValue) {
          statuses.push({
            id: value[0].id ?? 0,
            color: ColorEnum.DANGER,
            text: this.state.t(EValidationMessageTokens.REQUIRED),
          });
        }

        this.state.implementationValidationStatusRegistry = statuses;
        this.state.formConfig = this.handlerService.formConfigHandler.get(this.state);

        return statuses.length === 0;
      },
    );

    map[EFieldName.COMMENT] = this.validatorPlugin.getValidator();
    map[EFieldName.COMMENT]?.addValidator(
      EValidatorType.MAX_LENGTH,
      this.state.t(EValidationMessageTokens.MAX_LENGTH, { number: textAreaMaxLength }),
      textAreaMaxLength,
    );

    return map[fieldName] ?? null;
  };
}

export default ValidationHandler;
