import IArrayTool from '@/app/Service/UtilsService/Contract/IArrayTool';
import IUtilsService from '@/app/Service/UtilsService/Contract/IUtilsService';
import ITypeCheckTool from '@/app/Service/UtilsService/Contract/ITypeCheckTool';
import IComparatorTool from '@/app/Service/UtilsService/Contract/IComparatorTool';
import IDataTool from '@/app/Service/UtilsService/Contract/IDataTool';
import IStringTool from '@/app/Service/UtilsService/Contract/IStringTool';
import IClipboardTool from '@/app/Service/UtilsService/Contract/IClipboardTool';
import IJsonTool from '@/app/Service/UtilsService/Contract/IJsonTool';
import IObjectTool from '@/app/Service/UtilsService/Contract/IObjectTool';
import TypeCheckTool from '@/app/Service/UtilsService/TypeCheckTool';
import ArrayTool from '@/app/Service/UtilsService/ArrayTool';
import ComparatorTool from '@/app/Service/UtilsService/ComparatorTool';
import DataTool from '@/app/Service/UtilsService/DataTool';
import StringTool from '@/app/Service/UtilsService/StringTool';
import ClipboardTool from '@/app/Service/UtilsService/ClipboardTool';
import JsonTool from '@/app/Service/UtilsService/JsonTool';
import ObjectTool from '@/app/Service/UtilsService/ObjectTool';
import IFileTool from '@/app/Service/UtilsService/Contract/IFileTool';
import FileTool from '@/app/Service/UtilsService/FileTool';
import CheckConditionTool from '@/widgets/FormComponent/lib/CheckConditionTool';
import ICheckConditionTool from '@/widgets/FormComponent/lib/Contract/ICheckConditionTool';
import IStyleTool from '@/app/Service/UtilsService/Contract/IStyleTool';
import StyleTool from '@/app/Service/UtilsService/StyleTool';
import IDateTool from '@/app/Service/UtilsService/Contract/IDateTool';
import DateTool from '@/app/Service/UtilsService/DateTool';

class UtilsService implements IUtilsService {
  private _checkCondition?: ICheckConditionTool;

  get array(): IArrayTool {
    return new ArrayTool(this);
  }

  get typeCheck(): ITypeCheckTool {
    return new TypeCheckTool();
  }

  get comparator(): IComparatorTool {
    return new ComparatorTool(this);
  }

  get data(): IDataTool {
    return new DataTool(this);
  }

  get date(): IDateTool {
    return new DateTool(this);
  }

  get string(): IStringTool {
    return new StringTool();
  }

  get clipboard(): IClipboardTool {
    return new ClipboardTool();
  }

  get json(): IJsonTool {
    return new JsonTool(this);
  }

  get object(): IObjectTool {
    return new ObjectTool(this);
  }

  get file(): IFileTool {
    return new FileTool();
  }

  get style(): IStyleTool {
    return new StyleTool(this);
  }

  get checkCondition(): ICheckConditionTool {
    if (!this._checkCondition) {
      this._checkCondition = new CheckConditionTool(this);
    }

    return this._checkCondition;
  }
}

export default UtilsService;
