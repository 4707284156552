import { createI18n, I18n } from 'vue-i18n';
import ILocalisationInit from '@/app/providers/i18n/Contract/ILocalisationInit';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import TLocalisation from '@/app/providers/i18n/TLocalisation';
import ELocale from '@/app/Enum/ELocale';
import ELocalStorageKeys from '@/app/Service/LocalStorage/Enum/ELocalStorageKeys';
import ILocalisation from '@/app/providers/i18n/ILocalisation';

class LocalisationInit implements ILocalisationInit {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  init = async (): Promise<I18n> => {
    const messages = await this.loadLocaleMessages();

    return Promise.resolve(this.getLocalisationEngine(messages));
  };

  private loadLocaleMessages = (): Promise<TLocalisation> => {
    const translationApi = this.serviceContainer.apiService.translation;

    const localisationRequests = [
      translationApi.list(ELocale.EN),
      translationApi.list(ELocale.RU),
    ];

    return Promise.all(localisationRequests)
      .then((responses) => ({
        en: responses[0],
        ru: responses[1],
      }));
  };

  private getLocalisationEngine = (
    messages: TLocalisation,
  ) => createI18n<[ILocalisation], ELocale>({
    legacy: false,
    locale: this.getLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || ELocale.RU,
    messages,
    pluralizationRules: {
      ru(choice: number, choicesLength: number) {
        if (choice === 0) {
          return 0;
        }

        const teen = choice > 10 && choice < 20;
        const endsWithOne = choice % 10 === 1;

        if (choicesLength < 4) {
          return (!teen && endsWithOne) ? 1 : 2;
        }
        if (!teen && endsWithOne) {
          return 1;
        }
        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
          return 2;
        }

        return (choicesLength < 4) ? 2 : 3;
      },
    },
  });

  private getLocale = (): ELocale => {
    const { localStorageService, store } = this.serviceContainer;

    const localStorageLocale = localStorageService.read(ELocalStorageKeys.LOCALE) as ELocale;
    const storeLocale = store.getters.getLocale;

    if (localStorageLocale) {
      return localStorageLocale;
    }

    if (storeLocale) {
      localStorageService.write(ELocalStorageKeys.LOCALE, storeLocale);

      return storeLocale;
    }

    store.dispatch('updateLocale', ELocale.RU);
    localStorageService.write(ELocalStorageKeys.LOCALE, ELocale.RU);

    return ELocale.RU;
  }
}

export default LocalisationInit;
