import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f841b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-menu-dropdown" }
const _hoisted_2 = ["onClick", "onMouseenter", "onMouseleave", "onFocus", "onBlur"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SIcon = _resolveComponent("SIcon")!
  const _component_SLink = _resolveComponent("SLink")!
  const _component_STextElement = _resolveComponent("STextElement")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "toggler",
      class: "c-menu-dropdown__toggler",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
      onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
      onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
      onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
      onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 544),
    (_ctx.state.isOpenFullModal || _ctx.state.isOpenHoverModal)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.modalClasses)
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.triangleStyle),
            class: "c-menu-dropdown__triangle"
          }, null, 4),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentMenuList, (list, listIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `menu-block-${listIndex}`,
              class: _normalizeClass(_ctx.listClasses(listIndex))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list, (item) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (item.type === 'link' && _ctx.isLink(item))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: `menu-${item.title}`,
                        class: "c-menu-dropdown__item c-menu-dropdown__item_link",
                        onClick: ($event: any) => (_ctx.handleClickLinkItem($event, item)),
                        onMouseenter: ($event: any) => (_ctx.handleItemMouseEnter(item)),
                        onMouseleave: ($event: any) => (_ctx.handleItemMouseLeave(item)),
                        onFocus: ($event: any) => (_ctx.handleItemMouseEnter(item)),
                        onBlur: ($event: any) => (_ctx.handleItemMouseLeave(item))
                      }, [
                        _createVNode(_component_SLink, {
                          download: item.download,
                          "is-router-link": _ctx.getIsRouterLink(item),
                          target: item.target,
                          title: item.title,
                          url: item.url,
                          variant: _ctx.getTitleVariant(item),
                          class: "c-menu-dropdown__text",
                          color: "white"
                        }, _createSlots({ _: 2 }, [
                          (item.iconName)
                            ? {
                                name: "icon-before",
                                fn: _withCtx(() => [
                                  _createVNode(_component_SIcon, {
                                    color: _ctx.getItemIconColor(item),
                                    name: item.iconName,
                                    size: "xs"
                                  }, null, 8, ["color", "name"])
                                ]),
                                key: "0"
                              }
                            : undefined
                        ]), 1032, ["download", "is-router-link", "target", "title", "url", "variant"])
                      ], 40, _hoisted_2))
                    : (item.type === 'email'  && _ctx.isEmail(item))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: `menu-${item.title}`,
                          class: "c-menu-dropdown__item"
                        }, [
                          _createVNode(_component_STextElement, {
                            variant: _ctx.getTitleVariant(item),
                            class: "c-menu-dropdown__title",
                            color: "white"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["variant"]),
                          _createVNode(_component_SLink, {
                            "is-router-link": false,
                            title: item.text,
                            url: item.url,
                            variant: _ctx.getTextVariant(item),
                            class: "c-menu-dropdown__text"
                          }, _createSlots({ _: 2 }, [
                            (item.iconName)
                              ? {
                                  name: "icon-before",
                                  fn: _withCtx(() => [
                                    _createVNode(_component_SIcon, {
                                      color: _ctx.getItemIconColor(item),
                                      name: item.iconName,
                                      size: "xs"
                                    }, null, 8, ["color", "name"])
                                  ]),
                                  key: "0"
                                }
                              : undefined
                          ]), 1032, ["title", "url", "variant"])
                        ]))
                      : (item.type === 'text' && _ctx.isText(item))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: `menu-${item.title}`,
                            class: "c-menu-dropdown__item"
                          }, [
                            _createVNode(_component_STextElement, {
                              variant: _ctx.getTitleVariant(item),
                              class: "c-menu-dropdown__title",
                              color: "white"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["variant"]),
                            (item.text)
                              ? (_openBlock(), _createBlock(_component_STextElement, {
                                  key: 0,
                                  variant: _ctx.getTextVariant(item),
                                  class: "c-menu-dropdown__text",
                                  color: "white"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.text), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["variant"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ], 2))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.handleClickOutside]
  ])
}