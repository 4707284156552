import IStringTool from '@/app/Service/UtilsService/Contract/IStringTool';

class StringTool implements IStringTool {
  replace = (value: string, search: string, replace: string): string => value.replace(search, replace);

  replaceAll = (value: string, search: string, replace: string): string => value.replaceAll(search, replace);

  replaceLast = (value: string, search: string, replace: string): string => {
    const parts = value.split(search);
    if (parts.length <= 1) {
      return value;
    }

    const lastPart = parts.pop();
    return `${parts.join(search)}${replace}${lastPart}`;
  };

  ucFirst = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

  snakeToCamelCase = (value: string): string => {
    const parts = value.split('_');
    return parts
      .map((part, index) => {
        if (index !== 0) {
          return this.ucFirst(part);
        }

        return part;
      })
      .join('');
  };
}

export default StringTool;
