import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from 'vue-router';
import {
  VueI18nTranslation,
} from 'vue-i18n';
import {
  nextTick,
} from 'vue';
import {
  Store,
} from 'vuex';

import {
  IconNameEnum,
} from '@/shared/DesignSystem';

import EAboutRouteName from '@/app/Enum/router/Service/EAboutRouteName';
import EAboutRoutePath from '@/app/Enum/router/Service/EAboutRoutePath';
import EAuthRouteName from '@/app/Enum/router/EAuthRouteName';
import EAuthRoutePath from '@/app/Enum/router/EAuthRoutePath';
import ECoMarketingRouteName from '@/app/Enum/router/Service/ECoMarketingRouteName';
import ECoMarketingRoutePath from '@/app/Enum/router/Service/ECoMarketingRoutePath';
import EContactRouteName from '@/app/Enum/router/Service/EContactRouteName';
import EContactRoutePath from '@/app/Enum/router/Service/EContactRoutePath';
import EDefaultRouteName from '@/app/Enum/router/EDefaultRouteName';
import EDefaultRoutePath from '@/app/Enum/router/EDefaultRoutePath';
import EPortfolioRouteName from '@/app/Enum/router/Service/EPortfolioRouteName';
import EPortfolioRoutePath from '@/app/Enum/router/Service/EPortfolioRoutePath';
import EPriceListRouteName from '@/app/Enum/router/Service/EPriceListRouteName';
import EPriceListRoutePath from '@/app/Enum/router/Service/EPriceListRoutePath';
import EPromoRouteName from '@/app/Enum/router/Service/EPromoRouteName';
import EPromoRoutePath from '@/app/Enum/router/Service/EPromoRoutePath';
import EVendorRouteName from '@/app/Enum/router/EVendorRouteName';
import EVendorRoutePath from '@/app/Enum/router/EVendorRoutePath';
import EVendorStatus from '@/entities/Enum/EVendorStatus';

import IAuthorizationService from '@/app/Service/Authorization/Contract/IAuthorizationService';
import IServiceContainer from '@/shared/ServiceContainer/Contract/IServiceContainer';
import IVendorModel from '@/entities/Model/IVendorModel';

import AboutFormPage from '@/pages/Service/About/AboutFormPage/AboutFormPage.vue';
import AboutPage from '@/pages/Service/About/AboutPage/AboutPage.vue';
import CoMarketingFormPage from '@/pages/Service/CoMarketing/CoMarketingFormPage/CoMarketingFormPage.vue';
import CoMarketingPage from '@/pages/Service/CoMarketing/CoMarketingPage/CoMarketingPage.vue';
import ContactFormPage from '@/pages/Service/Contact/ContactFormPage/ContactFormPage.vue';
import ContactPage from '@/pages/Service/Contact/ContactPage/ContactPage.vue';
import CooperationRefusedPage from '@/pages/CooperationRefusedPage.vue';
import HomePage from '@/pages/HomePage/HomePage.vue';
import NotFoundPage from '@/pages/NotFoundPage.vue';
import PortfolioFormPage from '@/pages/Service/Portfolio/PortfolioFormPage/PortfolioFormPage.vue';
import PortfolioPage from '@/pages/Service/Portfolio/PortfolioPage/PortfolioPage.vue';
import PricelistFormPage from '@/pages/Service/PriceList/PriceListFormPage/PriceListFormPage.vue';
import PricelistPage from '@/pages/Service/PriceList/PriceListPage/PriceListPage.vue';
import PromoFormPage from '@/pages/Service/Promo/PromoFormPage/PromoFormPage.vue';
import PromoPage from '@/pages/Service/Promo/PromoPage/PromoPage.vue';
import ScoringPage from '@/pages/ScoringFormPage/ScoringFormPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: EVendorRoutePath.SCORING,
    name: EVendorRouteName.SCORING,
    component: ScoringPage,
    meta: {
      title: 'meta.titles.scoring.form',
      showInSidebar: false,
    },
  },
  {
    path: EAuthRoutePath.SSO_AUTH,
    name: EAuthRouteName.SSO_AUTH,
  } as RouteRecordRaw,
  {
    path: EAuthRoutePath.ADMIN_LOGIN,
    name: EAuthRouteName.ADMIN_LOGIN,
  } as RouteRecordRaw,
  {
    path: EDefaultRoutePath.HOME,
    name: EDefaultRouteName.HOME,
    component: HomePage,
    meta: {
      title: 'meta.titles.home',
      icon: IconNameEnum.SERVICES,
      showInSidebar: true,
      menuOrder: 1,
    },
  },
  {
    path: EAboutRoutePath.OVERVIEW,
    name: EAboutRouteName.OVERVIEW,
    component: AboutPage,
    meta: {
      title: 'meta.titles.about.overview',
      icon: IconNameEnum.CODE,
      showInSidebar: true,
      menuOrder: 2,
    },
  },
  {
    path: EAboutRoutePath.FORM,
    name: EAboutRouteName.FORM,
    component: AboutFormPage,
    meta: {
      title: 'meta.titles.about.form',
      showInSidebar: false,
    },
  },
  {
    path: EPriceListRoutePath.OVERVIEW,
    name: EPriceListRouteName.OVERVIEW,
    component: PricelistPage,
    meta: {
      title: 'meta.titles.pricelist.overview',
      icon: IconNameEnum.BANK_CARD,
      showInSidebar: true,
      menuOrder: 3,
    },
  },
  {
    path: EPriceListRoutePath.FORM,
    name: EPriceListRouteName.FORM,
    component: PricelistFormPage,
    meta: {
      title: 'meta.titles.pricelist.form',
      showInSidebar: false,
    },
  },
  {
    path: EPortfolioRoutePath.OVERVIEW,
    name: EPortfolioRouteName.OVERVIEW,
    component: PortfolioPage,
    meta: {
      title: 'meta.titles.portfolio.overview',
      icon: IconNameEnum.INFO,
      showInSidebar: true,
      menuOrder: 4,
    },
  },
  {
    path: EPortfolioRoutePath.FORM,
    name: EPortfolioRouteName.FORM,
    component: PortfolioFormPage,
    meta: {
      title: 'meta.titles.portfolio.form',
      showInSidebar: false,
    },
  },
  {
    path: EPromoRoutePath.OVERVIEW,
    name: EPromoRouteName.OVERVIEW,
    component: PromoPage,
    meta: {
      title: 'meta.titles.promo.overview',
      icon: IconNameEnum.BINOCULARS,
      showInSidebar: true,
      menuOrder: 5,
    },
  },
  {
    path: EPromoRoutePath.FORM,
    name: EPromoRouteName.FORM,
    component: PromoFormPage,
    meta: {
      title: 'meta.titles.promo.form',
      showInSidebar: false,
    },
  },
  {
    path: ECoMarketingRoutePath.OVERVIEW,
    name: ECoMarketingRouteName.OVERVIEW,
    component: CoMarketingPage,
    meta: {
      title: 'meta.titles.co_marketing.overview',
      icon: IconNameEnum.COUPON,
      showInSidebar: false,
      menuOrder: 6,
    },
  },
  {
    path: ECoMarketingRoutePath.FORM,
    name: ECoMarketingRouteName.FORM,
    component: CoMarketingFormPage,
    meta: {
      title: 'meta.titles.co_marketing.form',
      showInSidebar: false,
    },
  },
  {
    path: EContactRoutePath.OVERVIEW,
    name: EContactRouteName.OVERVIEW,
    component: ContactPage,
    meta: {
      title: 'meta.titles.contact.overview',
      icon: IconNameEnum.BOOK,
      showInSidebar: true,
      menuOrder: 7,
    },
  },
  {
    path: EContactRoutePath.FORM,
    name: EContactRouteName.FORM,
    component: ContactFormPage,
    meta: {
      title: 'meta.titles.contact.form',
      showInSidebar: false,
    },
  },
  {
    path: EDefaultRoutePath.NOT_FOUND_PAGE,
    name: EDefaultRouteName.NOT_FOUND_PAGE,
    component: NotFoundPage,
    meta: {
      title: 'meta.titles.not_found',
    },
  },
  {
    path: EDefaultRoutePath.COOPERATION_REFUSED,
    name: EDefaultRouteName.COOPERATION_REFUSED,
    component: CooperationRefusedPage,
    meta: {
      title: 'meta.titles.cooperation_refused',
    },
  },
];

const processSSOAuthorization = async (
  authorizationService: IAuthorizationService,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  await authorizationService.auth(to.query.code as number | string);

  return next({ name: EDefaultRouteName.HOME });
};

const processAdminAuthorization = async (
  authorizationService: IAuthorizationService,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  await authorizationService.adminAuth(to.query.token as number | string);

  return next({ name: EDefaultRouteName.HOME });
};

const processUserRouting = async (
  store: Store<unknown>,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  await store.dispatch('refreshInfo');
  const vendor: IVendorModel = store.getters.getVendor;

  if (vendor.status === EVendorStatus.SCORING_REJECTED) {
    if (to.path === EDefaultRoutePath.COOPERATION_REFUSED) {
      return next();
    }

    return next({ name: EDefaultRouteName.COOPERATION_REFUSED });
  }

  if (to.path === EDefaultRoutePath.COOPERATION_REFUSED) {
    return next({ name: EDefaultRouteName.HOME });
  }

  return next();
};

const setPageTitle = async (
  t: VueI18nTranslation,
  to: RouteLocationNormalized,
) => {
  let pageTitle = '';

  if (to.meta && to.meta.title && typeof to.meta.title === 'string') {
    pageTitle = t(to.meta.title);
  }

  await nextTick(() => {
    document.title = pageTitle;
  });
};

export const getRouter = (serviceContainer: IServiceContainer, t: VueI18nTranslation): Router => {
  const router = createRouter({
    scrollBehavior() {
      return { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    const {
      authorizationService,
      store,
    } = serviceContainer;

    if (to.path === EAuthRoutePath.SSO_AUTH) {
      return processSSOAuthorization(authorizationService, to, next);
    }

    if (to.path === EAuthRoutePath.ADMIN_LOGIN) {
      return processAdminAuthorization(authorizationService, to, next);
    }

    const isAuthToken = authorizationService.isAuthorised();

    if (isAuthToken) {
      return processUserRouting(store, to, next);
    }

    return authorizationService.goToSSO();
  });

  router.afterEach(async (to) => {
    await setPageTitle(t, to);
  });

  return router;
};
